import { createAction, props } from '@ngrx/store';
import { airItineraries, FareRules, flight, FlightSearchResult, flightOfflineService, PassengersModule, Selectedflight } from '@src/app/interface/flight-search-result';
import { FilterInputsModule } from '@src/app/models/filter-inputs/filter-inputs.module';
import { SearchFlightModule } from '@src/app/models/search-flight/search-flight.module';

export const loadFlightSearchs = createAction(
  '[FlightSearch] Load FlightSearchs',
  props<{search: SearchFlightModule}>()
);

export const loadFlightSearchsSuccess = createAction(
  '[FlightSearch] Load FlightSearchs Success',
  props<{ data: FlightSearchResult }>()
);

export const loadFlightSearchsFailure = createAction(
  '[FlightSearch] Load FlightSearchs Failure',
  props<{err:any}>()
);


export const loadSelectedFlight = createAction(
  '[SelectedFlight] Load SelectedFlight',
  props<{searchid: string, sequenceNum: number, providerKey: string,pcc:string}>()
);

export const loadSelectedFlightSuccess = createAction(
  '[SelectedFlight] Load SelectedFlight Success',
  props<{ data: Selectedflight }>()
);

export const loadSelectedFlightFailure = createAction(
  '[SelectedFlight] Load SelectedFlight Failure',
  props<{err:any}>()
);
export const updateTotalAmount = createAction(
  '[selectedFlight] Update selectedFlightTotalAmount',
  props<{totalAmount:number}>()
);
// select ReturnFlight by select Departflight
// export const loadSelectedReturnFlight = createAction(
//   '[SelectedReturnFlight] Load SelectedReturnFlight',
//   props<{flightNumber: number, airlineCode: any}>()
// );

// export const loadSelectedReturnFlightSuccess = createAction(
//   '[SelectedReturnFlight] Load SelectedReturnFlight Success',
//   props<{ data: SelectedReturnFlight }>()
// );

// export const loadSelectedReturnFlightFailure = createAction(
//   '[SelectedReturnFlight] Load SelectedReturnFlight Failure',
//   props<{err:any}>()
// );


export const  pickySelectionAction = createAction(
    '[pickySelection] User Picky Select',
    props<{flight: flight, airItinerary:airItineraries,flightIndex:number}>()
  );

export const loadFares = createAction(
  '[Fares] Load Fares',
  props<{searchid: string, sequenceNum: number, providerKey: string}>()
);

export const loadFaresSuccess = createAction(
  '[Fares] Load Fares Success',
  props<{ data: FareRules[] }>()
);

export const loadFaresFailure = createAction(
  '[Fares] Load Fares Failure',
  props<{err:any}>()
);

export const loadOfflineSrvices = createAction(
  '[OfflineSrvices ] Load OfflineSrvices ',
  props<{searchid: string,POS:string}>()
);

export const loadOfflineSrvicesSuccess = createAction(
  '[OfflineSrvices ] Load OfflineSrvices  Success',
  props<{ data: flightOfflineService[] }>()
);

export const loadOfflineSrvicesFailure = createAction(
  '[OfflineSrvices ] Load OfflineSrvices  Failure',
  props<{err:any}>()
);

export const SaveBooking = createAction(
  '[SaveBooking ] SaveBooking ',
  props<{searchid: string,pcc:string,sequenceNum: number, body: PassengersModule, pkey: string,lang:string,ip:string,iplocation:string,selectedServices:any[]}>()
);

export const SaveBookingSuccess = createAction(
  '[SaveBooking ] SaveBooking  Success',
  props<{ data: any }>()
);

export const SaveBookingFailure = createAction(
  '[SaveBooking ] SaveBooking Failure',
  props<{err:any}>()
);


export const updateSearchCretaria = createAction(
  '[FlightSearch] Update Search Cretaria',
  props<{searchData:SearchFlightModule}>()
);

export const filterFlight = createAction(
  '[FlightSearch] Filter Flight',
  props<{filter?:FilterInputsModule,airItineraries:airItineraries[],roundTrip:boolean}>()
);

export const filterFlightSuccess = createAction(
  '[FlightSearch] Filter Flight Success',
  props<{airItineraries:airItineraries[][]}>()
);

export const filterFlightFailure = createAction(
  '[FlightSearch ] Filter Flight Failure',
  props<{err:any}>()
);
export const filterSelectedFlight = createAction(
  '[filterSelectedFlight] filterSelected Flight',
  props<{filter?:FilterInputsModule,airItineraries:airItineraries[],roundTrip:boolean}>()
);

export const filterSelectedFlightSuccess = createAction(
  '[filterSelectedFlight] filterSelected Flight Success',
  props<{airItineraries:airItineraries[]}>()
);

export const filterSelectedFlightFailure = createAction(
  '[filterSelectedFlight ] filterSelected Flight Failure',
  props<{err:any}>()
);

export const sortFlight = createAction(
  '[FlightSearch] Sort Flight',
  props<{airItineraries:airItineraries[] , sortType:number}>()
);

export const sortFlightSuccess = createAction(
  '[FlightSearch] Sort Flight Success',
  props<{airItineraries:airItineraries[][]}>()
);

export const sortFlightFailure = createAction(
  '[FlightSearch] Sort Flight Failure',
  props<{err:any}>()
);
export const sortSelectedFlight = createAction(
  '[FlightSearch] sortSelected Flight',
  props<{airItineraries:airItineraries[] , sortType:number}>()
);

export const sortSelectedFlightSuccess = createAction(
  '[FlightSearch] sortSelected Flight Success',
  props<{airItineraries:airItineraries[]}>()
);

export const sortSelectedFlightFailure = createAction(
  '[FlightSearch] sortSelected Flight Failure',
  props<{err:any}>()
);

export const resetSelectedFlight = createAction(
  '[FlightSearch] Reset Selected Flight'
);

export const updateFlightType = createAction(
  '[FlightSearch] Update Flight Type',
  props<{flightType:string}>()
);

export const cFlightSearchs = createAction(
  '[FlightSearch] clear FlightSearchs'
);
export const updateFlightSummaryData = createAction(
  '[FlightSearch] Update Flight Summary Data',
  props<{searchData:any}>()
);
