import { Action, createReducer, on } from '@ngrx/store';
import { airItineraries, FareRules, flight, FlightSearchResult, flightOfflineService, pickySelection, Selectedflight } from '@src/app/interface/flight-search-result';
import { SearchFlightModule } from '@src/app/models/search-flight/search-flight.module';
import * as flights from '../flight-search/flight-search.actions';


export const flightSearchFeatureKey = 'flight';

export interface State {
  loader         : boolean,
  offlineServicesLoader : boolean,
  flights        : FlightSearchResult,
  selectedFlight : Selectedflight,
  searchCretaria : SearchFlightModule,
  filterdFlights :airItineraries[][],
  filterSelectedFlight:airItineraries[],
  offlineServices:flightOfflineService[],
  fareRules:FareRules[],
  link:string,
  flightType : string,
  selectedFlightTotalAmount:number,
  pickySelection:pickySelection,
  error:any
}

export const initialState: State = {
  loader         : false,
  offlineServicesLoader: false,
  flights        : undefined,
  selectedFlight : undefined,
  selectedFlightTotalAmount:0,
  searchCretaria : undefined,
  filterdFlights: undefined,
  filterSelectedFlight:undefined,
  error          :undefined,
  offlineServices:undefined,
  fareRules      :undefined,
  link:undefined,
  flightType : undefined,
  pickySelection:undefined,
};


export const reducer = createReducer(
  initialState,
  on(flights.loadFlightSearchs , (state)=> ({...state , loader:true})),
  on(flights.loadFlightSearchsSuccess , (state , Flights)=>({...state , loader : false , flights:Flights.data})),
  on(flights.loadFlightSearchsFailure , (state,error)=>({...state , loader:false,error:error.err})),
  on(flights.loadFares , (state)=> ({...state , loader:true})),
  on(flights.loadFaresSuccess , (state ,fares)=>({...state , loader : false , fareRules:fares.data})),
  on(flights.loadFaresFailure , (state,error)=>({...state , loader:false,error:error.err})),
  on(flights.loadOfflineSrvices , (state)=> ({...state , offlineServicesLoader:true})),
  on(flights.loadOfflineSrvicesSuccess, (state ,services)=>({...state , offlineServicesLoader : false , offlineServices:services.data})),
  on(flights.loadOfflineSrvicesFailure , (state,error)=>({...state , offlineServicesLoader:false,error:error.err})),
  on(flights.loadSelectedFlight , (state)=> ({...state , loader:true})),
  on(flights.loadSelectedFlightSuccess , (state , flight)=>({...state , loader : false , selectedFlight:flight.data})),
  on(flights.loadSelectedFlightFailure , (state,error)=>({...state , loader:false,error:error.err})),
  on(flights.updateTotalAmount,(state,action)=>({...state,selectedFlightTotalAmount:action.totalAmount})),
  // on(flights.loadSelectedReturnFlightSuccess , (state , flight)=>({...state , loader : false , selectedReturnFlight:flight.data.for (const iterator of data.airItineraries) {
  //   iterator.filter(v=>{
  //     v.
  //   })
  // }
  // })),

//   on(flights.loadSelectedReturnFlightSuccess , (state , flight)=>({...state , loader : false , selectedReturnFlight:[...flight.data.airItineraryDTO.flightDTO.filter((v)=>{
      
//     v.flightInfo.flightNumber && v.flightAirline.airlineCode

//   })]
// })),
  on(flights.updateSearchCretaria , (state , searchData)=> ({...state , searchCretaria:searchData.searchData})),
   
  on(flights.updateFlightSummaryData , (state , param)=> ({...state , flightSummary:param.searchData})),
  on(flights.SaveBooking , (state)=> ({...state , loader:true})),
  on(flights.SaveBookingFailure , (state,error)=>({...state , loader:false,error:error.err})),
  on(flights.SaveBookingSuccess , (state , link)=>({...state , loader : false , link:link.data})),
  on(flights.filterFlight , (state)=> ({...state })),
  on(flights.filterFlightSuccess , (state , Flights)=>({...state , loader : false ,filterdFlights:Flights.airItineraries})),
  on(flights.filterFlightFailure , (state,error)=>({...state , loader:false,error:error.err})),
  on(flights.filterSelectedFlight , (state)=> ({...state })),
  on(flights.filterSelectedFlightSuccess , (state , Flights)=>({...state , loader : false ,pickySelection:{...state.pickySelection,otherOptions:Flights.airItineraries}})),
  on(flights.filterSelectedFlightFailure , (state,error)=>({...state , loader:false,error:error.err})),
  on(flights.sortFlightSuccess , (state , param) => ({...state , filterdFlights:param.airItineraries})),
  on(flights.sortSelectedFlightSuccess , (state , param) => ({...state , pickySelection:{...state.pickySelection,otherOptions:param.airItineraries}})),

  on(flights.sortSelectedFlightFailure , (state , param) => ({...state , error:param.err})),
  on(flights.resetSelectedFlight , (state)=>({...state , selectedFlight:undefined})),
  on(flights.updateFlightType , (state , param)=>({...state , flightType:param.flightType})),
  on(flights.pickySelectionAction , (state ,params)=>({...state,pickySelection:PickySelect(params.flight,params.flightIndex,params.airItinerary,[...state.flights.airItineraries],filterByFlight)})),
  on(flights.cFlightSearchs , (state)=>({...initialState}))
);

export function FlightReducer(
  state: State | undefined,
  action: Action) {
  return reducer(state, action);
}

type filterByFlight = (flight: flight,flightIndex:number,airItineraries:airItineraries[]) => airItineraries[] ;
export const filterByFlight: filterByFlight = (flight: flight,flightIndex:number,airItineraries:airItineraries[]):airItineraries[] => {
  let flightNumList:any [] = flight?.flightDTO.map(v=>v.flightInfo.flightNumber);
  console.log(flightNumList);
  let filterdV = airItineraries?.filter((v)=>{ return v.allJourney.flights[flightIndex]?.flightAirline.airlineCode === flight?.flightAirline.airlineCode 
    && v.allJourney.flights[flightIndex]?.flightDTO.map(v=>v.flightInfo.flightNumber).reduce((p,c)=>{return (flightNumList.indexOf(p ) != -1) && (flightNumList.indexOf(c)  != -1)})} )
  console.log(filterdV,'filterd arr')
  return filterdV?filterdV: []
}
type PickySelectF = (flight:flight,flightIndex:number,Itinerary:airItineraries,AllItinerary:airItineraries[] | undefined,filterByFlight:filterByFlight) => pickySelection;
export const PickySelect:PickySelectF =(flight:flight,flightIndex:number,Itinerary:airItineraries,AllItinerary:airItineraries[],filterByFlight) :pickySelection =>
{
  console.log(AllItinerary ,"airt reducer")
  let pickySelection :pickySelection ={
    selectedFlight:flight,
    selectedFlightItinerary:Itinerary,
    flightIndex:flightIndex,
    otherOptions:  filterByFlight(flight,flightIndex,AllItinerary) 
  }
  console.log(flight,Itinerary,flightIndex)
  console.log(pickySelection,'picky')
  return pickySelection?pickySelection:undefined;
}

