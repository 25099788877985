import { updateSearchCretaria } from "./flight-search.actions";
import { MyapiService } from "src/app/services/myapi.service";
import {
  mergeMap,
  map,
  catchError,
  retry,
  take,
  toArray,
  tap,
} from "rxjs/operators";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as flightActions from "../flight-search/flight-search.actions";
import { from, of } from "rxjs";
import { Store } from "@ngrx/store";
import {
  airItineraries,
  flightFilter,
  FlightSearchResult,
} from "@src/app/interface/flight-search-result";
import { CurrencyModule } from "@src/app/models/currency/currency.module";
export var Tflights: airItineraries[] = undefined;

@Injectable()
export class FlightSearchEffects {
  constructor(
    private actions$: Actions,
    private apiService: MyapiService,
    private store: Store
  ) {}

  loadFlights$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.loadFlightSearchs),
      mergeMap((searchCrtariea) =>
        this.apiService.searchFlight(searchCrtariea.search).pipe(
          map((v) => {
            this.store.dispatch(
              updateSearchCretaria({ searchData: searchCrtariea.search })
            );
            Tflights = v.airItineraries;
            return flightActions.loadFlightSearchsSuccess({
              data: assignBestpriceTag(v),
            });
          }),
          catchError((err) => {
            this.apiService.openSnackBar(
              "loadFlightSearchsFailure",
              " faild",
              "snakFaild"
            );

            // const errorMessage = this.apiService.getErrorMessage(err)
            console.log(err);
            return of(flightActions.loadFlightSearchsFailure(err));
          })
        )
      )
    )
  );
  filterFlight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.filterFlight),
      map((ac) => {
        console.log(ac.airItineraries, "filterinput");
        if (!ac.filter) {
          return flightActions.filterFlightSuccess({
            airItineraries: orgnize(ac.airItineraries),
          });
        } else {
          return flightActions.filterFlightSuccess({
            airItineraries: orgnize(
              ac.airItineraries.filter(
                (v) =>
                  filterFlighWithPrice(v, ac.filter) &&
                  filterFlighWithDuration(v, ac.filter) &&
                  filterFlighWithDepartionTime(v, ac.filter, convertToMin) &&
                  filterFlighWithReturnTime(
                    v,
                    ac.filter,
                    ac.roundTrip,
                    convertToMin
                  ) &&
                  filterFlightWithNumberofStopsFunction(v, ac.filter) &&
                  filterFlightWithAirlineFunction(v, ac.filter)
              )
            ),
          });
        }
      })
    )
  );
  filterSelectedFlight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.filterSelectedFlight),
      map((ac) => {
        console.log(ac.airItineraries, "filterinput");
        if (!ac.filter) {
          return flightActions.filterSelectedFlightSuccess({
            airItineraries: ac.airItineraries,
          });
        }
        else if(ac.airItineraries.length == 1){

            return flightActions.filterSelectedFlightSuccess({
              airItineraries: ac.airItineraries,
            });

        }
        else {
          return flightActions.filterSelectedFlightSuccess({
            airItineraries: ac.airItineraries.filter(
              (v) =>
                filterFlighWithPrice(v, ac.filter) &&
                filterFlighWithDuration(v, ac.filter) &&
                filterFlighWithDepartionTime(v, ac.filter, convertToMin) &&
                filterFlighWithReturnTime(
                  v,
                  ac.filter,
                  ac.roundTrip,
                  convertToMin
                ) &&
                filterFlightWithNumberofStopsFunction(v, ac.filter) &&
                filterFlightWithAirlineFunction(v, ac.filter)
            ),
          });
        }
      })
    )
  );
  GetFareRules$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.loadFares),
      mergeMap((param) =>
        this.apiService
          .fareRules(param.searchid, param.sequenceNum, param.providerKey)
          .pipe(
            map((fares) => {
              return flightActions.loadFaresSuccess({ data: fares });
            }),
            catchError((err) => {
              this.apiService.openSnackBar(
                "loadFaresFailure",
                " faild",
                "snakFaild"
              );

              return of(flightActions.loadFaresFailure(err));
            })
          )
      )
    )
  );

  GetSelectedFlight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.loadSelectedFlight),
      mergeMap((param) =>
        this.apiService
          .getSelectedFlight(
            param.searchid.split('_')[0],
            param.sequenceNum,
            param.providerKey,
            param.pcc
          )
          .pipe(
            map((SelectdeFlights) => {
              return flightActions.loadSelectedFlightSuccess({
                data: SelectdeFlights,
              });
            }),
            catchError((err) => {
              this.apiService.openSnackBar(
                "loadSelectedFlightFailure",
                " faild",
                "snakFaild"
              );

              return of(flightActions.loadSelectedFlightFailure(err));
            })
          )
      )
    )
  );

  GetOfflineServices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.loadOfflineSrvices),
      mergeMap((param) =>
        this.apiService.offlineServices(param.searchid, param.POS).pipe(
          map((offlineService) => {
            return flightActions.loadOfflineSrvicesSuccess({
              data: offlineService,
            });
          }),
          catchError((err) => {
            this.apiService.openSnackBar(
              "loadSelectedFlightFailure",
              " faild",
              "snakFaild"
            );

            return of(flightActions.loadSelectedFlightFailure(err));
          })
        )
      )
    )
  );

  saveBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.SaveBooking),
      take(1),
      mergeMap((param) =>
        this.apiService
          .saveBooking(
            param.searchid,
            param.sequenceNum,
            param.body,
            param.pkey,
            param.pcc
          )
          .pipe(
            mergeMap((v) =>
              v.hgNumber
                ? this.apiService
                    .CheckFlightValidation(
                      v.hgNumber,
                      param.lang,
                      param.searchid,
                      param.sequenceNum,
                      param.pkey
                    )
                    .pipe(
                      retry(3),
                      mergeMap(
                        (val) =>
                          (val.status = "Valid"
                            ? this.apiService
                                .GetPaymentView(
                                  param.ip,
                                  param.iplocation,
                                  v.hgNumber,
                                  param.searchid,
                                  "",
                                  param.selectedServices
                                )
                                .pipe(
                                  map((link) => {
                                    // window.location.href = link.link;
                                    return flightActions.SaveBookingSuccess({
                                      data: link.link,
                                    });
                                  }),
                                  catchError((err) => {
                                    return of(
                                      flightActions.SaveBookingFailure(err)
                                    );
                                  })
                                )
                            : of(
                                flightActions.SaveBookingFailure({
                                  err: "FAildToSaveBooking",
                                })
                              ))
                      ),
                      catchError((err) => {
                        this.apiService.openSnackBar(
                          "SaveBookingFailure",
                          " faild",
                          "snakFaild"
                        );

                        return of(flightActions.SaveBookingFailure(err));
                      })
                    )
                : of(
                    flightActions.SaveBookingFailure({
                      err: "FAildToSaveBooking",
                    })
                  )
            ),
            catchError((err) => {
              this.apiService.openSnackBar(
                "SaveBookingFailure",
                " faild",
                "snakFaild"
              );

              return of(flightActions.SaveBookingFailure(err));
            })
          )
      )
    )
  );
  // saveBooking$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(flightActions.SaveBooking),
  //     take(1),
  //     mergeMap((param) =>
  //       this.apiService
  //         .saveBooking(
  //           param.searchid,
  //           param.sequenceNum,
  //           param.body,
  //           param.pkey
  //         )
  //         .pipe(
  //           tap(v=>console.log(v,'save booking')),
  //           mergeMap(
  //             (val) =>
              
  //             (val.hgNumber
  //               ? this.apiService
  //                 .GetPaymentView(
  //                   param.ip,
  //                   param.iplocation,
  //                   val.hgNumber,
  //                   param.searchid,
  //                   "",
  //                   param.selectedServices
  //                 )
  //                 .pipe(
  //                   map((link) => {
  //                     // window.location.href = link.link;
  //                     return flightActions.SaveBookingSuccess({
  //                       data: link.link,
  //                     });
  //                   }),
  //                   catchError((err) => {
  //                     return of(
  //                       flightActions.SaveBookingFailure(err)
  //                     );
  //                   })
  //                 )
  //               : of(
  //                 flightActions.SaveBookingFailure({
  //                   err: "FAildToSaveBooking",
  //                 })
  //               ))
  //           ),
  //           catchError((err) => {
  //             this.apiService.openSnackBar(
  //               "SaveBookingFailure",
  //               " faild",
  //               "snakFaild"
  //             );

  //             return of(flightActions.SaveBookingFailure(err));
  //           })
  //         )
  //     )
  //   )
  // );

  sortFlight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.sortFlight),
      map((param) => {
        return flightActions.sortFlightSuccess({
          airItineraries: orgnize(
            sortMyResult(param.sortType, param.airItineraries)
          ),
        });
      })
    )
  );

  sortFlightSelected$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flightActions.sortSelectedFlight),
      map((param) => {
        console.log(param);
        return flightActions.sortSelectedFlightSuccess({
          airItineraries: sortMyResult(param.sortType, [
            ...param.airItineraries,
          ]),
        });
      })
    )
  );
}

type filterFlightWithAirlineFunction = (
  airItineraries: airItineraries,
  filter: flightFilter
) => boolean;
type filterFlightWithNumberofStopsFunction = (
  airItineraries: airItineraries,
  filter: flightFilter
) => boolean;
type filterFlighWithDepartionTime = (
  airItineraries: airItineraries,
  filter: flightFilter,
  timeTOmin: timeToMinits
) => boolean;
type filterFlighWithReturnTime = (
  airItineraries: airItineraries,
  filter: flightFilter,
  isRound: boolean,
  timeTOmin: timeToMinits
) => boolean;
type filterFlighWithDuration = (
  airItineraries: airItineraries,
  filter: flightFilter
) => boolean;
type filterFlighWithPrice = (
  airItineraries: airItineraries,
  filter: flightFilter
) => boolean;
type timeToMinits = (time: string) => number;
type orgnizeArr = (airItineraries: airItineraries[]) => airItineraries[][];
type filterCompose = (
  airItineraries: airItineraries[],
  filter: flightFilter,
  isRound: boolean,
  fn1: filterFlighWithPrice,
  fn2: filterFlightWithNumberofStopsFunction,
  fn3: filterFlighWithDepartionTime,
  fn4: filterFlighWithReturnTime,
  fn5: filterFlighWithDuration,
  fn6: filterFlighWithPrice,
  fn7: timeToMinits,
  fn8: orgnizeArr
) => airItineraries[][];

// export const filterFlighWithPrice: filterFlighWithPrice = (flight, filter) =>{
//   flight.itinTotalFare.amount >= filter.priceMin &&
//   flight.itinTotalFare.amount < filter.priceMax;
// }

export function filterFlighWithPrice(flight: airItineraries,filter: flightFilter): boolean{
  if(localStorage.getItem('currency')){
    let currenyObj : CurrencyModule = JSON.parse(localStorage.getItem('currency'))
    return (flight.itinTotalFare.amount * currenyObj.rate) >= filter.priceMin && (flight.itinTotalFare.amount * currenyObj.rate)< filter.priceMax;
  }else{
    console.log("thie price filter body IN ELSE",flight.itinTotalFare.amount)
    return flight.itinTotalFare.amount >= filter.priceMin && flight.itinTotalFare.amount < filter.priceMax;
  }
}
  

export const filterFlighWithDuration: filterFlighWithDuration = (
  flight,
  filter
) =>
  flight.totalDuration >= filter.durationMin &&
  flight.totalDuration < filter.durationMax;

export const filterFlighWithReturnTime: filterFlighWithReturnTime = (
  flight,
  filter,
  round,
  convertToMin
) =>
  round
    ? convertToMin(flight.allJourney.flights[1].flightDTO[0].departureDate) >=
        filter.returnMin &&
      convertToMin(flight.allJourney.flights[1].flightDTO[0].departureDate) <
        filter.returnMax
    : true;

    export const filterFlighWithDepartionTime: filterFlighWithDepartionTime = (flight, filter, convertToMin) => convertToMin(flight.allJourney.flights[0].flightDTO[0].departureDate) >= filter.depatingMin && convertToMin(flight.allJourney.flights[0].flightDTO[0].departureDate) < filter.departingMax;

//  take date string return number
export const convertToMin = function (time: string): number {
  let date = time;
  let T = date.indexOf("T");
  let h = date.slice(T + 1);
  let hr = +h.slice(0, 2) * 60;
  let m = +h.slice(3, 5);
  let tm = hr + m;
  return tm;
};

export const filterFlightWithNumberofStopsFunction: filterFlightWithNumberofStopsFunction =
  (airItineraries: airItineraries, filter: flightFilter) =>
    airItineraries.allJourney.flights
      .map((v) => filter.stops.indexOf(v.stopsNum) != -1)
      .reduce((sum, next) => sum && next, true);
export const filterFlightWithAirlineFunction: filterFlightWithAirlineFunction =
  (airItineraries: airItineraries, filter: flightFilter) =>
    filter.airlines.length > 0
      ? filter.airlines.indexOf(
          airItineraries.allJourney.flights[0].flightDTO[0].flightAirline
            .airlineName
        ) != -1
      : true;
export const orgnize: orgnizeArr = function (
  array: airItineraries[]
): airItineraries[][] {
  let out: airItineraries[][] = [];
  let remain: airItineraries[] = array;
  let i = 0;
  while (remain.length > 0 || !remain) {
    if (i > 50) {
      break;
    } else {
      out.push(
        remain.filter(
          (v, i, a) =>
            v.allJourney.flights[0].flightDTO[0].flightAirline.airlineCode ===
              a[0].allJourney.flights[0].flightDTO[0].flightAirline
                .airlineCode &&
            v.itinTotalFare.amount === a[0].itinTotalFare.amount
        )
      );
      remain = remain.filter(
        (v, i, a) =>
          v.allJourney.flights[0].flightDTO[0].flightAirline.airlineCode !=
            a[0].allJourney.flights[0].flightDTO[0].flightAirline.airlineCode ||
          v.itinTotalFare.amount != a[0].itinTotalFare.amount
      );
    }
    i = i + 1;
  }

  console.log(i, out);
  return out;
};

export function sortMyResult(
  type: number,
  data: airItineraries[]
): airItineraries[] {
  /* Cheapest */
  if (type == 1) {
    console.log(
      data,
      "before sorting",
      type,
      [...data].sort((a, b) => {
        return a.itinTotalFare.amount - b.itinTotalFare.amount;
      })
    );
    return [...data].sort((a, b) => {
      return a.itinTotalFare.amount - b.itinTotalFare.amount;
    });
  }
  if (type == 2) {
    return [...data].sort((a, b) => {
      return a.totalDuration - b.totalDuration;
    });
  }
  if (type == 3) {
    return [...data].sort((a, b) => {
      return <any>new Date(a.deptDate) - <any>new Date(b.deptDate);
    });
  }
  if (type == 4) {
    return [...data].sort((a, b) => {
      return <any>new Date(b.deptDate) - <any>new Date(a.deptDate);
    });
  }
  if (type == 5) {
    return [...data].sort((a, b) => {
      return (
        <any>new Date(a.allJourney.flights[1].flightDTO[0].departureDate) -
        <any>new Date(b.allJourney.flights[1].flightDTO[0].departureDate)
      );
    });
  }

  if (type == 6) {
    return [...data].sort((a, b) => {
      return (
        <any>new Date(b.allJourney.flights[1].flightDTO[0].departureDate) -
        <any>new Date(a.allJourney.flights[1].flightDTO[0].departureDate)
      );
    });
  }
  return [...data];
}

export function assignBestpriceTag(
  data: FlightSearchResult
): FlightSearchResult {
  var newData = data;
  var sorted = newData.airItineraries.sort((a, b) => {
    return a.itinTotalFare.amount - b.itinTotalFare.amount;
  });
  sorted[0].bestPrice = true;
  newData.airItineraries = sorted;
  return newData;
}

