import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {
  @ViewChild('Cf',{static: false}) cf: ElementRef;
  @ViewChild('Unsubscribing',{static: false}) unsubscribing: ElementRef;
  @ViewChild('FS',{static: false}) fs: ElementRef;
  @ViewChild('IA',{static: false})ia: ElementRef;
  @ViewChild('Cookies',{static: false})cookies: ElementRef;
  @ViewChild('WYS',{static: false})wys: ElementRef;
  constructor( 
     public translate: TranslateService,
     private gtmService: GoogleTagManagerService,
     private router: Router,) {
    const gtmTag = {
      event: 'Page View',
      pageName: 'Privacy And Policy',
      language: this.translate.currentLang, 
      url:this.router.url
    };
    this.gtmService.pushTag(gtmTag);
   }
  scroll(elem:ElementRef) {
    elem.nativeElement.scrollIntoView();
  }
  ngOnInit() {
  }

}
