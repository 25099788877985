import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
 isFlight:boolean =true;
 @Input()email:string = '';
  constructor() { }

  ngOnInit() {
  }
  switch (key:string) {
      switch (key) {
        case "hotel":
          this.isFlight=false;
          break;
        case "flight":
          this.isFlight=true;
          break;
        default:
          this.isFlight=false;
          break;
      }
  }

 
}
