import { HotelnameService } from 'src/app/services/hotelname.service';
import { FlightSearchEffects } from './store/flight-search/flight-search.effects';
import { reducers, metaReducers } from './store/selectors';
import { Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from '@src/app/app-routing.module';
import {MatIconModule} from '@angular/material/icon';
import { CommonModule, registerLocaleData } from '@angular/common';

import { AppComponent } from '@src/app/app.component';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { EffectsModule } from '@ngrx/effects';
import {  TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import{ServiceWorkerModule, SwRegistrationOptions} from '@angular/service-worker';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {  SharedModule } from './shared.module';
import * as fromState from './store';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatListModule } from '@angular/material/list'
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {  HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { AppUtilitiesEffects } from './store/app-utilities/app-utilities.effects';
import { AppSettingEffects } from './store/app-setting/app-setting.effects';
import { HotelSearchEffects } from './store/hotel-search/hotel-search.effects';
import { UserEffects } from './store/user/user.effects';
import { OffersEffects } from './store/app-offers/offers.effects';


import * as fromFlights from './store/flight-search/flight-search.reducer';
import * as fromHotels from './store/hotel-search/hotel-search.reducer';
import * as fromUtilities from './store/app-utilities/app-utilities.reducer';
import * as fromSettings from './store/app-setting/app-setting.reducer';
import * as fromUser from './store/user/user.reducer';
import * as fromOffers from './store/app-offers/offers.reducer';

import { AuthInterceptorService } from './interseptor/auth-interceptor.service';
import { FlightServiceService } from './services/flight-service.service';

import{SocialAuthServiceConfig,SocialAuthService, GoogleLoginProvider, SocialLoginModule} from 'angularx-social-login';
import{FacebookLoginProvider, SocialUser} from 'angularx-social-login';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { DatepickerI18nService } from './services/datepicker-i18n.service';
import { NgbDatepickerI18n } from '@ng-bootstrap/ng-bootstrap';
import arSaLocale from '@angular/common/locales/ar-SA'
registerLocaleData(arSaLocale)
@Injectable({
  providedIn: 'root'
})
export class HammerConfig extends HammerGestureConfig {
  overrides = {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

const googleLoginOptions = {
  scope: 'profile email',
  plugin_name:'login' //you can use any name here
};
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent

  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CommonModule,
    SocialLoginModule,
    MatListModule,
  
    FontAwesomeModule,
    TranslateModule.forRoot({
      defaultLanguage: 'ar',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
    },
    
  }),
  GoogleTagManagerModule.forRoot({
    id: 'GTM-PD27GN8',
  }),
  ServiceWorkerModule.register('src/ngsw-worker.js'),
 
    MatIconModule,
    StoreModule.forRoot(reducers, {
      metaReducers, 
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      }
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    StoreModule.forFeature(fromFlights.flightSearchFeatureKey, fromFlights.reducer),
    StoreModule.forFeature(fromUtilities.appUtilitiesFeatureKey, fromUtilities.reducer),
    StoreModule.forFeature(fromSettings.appSettingFeatureKey, fromSettings.reducer),
    StoreModule.forFeature(fromUser.userFeatureKey, fromUser.reducer),
    StoreModule.forFeature(fromOffers.appOffersFeatureKey, fromOffers.reducer),
    HammerModule,

    SharedModule,
    StoreModule.forFeature(fromState.stateFeatureKey, fromState.reducers, { metaReducers: fromState.metaReducers }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    EffectsModule.forRoot([AppUtilitiesEffects, OffersEffects,AppSettingEffects, HotelSearchEffects, UserEffects,FlightSearchEffects])
  ],
  
  providers: [
    // {
    //   provide: NgbDatepickerI18n,
    //   useClass: DatepickerI18nService,
    //   deps: [TranslateService], // Include the TranslateService dependency
    // },
    FlightServiceService,
    {
    provide: SwRegistrationOptions,
    useFactory: () => ({ enabled: environment.production }),
   
  }, {
    
    provide: HAMMER_GESTURE_CONFIG,
    useClass: HammerConfig
  },HotelnameService ,  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true
  },
{
  provide:'SocialAuthServiceConfig',
  useValue:{
    autoLogin:false,
    providers:[
      {
        id:FacebookLoginProvider.PROVIDER_ID,
        provider:new FacebookLoginProvider("3481677202157637")
      },
      {
        id:GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider('829934546921-lghc6mhmb5jod382m6nkh82hbiuong5j.apps.googleusercontent.com', googleLoginOptions)
      },
      
      // {provide: 'googleTagManagerId', useValue:'GTM-PD27GN8'}
    ],
    onError:(err) =>{console.log(err)},
  } as SocialAuthServiceConfig
},SocialAuthService,




],
  bootstrap: [AppComponent],
  exports:[ 
    // SearchflightComponent,
  //  HeaderComponent,
  //   FooterComponent
  ]
})
export class AppModule { }

