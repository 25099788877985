<app-header [bg]=true></app-header>
<!-- <app-navlist></app-navlist> -->
<div class="wrapper" [ngClass]="{ar: translate.currentLang =='ar'}" >
    <div class="container">

        <div class="privacy">
            <div class="title">
                <h1>{{"termsOfUse.title" | translate}}</h1>
            </div>

            <div class="policies">
                <div class="policy">
                    <p>
                        <span style="font-weight: bold;">{{"termsOfUse.span" | translate}}</span>
                        {{"termsOfUse.item" | translate}}
                    </p>
                </div>
                <div class="policy">
                    <p>
                        <span style="font-weight: bold;">{{"termsOfUse.span2" | translate}}</span>
                        {{"termsOfUse.item2" | translate}}
                    </p>
                </div>
                <div class="policy">
                    <p>
                        <span style="font-weight: bold;">{{"termsOfUse.span3" | translate}}</span>
                        {{"termsOfUse.item3" | translate}}
                    </p>
                </div>
                <div class="policy">
                    <p>
                        <span style="font-weight: bold;">{{"termsOfUse.span4" | translate}}</span>
                        {{"termsOfUse.item4" | translate}}
                    </p>
                </div>
                <div class="policy">
                    <p>
                        <span style="font-weight: bold;">{{"termsOfUse.span5" | translate}}</span>
                        {{"termsOfUse.item5" | translate}}
                    </p>
                </div>
                <div class="policy">
                    <p>
                        <span style="font-weight: bold;">{{"termsOfUse.span6" | translate}}</span>
                        {{"termsOfUse.item6" | translate}}
                    </p>
                </div>
                <div class="policy">
                    <p>
                        <span style="font-weight: bold;">{{"termsOfUse.span7" | translate}}</span>
                        {{"termsOfUse.item7" | translate}}
                    </p>
                </div>
                <div class="policy">
                    <p>
                        <span style="font-weight: bold;">{{"termsOfUse.span8" | translate}}</span>
                        {{"termsOfUse.item8" | translate}}
                    </p>
                </div>
                <div class="policy">
                    <p>
                        <span style="font-weight: bold;">{{"termsOfUse.span9" | translate}}</span>
                        {{"termsOfUse.item9" | translate}}
                    </p>
                </div>
                <div class="policy">
                    <p>
                        <span style="font-weight: bold;">{{"termsOfUse.span10" | translate}}</span>
                        {{"termsOfUse.item10" | translate}}
                    </p>
                </div>
                <div class="policy">
                    <p>
                        <span style="font-weight: bold;">{{"termsOfUse.span11" | translate}}</span>
                        {{"termsOfUse.item11" | translate}}
                    </p>
                </div>
                <div class="policy">
                    <p>
                        <span style="font-weight: bold;">{{"termsOfUse.span12" | translate}}</span>
                        {{"termsOfUse.item12" | translate}}
                    </p>
                </div>

                <div class="title">
                    <h1>{{"privacyPolicy.title7" | translate}}</h1>
                </div>
    
                <div class="policies">
                    <div class="policy">
                        <p>{{"privacyPolicy.item7" | translate}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

   
</div>

<app-footer></app-footer>