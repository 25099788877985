import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { faAngleRight, faEnvelope, faHome, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  sendmessage: FormGroup;
  submitted = false;
  faHome = faHome;
 faAngleRight =  faAngleRight;
 faInfoCircle = faInfoCircle
 faEnvelope = faEnvelope
  constructor(
    private formBuilder: FormBuilder,
    public translate : TranslateService,
    private gtmService: GoogleTagManagerService,
    private router: Router,) { 
      const gtmTag = {
        event: 'Page View',
        pageName: 'Contact Us',
        language: this.translate.currentLang, 
        url:this.router.url
      };
      this.gtmService.pushTag(gtmTag);
    } 

  ngOnInit() {
    this.sendmessage = this.formBuilder.group({
      FirstName: ['', Validators.required],
      LastName: ['', Validators.required],
      Phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]

    });
  }
  get f() { return this.sendmessage.controls; }


  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.sendmessage.invalid) {
      return;
    }

    // display form values on success
    // alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.sendmessage.value, null, 4));
    this.sendmessage.reset()
  }

  onReset() {
    this.submitted = false;
    this.sendmessage.reset();
  }

}