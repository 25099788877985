// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


export const environment = {
  production: true,
  baseCurrency: 'KWD',
  endPoints: {
    offlineSeats:"http://154.41.209.73:7025",
    searchflow:  'https://flightsearch.Tazkrti.com',
    BookingFlow:  'https://flightflow.Tazkrti.com',
    FareRules:  'https://flightprov.Tazkrti.com',
    asm:  'https://backofficeapi.Tazkrti.com',
    Apihotels:"https://Hotelsapi.ticketboarding.com",
    hotelprepay: 'https://prepayapi.Tazkrti.com',
    users:  'https://usersapi.Tazkrti.com',
    admin:  'https://adminapi.Tazkrti.com/',
    getDPayment:  'https://adminapi.Tazkrti.com/',
    bookHotels: "https://hotels.Tazkrti.com",
    hotelPrepay: 'https://prepayapi.Tazkrti.com',
    backOffice: 'https://backofficeapi.Tazkrti.com',
    FlightTop:'https://flightsearch.Tazkrti.com',
    offers: {
      getAll: 'http://154.41.209.73:7893/api/GetAllOffersAPI?POS=',
      getByID: 'http://154.41.209.73:7893/api/GetOfferByIdAPI?OfferId=',
      BookOffer: "http://154.41.209.73:7895/api/BookOffer",
      RetriveItineraryDetails:'/api/Admin/RetriveItineraryDetails'
    }
  }
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
