import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { tap, take } from 'rxjs/operators';
import { FareRules } from '@src/app/interface/flight-search-result';
import { MyapiService } from '@src/app/services/myapi.service';

@Component({
  selector: 'app-far-rules',
  templateUrl: './far-rules.component.html',
  styleUrls: ['./far-rules.component.css']
})
export class FarRulesComponent implements OnInit {
  @Input() squencNumber: number;
  FareLoading: boolean = true;
  @Input() serachId: string;
  fare: FareRules[];
  @Input() providerKey: string;
  // @Input() showFares:boolean=false;
  constructor(private search: MyapiService, public activeModal: NgbActiveModal, private router: Router) { }

  ngOnInit() {

    this.showFareRules();

    // Close dialog ref on route changes
    this.router.events.pipe(
      tap(() => this.activeModal.close()),
      take(1),
    ).subscribe();
  }



  // show fairRules
  showFareRules() {

    this.FareLoading = true;
    this.search.fareRules(this.serachId, this.squencNumber, this.providerKey).subscribe(
      (result) => {
        this.FareLoading = false;
        this.fare = result;
      }

    );
  }


}
