import { Injectable, EventEmitter, Output, Input } from '@angular/core';
import { HotelsCitiesModule } from 'src/app/models/hotels-cities/hotels-cities.module';

@Injectable({
  providedIn: 'root'
})
export class HotelnameService {
  @Input('isflight') Isflight: boolean = true;
  dubai:any;
  paris:any;
  pavaria:any;
  cairo:any;
  kuwait:any;
  marakesh:any
  hcity:EventEmitter<HotelsCitiesModule> = new EventEmitter();
  city:EventEmitter<string> = new EventEmitter();
  hotelRooms:any[]
  

  constructor() { }

  cityName(){
    if(this.Isflight){
      this.dubai = 'Dubai-Dubai International Airport-DXB';
      this.paris="Paris-Charles de Gaulle International Airport-CDG";
      // this.pavaria="BAVARIA";
      this.pavaria="Hamburg-Hamburg Airport-HAM";
      this.cairo="Cairo-Cairo International Airport-CAI";
      this.kuwait="Kuwait-Kuwait International Airport-KWI";
      // this.marakesh="MARRAKECH";
      this.marakesh="Casablanca-Mohammed V International Airport-CMN";
  }
  else{
    this.dubai={
      CityId: 6125,
      City: 'DUBAI',
      Country: 'UNITED ARAB EMIRATES',
      CityWithCountry: 'DUBAI,UNITED ARAB EMIRATES'
  };
  this.paris={
    CityId: 18745,
     City: 'PARIS',
     Country: 'FRANCE',
     CityWithCountry: 'PARIS,FRANCE'
 };
//  this.pavaria=
//  {
//   CityId: 1150,
//   City: 'BAVARIA',
//   Country: 'GERMANY',
//   CityWithCountry: 'BAVARIA,GERMANY' };
this.pavaria=
{
     "CityId": 10131,
     "City": "HAMBURG",
     "Country": "GERMANY",
     "CityWithCountry": "HAMBURG,GERMANY"
 };
  this.cairo= {
    "CityId": 2865,
    "City": "CAIRO",
    "Country": "EGYPT",
    "CityWithCountry": "CAIRO,EGYPT"
};
  this.kuwait=   {
    "CityId": 13006,
    "City": "KUWAIT",
    "Country": "KUWAIT",
    "CityWithCountry": "KUWAIT,KUWAIT"
};
//   this.marakesh= {
//     "CityId": 15396,
//     "City": "MARRAKECH",
//     "Country": "MOROCCO",
//     "CityWithCountry": "MARRAKECH,MOROCCO"
// };
this.marakesh=    {
  "CityId": 3486,
  "City": "CASABLANCA",
  "Country": "MOROCCO",
  "CityWithCountry": "CASABLANCA,MOROCCO"
};

  }
  }

  cityPick (cityName){
    console.log(cityName);
    if(this.Isflight)
    {
      this.city.emit(cityName);
    }
    else
    {
      this.hcity.emit(cityName);
    }

  }

}
