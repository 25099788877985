import {
    ActionReducer,
    ActionReducerMap,
    createFeatureSelector,
    createSelector,
    MetaReducer
  } from '@ngrx/store';
  import { environment } from '../../environments/environment';

  import * as flights from './flight-search/flight-search.reducer';
  import * as hotels from './hotel-search/hotel-search.reducer';
  import * as utilities from './app-utilities/app-utilities.reducer';
  import * as settings from './app-setting/app-setting.reducer';
  import * as user from './user/user.reducer';
  import * as offers from './app-offers/offers.reducer';
import { state } from '@angular/animations';

  export interface State {
    flight    : flights.State,
    utilities : utilities.State,
    settings  : settings.State,
    user      : user.State
    hotel     : hotels.State
    offers    : offers.State
   }

   export const reducers: ActionReducerMap<State> = {
    flight    :flights.FlightReducer,
    utilities :utilities.UtilitiesReducer,
    settings  :settings.AppSettingReducer,
    user      :user.UserReducer,
    hotel     :hotels.HotelReducer,
    offers    :offers.OffersReducer
   }

   export const flightFeatureKey  = 'flight';
   export const utilityFeatureKey = 'utilities';
   export const settingFeatureKey = 'settings';
   export const userFeatureKey    = 'user';
   export const hotelFeatureKey   = 'hotel';
   export const offersFeatureKey   = 'offers';

   export const selectFlightFeature    = createFeatureSelector<State, flights.State>(flightFeatureKey);
   export const selectUtilitiesFeature = createFeatureSelector<State, utilities.State>(utilityFeatureKey);
   export const selectSettingsFeature  = createFeatureSelector<State, settings.State>(settingFeatureKey);
   export const selectUserFeature      = createFeatureSelector<State, user.State>(userFeatureKey);
   export const selectHotelFeature     = createFeatureSelector<State, hotels.State>(hotelFeatureKey);
   export const selectOffersFeature    = createFeatureSelector<State, offers.State>(offersFeatureKey);


    //-------------> User Selectors
    export const selectUserlLoader = createSelector(
      selectUserFeature,
      (state : user.State) => state.loading
  );

  export const selectAppUser= createSelector(
    selectUserFeature,
    (state : user.State) => state.appUser
);

   //-------------> Offers Search Selectors

   export const selectOfferslLoader = createSelector(
    selectOffersFeature,
    (state : offers.State) => state.loading
);
export const paymentview = createSelector(
  //selected flight
  selectFlightFeature,
  
  (state:flights.State) => state.link
);
export const selectTopDestination = createSelector(
  selectUtilitiesFeature,
  (state:utilities.State)=>state.FTcities
);

export const selectTripIdeas = createSelector(
  selectUtilitiesFeature,
  (state:utilities.State)=>state.FTIdeas
);
export const selectAllOffers = createSelector(
  selectOffersFeature,
  (state : offers.State) => state.offers
);

export const selectServicesImages = createSelector(
  selectOffersFeature,
  (state : offers.State) => state.servicesImages
);

export const selectSelectedOffer = createSelector(
  selectOffersFeature,
  (state : offers.State) => state.selecyedOffer
);

export const selectOfflineItinerary = createSelector(
  selectOffersFeature,
  (state : offers.State) => state.offlineItinerary
);


   //-------------> Hotels Search Selectors

   export const selectHotelLoader = createSelector(
    selectHotelFeature,
    (state : hotels.State) => state.loader
);

export const selectAllHotels = createSelector(
  selectHotelFeature,
    (state:hotels.State) => state.searchResult
  );

  export const selectFilteredHotels = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.filtredHotels
  );

  export const selectSelectedRooms = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.selectedRooms
  );

  export const selectTotalPrice = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.totalPrice
  );
  export const selectRequiredHotel = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.requiredHotel
  );

  export const selectHotelSearchCriteria = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.searchCriteria
  );

  export const selectCancellationPolicy = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.cancellationPolicy
  );

  export const selectHotelRooms = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.hotelRooms
  );

  export const selectHotelAmenties = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.hotelAmenties
  );

  export const selectRoomImages = createSelector(
    selectHotelFeature,
    (state:hotels.State) => state.roomImages
  );


    //-------------> Flights Search Selectors

    export const selectFlightLoader = createSelector(
      selectFlightFeature,
      (state : flights.State) => state.loader
  );

  export const selectFlightError = createSelector(
    selectFlightFeature,
    (state : flights.State) => state.error
);

  export const selectAllFlights = createSelector(
      selectFlightFeature,
      (state:flights.State) => state.flights
    );

    export const selectFilteredFlights = createSelector(
      selectFlightFeature,
      (state:flights.State) => state.filterdFlights
    );
    export const selectFilteredSelectedFlights = createSelector(
      selectFlightFeature,
      (state:flights.State) => state.filterSelectedFlight
    );
    export const selectFlightType = createSelector(
      selectFlightFeature,
      (state:flights.State) => state.flightType
    );

    export const selectFlightSearchCretaria = createSelector(
      selectFlightFeature,
      (state:flights.State) => state.searchCretaria
    );
    
    export const selectedFlight = createSelector(
      //selected flight
      selectFlightFeature,
      
      (state:flights.State) => state.selectedFlight
    );

    export const offlineServicesLoader = createSelector(
      //selected flight
      selectFlightFeature,
      
      (state:flights.State) => state.offlineServicesLoader
    );

    export const selectedFlightTotalAmount = createSelector(
      //selected flight
      selectFlightFeature,
      
      (state:flights.State) => state.selectedFlightTotalAmount
    );
    export const selectPickySelection = createSelector(
      //fare rule selector
      selectFlightFeature,
      (state:flights.State) => state.pickySelection
    );
     
      export const fareRules = createSelector(
        //fare rule selector
        selectFlightFeature,
        (state:flights.State) => state.fareRules
      );
      
      export const offlineServices = createSelector(
        //offline service selector
        selectFlightFeature,
         (state:flights.State) => state.offlineServices)
      //-------------> App Utilities Selectors

      export const selectAllAirports = createSelector(
        selectUtilitiesFeature,
        (state:utilities.State) => state.airports
      );

      export const selectAllCurrency = createSelector(
        selectUtilitiesFeature,
        (state:utilities.State) => state.currency
      ); 

      export const selectAllPOS = createSelector(
        selectUtilitiesFeature,
        (state:utilities.State) => state.pos
      );
      
      export const selectCountries = createSelector(
        selectUtilitiesFeature,
        (state:utilities.State) => state.countryies
      );

      export const selectAllcities = createSelector(
        selectUtilitiesFeature,
        (state:utilities.State) => state.cities
      );
       

      //-------------> App Settings Selectors
      export const selectAppCurrency = createSelector(
        selectSettingsFeature,
        (state:settings.State) => state.currency
      );

      export const selectAppPOS = createSelector(
        selectSettingsFeature,
        (state:settings.State) => state.pos
      );

      export const selectAppLang = createSelector(
        selectSettingsFeature,
        (state:settings.State) => state.currentLang
      );

      //-------------> user data Selectors.

      export const selectUserLoader = createSelector(
        selectUserFeature,
        (state:user.State) => state.loading
      );

      export const selectUserData = createSelector(
        selectUserFeature,
        (state:user.State) => state.user
      );


      export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
  