import { TranslateService } from '@ngx-translate/core';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { userdata, UserLoginComponent } from '../user-login/user-login.component';
import { MyapiService } from '../../services/myapi.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthUser } from '@src/app/models/user/user';
import { Store } from '@ngrx/store';
import { loadAuthUserFailure, loadAuthUserSuccess } from '@src/app/store/user/user.actions';
import { FacebookLoginProvider, SocialAuthService } from 'angularx-social-login';
import { Location } from '@angular/common';

@Component({
  selector: 'app-user-signup',
  templateUrl: './user-signup.component.html',
  styleUrls: ['./user-signup.component.scss']
})
export class UserSignupComponent implements OnInit {
  userLoader: boolean = false;
  showPassword = false;
  showConfirmPassword = false;
  signupform: FormGroup;
  public getScreenWidth: any;
  constructor(
    private myapi: MyapiService,
    private _snackBar: MatSnackBar,
    private Router: Router,
    public translate: TranslateService,
    public dialog: MatDialog,
    private authService : SocialAuthService,
    private location: Location,
    private readonly store: Store) { }

  ngOnInit() {
    this.getScreenWidth = window.innerWidth;

    this.signupform = new FormGroup({
      'Isbase': new FormControl(1),
      'Email': new FormControl('', [Validators.email, Validators.minLength(8), Validators.required]),
      'Password': new FormControl('', [Validators.required, Validators.minLength(8)]),
      'FirstName': new FormControl(''),
      'LastName': new FormControl(''),
      'ImageURL': new FormControl(''),
      'PhoneNumber': new FormControl('', [Validators.required, Validators.minLength(5)]),
      'ConfirmPassword': new FormControl('', [Validators.required, Validators.minLength(8)]),
      'User_Name': new FormControl(''),
      'notification': new FormControl(true)
    })
  }
  onSubmit() {
    if(this.signupform.invalid) return;
    this.userLoader = true;
    this.signupform.get('User_Name').setValue(
      this.signupform.get('FirstName').value + this.signupform.get('LastName').value
    )
    this.myapi.signup(this.signupform.value).subscribe(
      (result: AuthUser) => {
        switch (result.Comment) {
          case 0: {
            this.userLoader = false;
            this.store.dispatch(loadAuthUserFailure({ error: 'somthing went wrong' }))
            break
          }
          case 1: {
            this.userLoader = false;
            sessionStorage.setItem('cls', result.AccessToken);
            this.openSnackBar("Welcome " + result.applicationUser.FirstName, 'Ok', "snakSuccess");
            this.store.dispatch(loadAuthUserSuccess({ user: result }))
            this.closeDialog()
            // this.toHomepage();
            break
          }
          case -2: {
            this.userLoader = false;
            this.openSnackBar("Sorry user does not exist", "Signup faield", "snakFaild");
            this.signupform.reset();
            this.signupform.updateValueAndValidity();
            this.store.dispatch(loadAuthUserFailure({ error: 'Signup faield' }))
            break
          }
          case -1: {
            this.userLoader = false;
            this.openSnackBar("User already exist", "Signup faield", 'snakFaild');
            this.store.dispatch(loadAuthUserFailure({ error: 'User already exist' }))
          }
        }
      }
    );

  }
  openSnackBar(message: string, action: string, Staus: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: [Staus]
    });
  }
  toHomepage() {
    this.Router.navigate(['/']);
  }

  toLogin() {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(UserLoginComponent,{ panelClass: 'custom-dialog-container'});
  }

  closeDialog() {
    this.dialog.closeAll()
  }
  close(){
    
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  toggleShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  submitFbLogin(){
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
  
  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }
}
