
import { Component, OnInit, inject } from '@angular/core';
import { HotelSearchService } from '../services/hotel-search.service';
import { MyapiService } from '../services/myapi.service';
import { HotelsCitiesModule } from '../models/hotels-cities/hotels-cities.module';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe, Location } from '@angular/common';
import { CurrencyModule } from '../models/currency/currency.module';
import { State, selectAppCurrency, selectSettingsFeature } from '../store/selectors';
import { take } from 'rxjs/operators';
import { Store } from '@ngrx/store';



@Component({
  selector: 'app-hotelsearch',
  templateUrl: './hotelsearch.component.html',
  styleUrls: ['./hotelsearch.component.css']
})
export class HotelsearchComponent implements OnInit {
  selectedCurrency$: Observable<CurrencyModule> = this.store.select(selectAppCurrency);
  private subscription: Subscription = new Subscription();
  setting$ = this.store.select(selectSettingsFeature);

  selectAllcities: HotelsCitiesModule[] = []
  RoomsA = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  childrenA = [0, 1, 2];
  currentCurr: string = 'KWD'
  lang: string = "en";
  today=new Date()
   lan:string  =localStorage.getItem('lang')?localStorage.getItem('lang'):'ar';

  constructor(public route:ActivatedRoute ,public hotelSearch: HotelSearchService, public MyApi: MyapiService, private readonly store: Store<State>, public router: Router, public location: Location, private datePipe: DatePipe) {

  }
  getcurr() {
   
    this.subscription.add(this.selectedCurrency$.pipe(take(1)).subscribe((curr) => {
      if (curr) {
        this.currentCurr = curr.Currency_Code
      }
      else {
        this.currentCurr = 'KWD'
      }
    }))
    return this.currentCurr
  }
   //get date of day
   todayDate() {
    let date = new Date();
    return date.toISOString().split('T')[0];

  }
  ngOnInit(): void {
    this.setting$.subscribe((v) => {
      this.lan = v.currentLang;
     


    })
   console.log("lang", this.lang,this.currentCurr ,"lan", this.lan)

    let form = JSON.parse(localStorage.getItem('form') as string);
    this.hotelSearch.initSearchForm(form)
    this.hotelSearch.HotelSearchForm.get("nation")?.setValue('Kuwait')
      this.hotelSearch.HotelSearchForm.get("roomN")?.setValue(this.hotelSearch.roomNumber);

  }
  //show cityname and country
  displayFn(city?: HotelsCitiesModule): string | undefined {
    return city ? city.City + ',' + city.Country : undefined;
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    let filter = filterValue.trim().toLowerCase();
    this.MyApi.getHotelsCitiesbyKey(filter).subscribe((x) => {
      this.selectAllcities = x;

    })
  }
  onSubmit() {

  
    let checkIn = this.datePipe.transform(this.hotelSearch.HotelSearchForm.get("checkIn").value, 'MMMM dd, y')
    let checkOut = this.datePipe.transform(this.hotelSearch.HotelSearchForm.get("checkOut").value, 'MMMM dd, y')
    this.hotelSearch.onSubmit(this.lan, this.currentCurr, 'KW')
    const url = this.router.serializeUrl(this.router.createUrlTree(['hotelResult', this.lan, this.currentCurr, 'EG', this.hotelSearch.searchApi.serachId, this.hotelSearch.searchApi.CityName, this.hotelSearch.searchApi.citywithcountry, this.hotelSearch.searchApi.nation, checkIn, checkOut, this.hotelSearch.searchApi.roomN, this.hotelSearch.stringGuest]));
       window.location.href='http://154.41.209.73:5442'+url
  // }
  }

}