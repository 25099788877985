import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  faFacebook,
  faInstagram,
  faTwitter,
  faYoutube,
  faWhatsapp,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCheck,
  faEnvelope,
  faInfoCircle,
  faMapMarker,
  faPhone,
  faTimesCircle,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";

import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { contactUs } from "../store/app-utilities/app-utilities.actions";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent implements OnInit {
  faInstagram = faInstagram;
  faFacebook = faFacebook;
  faTwitter = faTwitter;
  faYoutube = faYoutube;
  faLinkedIn= faLinkedin;
  faPhone = faPhone;
  faWhatsapp = faWhatsapp ;
  faMapMarkerAlt =faMapMarkerAlt;
  faEnvelope = faEnvelope;
  faMapMarker = faMapMarker;
  faInfoCircle = faInfoCircle;
  faCheck = faCheck;
  faTimesCircle = faTimesCircle;

  sendmessage: FormGroup;

  infoAlert: boolean = false;
  successAlert: boolean = false;
  constructor(
    public translate: TranslateService,
    private formBuilder: FormBuilder,
    private readonly store: Store,
    private gtmService: GoogleTagManagerService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.sendmessage = this.formBuilder.group({
      Name: ["", Validators.required],
      subject: [""],
      email: ["", [Validators.required]],
      message: ["", Validators.required],
    });
  }

  /**
   * Send Message Through the contact us form & handle alerts
   */
  sendMesssage() {
    this.infoAlert = false;
    this.successAlert = false;
    if (this.sendmessage.valid) {
      this.store.dispatch(contactUs({ data: this.sendmessage.value }));
      this.successAlert = true;
    } else {
      this.infoAlert = true;
    }
  }

  /**
   * close the alert dialogue of the contact us section
   */
  closeAlert() {
    this.infoAlert = false;
    this.successAlert = false;
  }

  /**
   * Scroll Automatically to the top view of the current window
   */
  scrollToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }
  whatsApp(){
    const whatsAPPf = {
      event: 'Whatsapp Button',
      position: 'from footer',
      language: this.translate.currentLang,
      url:this.router.url
    };
    this.gtmService.pushTag(whatsAPPf);
   }
   call(){
    const PhoneCAllf = {
      event: 'Call Button',
      position: 'from footer',
      language: this.translate.currentLang,
      url:this.router.url
    };
    this.gtmService.pushTag(PhoneCAllf);

   }
}
