import { Component, OnInit } from '@angular/core';
import { faCheckCircle, faUserLock } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  faUserLock= faUserLock;
  faCheckCircle =faCheckCircle;
  constructor(
    public translate :TranslateService,
    private authService : SocialAuthService,
  ) { }

  ngOnInit() {
  }
  submitGoogleLogin(){
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }
  submitFbLogin(){
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
}
