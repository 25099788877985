<app-header [bg]=true></app-header>
<!-- <app-navlist></app-navlist> -->



<section class="contactUs">
  <div class="header">
    <h1>{{"contact.contactUs2" | translate }}</h1>
  </div>
  <div class="container" [ngClass]="{ar: translate.currentLang =='ar'}">
    <div class="form">
      <div class="row">
        <div class="form_wrapperLoca col-lg-4 order-sm-2">
          <h4 class="contactTitle">{{'contact.contactInfo' | translate}}</h4>
          <div class="row">
            <div class="col-12 d-flex my-3">
              <div class="iconImg">
                <img src="../../assets/img/albaraq/PhoneIcon.svg">
              </div>
              <div class="txt">
                <p>{{'contact.phoneNumber' | translate}}</p>
              </div>
            </div>
            <div class="col-12 d-flex mb-3">
              <div class="iconImg">
                <img src="../../assets/img/albaraq/WhatsAppIcon.svg">
              </div>
              <div class="txt">
                <p>{{'footer.whatsApp' | translate}}</p>
              </div>
            </div>
            <div class="col-12 d-flex mb-4">
              <div class="iconImg">
                <fa-icon class="icon" [icon]="faEnvelope"></fa-icon>
              </div>
              <div class="txt">
                <p><a style="color:inherit" href="mailto: Support@Tazkrti.com">{{"footer.email" | translate}}</a></p>
              </div>
            </div>
            <!-- MAP -->
            <div class="map col-12">
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe width="100%" height="100%" id="gmap_canvas"
                    src="https://maps.google.com/maps?q=Dawliyah%20Commercial%20Complex,%20Kuwait%20City,%20Kuwait.&t=&z=13&ie=UTF8&iwloc=&output=embed"
                    frameborder="0" scrolling="no" marginheight="0" marginwidth="0">
                  </iframe>
                  <a href="https://www.whatismyip-address.com"></a>
                  <br>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form_wrapper col-lg-8 order-sm-1">
          <h4 class="formTitle">{{'contact.send' | translate}}</h4>
          <form [formGroup]="sendmessage" (ngSubmit)="onSubmit()">
            <div class="row">
              <!-- First Name Input -->
              <div class="col-12 col-lg-6">
                <label class="inputTxt col-12">{{'contact.firstName' | translate}}*</label>
                <input type="text" class="inputField col-12 form-control" formControl="FistName"
                  [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }">
                <!-- Error Message -->
                <div *ngIf="submitted && f.FirstName.errors" class="error-alert mt-2">
                  <div *ngIf="f.FirstName.errors.required" class="text-danger">
                    {{"contact.err1" | translate}}
                  </div>
                </div>
              </div>
              <!-- Last Name Input -->
              <div class="col-12 col-lg-6">
                <label class="inputTxt col-12">{{'contact.lastName' | translate}}*</label>
                <input type="text" class="inputField col-12 form-control" formControl="LastName"
                  [ngClass]="{ 'is-invalid': submitted && f.LastName.errors }">
                <!-- Error Message -->
                <div *ngIf="submitted && f.LastName.errors" class="error-alert mt-2">
                  <div *ngIf="f.LastName.errors.required" class="text-danger">
                    {{"contact.err1" | translate}}
                  </div>
                </div>
              </div>
              <!-- Email Input -->
              <div class="col-12 col-lg-6">
                <label class="inputTxt col-12">{{'contact.email' | translate}}</label>
                <input type="text" class="inputField col-12 form-control" formControlName="email"
                  [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                <!-- Email Error Message -->
                <div *ngIf="submitted && f.email.errors" class="error-alert mt-2">
                  <div *ngIf="f.email.errors.required" class="text-danger">
                    {{"contact.err2" | translate}}
                  </div>
                  <div *ngIf="f.email.errors.email" class="text-danger">
                    {{"contact.err3" | translate}}
                  </div>
                </div>
              </div>
              <!-- Phone Number -->
              <div class="col-12 col-lg-6">
                <label class="inputTxt col-12">{{'contact.phone' | translate}}</label>
                <input type="text" class="inputField col-12 form-control" formControl="Phone" [ngClass]="{ 'is-invalid': submitted && f.FirstName.errors }">
                <!-- ERROR MESSAGE -->
                <div *ngIf="submitted && f.Phone.errors" class="error-alert mt-2">
                  <div *ngIf="f.Phone.errors.required" class="text-danger">
                    {{"contact.err4" | translate}}
                  </div>
                  <div *ngIf="f.Phone.errors.email" class="text-danger">
                    {{"contact.err4" | translate}}
                  </div>
                </div>
              </div>
              <!-- Message Field -->
              <div class="form-group col-12 col-lg-12">
                <label class="inputTxt col-12">{{'contact.help' | translate}}</label>
                <textarea class="form-control" formControlName="message" name="message" rows="6" data-rule="required"
                  data-msg="Please write something for us" [ngClass]="{ 'is-invalid': submitted && f.message.errors }"
                  type="text" class="inputField col-12"></textarea>
                <!--ERROR MESSAGE-->
                <div *ngIf="submitted && f.message.errors" class="error-alert mt-2">
                  <div *ngIf="f.message.errors.required" class="text-danger">
                    <fa-icon class="currArrow" [icon]="faInfoCircle" aria-hidden="true"></fa-icon>
                    {{"contact.err5" | translate}}
                  </div>
                </div>
              </div>
            </div>
            <div class="submitBtn">
              <button class="" type="submit">
                {{ "contact.submit" | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>

<app-footer></app-footer>
<!-- #footer -->