import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FarRulesComponent } from './../far-rules/far-rules.component';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';


import {
  airItineraries,
  fare,
  FareRules,
  flight,
  flightOfflineService,
  passengerFareBreakDownDTOs,
  Selectedflight,
} from '../../interface/flight-search-result';
import { MyapiService } from '@src/app/services/myapi.service';
import { FlightServiceService } from '@src/app/services/flight-service.service';
import { faAngleDoubleDown, faArrowDown, faArrowRight, faCaretRight, faChevronDown, faChevronRight, faForward, faGripVertical, faInfo, faInfoCircle, faLuggageCart, faPlane, faPlaneArrival, faPlaneDeparture, faSlidersH, faSuitcaseRolling, faUsers, faUtensils, faWifi } from '@fortawesome/free-solid-svg-icons';
import { faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { SelectReturnFlightComponent } from '../select-return-flight/select-return-flight.component';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '@src/app/store/flight-search/flight-search.reducer';
import { selectedFlight } from '@src/app/store/selectors';
import { pickySelectionAction } from '@src/app/store/flight-search/flight-search.actions';
import { Subscription } from 'rxjs';

type fareCalc = (fare:fare[])=>number;
type calcEqfare =(flightFaresDTO: passengerFareBreakDownDTOs[],type:string,farecalc:fareCalc)=>number;
declare var $: any;

export interface BreakDownView{
  ADT:{
    totalFare:[number,string],
    ScFare:[number,string,number]
  },
  CNN:{
    totalFare:[number,string],
    ScFare:[number,string,number]
  },
  INF:{
    totalFare:[number,string],
    ScFare:[number,string,number]
  }
}

@Component({
  selector: 'app-round-trip',
  templateUrl: './round-trip.component.html',
  styleUrls: ['./round-trip.component.scss']
})
export class RoundTripComponent implements OnInit,OnDestroy {
  subscribtions:Subscription = new Subscription() ;
  faLuggageCart = faLuggageCart;
  faPlaneDeparture = faPlaneDeparture;
  faPlaneArrival = faPlaneArrival;
  faUsers = faUsers;
  faPlane = faPlane;
  faAngleDoubleDown = faAngleDoubleDown;
  faForward = faForward;
  faSlidersH = faSlidersH;
  faCaretRight = faCaretRight;
  faInfoCircle = faInfoCircle;
  faArrowRight = faArrowRight;
   faArrowDown = faArrowDown;
  faChevronRight= faChevronRight;
   faSuitcaseRolling=faSuitcaseRolling;
   showDetails: boolean = false;
  showDetailsMore: boolean[] = [];
  disc: [number, string, string];
  fareView:BreakDownView;
   faGripVertical = faGripVertical;
   faInfo=faInfo;
   faUtensils = faUtensils;
   faYoutubeSquare= faYoutubeSquare;
   faWifi=faWifi;
   faChevronDown=faChevronDown;
   @Input() item: airItineraries[] = [];
   @Input() Selected:Selectedflight ;
   @Input() i: number = 0;
   @Input() over: boolean = false;
   @Input() checkout: boolean;
   FareLoading: boolean = true;
   fare: FareRules[];
   trueT: boolean[] = [];
   FlightData:any;
   moreT: boolean = true;
   airItinerData:airItineraries
   fN:number
   constructor(
     public flight: FlightServiceService,
      private search: MyapiService,
       private modalService: NgbModal,
       private router: Router,
       public translate : TranslateService,
       private store: Store<State>,) { }
 
   ngOnInit() {
     this.trueT = this.flight.valuesoftrue(this.item);
    if (this.Selected != undefined) {
      this.item = this.mapSelected(this.Selected);
    }

   this.item.map(v=>{
   this.airItinerData= v
   })

   }
   showFareRules(squencNumber: number, pKey: string) {
     this.FareLoading = true;
     this.subscribtions.add(
     this.subscribtions.add(this.search.fareRules(this.flight.serachId, squencNumber, pKey).subscribe(
       (result) => {
         this.FareLoading = false;
         this.fare = result;
       }
 
     )));
   }
 
 
   togglemodel(index: number, sequenceNum: number, pKey: string) {
     let id = '#fareModal' + index;
     $(id).modal('toggle');
     this.showFareRules(sequenceNum, pKey);
   }
 
   togglemodelt(i: number, m: number, sequenceNum: number, pKey: string) {
     let id = '#fareModal' + i + 'l' + m;
     $(id).modal('toggle');
     this.showFareRules(sequenceNum, pKey);
   }
 
 
   open(sequenceNum: number, pKey: string) {
 
     const modalRef = this.modalService.open(FarRulesComponent);
     modalRef.componentInstance.serachId = this.flight.serachId;
     modalRef.componentInstance.squencNumber = sequenceNum;
     modalRef.componentInstance.providerKey = pKey;
 
 
 
   }
   selectReturnFlight(item: airItineraries, FlightIndex:number){
    if(item ){
      this.FlightData= item.allJourney.flights[FlightIndex];
      this.store.dispatch(pickySelectionAction({flight:item.allJourney.flights[FlightIndex], airItinerary:item ,flightIndex:FlightIndex}));
      this.flight.SelectOneFlight = true;
    }else {
      return
    }
   }

  toggleDetails() {
    this.showDetails = !this.showDetails;
  }
  toggleDetailsMore(index: number) {
    this.showDetailsMore[index] = !this.showDetailsMore[index];
  }



  mapSelected(selected: Selectedflight): airItineraries[] {
    debugger
    let out: airItineraries = {
      sequenceNum: selected.airItineraryDTO.sequenceNum,
      pKey: selected.airItineraryDTO.pKey,
      isRefundable: selected.airItineraryDTO.isRefundable,
      itinTotalFare: selected.airItineraryDTO.itinTotalFare,
      totalDuration: selected.airItineraryDTO.totalDuration,
      deptDate: selected.airItineraryDTO.deptDate,
      arrivalDate: selected.airItineraryDTO.arrivalDate,
      cabinClass: selected.airItineraryDTO.cabinClass,
      flightType: selected.airItineraryDTO.flightType,
      pcc: selected.airItineraryDTO.pcc,
      allJourney: {
        flights: selected.airItineraryDTO.allJourney.flights
      },
      baggageInformation: selected.airItineraryDTO.baggageInformation
    }

    return [out]
  }

  
  /**
  * 
  * @param flightFaresDTO 
  * @param passNumber 
  * @returns [total value ,curruncy code]
  */
   returnPassTotalFar(flightFaresDTO: fare[], passNumber: number, calcfare: fareCalc): [number, string] {
    let Total: fare = flightFaresDTO.filter(v => v.fareType.toLowerCase() === 'equivfare')[0];
    return Total ? [calcfare(flightFaresDTO) * passNumber, Total.currencyCode] : [NaN, 'KWD'];
  }
  /**
   * 
   * @param flightFaresDTO 
   * @param passNumber 
   * @returns [total value per passenger ,curruncy code , number of passenger]
   */
  returnPassFareScatterd(flightFaresDTO: fare[], passNumber: number, calcfare: fareCalc): [number, string, number] {
    let Total: fare = flightFaresDTO.filter(v => v.fareType.toLowerCase() === 'equivfare')[0];
    return Total ? [calcfare(flightFaresDTO), Total.currencyCode, passNumber] : [NaN, 'KWD', NaN];
  }
  /**
   * 
   * @param flightFaresDTO 
   * @param totalAmount 
   * @param totalTax 
   * @param curruncy 
   * @param calcEqfare 
   * @param fareCalc 
   * @returns value of discount or service fees
   */
  returnPassTotalFarDifferance(flightFaresDTO: passengerFareBreakDownDTOs[], totalAmount: number, totalTax: number, curruncy: string, calcEqfare: calcEqfare, fareCalc: fareCalc): [number, string, string] {
    if(!flightFaresDTO){
      return  [0, '', 'KWD'];
    }
    let AdtFares = calcEqfare(flightFaresDTO, 'ADT', fareCalc);
    let childFare = calcEqfare(flightFaresDTO, 'CNN', fareCalc);
    let infentFare = calcEqfare(flightFaresDTO, 'INF', fareCalc);
    let TotalFare = AdtFares + childFare + infentFare + totalTax;
    let fareDiff = totalAmount - TotalFare;
    if (fareDiff > 0) {
      return [Math.round(fareDiff), 'Service Fees', curruncy]
    } else if (fareDiff < 0) {
      return [Math.round(-1 * fareDiff), 'Discount', curruncy]
    } else {
      return [0, '', 'KWD'];
    }

  }

  /**
   * 
   * @param flightFaresDTO 
   * @param type 
   * @param farecalc 
   * @returns numer of passenger * fare of passenger
   */
  calcEqfare(flightFaresDTO: passengerFareBreakDownDTOs[], type: string, farecalc: fareCalc): number {
    let fare = farecalc(flightFaresDTO.filter((v) => v.passengerType === type)[0]?.flightFaresDTOs);
    // let fare = flightFaresDTO.filter((v)=>v.passengerType === type)[0]?.flightFaresDTOs.filter(v=>v.fareType.toLowerCase() === 'equivfare')[0].fareAmount;
    let quntity = flightFaresDTO.find((v) => v.passengerType === type)?.passengerQuantity;
    return fare && quntity ? fare * quntity : 0;
  }

  /**
   * 
   * @param fare 
   * @returns validate equivelent fare
   */

  returnCorrectFare(fare: fare[]): number {
    if (fare) {
      let equivfare = fare.find(v => v.fareType.toLowerCase() === 'equivfare').fareAmount;
      let totalFare = fare.find(v => v.fareType.toLowerCase() === 'totalfare').fareAmount;
      let totalTax = fare.find(v => v.fareType.toLowerCase() === 'totaltax').fareAmount;
      return equivfare > 0 ? equivfare : totalFare - totalTax;
    } else {
      return 0
    }

  }



 ngOnDestroy(): void {
   this.subscribtions.unsubscribe();
 }
}

