<div
  class="searchBoxContainer"
  [ngClass]="{ ar: translate.currentLang == 'ar' }"
>
  <form [formGroup]="searchFlight" class="SearchForm">
    <div class="container-fluid">
      <div class="row mb-3">
        <div class="col-12 col-12 col-lg-5 col-xl-5 mobileHide">
          <mat-radio-group formControlName="flightType">
            <div class="row mt-2">
              <div class="col-4">
                <mat-radio-button value="oneway" (change)="radioChange($event)">
                  {{ "searchFlight.oneway" | translate }}
                </mat-radio-button>
              </div>
              <div class="col-4">
                <mat-radio-button
                  value="RoundTrip"
                  (change)="radioChange($event)"
                >
                  {{ "searchFlight.RoundTrip" | translate }}
                </mat-radio-button>
              </div>
              <div class="col-4">
                <mat-radio-button
                  value="Multicity"
                  (change)="radioChange($event)"
                  (click)="validateDate()"
                >
                  {{ "searchFlight.multi" | translate }}
                </mat-radio-button>
              </div>
            </div>
          </mat-radio-group>
        </div>
        <div class="col-0 col-md-0 col-lg-2 col-xl-2 mobileHide"></div>
        <!---->
        <div class="col-4 mobilePad0 col-lg-3 col-xl-3 desktopHide">
          <button
            class="selectClass col-12"
            mat-button
            [matMenuTriggerFor]="flightTypeMenu"
          >
            {{ searchFlight.get("flightType").value | translate }}
            <fa-icon class="passMenuIcon" [icon]="faCaretDown"></fa-icon>
          </button>
          <mat-menu #flightTypeMenu="matMenu" class="classMenu">
            <div
              [ngClass]="{
                selectedClass: searchFlight.get('flightType').value == 'oneway'
              }"
              class="classOption"
              (click)="selectFlightType('oneway')"
            >
              {{ "searchFlight.oneway" | translate }}
            </div>
            <div
              [ngClass]="{
                selectedClass:
                  searchFlight.get('flightType').value == 'RoundTrip'
              }"
              class="classOption"
              (click)="selectFlightType('RoundTrip')"
            >
              {{ "searchFlight.RoundTrip" | translate }}
            </div>
            <div
              [ngClass]="{
                selectedClass:
                  searchFlight.get('flightType').value == 'Multicity'
              }"
              class="classOption"
              (click)="selectFlightType('Multicity')"
            >
              {{ "searchFlight.multi" | translate }}
            </div>
          </mat-menu>
        </div>
        <div class="col-8 mobilePad0 col-lg-3 col-xl-3">
          <div class="row mobPRLeftRow">
            <!--CLASS MENU-->
            <ng-container>
              <button
                class="selectClass col-6"
                mat-button
                [matMenuTriggerFor]="classmenu"
              >
                {{ searchFlight.get("class").value | translate }}
                <fa-icon class="passMenuIcon" [icon]="faCaretDown"></fa-icon>
              </button>
              <mat-menu #classmenu="matMenu" class="classMenu">
                <div
                  [ngClass]="{
                    selectedClass: searchFlight.get('class').value == 'Economy'
                  }"
                  class="classOption"
                  (click)="selectClass('Economy')"
                >
                  {{ "searchFlight.economy" | translate }}
                </div>

                <div
                  [ngClass]="{
                    selectedClass: searchFlight.get('class').value == 'Premium'
                  }"
                  class="classOption"
                  (click)="selectClass('Premium')"
                >
                  {{ "searchFlight.premium" | translate }}
                </div>

                <div
                  [ngClass]="{
                    selectedClass: searchFlight.get('class').value == 'Business'
                  }"
                  class="classOption"
                  (click)="selectClass('Business')"
                >
                  {{ "searchFlight.business" | translate }}
                </div>

                <div
                  [ngClass]="{
                    selectedClass: searchFlight.get('class').value == 'First'
                  }"
                  class="classOption"
                  (click)="selectClass('First')"
                >
                  {{ "searchFlight.first" | translate }}
                </div>
              </mat-menu>
            </ng-container>
            <!--PASSANGER MENUE-->
            <div class="col-6 mobPRLeft">
              <ng-container formGroupName="passengers">
                <button
                  class="passMenu"
                  mat-button
                  [matMenuTriggerFor]="passMenu"
                >
                  {{
                    searchFlight.get("passengers").get("adults").value +
                      searchFlight.get("passengers").get("child").value +
                      searchFlight.get("passengers").get("infent").value
                  }}
                  {{ "searchFlight.tAndC" | translate }}
                  <fa-icon class="passMenuIconP" [icon]="faCaretDown"></fa-icon>
                </button>

                <mat-menu #passMenu="matMenu" class="passMenue">
                  <div
                    class="inputText mt-3"
                    (click)="$event.stopPropagation()"
                  >
                    <div class="theTextWrap">
                      <div class="theText">
                        {{ "searchFlight.adults" | translate }}
                      </div>
                      <div class="textIndicator">
                        {{ "searchFlight.adultsIndicator" | translate }}
                      </div>
                    </div>
                    <div class="theInput">
                      <span
                        class="IncAndDex"
                        (click)="changeAdultValue('decrease')"
                        >-</span
                      >
                      <span class="theValue">{{
                        searchFlight.get("passengers").get("adults").value
                      }}</span>
                      <span
                        class="IncAndDex"
                        (click)="changeAdultValue('increase')"
                        >+</span
                      >
                    </div>
                  </div>

                  <div
                    class="inputText mt-3"
                    (click)="$event.stopPropagation()"
                  >
                    <div class="theTextWrap">
                      <div class="theText">
                        {{ "searchFlight.child" | translate }}
                      </div>
                      <div class="textIndicator">
                        {{ "searchFlight.childIndicator" | translate }}
                      </div>
                    </div>
                    <div class="theInput">
                      <span
                        class="IncAndDex"
                        (click)="changeChildValue('decrease')"
                        >-</span
                      >
                      <span class="theValue">{{
                        searchFlight.get("passengers").get("child").value
                      }}</span>
                      <span
                        class="IncAndDex"
                        (click)="changeChildValue('increase')"
                        >+</span
                      >
                    </div>
                  </div>

                  <div
                    class="inputText mt-3"
                    (click)="$event.stopPropagation()"
                  >
                    <div class="theTextWrap">
                      <div class="theText">
                        {{ "searchFlight.infent" | translate }}
                      </div>
                      <div class="textIndicator">
                        {{ "searchFlight.infentIndicator" | translate }}
                      </div>
                    </div>
                    <div class="theInput">
                      <span
                        class="IncAndDex"
                        (click)="changeInfantValue('decrease')"
                        >-</span
                      >
                      <span class="theValue">{{
                        searchFlight.get("passengers").get("infent").value
                      }}</span>
                      <span
                        class="IncAndDex"
                        (click)="changeInfantValue('increase')"
                        >+</span
                      >
                    </div>
                  </div>

                  <div class="text-center">
                    <button mat-raised-button color="primary" class="doneBtn">
                      {{ "searchFlight.done" | translate }}
                    </button>
                  </div>
                </mat-menu>
              </ng-container>
            </div>
          </div>
        </div>
        <!-- -->
        <div class="col-0 col-md-0 col-lg-2 col-xl-2 mobileHide"></div>
      </div>
      <!-- oneway component -->
      <div *ngIf="searchFlight.get('flightType').value == 'oneway'">
        <div class="row">
          <!--airports selections-->
          <ng-container formArrayName="Flights">
            <ng-container
              *ngFor="
                let item of flightsArray['controls'] | slice : 0 : 1;
                let i = index
              "
            >
              <ng-container [formGroupName]="0">
                <div class="col-12 col-md-12 col-lg-3 col-xl-3 p-1">
                  <mat-form-field color="accent" appearance="outline">
                    <input
                      type="text"
                      placeholder="{{ 'searchFlight.dpart' | translate }}"
                      aria-label="City"
                      matInput
                      formControlName="departing"
                      [matAutocomplete]="autoa"
                      #firstD
                    />

                    <mat-autocomplete #autoa="matAutocomplete">
                      <mat-option
                        *ngFor="
                          let city of cities
                            | filterCity : item.get('departing').value
                            | limitTo : 5
                        "
                        [value]="city.cityName + '(' + city.airportCode + ')'"
                      >
                        <span
                          [innerHTML]="
                            city.airportName
                              | highlighter : item.get('departing').value
                          "
                        ></span>
                        -
                        <span
                          [innerHTML]="
                            city.countryName
                              | highlighter : item.get('departing').value
                          "
                        ></span>
                        (
                        <span
                          [innerHTML]="
                            city.airportCode
                              | highlighter : item.get('departing').value
                          "
                        ></span>
                        )
                      </mat-option>
                    </mat-autocomplete>
                    <img
                      height="25"
                      matPrefix
                      src="./../../assets/img/albaraq/LocationIcon.svg"
                      class="IconDepar"
                      alt="departing"
                    />
                  </mat-form-field>
                </div>
                <div class="divswitch" (click)="switchDes(item)">
                  <img
                    class="icon-arrow WHmobile"
                    src="../../assets/img/albaraq/SwitchIcon.svg"
                    alt=""
                    srcset=""
                  />
                </div>

                <div class="col-12 col-md-12 col-lg-3 col-xl-3 p-1">
                  <mat-form-field color="accent" appearance="outline">
                    <input
                      type="text"
                      placeholder="{{ 'searchFlight.land' | translate }}"
                      aria-label="City"
                      matInput
                      formControlName="landing"
                      [matAutocomplete]="autob"
                      #firstAr
                    />

                    <mat-autocomplete #autob="matAutocomplete">
                      <mat-option
                        *ngFor="
                          let city of cities
                            | filterCity : item.get('landing').value
                            | limitTo : 5
                        "
                        [value]="city.cityName + '(' + city.airportCode + ')'"
                      >
                        <span
                          [innerHTML]="
                            city.airportName
                              | highlighter : item.get('landing').value
                          "
                        ></span>
                        -
                        <span
                          [innerHTML]="
                            city.countryName
                              | highlighter : item.get('landing').value
                          "
                        ></span>
                        (
                        <span
                          [innerHTML]="
                            city.airportCode
                              | highlighter : item.get('landing').value
                          "
                        ></span
                        >)
                      </mat-option>
                    </mat-autocomplete>
                    <img
                      height="25"
                      matPrefix
                      src="./../../assets/img/albaraq/LocationIcon.svg"
                      class="IconDepar"
                      alt="departing"
                    />
                  </mat-form-field>
                </div>

                <!--ONE WAY DATES SELECTIONS (POP MENUE)-->
                <mat-menu #oneWayCalender="matMenu" class="calenderMenu">
                  <div class="datesSummary" (click)="$event.stopPropagation()">
                    <div class="dateSummaryBox">
                      <!-- <span>{{ "searchFlight.Today" | translate }}</span> -->
                      <span class="dateSummaryValue" [ngClass]="{
                        'dateSummaryValueAR': translate.currentLang=='ar'
                      }">{{
                        getFormattedDate(fromDateBinding[i])
                      }}</span>
                    </div>
                  </div>
                  <ngb-datepicker
                 
                    class="searchBoxPicker"
                    [displayMonths]="1"
                    
                    (click)="$event.stopPropagation()"

                    navigation="arrows"
                    [dayTemplate]="onwwayDAY"
                    outsideDays="hidden"
                    [minDate]="minDate"
                  >
                  </ngb-datepicker>

                  <ng-template
                    #onwwayDAY
                    let-date
                    let-focused="focused"
                    (click)="$event.stopPropagation()"
                  >
                    <span
                      class="custom-day"
                      (click)="$event.stopPropagation()"
                      (click)="onOneWayDateSelection(date, i)"
                      [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date"
                      (click)="onDateSelection(date, i)"
                      (click)="closeOneWay()"
                      (mouseleave)="hoveredDate = null"
                    >
                      {{ date.day | translate }}
                    </span>
                  </ng-template>
                </mat-menu>

                <!--ONE WAY DATES SECTION-->
                <div
                  class="col-12 col-lg-4 p-1"
                  [matMenuTriggerFor]="oneWayCalender"
                  id="oneWayCalenderTrigger"
                  #oneWayCalenderTrigger
                >
                  <div class="selectionBox">
                    <div class="selectionBoxField">
                      <img
                        height="55"
                        style="width: 5%"
                        matPrefix
                        class="IconDepar"
                        src="../../assets/img/albaraq/CheckIn.svg"
                      />
                      <p class="selectionBoxFieldValue">
                        {{ getFormattedDate(fromDateBinding[i]) }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-lg-2 p-1 searchDivOne">
                  <button
                    [ngClass]="{
                      'submitBtn-valid': searchFlight.valid && isHomeComponent
                    }"
                    class="submitBtn"
                    mat-raised-button
                    (click)="onSubmit()"
                  >
                    {{ "searchFlight.search" | translate }}
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <!-- Direct Button -->
          <div class="col-12 desktopHide">
            <mat-checkbox formControlName="Direct">{{
              "searchFlight.dirc" | translate
            }}</mat-checkbox>
          </div>
          <div class="col-12 mobileHide">
            <mat-checkbox formControlName="Direct">{{
              "searchFlight.dirc" | translate
            }}</mat-checkbox>
          </div>
        </div>
      </div>
      <!--RoundTrip  -->
      <div *ngIf="searchFlight.get('flightType').value == 'RoundTrip'">
        <div class="row">
          <!--airports selections-->
          <ng-container formArrayName="Flights">
            <ng-container
              *ngFor="
                let item of flightsArray['controls'] | slice : 0 : 1;
                let i = index
              "
            >
              <ng-container [formGroupName]="0">
                <div class="col-12 col-md-12 col-lg-3 col-xl-3 p-1">
                  <mat-form-field color="accent" appearance="outline">
                    <input
                      type="text"
                      placeholder="{{ 'searchFlight.dpart' | translate }}"
                      aria-label="City"
                      matInput
                      formControlName="departing"
                      [matAutocomplete]="autoa"
                      #firstD
                    />

                    <mat-autocomplete #autoa="matAutocomplete">
                      <mat-option
                        *ngFor="
                          let city of cities
                            | filterCity : item.get('departing').value
                            | limitTo : 5
                        "
                        [value]="city.cityName + '(' + city.airportCode + ')'"
                      >
                        <span
                          [innerHTML]="
                            city.airportName
                              | highlighter : item.get('departing').value
                          "
                        ></span>
                        -
                        <span
                          [innerHTML]="
                            city.countryName
                              | highlighter : item.get('departing').value
                          "
                        ></span>
                        (
                        <span
                          [innerHTML]="
                            city.airportCode
                              | highlighter : item.get('departing').value
                          "
                        ></span>
                        )
                      </mat-option>
                    </mat-autocomplete>
                    <img
                      height="25"
                      matPrefix
                      src="./../../assets/img/albaraq/LocationIcon.svg"
                      class="IconDepar"
                      alt="departing"
                    />
                  </mat-form-field>
                </div>
                <div class="divswitch" (click)="switchDes(item)">
                  <img
                    class="icon-arrow WHmobile"
                    src="../../assets/img/albaraq/SwitchIcon.svg"
                    alt=""
                    srcset=""
                  />
                </div>

                <div class="col-12 col-md-12 col-lg-3 col-xl-3 p-1">
                  <mat-form-field color="accent" appearance="outline">
                    <input
                      type="text"
                      placeholder="{{ 'searchFlight.land' | translate }}"
                      aria-label="City"
                      matInput
                      formControlName="landing"
                      [matAutocomplete]="autob"
                      #firstAr
                    />

                    <mat-autocomplete #autob="matAutocomplete">
                      <mat-option
                        *ngFor="
                          let city of cities
                            | filterCity : item.get('landing').value
                            | limitTo : 5
                        "
                        [value]="city.cityName + '(' + city.airportCode + ')'"
                      >
                        <span
                          [innerHTML]="
                            city.airportName
                              | highlighter : item.get('landing').value
                          "
                        ></span>
                        -
                        <span
                          [innerHTML]="
                            city.countryName
                              | highlighter : item.get('landing').value
                          "
                        ></span>
                        (
                        <span
                          [innerHTML]="
                            city.airportCode
                              | highlighter : item.get('landing').value
                          "
                        ></span
                        >)
                      </mat-option>
                    </mat-autocomplete>
                    <img
                      height="25"
                      matPrefix
                      src="./../../assets/img/albaraq/LocationIcon.svg"
                      class="IconDepar"
                      alt="departing"
                    />
                  </mat-form-field>
                </div>
             
                <!--ROUND TRIP DATES SELECTIONS (POP MENUE)-->
                
                <!-- departCalender in roundTrip -->
                <mat-menu #roundTripCalenders="matMenu" class="calenderMenu">
                  <div class="datesSummary" >
                    <div class="dateSummaryBox">
                      <!-- <span>{{'searchFlight.Today' | translate}}</span> -->
                      <span class="dateSummaryValue"  [ngClass]="{
                        'dateSummaryValueAR': translate.currentLang=='ar'
                      }">{{getFormattedDate(fromDateBinding[i]) }}</span>
                    </div>
                  </div>
                  <ngb-datepicker  class="searchBoxPicker" [displayMonths]="1" #oneway (click)="$event.stopPropagation()"
                  outsideDays="hidden"
                  
                     navigation="arrows" [dayTemplate]="onwwayDAY" [minDate]="minDate" [startDate]="StartDate"> </ngb-datepicker>

                  <ng-template #onwwayDAY  let-date let-focused="focused" >
                    <span class="custom-day" 
                    
                      (click)="onOneWayDateSelection(date , i)" [class.range]="isRange(date)"
                   [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date" (click)="onDateSelection(date , i)"
                      (mouseleave)="hoveredDate = null"
                      >
                      {{ date.day }}
                    </span>
                  </ng-template>
                </mat-menu>
                <!--  ReturnCalender in roundTrip-->
                <mat-menu #roundTripCalender="matMenu" class="calenderMenu">
                  <div class="datesSummary" (click)="$event.stopPropagation()">
                    <div class="dateSummaryBox d-flex justify-content-center">
                      <!-- <span>{{'searchFlight.Today' | translate}}</span> -->

                      <span class="dateSummaryValue" [ngClass]="{
                        'dateSummaryValueAR': translate.currentLang=='ar'
                      }">{{getFormattedDate(fromDateBinding[i]) }}</span>
                      <span> - </span>
                      <span class="dateSummaryValue" [ngClass]="{
                        'dateSummaryValueAR': translate.currentLang=='ar'
                      }">{{getFormattedDate(toDateBinding) }}</span>

                    </div>

                    <div class="dateSummaryBox">
                    </div>
                  </div>

                  <ngb-datepicker       class="searchBoxPicker"  [displayMonths]="1" (click)="$event.stopPropagation()"
                    [dayTemplate]="t" outsideDays="hidden" navigation="arrows" [startDate]="fromDate" [minDate]="fromDate">
                  </ngb-datepicker>

                  <ng-template #t  let-date let-focused="focused">
                    <span class="custom-day"  [class.focused]="focused"
                      [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)"
                      (mouseenter)="hoveredDate = date" (click)="onDateSelection(date , i)"
                      (mouseleave)="hoveredDate = null">
                      {{ date.day }}
                    </span>
                  </ng-template>
                </mat-menu>

                <!--ROUND TRIP DATES SECTION-->
                <div class="col-12 col-lg-4 p-1" >
                  <div class="selectionBox BoxRoundTrip" [ngClass]="{ar: translate.currentLang =='ar'}">
                    <div class="selectionBoxField" [matMenuTriggerFor]="roundTripCalenders" #roundTripCalender2 id="roundTripCalender2">
                      <div class="d-flex">
                        <img height="55" style="width: 10%;" matPrefix class="IconDepar"
                          src="../../assets/img/albaraq/CheckIn.svg">

                        <p class="selectionBoxFieldValue">{{getFormattedDate(fromDateBinding[i]) }}</p>
                      </div>

                    </div>



                    <div class="selectionBoxField" [matMenuTriggerFor]="roundTripCalender" #roundTripCalenderTrigger id="roundTripCalenderTrigger">
                      <div class="d-flex">
                        <img height="55" style="width: 10%; margin-left:  10px;" matPrefix class="IconDepar"
                          src="../../assets/img/albaraq/CheckIn.svg">

                        <p class="selectionBoxFieldValue">{{getFormattedDate(toDateBinding) }}</p>
                      </div>
                    </div>

                  </div>
                </div>
            
                
                <div class="col-12 col-lg-2 searchDiv">
                  <button
                    [ngClass]="{
                      'submitBtn-valid': searchFlight.valid && isHomeComponent
                    }"
                    class="submitBtn"
                    mat-raised-button
                    (click)="onSubmit()"
                  >
                    {{ "searchFlight.search" | translate }}
                  </button>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>

          <div class="col-12 desktopHide">
            <mat-checkbox formControlName="Direct">{{
              "searchFlight.dirc" | translate
            }}</mat-checkbox>
          </div>
          <div class="col-12 mobileHide">
            <mat-checkbox formControlName="Direct">{{
              "searchFlight.dirc" | translate
            }}</mat-checkbox>
          </div>
        </div>
      </div>
      <!-- multicity -->
      <div *ngIf="searchFlight.get('flightType').value == 'Multicity'">
        <div class="row">
          <!--airports selections-->
          <ng-container formArrayName="Flights">
            <ng-container
              *ngFor="let item of flightsArray['controls']; let i = index"
            >
              <ng-container [formGroupName]="i">
                <div class="col-12 col-12">
                  <div class="row">
                    <div class="col-12 col-md-12 col-lg-3 col-xl-3 p-1">
                      <mat-form-field color="accent" appearance="outline">
                        <input
                          type="text"
                          placeholder="{{ 'searchFlight.dpart' | translate }}"
                          aria-label="City"
                          matInput
                          formControlName="departing"
                          [matAutocomplete]="autoa"
                          #firstD
                        />

                        <mat-autocomplete #autoa="matAutocomplete">
                          <mat-option
                            *ngFor="
                              let city of cities
                                | filterCity : item.get('departing').value
                                | limitTo : 5
                            "
                            [value]="
                              city.cityName + '(' + city.airportCode + ')'
                            "
                          >
                            <span
                              [innerHTML]="
                                city.airportName
                                  | highlighter : item.get('departing').value
                              "
                            ></span>
                            -
                            <span
                              [innerHTML]="
                                city.countryName
                                  | highlighter : item.get('departing').value
                              "
                            ></span>
                            (
                            <span
                              [innerHTML]="
                                city.countryName
                                  | highlighter : item.get('departing').value
                              "
                            ></span>
                            )
                          </mat-option>
                        </mat-autocomplete>
                        <img
                          height="25"
                          matPrefix
                          src="./../../assets/img/albaraq/LocationIcon.svg"
                          class="IconDepar"
                          alt="departing"
                        />
                      </mat-form-field>
                    </div>
                    <div class="divswitch" (click)="switchDes(item)">
                      <img
                        class="icon-arrow WHmobile"
                        src="../../assets/img/albaraq/SwitchIcon.svg"
                        alt=""
                        srcset=""
                      />
                    </div>

                    <div class="col-12 col-md-12 col-lg-3 col-xl-3 p-1">
                      <mat-form-field color="accent" appearance="outline">
                        <input
                          type="text"
                          placeholder="{{ 'searchFlight.land' | translate }}"
                          aria-label="City"
                          matInput
                          formControlName="landing"
                          [matAutocomplete]="autob"
                          #firstAr
                        />

                        <mat-autocomplete #autob="matAutocomplete">
                          <mat-option
                            *ngFor="
                              let city of cities
                                | filterCity : item.get('landing').value
                                | limitTo : 5
                            "
                            [value]="
                              city.cityName + '(' + city.airportCode + ')'
                            "
                          >
                            <span
                              [innerHTML]="
                                city.airportName
                                  | highlighter : item.get('landing').value
                              "
                            ></span>
                            -
                            <span
                              [innerHTML]="
                                city.countryName
                                  | highlighter : item.get('landing').value
                              "
                            ></span>
                            (
                            <span
                              [innerHTML]="
                                city.airportCode
                                  | highlighter : item.get('landing').value
                              "
                            ></span
                            >)
                          </mat-option>
                        </mat-autocomplete>
                        <img
                          height="25"
                          matPrefix
                          src="./../../assets/img/albaraq/LocationIcon.svg"
                          class="IconDepar"
                          alt="departing"
                        />
                      </mat-form-field>
                    </div>

                    <!--multi DATES SELECTIONS (POP MENUE)-->
                    <mat-menu #roundTripCalender="matMenu" class="calenderMenu">
                      <div
                        class="datesSummary"
                        (click)="$event.stopPropagation()"
                      >
                        <div class="dateSummaryBox">
                          <!-- <span>{{ "searchFlight.Today" | translate }}</span> -->
                          <span *ngIf="this.lang ==='en'" class="dateSummaryValue" >{{fromDateBinding[i] | date:'d MMM , EEE':null:"en-US" }}</span>

                          <span *ngIf="this.lang ==='ar'" class="dateSummaryValue" class="dateSummaryValueAR">{{fromDateBinding[i] | date:'d MMM , EEE':null:"ar-SA" }}</span>
                          
                          
                          
                        </div>
                      </div>

                      <ngb-datepicker
                        class="searchBoxPicker"
                        [displayMonths]="1"
                        #oneway
                        (click)="$event.stopPropagation()"
                        navigation="arrows"
                        [dayTemplate]="onwwayDAY"
                        outsideDays="hidden"
                        [minDate]="minDate"
                      >
                      </ngb-datepicker>

                      <ng-template
                        #onwwayDAY
                        let-date
                        let-focused="focused"
                        (click)="$event.stopPropagation()"
                      >
                        <span
                          class="custom-day"
                          (click)="$event.stopPropagation()"
                          (click)="onMultiCityDateSelection(date, i)"
                          [class.range]="isRange(date)"
                        >
                          {{ date.day }}
                        </span>
                      </ng-template>
                    </mat-menu>

                    <!--multi DATES SECTION-->
                    <div *ngIf="i==0"
                    class="col-12 col-lg-3 col-xl-3 col-md-12"
                    [matMenuTriggerFor]="roundTripCalender"
                    [id]="'multiCityCalenderTrigger_' + i"
                    #multiCityCalenderTrigger_0
                    style="position: relative"
                  >
                    <div class="selectionBox selecBox">
                      <div class="selectionBoxField">
                      
                        
                          <img
                          height="55"
                          style="width: 8%"
                          matPrefix
                          class="IconDepar"
                          src="../../assets/img/albaraq/CheckIn.svg"
                        />
                          <p class="selectionBoxFieldValue">
                            {{ getFormattedDate(fromDateBinding[i]) }}
                          </p>
                       
                      </div>
                    </div>
                  </div>

                  <div *ngIf="i==1"
                    class="col-12 col-lg-3 col-xl-3 col-md-12"
                    [matMenuTriggerFor]="roundTripCalender"
                    [id]="'multiCityCalenderTrigger_' + i"
                    #multiCityCalenderTrigger_1
                    style="position: relative"
                  >
                    <div class="selectionBox ">
                      <div class="selectionBoxField">
                      
                        <img
                        height="55"
                        style="width: 8%"
                        matPrefix
                        class="IconDepar"
                        src="../../assets/img/albaraq/CheckIn.svg"
                      />
                          <p class="selectionBoxFieldValue">
                            <span *ngIf="this.lang ==='en'" class="dateSummaryValue" >{{fromDateBinding[i] | date:'d MMM , EEE':null:"en-US" }}</span>
                            <span *ngIf="this.lang ==='ar'" class="dateSummaryValue" class="dateSummaryValueAR">{{fromDateBinding[i] | date:'d MMM , EEE':null:"ar-SA" }}</span>
                          </p>
                        
                      </div>
                    </div>
                  </div>

                  <div *ngIf="i==2"
                    class="col-12 col-lg-3 col-xl-3 col-md-12"
                    [matMenuTriggerFor]="roundTripCalender"
                    [id]="'multiCityCalenderTrigger_' + i"
                    #multiCityCalenderTrigger_2
                    style="position: relative"
                  >
                    <div class="selectionBox selecBox">
                      <div class="selectionBoxField">
                        
                        <img
                        height="55"
                        style="width: 8%"
                        matPrefix
                        class="IconDepar"
                        src="../../assets/img/albaraq/CheckIn.svg"
                      />
                          <p class="selectionBoxFieldValue">
                            <span *ngIf="this.lang ==='en'" class="dateSummaryValue" >{{fromDateBinding[i] | date:'d MMM , EEE':null:"en-US" }}</span>

                            <span *ngIf="this.lang ==='ar'" class="dateSummaryValue" class="dateSummaryValueAR">{{fromDateBinding[i] | date:'d MMM , EEE':null:"ar-SA" }}</span>
                          </p>
                        
                      </div>
                    </div>
                  </div>

                  <div *ngIf="i==3"
                    class="col-12 col-lg-3 col-xl-3 col-md-12"
                    [matMenuTriggerFor]="roundTripCalender"
                    [id]="'multiCityCalenderTrigger_' + i"
                    #multiCityCalenderTrigger_3
                    style="position: relative"
                  >
                    <div class="selectionBox selecBox">
                      <div class="selectionBoxField">
                        
                        <img
                        height="55"
                        style="width: 8%"
                        matPrefix
                        class="IconDepar"
                        src="../../assets/img/albaraq/CheckIn.svg"
                      />
                          <p class="selectionBoxFieldValue">
                            <span *ngIf="this.lang ==='en'" class="dateSummaryValue" >{{fromDateBinding[i] | date:'d MMM , EEE':null:"en-US" }}</span>

                            <span *ngIf="this.lang ==='ar'" class="dateSummaryValue" class="dateSummaryValueAR">{{fromDateBinding[i] | date:'d MMM , EEE':null:"ar-SA" }}</span>                          </p>
                        
                      </div>
                    </div>
                  </div>

                    <div class="col-2" *ngIf="i >= 2">
                      <div class="multiCityActions2">
                        <div class="action" (click)="removeflight(i)">
                          <fa-icon  class="clearAllIcon"  [icon]="faTimes"></fa-icon>
                        </div>
                      </div>
                    </div>
                    <div class="col-6 col-md-2 col-lg-2 MultiWeb searchDiv">
                      <button [ngClass]="{'submitBtn-valid':searchFlight.valid && isHomeComponent}" class="submitBtn" *ngIf="i == 0" mat-raised-button (click)="onSubmit()">
                        {{ "searchFlight.search" | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </ng-container>
          <div class="col-12 col-md-12 col-lg-6 mobileHide">
            <mat-checkbox formControlName="Direct">{{
              "searchFlight.dirc" | translate
            }}</mat-checkbox>
          </div>
          <div class="col-12 col-md-12 col-lg-6">
            <div class="multiCityActions">
              <div class="action" (click)="resetFlights()" *ngIf="searchFlight.get('Flights').length > 2">
                <fa-icon class="clearAllIcon" [icon]="faTimes"></fa-icon>
                {{ "searchFlight.clearAll" | translate }}
              </div>

              <div
                class="action"
                (click)="onAddFlight()"
                *ngIf="searchFlight.get('Flights').length < 4"
              >
                <fa-icon class="clearAllIcon" [icon]="faPlusCircle"></fa-icon>
                {{ "searchFlight.addNewFlight" | translate }}
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 col-lg-6 desktopHide">
            <mat-checkbox formControlName="Direct">{{
              "searchFlight.dirc" | translate
            }}</mat-checkbox>
          </div>
          <div class="col-12 col-lg-2 MultiMob">
            <button
              [ngClass]="{
                'submitBtn-valid': searchFlight.valid && isHomeComponent
              }"
              class="submitBtn"
              mat-raised-button
              (click)="onSubmit()"
            >
              {{ "searchFlight.search" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>

  <ng-container *ngTemplateOutlet="alerts"> </ng-container>
</div>

<ng-template #alerts>
  <div class="col-12" *ngIf="maxPassangers">
    <div class="info-alert mt-1">
      <fa-icon
        class="currArrow"
        [icon]="faInfoCircle"
        aria-hidden="true"
      ></fa-icon>
      {{ "searchFlight.alert3" | translate }}
      <fa-icon
        (click)="maxPassangers = !maxPassangers"
        class="closeAlert"
        [icon]="faTimesCircle"
        aria-hidden="true"
      >
      </fa-icon>
    </div>
  </div>

  <div class="col-12" *ngIf="adultAlert">
    <div class="info-alert mt-1">
      <fa-icon
        class="currArrow"
        [icon]="faInfoCircle"
        aria-hidden="true"
      ></fa-icon>
      {{ "searchFlight.alert1" | translate }}
      <fa-icon
        (click)="adultAlert = !adultAlert"
        class="closeAlert"
        [icon]="faTimesCircle"
        aria-hidden="true"
      >
      </fa-icon>
    </div>
  </div>

  <div class="col-12" *ngIf="infentMax">
    <div class="info-alert mt-1">
      <fa-icon
        class="currArrow"
        [icon]="faInfoCircle"
        aria-hidden="true"
      ></fa-icon>
      {{ "searchFlight.alert2" | translate }}
      <fa-icon
        (click)="infentMax = !infentMax"
        class="closeAlert"
        [icon]="faTimesCircle"
        aria-hidden="true"
      ></fa-icon>
    </div>
  </div>

  <ng-container *ngFor="let flight of searchFlight.get('Flights')['controls']">
    <div class="col-12" *ngIf="!flight.valid && flight.dirty && flight.errors">
      <div class="info-alert mt-1">
        <fa-icon
          class="currArrow"
          [icon]="faInfoCircle"
          aria-hidden="true"
        ></fa-icon>
        {{ "searchFlight.alert4" | translate }}
        <!-- <fa-icon class="closeAlert" [icon]="faTimesCircle" aria-hidden="true"></fa-icon> -->
      </div>
    </div>
    <div
      class="col-12"
      *ngIf="
        !flight['controls']['departing'].valid &&
        flight['controls']['departing'].dirty
      "
    >
      <div class="info-alert mt-1">
        <fa-icon
          class="currArrow"
          [icon]="faInfoCircle"
          aria-hidden="true"
        ></fa-icon>
        {{ "searchFlight.alert5" | translate }}
        <!-- <fa-icon class="closeAlert" [icon]="faTimesCircle" aria-hidden="true"></fa-icon> -->
      </div>
    </div>
    <div
      class="col-12"
      *ngIf="
        !flight['controls']['landing'].valid &&
        flight['controls']['landing'].dirty
      "
    >
      <div class="info-alert mt-1">
        <fa-icon
          class="currArrow"
          [icon]="faInfoCircle"
          aria-hidden="true"
        ></fa-icon>
        {{ "searchFlight.alert6" | translate }}
        <!-- <fa-icon class="closeAlert" [icon]="faTimesCircle" aria-hidden="true"></fa-icon> -->
      </div>
    </div>
    
  </ng-container>
  <div class="col-12" *ngIf="alertDate">
  <div class="info-alert mt-1">
    <fa-icon
      class="currArrow"
      [icon]="faInfoCircle"
      aria-hidden="true"
    ></fa-icon>
    {{ "searchFlight.alert7" | translate }}
  </div>
  </div>
</ng-template>
