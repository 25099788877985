<ng-template #direct>
    <div class="dir-container">
        <!-- <span>Direct</span> -->
        <!-- <img  src="assets/img/firstClass/directSegment.svg" matTooltip=" Total duration {{duration| hourminute}}" matTooltipClass="g-toolip"> -->
        <img  src="assets/img/firstClass/Direct.svg" matTooltip=" Total duration {{duration| hourminute}}" matTooltipClass="g-toolip">
    </div>
</ng-template>
<ng-template #one>
    <div class="dir-container">
        <!-- <img src="assets/img/firstClass/oneStop.svg" matTooltip=" Total duration {{duration| hourminute}}" matTooltipClass="g-toolip"> -->
        <img src="assets/img/firstClass/NewOneStop.svg" matTooltip=" Total duration {{duration| hourminute}}" matTooltipClass="g-toolip">
    </div>
</ng-template>
<ng-template #two>
    <div class="dir-container-two">
        <!-- <img src="assets/img/firstClass/twoStops.svg" matTooltip=" Total duration {{duration| hourminute}}" matTooltipClass="g-toolip" class="t2-img"> -->
        <img src="assets/img/firstClass/NewTwoStops.svg" matTooltip=" Total duration {{duration| hourminute}}" matTooltipClass="g-toolip" class="t2-img">
    </div>
</ng-template>
<ng-template #three>
    <div class="dir-container-three">
        <!-- <img src="assets/img/firstClass/threeStops.svg" matTooltip=" Total duration {{duration| hourminute}}" matTooltipClass="g-toolip" class="t3-img"> -->
        <img src="assets/img/firstClass/NewThreeStops.svg" matTooltip=" Total duration {{duration| hourminute}}" matTooltipClass="g-toolip" class="t3-img">
    </div>
</ng-template>
<ng-template #four>
    <div class="dir-container-four">
        <!-- <img src="assets/img/firstClass/fourStops.svg" matTooltip=" Total duration {{duration| hourminute}}" matTooltipClass="g-toolip" class="t4-img"> -->
        <img src="assets/img/firstClass/NewFourStops.svg" matTooltip=" Total duration {{duration| hourminute}}" matTooltipClass="g-toolip" class="t4-img">
    </div>
</ng-template>
<ng-container [ngSwitch]="Stnumper">
    <ng-container *ngSwitchCase="0">
        <ng-container *ngTemplateOutlet="direct"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="1">
        <ng-container *ngTemplateOutlet="one"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="2">
        <ng-container *ngTemplateOutlet="two"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="3">
        <ng-container *ngTemplateOutlet="three"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="4">
        <ng-container *ngTemplateOutlet="four"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <ng-container *ngTemplateOutlet="direct"></ng-container>
    </ng-container>
</ng-container>