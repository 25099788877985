import { parseMetadata } from '@angular/localize/src/utils';
import { Action, createReducer, on } from '@ngrx/store';
import { AuthUser, User } from '@src/app/models/user/user';
import * as user from '../user/user.actions';


export const userFeatureKey = 'user';

export interface State {
  loading : boolean,
  error   : any,
  user    : User
  appUser : AuthUser
}
  
export const initialState: State = {
 loading : false,
 error: undefined,
 user:{
   userName:'',
   userEmail:'',
   userPhoneNumber:'',
   flightHistory:undefined,
   hotelHistory:undefined,
   token:undefined,
   id:undefined
 },
 appUser:undefined
};


export const reducer = createReducer(
  initialState,
  on(user.loadUser , (state) => ({...state , loading : true})),
  on(user.loadUserSucces , (state , param) => ({...state , loading : false , user:param.data})),
  on(user.loadUserFailure , (state , err) => ({...state , loading:false , error: err.error})),
  on(user.updateUserData , (state) => ({...state , loading:true})),
  on(user.UpdateUserDataSucces , (state , param)=> ({...state , loading: false , user:param.data})),
  on(user.UpdateUserDataFailure ,(state , err) => ({...state , loading : false , error:err.error})),
  on(user.addUserFlight , (state) => ({...state , loading : true})),
  on(user.addUserFlightSuccess , (state , param) => ({...state , loading:false , user:{...state.user, flightHistory:[...state.user.flightHistory , {...param.flight}]}})),
  on(user.addUserFlightFailure , (state , err) => ({...state , loading : false , error:err.error})),
  on(user.deleteUserFlight , (state)=> ({...state , loading:true})),
  on(user.deleteUserFlightSuccess , (state , params)=> ({...state , loading : false , user:{...state.user , flightHistory:[...state.user.flightHistory.filter(id => id.sequenceNum != params.sequenceNumber && id.pKey != params.providerNum)]}})),
  on(user.deleteUserFlightFailure , (state , err) => ({...state , loading : false , error:err.error})),

  on(user.addUserHotel , (state) => ({...state , loading : true})),
  on(user.addUserHotelSuccess , (state , param) => ({...state , loading:false , user:{...state.user, hotelHistory:[...state.user.hotelHistory , {...param.hotel}]}})),
  on(user.addUserHotelFailure , (state , err) => ({...state , loading : false , error:err.error})),
  on(user.deleteUserHotel , (state)=> ({...state , loading:true})),
  on(user.deleteUserHotelSuccess , (state , params)=> ({...state , loading : false , user:{...state.user , hotelHistory:[...state.user.hotelHistory.filter(id => id.hotel.hotelCode  != params.hotelCode)]}})),
  on(user.deleteUserHotelFailure , (state , err) => ({...state , loading : false , error:err.error})),
  
  on(user.ResetUserState , (state)=> ({...initialState})),
  on(user.loadAuthUser , (state) => ({...state , loading : true})),
  on(user.loadAuthUserSuccess , (state , param) => ({...state , loading : false , appUser:param.user})),
  on(user.loadAuthUserFailure , (state , param) => ({...state , loading : false , error:param.error})),
);

export function UserReducer(
  state: State | undefined,
  action: Action) {
  return reducer(state, action);
}
