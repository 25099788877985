import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { updateAppLang } from './store/app-setting/app-setting.actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  implements OnInit{
  param = {value: 'world'};
 
    constructor(private translate: TranslateService,private readonly store : Store) {
        sessionStorage.setItem('loaded','true'); 
        if(localStorage.getItem('lang') && ((localStorage.getItem('lang') != null || localStorage.getItem('lang') != 'null'))){
          translate.use(localStorage.getItem('lang'));
          this.store.dispatch(updateAppLang({ Lang: localStorage.getItem('lang') }));
        }
        else{
          // the lang to use, if the lang isn't available, it will use the current loader to get them
          localStorage.setItem('lang','ar');
          translate.use('ar');
          this.store.dispatch(updateAppLang({ Lang:'ar' }));
        }
        
    }
    
    ngOnInit(): void {
    }
}
