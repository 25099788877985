import { Router } from '@angular/router';
import { loadHotelSearchs, updateHotelSearchCretaria, loadHotelSearchsSuccess, loadHotelSearchsFailure, hotelSaveBooking, hotelSaveBookingSuccess, hotelSaveBookingFailure, loadSelectedHotelSuccess } from './hotel-search.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect , ofType} from '@ngrx/effects';
import { mergeMap, map, catchError } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { MyapiService } from '@src/app/services/myapi.service';
import * as hotelActions from "../hotel-search/hotel-search.actions";
import { of } from "rxjs";



@Injectable()
export class HotelSearchEffects {

  constructor(
    private actions$: Actions,
    private apiService: MyapiService,
    private store: Store,
    private route:Router) {}
    loadHotels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hotelActions.loadHotelSearchs),
      mergeMap((searchCrtariea) =>
        this.apiService.getHotels(searchCrtariea.data).pipe(
          map((v) => {
            this.store.dispatch(
              updateHotelSearchCretaria({ data: searchCrtariea.data })
            );
            return hotelActions.loadHotelSearchsSuccess({ data: v });
          }),
          catchError((err) => {
            return of(hotelActions.loadHotelSearchsFailure(err));
          })
        )
      )
    )
  );

  loadHotelRooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hotelActions.loadHotelRooms),
      mergeMap((param) =>
        this.apiService.GetHotelRooms(param.pId , param.sId , param.hotelID).pipe(
          map((v) => {
           
            console.log('HOTEL ROOMS RESPONSE',v)
            return hotelActions.loadHotelRoomsSuccess({ data: v });
          }),
          catchError((err) => {
            return of(hotelActions.loadHotelSearchsFailure(err));
          })
        )
      )
    )
  );

  loadHotelAmenties$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hotelActions.loadHotelAmenties),
      mergeMap((param) =>
        this.apiService.gethotelAmenities(param.hotelID).pipe(
          map((v) => {
           
            console.log('HOTEL AMENTIES RESPONSE',v)
            return hotelActions.loadHotelAmentiesSuccess({ data: v });
          }),
          catchError((err) => {
            return of(hotelActions.loadHotelAmentiesFailure(err));
          })
        )
      )
    )
  );

  hotelSaveBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hotelActions.hotelSaveBooking),
      mergeMap((param) =>
        this.apiService
          .HotelSaveBookingData(
            param.data
          ).pipe(
                mergeMap(
                  (val) =>
                    (val.status = "Valid"
                      ? this.apiService
                          .GetHPaymentView(
                            param.ip,
                            param.ipLocation,
                            val.hgNumber,
                            param.searchId,
                            param.lang,
                          )
                          .pipe(
                            map((link:any) =>{
                              if(link == '' || link == ' '){
                                throw new Error("NO LINK")
                              }
                              window.location.href = link.link
                              return hotelActions.hotelSaveBookingSuccess({
                                data: link,
                              })
                            }
                            
                            ),
                            catchError((err) => {
                              return of(
                                hotelActions.hotelSaveBookingFailure(err)
                              );
                            })
                          )
                      : of(
                        hotelActions.hotelSaveBookingFailure({
                            error: "FAildToSaveBooking",
                          })
                        ))
                ),
                      catchError((err) => {
                        return of(hotelActions.hotelSaveBookingFailure(err));
                      })
                    )
            ),
            catchError((err) => {
              return of(hotelActions.hotelSaveBookingFailure(err));
            })
          )
      )

      loadRoomCancellation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hotelActions.loadRoomCancellation),
      mergeMap((param) =>
        this.apiService.roomcancelation(param.sid , param.hotelcode , param.roomindex , param.PId).pipe(
          map((v) => {
           
            console.log('ROOM CANCELLATION RESPONSE',v)
            return hotelActions.loadRoomCancellationSuccess({ data: v });
          }),
          catchError((err) => {
            return of(hotelActions.loadRoomCancellationFailure(err));
          })
        )
      )
    )
  );


  loadRequiredHotel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hotelActions.loadSelectedHotel),
      mergeMap((param) =>
        this.apiService.GetHotelRoomsNUM(param.sid , param.hotelID , param.Pid , param.rooms).pipe(
          map((v) => {
            console.log('REQUIRED HOTEL RESULT',v)
            return hotelActions.loadSelectedHotelSuccess({ data: v });
          }),
          catchError((err) => {
            return of(hotelActions.loadSelectedHotelFailure(err));
          })
        )
      )
    )
  );
    

}
