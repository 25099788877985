import { MyapiService } from 'src/app/services/myapi.service';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as actions from '../app-offers/offers.actions';
import { catchError, map, mergeMap} from 'rxjs/operators';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable()
export class OffersEffects {
  constructor(
    private _snackBar: MatSnackBar,
    private actions$   : Actions,
    private apiService : MyapiService) {}

  loadOffers$ = createEffect(()=>this.actions$.pipe(ofType(actions.loadAllOffers),mergeMap(
    (a) =>  this.apiService.GetAllOffers(a.pos).pipe(
      map(v=>{
        return actions.loadAllOffersSuccess({data:v})
      }),
      catchError(err => {
        return of(actions.loadAllOffersFailure({err:err}))
      })
    )
  ))
  )

  loadOfferById$ = createEffect(()=>this.actions$.pipe(ofType(actions.loadOfferById),mergeMap(
    (param) =>  this.apiService.GetOfferBYId(param.id).pipe(
      map(v=>{
        return actions.loadOfferByIdSuccess({data:v})
      }),
      catchError(err => {
        return of(actions.loadOfferByIdFailure({err:err}))
      })
    )
  ))
  )

  loadOfflineItinerary$ = createEffect(()=>this.actions$.pipe(ofType(actions.retrieveItinerary),mergeMap(
    (param) =>  this.apiService.RetriveItinerary(param.id).pipe(
      map(v=>{
        return actions.retrieveItinerarySuccess({data:v})
      }),
      catchError(err => {
        return of(actions.retrieveItineraryFailure({err:err}))
      })
    )
  ))
  )

  BookOffer$ = createEffect(()=>this.actions$.pipe(ofType(actions.BookOffers),mergeMap(
    (param) =>  this.apiService.BookOffers(param.Source, param.LanguageCode,param.body, param.searchID).pipe(
      map(v=>{
        return actions.loadOfferByIdSuccess({data:v})
        this.openSnackBar("successed Book Offer ", "ok","OK");

      }),
      catchError(err => {
        return of(actions.loadOfferByIdFailure({err:err}))
      })
    )
  ))
  )

  openSnackBar(message: string, action: string,Staus:string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass:[Staus]
    });
  }
}
