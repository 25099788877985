<div class="container">
    <mat-accordion *ngIf="!loading">
        <mat-expansion-panel *ngIf="upcomingl > 0 ">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{'user.upcomingtrip'|translate}}
                </mat-panel-title>
                <mat-panel-description>
                    {{'user.youhave'|translate}} {{upcomingl}} {{'user.upcomingtrip'|translate}}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngIf="upcomingl > 0">
                <ng-container *ngTemplateOutlet="Upcoming"></ng-container>
            </ng-container>

        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="historyl > 0">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{'user.history'|translate}}
                </mat-panel-title>
                <mat-panel-description>
                    {{'user.youhave'|translate}} {{historyl}} {{'user.ofhistory'|translate}}
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngIf="historyl > 0">
                <ng-container *ngTemplateOutlet="History"></ng-container>
            </ng-container>
        </mat-expansion-panel>
    </mat-accordion>
    <ng-container *ngIf="loading">
        <ng-container *ngTemplateOutlet="loadingkst"></ng-container>
    </ng-container>
</div>
<h2 class="text-center no-result" *ngIf="historyl == 0 && upcomingl == 0 && !loading"> you dont have any reservation </h2>
<ng-template #Upcoming>
    <ng-container *ngFor="let item of history">
        <div class="card mt-2">

            <div class="card-body">
                <h4>{{'user.BookingStatus'|translate}} : {{item.status}}</h4>
                <h4>{{'user.PNR'|translate}} : {{item.pnr}}</h4>
                <h4>{{'user.BookingNo'|translate}} : {{item.hgNumber}}</h4>
                <ng-container *ngFor="let term of item.airItineraries[0]['allJourney']['flights']">
                    <div class="card container m-3 ml-auto mr-auto">
                        <div class=" row ">
                            <div class="col-6 text-left pt-3">
                                <p>{{term.flightDTO[0].departureTerminalAirport.airportCode}} to {{term.flightDTO[term.flightDTO.length - 1].arrivalTerminalAirport.airportCode}} {{term.flightDTO[0]['departureDate']|date:'mediumDate'}}
                                </p>
                                <p>{{term.flightDTO[0]['departureDate']|date:'shortTime'}} {{term.flightDTO[term.flightDTO.length - 1]['arrivalDate']|date:'shortTime'}}</p>
                                <p> {{item.airItineraries[0]['totalDuration']| hourminute}}</p>
                            </div>
                            <div class="col-6 text-right ">
                                <img src="{{term.flightDTO[0]['flightAirline']['airlineLogo']}}" alt="" class="text-center logo">
                                <h3>{{term.flightDTO[0]['flightAirline']['airlineName']}}</h3>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <div class="row">
                    <div class="col-6">
                        <p> {{item.airItineraries[0]['flightType']}}</p>
                        <p class="red" *ngIf="!item.airItineraries[0]['isRefundable']">{{'SearchResult.NonRefundable'|translate}}</p>
                        <p style="color: green;" *ngIf="item.airItineraries[0]['isRefundable']">{{'SearchResult.Refundable'|translate}}</p>
                        <h3> {{item.airItineraries[0]['itinTotalFare']['currencyCode']}} {{item.airItineraries[0]['itinTotalFare']['amount']}} </h3>
                        <small>  {{'user.taxesandfees'|translate}}</small>
                    </div>
                    <div class="col-6 text-right m-auto">
                        <a href="" class="red">
                            <h3> {{'user.CancelTrip'|translate}}</h3>
                        </a>
                    </div>
                </div>
            </div>


        </div>
    </ng-container>
</ng-template>
<ng-template #History>
    <ng-container *ngFor="let item of history">
        <div class="card mt-2">

            <div class="card-body">
                <h4>{{'user.BookingStatus'|translate}} : {{item.status}}</h4>
                <h4>{{'user.PNR'|translate}} : {{item.pnr}}</h4>
                <h4>{{'user.BookingNo'|translate}} : {{item.hgNumber}}</h4>
                <ng-container *ngFor="let term of item.airItineraries[0]['allJourney']['flights']">
                    <div class="card container m-3 ml-auto mr-auto">
                        <div class=" row ">
                            <div class="col-6 text-left pt-3">
                                <p>{{term.flightDTO[0].departureTerminalAirport.airportCode}} to {{term.flightDTO[term.flightDTO.length - 1].arrivalTerminalAirport.airportCode}} {{term.flightDTO[0]['departureDate']|date:'mediumDate'}}
                                </p>
                                <p>{{term.flightDTO[0]['departureDate']|date:'shortTime'}} {{term.flightDTO[term.flightDTO.length - 1]['arrivalDate']|date:'shortTime'}}</p>
                                <p> {{item.airItineraries[0]['totalDuration']| hourminute}}</p>
                            </div>
                            <div class="col-6 text-right ">
                                <img src="{{term.flightDTO[0]['flightAirline']['airlineLogo']}}" alt="" class="text-center logo">
                                <h3>{{term.flightDTO[0]['flightAirline']['airlineName']}}</h3>
                            </div>
                        </div>
                    </div>

                </ng-container>

                <div class="row">
                    <div class="col-6">
                        <p> {{item.airItineraries[0]['flightType']}}</p>
                        <p class="red" *ngIf="!item.airItineraries[0]['isRefundable']">{{'SearchResult.NonRefundable'|translate}}</p>
                        <p style="color: green;" *ngIf="item.airItineraries[0]['isRefundable']">{{'SearchResult.Refundable'|translate}}</p>
                        <h3> {{item.airItineraries[0]['itinTotalFare']['currencyCode']}} {{item.airItineraries[0]['itinTotalFare']['amount']}} </h3>
                        <small> {{'user.taxesandfees'|translate}}</small>
                    </div>
                    <div class="col-6 text-right m-auto">
                        <!-- <a href="" class="red"> <h3> Cancel Trip</h3></a> -->
                    </div>
                </div>
            </div>


        </div>
    </ng-container>
</ng-template>
<ng-template #loadingkst>
    <div class="preloader">

        <div class="sk-circle">
            <div class="sk-circle1 sk-child"></div>
            <div class="sk-circle2 sk-child"></div>
            <div class="sk-circle3 sk-child"></div>
            <div class="sk-circle4 sk-child"></div>
            <div class="sk-circle5 sk-child"></div>
            <div class="sk-circle6 sk-child"></div>
            <div class="sk-circle7 sk-child"></div>
            <div class="sk-circle8 sk-child"></div>
            <div class="sk-circle9 sk-child"></div>
            <div class="sk-circle10 sk-child"></div>
            <div class="sk-circle11 sk-child"></div>
            <div class="sk-circle12 sk-child"></div>
        </div>
    </div>

</ng-template>