<!-- add comment  -->
<mat-card class="example-card" [formGroup]="filterForm" style="position: sticky;  margin-bottom: 10%;" *ngIf="showFilter">
  <div class="filtersHeader">
    {{"SearchResult.filteredBy" | translate }}
  </div>
  <hr>
  <mat-card-content>

   <!--FLIGHT STOPS FILTIRATION-->
  <mat-accordion>
    <mat-expansion-panel class="filter-expansion" [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{'SearchResult.Stops' | translate}}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="" formGroupName="stopsForm">
        <div class="row pl-3">
          <mat-checkbox class="filterCheckbox" formControlName="noStops"> {{'SearchResult.direct' |
            translate}}</mat-checkbox>
        </div>
        <div class="row pl-3">
          <mat-checkbox class="filterCheckbox" formControlName="oneStop"> {{'SearchResult.oneStop' |
            translate}}</mat-checkbox>
        </div>
        <div class="row pl-3">
          <mat-checkbox class="filterCheckbox" formControlName="twoAndm"> {{'SearchResult.twoStop' |
            translate}}</mat-checkbox>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <hr>
<!--FLIGHT AIRLINES FILTIRATION-->


<!--FLIGHT PRICE FILTIRATION-->
<mat-accordion>
  <mat-expansion-panel class="filter-expansion" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        {{'SearchResult.priceFilter' | translate}}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="custom-slider">
     
      <ngx-slider  formControlName="priceSlider" 
         [options]="options ">
      </ngx-slider>
    </div>
  </mat-expansion-panel>
</mat-accordion>
<hr>
















  

    
      <!--FLIGHT EXPERIENCE FILTIRATION-->
     

      <!--FLIGHT TIMES FILTIRATION-->
      <mat-accordion>
          <mat-expansion-panel class="filter-expansion" [expanded]="true">
              

              <p class="custom-mat-panel-title" *ngIf="Data == 1">
                
                {{'SearchResult.DpartingTime' | translate}}
                  <ng-container *ngIf="response">
               
              </ng-container>
              </p>
                  <div class="custom-slider" *ngIf="Data == 1"> 
              
                      <ngx-slider  formControlName="dpartingSlider" [options]="optionsdeparting">
                      </ngx-slider>
                  </div>

                  <p class="custom-mat-panel-title" style="margin-top: 15%;" *ngIf="Data == 0">
                      {{'SearchResult.Arrivaltime' | translate}}
                      <ng-container *ngIf="response">
                        
                      </ng-container>
                    
                  </p>
                      
                  <div class="custom-slider" *ngIf="Data == 0"> 
                
                      <ngx-slider formControlName="arrivingSlider" [options]="optionsArriving">
                      </ngx-slider>
                  </div>
          </mat-expansion-panel>
      </mat-accordion>
      <hr>

      <!--FLIGHT DURATION FILTIRATION-->
      <mat-accordion>
        <mat-expansion-panel class="filter-expansion" [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              {{'SearchResult.Duration' | translate}}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="custom-slider">
            <ngx-slider  formControlName="durationSlider" 
               [options]="optionsDurathion">
            </ngx-slider>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <!--FLIGHT AIRLINES FILTIRATION-->


      <!--FLIGHT Booking Sites FILTIRATION-->
      <!-- <mat-accordion>
          <mat-expansion-panel class="filter-expansion" [expanded]="true">
              <mat-expansion-panel-header>
              <mat-panel-title>
                  {{'SearchResult.bookingSites' | translate}}
              </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container formGroupName="bookingSite">
                  <ng-container formArrayName='bookingSites'>
                    <ng-container *ngFor="let item of filterForm.get('bookingSite').get('bookingSites')?.controls;let i= index">
                      <div class="row pl-3">
                        <mat-checkbox   class="filterCheckbox" [formControlName]="i">{{bookingSites[i]}}</mat-checkbox>
                      </div>
                    </ng-container>
                  </ng-container>
                </ng-container>
          </mat-expansion-panel>
      </mat-accordion>
      <hr>  -->

  </mat-card-content>
</mat-card>
<!-- add comment  -->