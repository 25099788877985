import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermOfuseComponent } from './term-ofuse/term-ofuse.component';
import { UserLoginComponent } from './users/user-login/user-login.component';
import { UserSignupComponent } from './users/user-signup/user-signup.component';
import { UsersComponent } from './users/users.component';
// import { TermOfuseComponent } from './term-ofuse/term-ofuse.component';
import{OrdersComponent} from './users/orders/orders.component'
import { SelectReturnFlightComponent } from './flight/select-return-flight/select-return-flight.component';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { ForgetPasswordComponent } from './users/forget-password/forget-password.component';
import { HomeComponent } from './home/home.component';
import { ConfirmPaymentComponent } from './checkout/confirm-payment/confirm-payment.component';

const  routes: Routes = [
  {
    path:'',
    loadChildren:()  =>import('./home/home.models').then((m)=> m.HomeModule) 
  },
  {
    path:'SelectReturn',
    component: SelectReturnFlightComponent,
    pathMatch: 'full'
  },
  {
    path:'login' , component:UserLoginComponent
  },
  {
    path:'changePassword' , component:ChangePasswordComponent
  },
  {
    path:'profile' , component:UsersComponent
  },
  {path:'confirmPayment', component:ConfirmPaymentComponent},

  {
    path:'signup' , component:UserSignupComponent
  },
  {
    path:'forgetPassword' , component:ForgetPasswordComponent
  },
  {
    path:'flightResult',
    
    loadChildren:()  =>import('./flight/flight.models').then((m)=> m.FlightModule) 
  },
  { path: 'paymentresult', loadChildren: () => import('./flight-cofirmation/comfirmation.module').then(m => m.PreComfirmationModule) },

  {
    path:'hotelResult',
    // loadChildren:()  =>import('./hotel/hotel.models').then((m)=> m.HotelModule) 
  },
  {
    path:'rooms/:pId/:sId/:hotelId',
    loadChildren:()=>import('./hotel-room/hotel-room.models').then((m)=>m.HotelRoomModule)
  },

  
  {
    path:'setting',
    loadChildren:()  =>import('./setting/setting.models').then((m)=> m.SettingModule) 
  },
  {
    path:'aboutUs',component: AboutUsComponent},
 
   { path:'Contact',component: ContactComponent}, 
   { path:'termsOfuse',component: TermOfuseComponent},
   { path:'privacyPolicy',component:PrivacyComponent },
  //add checkout routing
  {
    path:'checkout',
    loadChildren:()  =>import('./checkout/checkout.module').then((m)=> m.CheckoutModule) 
  },
  {
    path:'hotelCheckout/:pId/:sId/:hotelId/:rooms',
    loadChildren:()  =>import('./hotel-checkout/hotel-checkout.models').then((m)=> m.HotelCheckoutModule) 
  },
  {
    path:'CheckoutOffer/:id',
    loadChildren:()  =>import('./checkout-offer/checkout-offer.module').then((m)=> m.CheckoutOfferModule) 

    
  },
  {
    path:'confirmation-hotel',
    loadChildren:()  =>import('./hotel-confirmation/hotel-confirmation.models').then((m)=> m.HotelConfirmationModule) 
  }
];
const  routesEn: Routes = [
  {
    path:'/:lang',
    loadChildren:()  =>import('./home/home.models').then((m)=> m.HomeModule) 
  },
  {
    path:'SelectReturn/:lang',
    component: SelectReturnFlightComponent,
    pathMatch: 'full'
  },
  {
    path:'login/:lang' , component:UserLoginComponent
  },
  {
    path:'changePassword/:lang' , component:ChangePasswordComponent
  },
  {
    path:'profile/:lang' , component:UsersComponent
  },
  
  {
    path:'signup/:lang' , component:UserSignupComponent
  },
  {
    path:'forgetPassword/:lang' , component:ForgetPasswordComponent
  },
  {
    path:'flightResult/',
    
    loadChildren:()  =>import('./flight/flight.models').then((m)=> m.FlightModule) 
  },
  { path: 'paymentresult/:lang', loadChildren: () => import('./flight-cofirmation/comfirmation.module').then(m => m.PreComfirmationModule) },

  {
    path:'hotelResult/:lang',
    // loadChildren:()  =>import('./hotel/hotel.models').then((m)=> m.HotelModule) 
  },
  {
    path:'rooms/:lang/:pId/:sId/:hotelId',
    loadChildren:()=>import('./hotel-room/hotel-room.models').then((m)=>m.HotelRoomModule)
  },

  
  {
    path:'setting/:lang',
    loadChildren:()  =>import('./setting/setting.models').then((m)=> m.SettingModule) 
  },
  {
    path:'aboutUs/:lang',component: AboutUsComponent},
 
   { path:'Contact/:lang',component: ContactComponent}, 
   { path:'termsOfuse/:lang',component: TermOfuseComponent},
   { path:'privacyPolicy/:lang',component:PrivacyComponent },
  //add checkout routing
  {
    path:'checkout/:lang',
    loadChildren:()  =>import('./checkout/checkout.module').then((m)=> m.CheckoutModule) 
  },
  {
    path:'hotelCheckout/:lang/:pId/:sId/:hotelId/:rooms',
    loadChildren:()  =>import('./hotel-checkout/hotel-checkout.models').then((m)=> m.HotelCheckoutModule) 
  },
  {
    path:'CheckoutOffer/:lang/:id',
    loadChildren:()  =>import('./checkout-offer/checkout-offer.module').then((m)=> m.CheckoutOfferModule) 
  },
  {
    path:'confirmation-hotel/:lnag',
    loadChildren:()  =>import('./hotel-confirmation/hotel-confirmation.models').then((m)=> m.HotelConfirmationModule) 
  }
];
@NgModule({
  imports: [RouterModule.forRoot([...routes,...routesEn], {
    initialNavigation: 'enabled',scrollPositionRestoration: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
