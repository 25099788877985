import { Component, OnInit, EventEmitter, Output} from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-navlist',
  templateUrl: './navlist.component.html',
  styleUrls: ['./navlist.component.scss']
})
export class NavlistComponent implements OnInit {

  @Output() switch: EventEmitter<string> = new EventEmitter();
  constructor( public translate: TranslateService) { }
  toggleHF: string = "flight";
  ngOnInit() {
    // this.toggleHF = sessionStorage.getItem('type')
    // if (sessionStorage.getItem('type') == 'hotel') {
    //   document.getElementById('hotels').click()
    // }
  }


  switchHF(toggle: string) {
    this.toggleHF = toggle;
    this.switch.emit(this.toggleHF);
  }

}
