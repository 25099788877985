<div class="container-fluid">
  <div class="row">
    <div class="col-12">
      <form [formGroup]="this.hotelSearch.HotelSearchForm">
        <div class="row">
          <div class="col-12 col-lg-8"></div>
          <div class="example-full-width col-12 col-sm-12 col-md-4 col-lg-2 p-0">
            <div class="d-block menue ">
              <div class="d-block" autoClose="outside" class="drop-div">

                <button class="btn  mb-3 dropbtn " mat-button [matMenuTriggerFor]="menu" id="dropdownForm1"
                  type="button">
                  {{hotelSearch.roomNumber}}
                  <span *ngIf="hotelSearch.roomNumber > 1">
                    {{"hotelSearch.rooms"|translate}}</span>
                  <span *ngIf="hotelSearch.roomNumber === 1"> {{"hotelSearch.room"|translate}}
                  </span>
                  <span> / {{this.hotelSearch.allGuest}} {{"hotelSearch.guestNo" |translate}}
                  </span> <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19"
                    fill="none">
                    <path
                      d="M10.5059 16.712C10.561 16.8008 10.6347 16.8734 10.7206 16.9236C10.8065 16.9738 10.9021 17 10.9992 17C11.0963 17 11.1919 16.9738 11.2778 16.9236C11.3637 16.8734 11.4374 16.8008 11.4924 16.712L16.8928 8.04593C16.9554 7.94597 16.992 7.8289 16.9988 7.70742C17.0057 7.58595 16.9824 7.46472 16.9315 7.3569C16.8807 7.24909 16.8042 7.15882 16.7105 7.0959C16.6167 7.03298 16.5092 6.99981 16.3996 7H5.59876C5.48946 7.0005 5.38235 7.0341 5.28895 7.09717C5.19555 7.16024 5.11939 7.25041 5.06866 7.35797C5.01794 7.46553 4.99457 7.58642 5.00106 7.70764C5.00756 7.82886 5.04367 7.94581 5.10553 8.04593L10.5059 16.712Z"
                      fill="#043263" />
                  </svg>
                </button>
                <mat-menu #menu="matMenu">

                  <div aria-labelledby="dropdownForm1"
                    style="right: 0;text-align: center;padding-bottom:0px ;border-radius: 0;"
                    (click)="$event.stopPropagation()">

                    <div class="row roomrow " style="margin:0">
                      <div class="col-12 col-sm-4 roomT">
                        <h4>{{"hotelSearch.rooms"|translate}} </h4>
                      </div>
                      <div class="row" style="display: none">
                        <mat-form-field class="col-2" appearance="outline" color="accent" placeholder="Rooms">
                          <mat-label>{{"hotelSearch.roomNo"|translate}}</mat-label>
                          <mat-select #roomNum matNativeControl formControlName="roomN" placeholder="Rooms">
                            <mat-option *ngFor="let num of RoomsA|slice:0:5" [value]="num">
                              {{num}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                      <div class="col-12 col-sm-8 roomBtn">
                        <button type="button" mat-fab color="accent" class="add-btn hidePlus"
                          [ngClass]="{showPlus: hotelSearch.roomNumber > 4}" (click)="hotelSearch.addRoom()">
                          +
                        </button>
                        <button type="button" mat-fab color="accent" class="add-btn hideMinus"
                          [ngClass]="{showMinus: hotelSearch.roomNumber === 1}" (click)="hotelSearch.removeRoom()">
                          -
                        </button>
                      </div>
                    </div>

                    <div class="guestRow  align-self-center" formArrayName="guestInfo">
                      <ng-container
                        *ngFor="let user of hotelSearch.HotelSearchForm.get('guestInfo')['controls']; let i = index">
                        <ng-container [formGroupName]="i">
                          <h4 class="roomNo">
                            {{i+1}}
                          </h4>

                          <mat-form-field class="col-12 col-sm-12 col-md-4 p-0 " appearance="outline" color="accent">
                            <mat-label>{{"hotelSearch.adults"|translate}}</mat-label>

                            <mat-select #adultNum matNativeControl formControlName="adult"
                              placeholder='{{"hotelSearch.adults"|translate}}'>
                              <mat-option *ngFor="let num of RoomsA|slice:0:5" [value]="num">
                                {{num}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                          <mat-form-field class="col-12 col-sm-12 col-md-4 p-0 pl-1" appearance="outline"
                            color="accent">
                            <mat-label>{{"hotelSearch.Children"|translate}}</mat-label>
                            <mat-select matNativeControl #ChildNum formControlName="child"
                              placeholder='{{"hotelSearch.Children"|translate}}'>
                              <mat-option *ngFor="let num of childrenA" [value]="num">
                                {{num}}
                              </mat-option>
                            </mat-select>
                          </mat-form-field>

                        </ng-container>
                      </ng-container>
                    </div>



                  </div>
                  <button class="btn doneBtn" color='accent' appearance="outline" mat-raised-button
                    type="button">{{"searchFlight.done"|translate}} </button>
                </mat-menu>

              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <mat-form-field class="example-full-width col-12 col-sm-12 col-md-6 col-lg-3" appearance="outline"
            color="main" id="location" color='accent'>
            <input type="text" placeholder='{{"hotelSearch.location"|translate}}' aria-label="Number" matInput
              formControlName="location" [matAutocomplete]="auto" #loca (keyup)="applyFilter($event)">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let item of  selectAllcities|hotelecites:loca.value|limitTo:5" [value]="item">
                <span [innerHTML]="item.City| highlighter:loca.value"></span>,
                <span [innerHTML]="item.Country| highlighter:loca.value"></span>
              </mat-option>
            </mat-autocomplete>
            <img height="25" matPrefix src="./../../assets/img/albaraq/LocationIcon.svg" class="IconDepar"
              alt="departing" />
          </mat-form-field>

          <mat-form-field class="example-full-width col-12 col-sm-12 col-md-6 col-lg-3" appearance="outline"
            color="accent" (click)="pickerin.open()">
            <img class="datepicker"
              src="../../assets/img/albaraq/CheckIn.svg" />
            <input matInput [matDatepicker]="pickerin" placeholder="{{'hotelSearch.checkIn'| translate}}" [value]="hotelSearch.formday"
              formControlName='checkIn' [min]="todayDate()">
            <mat-datepicker-toggle matIconSuffix [for]="pickerin"></mat-datepicker-toggle>
            <mat-datepicker #pickerin color="primary"></mat-datepicker>
          </mat-form-field>


          <mat-form-field class="example-full-width col-12 col-sm-12 col-md-6 col-lg-4" appearance="outline"
            color="accent" (click)="pickerout.open()">
            <img class="datepicker2"
              src="../../assets/img/albaraq/CheckIn.svg" />
            <input matInput [matDatepicker]="pickerout" placeholder="{{'hotelSearch.checkOut'| translate}}" formControlName='checkOut'
              [min]="hotelSearch.HotelSearchForm.get('checkIn').value " [min]="todayDate()">
            <mat-datepicker-toggle matIconSuffix [for]="pickerout"></mat-datepicker-toggle>
            <mat-datepicker #pickerout color="primary"></mat-datepicker>
          </mat-form-field>
          <div class="col-12 col-md-6 col-lg-2 searchDivOne">
            <button class="submitBtn" [ngClass]="{
              'submitBtn-valid': this.hotelSearch.HotelSearchForm.valid }" mat-raised-button (click)="onSubmit()">
              {{ "searchFlight.search" | translate }}
            </button>
          </div>
          <p class="messageError">{{this.hotelSearch.RoomMessageError.enMsg}}</p>
          <p class="messageError">{{this.hotelSearch.guestMessageError.enMsg}}</p>

        </div>

      </form>
    </div>
  </div>
</div>