import { cFlightSearchs, filterSelectedFlight } from '../../store/flight-search/flight-search.actions';
import { airItineraries, pickySelection } from "@src/app/interface/flight-search-result";
import { TranslateService } from "@ngx-translate/core";
import {
  selectAppCurrency,
  selectFilteredFlights,
  selectFlightLoader,
  selectFlightType,
  selectPickySelection,
  selectSettingsFeature,
  State,
} from "@src/app/store/selectors";
import { CurrencyModule } from "src/app/models/currency/currency.module";
import { CurruncyServiceService } from "src/app/services/curruncy-service.service";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { DatePipe } from "@angular/common";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { Options } from "@angular-slider/ngx-slider";
import { FormGroup, FormArray, FormControl } from "@angular/forms";

import {
  Component,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  Inject,
  OnDestroy,
  HostListener,
  AfterViewChecked,
  EventEmitter,
} from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { MyapiService } from "../../services/myapi.service";
import { FlightServiceService } from "../../services/flight-service.service";
import { SearchFlightModule } from "../../models/search-flight/search-flight.module";
import { FlightSearchResult } from "../../interface/flight-search-result";
import { FilterInputsModule } from "../../models/filter-inputs/filter-inputs.module";
import { FareRules } from "../../interface/flight-search-result";

import {
  filterFlight,
  loadFlightSearchs,
  resetSelectedFlight,
  sortFlight,
  updateFlightType,
} from "../../store/flight-search/flight-search.actions";
import { selectAllFlights } from "../../store/selectors";
import { Observable } from "rxjs";
import {
  faAngleLeft,
  faAngleRight,
  faBars,
  faChevronLeft,
  faFilter,
  faSearch,
  faSlidersH,
  faSortAmountDown,
} from "@fortawesome/free-solid-svg-icons";
import { mergeMap, skipUntil, take, takeUntil, takeWhile } from "rxjs/operators";
import { convertToMin } from '@src/app/store/flight-search/flight-search.effects';
import { MatAccordion } from '@angular/material/expansion';
import { ThisReceiver, ThrowStmt } from '@angular/compiler';

interface customAirlineFilter {
  logo: string;
  stops: string | number;
  price: string | number;
  currency: string;
  name: string;
  selected: boolean;
}

declare var $: any;
@Component({
  selector: 'app-filter-selected-flight',
  templateUrl: './filter-selected-flight.component.html',
  styleUrls: ['./filter-selected-flight.component.css', '../flight.component.scss']
})
export class FilterSelectedFlightComponent implements OnDestroy, AfterViewChecked {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  rate: number = 1;
  code: string = "KWD";
  showFilter: boolean = false;
  searchCriteria: any;
  formINIT: boolean = false;
  filterCalled: EventEmitter<any> = new EventEmitter();
  private subscription: Subscription = new Subscription();
  currentCurruncy$: Observable<CurrencyModule> = this.store.select(selectAppCurrency);
  loading$: Observable<boolean> = this.store.select(selectFlightLoader);
  currency$ = this.store.select(selectAppCurrency);
  FilterChanges$: Subscription = new Subscription();
  PickyChange$: Subscription = new Subscription();
  loading: boolean = true;
  shownAirPortsFilter = 5;
  selectedpickySelectionFlight$: Observable<pickySelection> = this.store.select((statse) => statse.flight.pickySelection);
  optionsArriving: Options ;
  Data: number;
  filterForm = new FormGroup({
    airline: new FormGroup({
      airlines: new FormArray([])
    }),



    stopsForm: new FormGroup({
      noStops: new FormControl(false),
      oneStop: new FormControl(false),
      twoAndm: new FormControl(false)
    }),

    priceSlider: new FormControl([0, 0]),
    durationSlider: new FormControl([0, 7000]),
    dpartingSlider: new FormControl([0, 7000]),
    arrivingSlider: new FormControl([0, 7000]),



  });

  optionsLoading: Options = {
    ceil: 0,
    floor: 500,
  }

  options3: Options;
  options3Loading: Options = {
    ceil: 0,
    floor: 500,
  }

  priceMinValue: number = 0;
  priceMaxValue: number = 5000;

  options: Options;


  departingMin: number = 0;
  departingMax: number = 7000
  optionsdeparting: Options = {
    floor: 0,
    ceil: 5000,
    noSwitching: true,
    translate: (value: number): string => {
      let h = (value / 60) | 0;
      let m = value % 60 | 0;
      return h + ":" + m;
    },
  };


  durationMin: number = 0;
  durationMax: number = 7000;
  optionsDurathion: Options;

  response: pickySelection;
  normalError: any;
  normalErrorStatus: any;
  sequenceNumber: number[] = [];

  noResultAlert: boolean;
  roundT: boolean;
  Multi: boolean;

  minValue: number = 0
  maxValue: number = 5000

  arrivingMin : number = 0;
  arrivingMax : number = 7000
  airlinesA: any[] = [];
  airlinesForm: any = [];
  bookingSites: any[] = ['KhaleejGate', 'other'];
  bookingSitesForm: any[] = []

  filter?: FilterInputsModule;
  constructor(
    private store: Store<State>,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private flight: FlightServiceService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {

    this.subscription.add(
      this.currency$.subscribe(v=>{
        this.rate = v.rate;
        this.code = v.Currency_Code;
      })
    )

    this.subscription.add(
      this.selectedpickySelectionFlight$.subscribe((v) => {
        this.Data = v.flightIndex

      }))

    this.filterForm = new FormGroup({
      airline: new FormGroup({
        airlines: new FormArray([])
      }),

      bookingSite: new FormGroup({
        bookingSites: new FormArray([])
      }),

      stopsForm: new FormGroup({
        noStops: new FormControl(false),
        oneStop: new FormControl(false),
        twoAndm: new FormControl(false)
      }),
      sameAirline: new FormControl(false),
      priceSlider: new FormControl([0, 0]),
      durationSlider: new FormControl([0, 7000]),
      dpartingSlider: new FormControl([0, 7000]),
      // arrivingSlider:new FormControl([0,7000]),
      arrivingSlider: new FormControl([0, 7000]),

      experience: new FormGroup({
        overNight: new FormControl(false),
        longStops: new FormControl(false)
      }),

      flexibleTickets: new FormGroup({
        refund: new FormControl(false),
        nonRefund: new FormControl(false)
      })
    });


    // this.filterForm.reset();
    this.subscription.add(
      this.loading$.subscribe(v => {
        // this.loading = v;
        if (!v) {
          setTimeout(() => this.filterForm.enable(), 500);
        }
        else {
          setTimeout(() => this.filterForm.disable(), 200);
        }
      }));
    this.subscription.add(
      this.filterForm.valueChanges.pipe(skipUntil(this.filterCalled)).subscribe((v) => {
        if (this.response) {
          this.filter = new FilterInputsModule(
            this.filterForm.get("priceSlider").value[0],
            this.filterForm.get("priceSlider").value[1],
            // new
            this.filterForm.get("durationSlider").value[0],
            this.filterForm.get("durationSlider").value[1],
            this.filterForm.get("dpartingSlider").value[0],
            this.filterForm.get("dpartingSlider").value[1],
            this.filterForm.get("arrivingSlider").value[0],
            this.filterForm.get("arrivingSlider").value[1],
            this.stopsvalues(),
            this.filteringbyairline(
              this.filterForm.get("airline").get("airlines").value
            )
          );

          this.store.dispatch(
            filterSelectedFlight({
              filter: this.filter,
              airItineraries: this.response.otherOptions,
              roundTrip: this.roundT,
            })
          );

        }

      })
    )

    // this.subscription.add(this.store.select(selectPickySelection).subscribe((v)=>this.response = v.otherOptions));
    this.PickyChange$ = this.store.select(selectPickySelection).pipe(takeUntil(this.filterCalled)).subscribe(
      (res) => {
        this.response = res;

        if (!this.response) {
          return
        }
        else if (!this.response.selectedFlight || this.response.otherOptions.length === 0) {
          this.normalError = "No result found. <br> please search again"
          this.normalErrorStatus = true;
          return
        }
        else {
          // this.FilterChanges$.unsubscribe();

          //  debugger
          // this.fillSequenceArr();
          // this.filterForm.get('durationSlider')?.setValue(this.findDurationMinMax(this.response.otherOptions));
          this.findDurationMinMax(this.response.otherOptions)
          this.filterForm.get('durationSlider')?.updateValueAndValidity();

          // this.filterForm.get('dpartingSlider')?.setValue(this.findDepartingnMinMax(this.response.otherOptions));
          this.findDepartingnMinMax(this.response.otherOptions)
          this.filterForm.get('dpartingSlider')?.updateValueAndValidity();
          
          this.filterForm.get('arrivingSlider')?.setValue(this.findArrivingMinMax(this.response.otherOptions));
          this.filterForm.get('arrivingSlider')?.updateValueAndValidity();
          // this.filterForm.get('arrivingSlider')?.setValue(this.findArrivingMinMax(this.response.otherOptions))
         

          this.filterForm.get('priceSlider')?.setValue(this.minAnMax());
          // this.minAnMax();
          // this.options = {
          //   floor: this.minAnMax()[0],
          //   ceil: Math.round(this.minAnMax()[1]),
          //   minLimit:this.minAnMax()[0],
          //   maxLimit:this.minAnMax()[1],
          //   translate: (value: number): string => {
          //     return this.code + Math.round(value*this.rate);
          //   }
          // };
          // this.filterForm.get('priceSlider')?.updateValueAndValidity(); 



          // this.removeArrayControllers();
          this.airlinesA = [];
          this.airlinesForm = []
          this.airlinesA.forEach(element => {
            // this.airlinesForm.push(new FormControl(false));
            (<FormArray>this.filterForm.get('airline')?.get('airlines')).push(new FormControl(false));
          });

          this.bookingSitesForm = []
          this.bookingSites.forEach(element => {
            // this.bookingSitesForm.push(new FormControl(false));
            (<FormArray>this.filterForm.get('bookingSite')?.get('bookingSites')).push(new FormControl(false));
          })


          this.store.dispatch(filterSelectedFlight({ airItineraries: this.response.otherOptions, roundTrip: true }));
          this.setSliderOptions();
          this.filterForm.enable();
          this.formINIT = true;
          this.showFilter = true;
          this.filterCalled.emit(true)
        };
      }
    );




  }

  fillSequenceArr() {
    for (let i = 0; i < this.response.otherOptions.length; i++) {
      this.sequenceNumber.push(this.response.otherOptions[i].sequenceNum);
    }

  }

  findDurationMinMax(array: any[]) {
    let sorted = [...array].sort((a, b) => b.totalDuration - a.totalDuration);
    let min = sorted[sorted.length - 1]['totalDuration'];
    let max = sorted[0]['totalDuration'];
    this.durationMax = max + 100;
    this.durationMin = min;
    this.optionsDurathion = {
      floor: min,
      ceil: max + 100,
      noSwitching: true,
      translate: (value: number): string => {
        let h = value / 60 | 0;
        let m = value % 60 | 0;
        return h + "h" + ":" + m + "m";
      }
    }
    return [min, max + 100];
  }

  /**
   * after finding the min and max values for all filtiration critirias .. update the sliders with these ,,
   * minimum and maximum values
   */
  setSliderOptions() {
    this.optionsDurathion = {
      floor: this.durationMin,
      ceil: this.durationMax,
      noSwitching: true,
      translate: (value: number): string => {
        let h = value / 60 | 0;
        let m = value % 60 | 0;
        return h + "h" + ":" + m + "m";
      }
    }

    this.optionsdeparting = {
      floor: this.departingMin,
      ceil: this.departingMax,
      noSwitching: false,
      translate: (value: number): string => {
        let h = value / 60 | 0;
        let m = value % 60 | 0;
        
        return `${this.hoursFormater(h)}:${this.mFormater(m)} ${this.DayOrNight(h,m)}`;
      }
    };
  
      this.optionsArriving = {
        floor: this.arrivingMin,
        ceil: this.arrivingMax,
        noSwitching: true,
        translate: (value: number): string => {
          let h = value / 60 | 0;
          let m = value % 60 | 0;
          return `${this.hoursFormater(h)}:${this.mFormater(m)} ${this.DayOrNight(h,m)}`;
        }
      };

   
    this.options = {
      floor: this.priceMinValue,
      ceil: Math.round(this.priceMaxValue),
      minLimit: Math.round(this.priceMinValue),
      maxLimit: Math.round(this.priceMaxValue),
      translate: (value: number): string => {
        return this.code + Math.round(value * this.rate);
      }
    };
  }


  //filter by departing date
  findDepartingnMinMax(array: any[]) {
    let min = convertToMin(array[0].allJourney.flights[0].flightDTO[0].departureDate);
    let max = convertToMin(array[0].allJourney.flights[0].flightDTO[0].departureDate);
    array.forEach((element) => {
      let t = convertToMin(element.deptDate);
      if (t < min) {
        min = t;
      }
      if (t > max) {
        max = t;
      }
    });

    this.departingMin = min;
    this.departingMax = max;
    this.optionsdeparting = {
      floor: min,
      ceil: this.departingMax,
      noSwitching: true,
      translate: (value: number): string => {
        let h = (value / 60) | 0;
        let m = value % 60 | 0;
        return h + ":" + m;
      },
    };
    return [min, max];
  }
  //filter by arriving date
  findArrivingMinMax(array: airItineraries[]){
    
    let min = convertToMin(array[0].allJourney.flights[0].flightDTO[0].arrivalDate);
    let max = convertToMin(array[0].allJourney.flights[0].flightDTO[array[0].allJourney.flights[0].flightDTO.length -1].arrivalDate);
    array.forEach(element => {
      let t = convertToMin(element.allJourney.flights[0].flightDTO[element.allJourney.flights[0].flightDTO.length -1].arrivalDate)
      if (t < min) {
        min = t;
      }
      if (t > max) {
        max = t;
      }
    });
  
    this.arrivingMin = min;
    this.arrivingMax = max;
    this.optionsArriving = {
      floor: min,
      ceil: max,
      noSwitching: true,
      translate: (value: number): string => {
        let h = (value / 60) | 0;
        let m = value % 60 | 0;
        return h + ":" + m;
      },
    };
    return [min, max];
 
  }

  DayOrNight(h:number,m:number):string{
    let hourOfday = h > 24?h%24:h;
   return hourOfday+(m/100) > 12?'PM':"AM"
  }
  hoursFormater(h:number):string{
    let hourOfday = h > 24?h%24:h;
    let fHourOfday  = hourOfday >12? hourOfday -12 : hourOfday;
  
    return fHourOfday >= 10 ?fHourOfday.toString():`0${fHourOfday}`;
  }
  mFormater(m:number):string{
  return m >=10?m.toString():`0${m}`;
  }
 


  /**
   * Filter With Day Time Slots (Return Flight)
   **/


  //filter by price
  minAnMax() {
    let len: number = 20;
    let arr: airItineraries[] = [...this.response.otherOptions]
    let sortedRes = [...arr.sort((a, b) => { return a.itinTotalFare.amount - b.itinTotalFare.amount })]
    let minValue = sortedRes[0].itinTotalFare.amount -1;


    len = arr.length;
    len = len - 1;

    let maxValue1 = sortedRes[len].itinTotalFare.amount;


    // this.options.ceil  = Math.round(maxValue1+100 );
    // this.options.floor = minValue
    this.priceMinValue = Math.round(minValue);
    this.priceMaxValue = Math.round(maxValue1);
    this.options = {
      floor: this.priceMinValue,
      ceil: this.priceMaxValue,
      minLimit: this.priceMinValue,
      maxLimit: this.priceMaxValue,
      translate: (value: number): string => {
        return this.code + Math.round(value * this.rate);
      }
    };
    this.maxValue = Math.round(maxValue1 + 100);
    return [minValue, this.maxValue];

  }

  //  remove airlines arr
  removeArrayControllers() {
    if (((<FormArray>this.filterForm.get('airline')?.get("airlines")).length)) {
      while (((<FormArray>this.filterForm.get('airline')?.get("airlines"))).length >= 1) {
        (<FormArray>this.filterForm.get('airline')?.get("airlines")).removeAt(0);
      }
    }

  }

  //filter by stops value
  stopsvalues() {
    let out: number[] = [];
    if (this.filterForm.get('stopsForm')?.get('noStops')?.value) {
      out.push(0)
    }
    if (this.filterForm.get('stopsForm')?.get('oneStop')?.value) {
      out.push(1)
    }
    if (this.filterForm.get('stopsForm')?.get('twoAndm')?.value) {
      out.push(2);
      out.push(3);
      out.push(4);
    }

    if (!this.filterForm.get('stopsForm')?.get('noStops')?.value && !this.filterForm.get('stopsForm')?.get('oneStop')?.value && !this.filterForm.get('stopsForm')?.get('twoAndm')?.value) {
      out = [0, 1, 2, 3, 4];
    }
    return out
  }

  //filter by airline
  filteringbyairline(val: any[]) {
    let airL: any[] = [];
    for (let index = 0; index < val.length; index++) {
      const element = val[index];
      if (element) {
        airL.push(this.airlinesA[index]);
      }

    };
    if (airL.length == 0) {
      let out = airL;
      return out
    }
    else {
      return airL;
    }
  }
  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();

  }
  //filter by booking sites
  filteringbyBookingSites(val: any[]) {
    let selectedSites: any[] = [];
    for (let index = 0; index < val.length; index++) {
      const element = val[index];
      if (element) {
        selectedSites.push(this.bookingSites[index]);
      }

    };
    if (selectedSites.length == 0) {
      let out = selectedSites;
      return out
    }
    else {
      return selectedSites;
    }
  }


  /**
* here is the unsubscripe from all observables
*/
  ngOnDestroy() {
    this.filterForm = new FormGroup({
      airline: new FormGroup({
        airlines: new FormArray([])
      }),

      bookingSite: new FormGroup({
        bookingSites: new FormArray([])
      }),

      stopsForm: new FormGroup({
        noStops: new FormControl(false),
        oneStop: new FormControl(false),
        twoAndm: new FormControl(false)
      }),
      sameAirline: new FormControl(false),
      priceSlider: new FormControl([0, 0]),
      durationSlider: new FormControl([0, 7000]),
      dpartingSlider: new FormControl([0, 7000]),
      arrivingSlider: new FormControl([0, 7000]),

      experience: new FormGroup({
        overNight: new FormControl(false),
        longStops: new FormControl(false)
      }),

      flexibleTickets: new FormGroup({
        refund: new FormControl(false),
        nonRefund: new FormControl(false)
      })
    });
    this.subscription.unsubscribe();
    this.PickyChange$.unsubscribe();
  }


}