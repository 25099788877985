import { Component, OnInit, Input } from '@angular/core';
export interface minitodo {
  isStopSegment:boolean,
  transitTime:string,
  arrivalTerminalAirport:{
    airportCode:string,
    airportName:string,
    cityName:string,
    
  }
}
@Component({
  selector: 'app-seg',
  templateUrl: './seg.component.html',
  styleUrls: ['./seg.component.css']
})

export class SegComponent implements OnInit {
 @Input() Stnumper:number = 0;
 @Input()tdolist : minitodo[] =[];
 @Input()duration:number = 0;
 transitTime:string[]=[];
  constructor() { }

  ngOnInit() {
    this.transitTime = this.formatTransiteTime();
  }

  formatTransiteTime(): string[]
  {
     let transitTime : string[]=[]
     this.tdolist.forEach(element => {
       let transit = element.transitTime;
       transitTime.push(+transit.slice(0,2) +'H' +' :'+ +transit.slice(3,5)+'M');
     });
     return transitTime
  }

}
