import { Action, createReducer, on } from '@ngrx/store';
import * as actions from '../app-offers/offers.actions';
import { BookedOffer } from '@src/app/interface/BookOffer';
import { ImagesDTO, OfferDTO } from '@src/app/interface/offers';
import { Itinerary } from '@src/app/interface/offlineSeats';

export const appOffersFeatureKey = 'appOffers';

export interface State {
  loading        : boolean,
  error          : any,
  offers         : OfferDTO[],
  selecyedOffer  : OfferDTO,
  BookOffer: BookedOffer
  servicesImages : ImagesDTO[],
  offlineItinerary:Itinerary
}

export const initialState: State = {
  loading       : false,
  offers        : [],
  selecyedOffer : undefined,
  BookOffer: undefined,
  error         : undefined,
  servicesImages: undefined,
  offlineItinerary:undefined
};


export const reducer = createReducer(
  initialState,
  on(actions.loadAllOffers , (state)=>({...state , loading:true})),
  on(actions.loadAllOffersSuccess , (state , param)=>({...state , loading : false , offers:param.data})),
  on(actions.loadAllOffersFailure , (state, param)=>({...state , loading: false , error:param.err})),
  on(actions.loadOfferById , (state)=>({...state , loading:true})),
  on(actions.loadOfferByIdSuccess , (state , param)=>({...state , loading : false , selecyedOffer:param.data})),
  on(actions.loadOfferByIdFailure , (state, param)=>({...state , loading: false , error:param.err})),
  on(actions.BookOffers , (state)=>({...state , loading:true})),
  on(actions.BookOffersSuccess , (state , param)=>({...state , loading : false , BookOffer:param.data})),
  on(actions.BookOffersFailure , (state, param)=>({...state , loading: false , error:param.err})),
  on(actions.loadServicesImages , (state, param)=>({...state , loading: false , servicesImages:param.data})),
  on(actions.retrieveItinerary , (state)=>({...state , loading: true})),
  on(actions.retrieveItinerarySuccess , (state , param)=>({...state , loading: false , offlineItinerary:param.data})),
  on(actions.retrieveItineraryFailure , (state , param)=>({...state , loading: false , error:param.err})),
);

export function OffersReducer(
  state: State | undefined,
  action: Action) {
  return reducer(state, action);
}

