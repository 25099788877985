<mat-dialog-content [ngClass]="{ar: translate.currentLang =='ar'}">
    <div class="form">
        <div class="closeHeader">
            <div class="row">
                <div class="col-6 col-md-4 col-lg-2 logo">
                    <img src="../../../assets/img/albaraq/TAZKRTI-dark 2.svg" alt="logo">
                </div>
                <div class="col-6 col-md-8 col-lg-10 closeIcon">
                    <span class="" (click)="closeDialog()">X</span>
                </div>
            </div>
        </div>
        <h2 class="title" (click)="closeDialog()">{{'password.forget' | translate}}</h2>
        <form [formGroup]="forgetPassForm" class="form-container" (ngSubmit)="onSubmit()">
            <div class="row">
                <!-- Email -->
                <div class="col-12">
                    <mat-form-field color="accent" appearance="fill" class="authForm">
                        <!-- <fa-icon class="emailIcon" [icon]="faEnvelope"></fa-icon> -->
                        <input matInput
                            [placeholder]="translate.currentLang == 'en'?'Email Address':'البريد الالكتروني'" required
                            formControlName='Email' type="email"
                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                    </mat-form-field>
                </div>
                <!-- Resend Email -->
                <div class="col-12">
                    <mat-form-field color="accent" appearance="fill" class="authForm">
                        <!-- <fa-icon class="emailIcon" [icon]="faEnvelope"></fa-icon> -->
                        <input matInput
                            [placeholder]="translate.currentLang == 'en'?'OTP sent to email':'كلمة السر للمرة الواحدة'" required
                            formControlName='Email' type="email"
                            pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                    </mat-form-field>
                </div>
                <!-- Password -->
                <div class="col-12">
                    <mat-form-field color="accent" appearance="fill" class="authForm">

                        <input matInput [placeholder]="translate.currentLang=='en'?'Password':'كلمة السر'"
                            required formControlName='Password' [type]="showPassword?'text':'password'">
                        <img (click)="toggleShowPassword()" class="forget-password-icon"
                            src="assets/img/albaraq/icons/forget-password.svg" alt="">
                    </mat-form-field>
                </div>
                <!-- Confirm Password -->
                <div class="col-12">
                    <mat-form-field color="accent" appearance="fill" class="authForm">
                        <input matInput
                            [placeholder]="translate.currentLang == 'en'?'Confirm Password':'اعد كتابة كلمة السر'"
                            required formControlName="ConfirmPassword"
                            [type]="showConfirmPassword?'text':'password'" />
                        <img (click)="toggleShowConfirmPassword()" class="forget-password-icon"
                            src="assets/img/albaraq/icons/forget-password.svg" alt="">
                    </mat-form-field>
                </div>
                <!-- Submit Button-->
                <div class="col-12 mt-5" (click)="onSubmit()">
                    <button *ngIf="!userLoader" class="submitBtn" mat-button color="primary">
                        {{translate.currentLang=='en'?'Sign in':'تسجيل دخول'}}</button>
                    <mat-spinner *ngIf="userLoader"></mat-spinner>
                </div>
                <!-- Verfication Code -->
                <div class="col-12 verCode">
                    <div class="row">
                        <fa-icon class="col-1 col-lg-0 col-md-0 checkIcon" [icon]="faCheckCircle"></fa-icon>
                        <p class=" col-lg-0 col-md-0 ">{{'password.verCode' | translate}}</p>
                    </div>
                </div>
                <!-- OR LINE BREAK -->
                <!-- <div class="col-12 breakLine">
                    <div class="d-flex">
                        <div class="line"></div>
                        <span class="or-text">{{translate.currentLang=='en'?'OR':'أو'}}</span>
                        <div class="line"></div>
                    </div>
                </div> -->
                <!-- Social Buttons -->
                <!-- <div (click)="submitFbLogin()" class="col-12 col-xl-6 mb-3">
                    <button class="social-login" mat-button>
                        <img class="social-login-icon" src="assets/img/albaraq/icons/facebook.svg" alt="">
                        <span class="social-login-text">{{translate.currentLang=='en'?'Sign in with
                            Facebook':'تسجيل دخول بواسطة فيسبوك'}}</span>
                    </button>
                </div>
    
                <div class="col-12 col-xl-6" (click)="submitGoogleLogin()">
                    <button class="social-login" mat-button>
                        <img class="social-login-icon" src="assets/img/albaraq/icons/google.svg" alt="">
                        <span class="social-login-text">{{translate.currentLang=='en'?'Sign in with
                            Google':'تسجيل دخول بواسطة جوجل'}}</span>
                    </button>
                </div> -->
            </div>
        </form>
    </div>

</mat-dialog-content>