import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import { environment } from '@src/environments/environment';
import "hammerjs";
if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
     platformBrowserDynamic().bootstrapModule(AppModule)
     .then(()=>{
       if(navigator.serviceWorker){
         navigator.serviceWorker.register('/ngsw-worker.js');
       }
     })
  .catch(err => console.log(err));
   });
