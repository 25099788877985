import { createAction, props } from '@ngrx/store';
import { airItineraries } from '@src/app/interface/flight-search-result';
import { hotelBookingModel } from '@src/app/models/search-hotel/hotel-booking-model';
import { AuthUser, User } from '@src/app/models/user/user';

export const loadUser = createAction(
  '[User] Load User'
);

export const loadUserSucces = createAction(
  '[User] Load User Success',
  props<{ data: User }>()
);

export const loadUserFailure = createAction(
  '[User] Load User Failure',
  props<{ error: any }>()
);

export const updateUserData = createAction(
  '[User] Update User',
  props<{ user: User }>()
);

export const UpdateUserDataSucces = createAction(
  '[User] Update User Success',
  props<{ data: User }>()
);

export const UpdateUserDataFailure = createAction(
  '[User] Update User Failure',
  props<{ error: any }>()
);


export const addUserFlight = createAction(
  '[User] Add User Flight',
  props<{ flight: airItineraries }>()
);

export const addUserFlightSuccess = createAction(
  '[User] Add User Flight Success',
  props<{ flight: airItineraries }>()
);

export const addUserFlightFailure = createAction(
  '[User] Add User Flight Failure',
  props<{ error: any }>()
);


export const deleteUserFlight = createAction(
  '[User] Delete User Flight',
  props<{sequenceNumber: any , providerNum:any}>()
);

export const deleteUserFlightSuccess = createAction(
  '[User] Delete User Flight Success',
  props<{ sequenceNumber: any , providerNum:any}>()
);

export const deleteUserFlightFailure = createAction(
  '[User] Delete User Flight Failure',
  props<{ error: any }>()
);



export const addUserHotel = createAction(
  '[User] Add User Hotel',
  props<{ hotel: hotelBookingModel }>()
);

export const addUserHotelSuccess = createAction(
  '[User] Add User Hotel Success',
  props<{ hotel: hotelBookingModel }>()
);

export const addUserHotelFailure = createAction(
  '[User] Add User Hotel Failure',
  props<{ error: any }>()
);

export const deleteUserHotel = createAction(
  '[User] Delete User Hotel',
  props<{hotelCode : string}>()
);

export const deleteUserHotelSuccess = createAction(
  '[User] Delete User Hotel Success',
  props<{hotelCode : string}>()
);

export const deleteUserHotelFailure = createAction(
  '[User] Delete User Hotel Failure',
  props<{ error: any }>()
);

export const ResetUserState = createAction(
  '[User] Reset User Data'
);

export const loadAuthUser = createAction(
  '[User] AUTH USER'
);

export const loadAuthUserSuccess = createAction(
  '[User] AUTH USER SUCCESS',
  props<{ user: AuthUser }>()
);

export const loadAuthUserFailure = createAction(
  '[User] AUTH USER FAILURE',
  props<{ error: any }>()
);

