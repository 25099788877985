import { Pipe, PipeTransform } from '@angular/core';
import { CitiesModule } from '../models/cities/cities.module';
//this pipe take an argument as the input and return a filterd array wich include the search input
@Pipe({
  name: 'filterCity'
})
export class FilterCityPipe implements PipeTransform {

  transform(value: CitiesModule[], args: string): CitiesModule[] {
    if (!value || !args) {
      return [];
    }
    else {
      if(args.length< 3){
        return [];
       }
      let result: CitiesModule[] = []
      // for (let index = 0; index < value.length; index++) {
      //   let element: CitiesModule = value[index];
      //   let a = element.cityName.toLowerCase();
      //   let b = element.airportCode.toLowerCase();
      //   let c = element.airportName.toLowerCase();
      //   let d = element.countryName.toLowerCase();
      //   if (a.slice(0,args.length)  == args.toLowerCase() || b.slice(0,args.length) ==  args.toLowerCase() || c.slice(0,args.length ) == args.toLowerCase()) {
      //     result.push(element);
      //   }
      //   if (a.indexOf(args.toLowerCase()) != -1 || b.indexOf(args.toLowerCase()) != -1 || c.indexOf(args.toLowerCase()) != -1||d.indexOf(args.toLowerCase()) != -1) {
      //     result.push(element);
      //   }
        

      // }
      // filter by index 
      result = value.filter(val=> 
      val.cityName.toLowerCase().slice(0,args.length) == args.toLowerCase()||
      val.airportCode.toLowerCase().slice(0,args.length) == args.toLowerCase()||
      val.airportName.toLowerCase().slice(0,args.length) == args.toLowerCase()||
      val.countryName.toLowerCase().slice(0,args.length) == args.toLowerCase()
      );
      // un filtetrd result 
      let leftTofilter = value.filter(val=> !(
        val.cityName.toLowerCase().slice(0,args.length) == args.toLowerCase()||
        val.airportCode.toLowerCase().slice(0,args.length) == args.toLowerCase()||
        val.airportName.toLowerCase().slice(0,args.length) == args.toLowerCase()||
        val.countryName.toLowerCase().slice(0,args.length) == args.toLowerCase())
        );
      //  search any where 
      let rstOfResults = leftTofilter.filter(val=>
        val.cityName.toLowerCase().indexOf(args.toLowerCase()) != -1||
        val.airportCode.toLowerCase().indexOf(args.toLowerCase()) != -1||
        val.airportName.toLowerCase().indexOf(args.toLowerCase()) != -1||
        val.countryName.toLowerCase().indexOf(args.toLowerCase()) != -1
      );
      // add total result
      result = result.concat(rstOfResults);
      return result;
    }
    //   return value.filter ((city)=>{city.cityName.toLowerCase().indexOf(args.toLowerCase()) != -1||
    //     city.airportName.toLowerCase().indexOf(args.toLowerCase()) != -1||
    //     city.cityName.toLowerCase().indexOf(args.toLowerCase()) != -1})
  }

}
