import { Injectable } from "@angular/core";

@Injectable({
  providedIn:"root"
})
export class PointOfsaleModule {
  constructor(
    public ip?:string,
    public city?:string,
    public region?:string,
    public region_code?:string,
    public country?:string,
    public country_name?:string,
    public continent_code?:string,
    public airport?:string,
    public country_capital?:string,
    public in_eu?:boolean,
    public postal?:any,
    public latitude?:number,
    public longitude?:number,
    public timezone?:string,
    public utc_offset?:string,
    public country_calling_code?:string,
    public currency?:string,
    public languages?:string,
    public asn?:string,
    public org?:string
  ){
  }
 }

 export interface PointOfsaleModule { 
    ip?:string,
    city?:string,
    region?:string,
    region_code?:string,
    country?:string,
    country_name?:string,
    country_nameAr?:string,
    continent_code?:string,
    airport?:string
    country_capital?:string,
    in_eu?:boolean,
    postal?:any,
    latitude?:number,
    longitude?:number,
    timezone?:string,
    utc_offset?:string,
    country_calling_code?:string,
    currency?:string,
    languages?:string,
    asn?:string,
    org?:string
}
