
<div [ngClass]="[translate.currentLang =='ar'? 'ar' : '', returnPassTotalFarDifferance(
    this.item[0].passengerFareBreakDownDTOs,
    this.item[0].itinTotalFare.amount,
    this.item[0].itinTotalFare.totalTaxes,
    this.item[0].itinTotalFare.currencyCode,
    this.calcEqfare,this.returnCorrectFare
    )[0]
    &&
    returnPassTotalFarDifferance(
        this.item[0].passengerFareBreakDownDTOs,
        this.item[0].itinTotalFare.amount,
        this.item[0].itinTotalFare.totalTaxes,
        this.item[0].itinTotalFare.currencyCode,
        this.calcEqfare,this.returnCorrectFare
        )[1] == 'Discount' ? 'roundCard my-3' : ' roundCard my-4' ]">
    <div class="topCard">
        <div class="container">
            <div class="topCardWrapper" *ngIf="
            returnPassTotalFarDifferance(
                this.item[0].passengerFareBreakDownDTOs,
                this.item[0].itinTotalFare.amount,
                this.item[0].itinTotalFare.totalTaxes,
                this.item[0].itinTotalFare.currencyCode,
                this.calcEqfare,this.returnCorrectFare
                )[0]
                &&
                returnPassTotalFarDifferance(
                    this.item[0].passengerFareBreakDownDTOs,
                    this.item[0].itinTotalFare.amount,
                    this.item[0].itinTotalFare.totalTaxes,
                    this.item[0].itinTotalFare.currencyCode,
                    this.calcEqfare,this.returnCorrectFare
                    )[1] == 'Discount'
            ">
                <h3> {{"SearchResult.save" | translate}}

                    <span>
                        {{item[0]['itinTotalFare']['currencyCode']|exchange:'code'}}
                        {{returnPassTotalFarDifferance(
                        this.item[0].passengerFareBreakDownDTOs,
                        this.item[0].itinTotalFare.amount,
                        this.item[0].itinTotalFare.totalTaxes,
                        this.item[0].itinTotalFare.currencyCode,
                        this.calcEqfare,this.returnCorrectFare
                        )[0] | exchange:'value'}}
                    </span>
                    {{"SearchResult.bySelecting" | translate}}
                </h3>
            </div>
        </div>
    </div>
    <div class="bodyCard">
        <div class="container">
            <div class="row">
                <!--FLIGHT INFORMATION-->
                <div class="col-12 col-md-9 col-lg-9 flightInfoContainer ">
                    <div class="container">
                        <!--FIRST FLIGHT (IN ROUND TRIP IT WILL BE ANOTHER ROW OF THAT)-->
                        <div class="row mb-4 ">
                            <!--ARILINE DETAILS-->
                            <div class="col-5 col-md-5 col-lg-5">
                                <div class="row">

                                    <!-- CheckBOx-->
                                    <input class="col-2 col-lg-1 checkInput " type="checkbox"
                                        (click)="selectReturnFlight(item[0],0)">
                                    <!-- ARILINE IMAGE -->

                                    <img class="col-4 col-lg-4 airlineLogo"
                                        src="{{item[0].allJourney.flights[0].flightDTO[0].flightAirline.airlineLogo}}"
                                        alt="airline logo" title="Image Title">

                                    <!-- ARILINE NAME -->
                                    <span
                                        class="col-6 col-lg-5 airlineName">{{item[0].allJourney.flights[0].flightDTO[0].flightAirline.airlineName}}</span>
                                </div>







                            </div>
                            <!--FLIGHT DETAILS-->
                            <div class="col-7 col-md-5 col-lg-5 bodyCard_wrapper">
                                <div class="row">
                                    <!--DEPARTURE TIME-->
                                    <div class="col-4 col-md-3 col-lg-3 departSec">
                                        <div class="row">
                                            <div class="col-12 col-lg-12 time">
                                                {{item[0].allJourney.flights[0].flightDTO[0].departureDate |
                                                date:'shortTime'}}
                                            </div>
                                            <div class="col-12 col-lg-12 airport">
                                                ({{item[0].allJourney.flights[0].flightDTO[0].departureTerminalAirport.airportCode}})
                                            </div>
                                        </div>
                                    </div>
                                    <!--SEGMENT-->
                                    <div class="col-4 col-md-5 col-lg-5 divSegment">
                                        <div class="duration durationMob">( {{item[0].allJourney.flights[0].elapsedTime
                                            | hourminute}} )
                                        </div>
                                        <div class="segment">
                                            <img src="../../../assets/img/albaraq/Plane.png">
                                        </div>
                                        <div class="stops" *ngIf="item[0].allJourney.flights[0].stopsNum > 0">
                                            {{item[0].allJourney.flights[0].stopsNum}}
                                            {{"SearchResult.Stops"|translate}}
                                        </div>
                                        <div class="stops" *ngIf="item[0].allJourney.flights[0].stopsNum == 0">
                                           
                                            {{"SearchResult.direct"|translate}}
                                        </div>
                                    </div>
                                    <!--LANDING TIME-->
                                    <div class="col-4 col-md-4 col-lg-4 landSec">
                                        <div class="row">
                                            <div class="col-12 col-lg-12 time">
                                                {{item[0].allJourney.flights[0].flightDTO[item[0].allJourney.flights[0].flightDTO.length
                                                -1].arrivalDate | date:'shortTime'}}
                                            </div>
                                            <div class="col-12 col-lg-12 airport">
                                                ({{item[0].allJourney.flights[0].flightDTO[item[0].allJourney.flights[0].flightDTO.length
                                                -1].arrivalTerminalAirport.airportCode}})
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Total Flight Duration -->
                            <div class="col-0 col-md-2 col-lg-2 p-0 bodyCard_wrapper durationWeb">
                                <div class="duration">( {{item[0].allJourney.flights[0].elapsedTime | hourminute}} )
                                </div>
                            </div>
                        </div>

                        <!--SECOUND FLIGHT (IN ROUND TRIP IT WILL BE ANOTHER ROW OF THAT)-->
                        <div class="row my-3" *ngIf="item[0].allJourney.flights[1]">
                            <!--ARILINE DETAILS-->
                            <div class="col-5 col-md-5 col-lg-5">
                                <div class="row">
                                    <!-- CheckBOx-->
                                    <input class="col-2 col-lg-1 checkInput " type="checkbox"
                                        (click)="selectReturnFlight(item[0],1)">
                                    <!-- ARILINE IMAGE -->
                                    <div class="col-4 col-lg-4 airlineLogo">
                                        <img src="{{item[0].allJourney.flights[0].flightDTO[0].flightAirline.airlineLogo}}"
                                            alt="airline logo" title="Image Title">
                                    </div>
                                    <!-- ARILINE NAME -->
                                    <span
                                        class="col-6 col-lg-5 airlineName">{{item[0].allJourney.flights[1].flightDTO[0].flightAirline.airlineName}}</span>
                                </div>
                            </div>
                            <!--FLIGHT DETAILS-->
                            <div class="col-7 col-md-5 col-lg-5 bodyCard_wrapper">
                                <div class="row">
                                    <!--DEPARTURE TIME-->
                                    <div class="col-4 col-md-3 col-lg-3 departSec">
                                        <div class="row">
                                            <div class="col-12 col-lg-12 time">
                                                {{item[0].allJourney.flights[1].flightDTO[0].departureDate |
                                                date:'shortTime'}}
                                            </div>
                                            <div class="col-12 col-lg-12 airport">
                                                ({{item[0].allJourney.flights[1].flightDTO[0].departureTerminalAirport.airportCode}})
                                            </div>
                                        </div>
                                    </div>
                                    <!--SEGMENT-->
                                    <div class="col-4 col-md-5 col-lg-5 divSegment">
                                        <div class="duration durationMob">( {{item[0].allJourney.flights[0].elapsedTime
                                            | hourminute}} )
                                        </div>
                                        <div class="segment">
                                            <img src="../../../assets/img/albaraq/Plane.png">
                                        </div>
                                        <div class="stops" *ngIf="item[0].allJourney.flights[0].stopsNum > 0">
                                            {{item[0].allJourney.flights[0].stopsNum}}
                                            {{"SearchResult.Stops"|translate}}
                                        </div>
                                        <div class="stops" *ngIf="item[0].allJourney.flights[0].stopsNum == 0">
                                           
                                            {{"SearchResult.direct"|translate}}
                                        </div>
                                    </div>
                                    <!--LANDING TIME-->
                                    <div class="col-4 col-md-4 col-lg-4 landSec">
                                        <div class="row">
                                            <div class="col-12 col-lg-12 time">
                                                {{item[0].allJourney.flights[1].flightDTO[item[0].allJourney.flights[1].flightDTO.length
                                                -1].arrivalDate | date:'shortTime'}}
                                            </div>

                                            <div class="col-12 col-lg-12 airport">
                                                ({{item[0].allJourney.flights[1].flightDTO[item[0].allJourney.flights[1].flightDTO.length
                                                -1].arrivalTerminalAirport.airportCode}})
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Total Flight Duration -->
                            <div class="col-0 col-md-2 col-lg-2 p-0 bodyCard_wrapper durationWeb">
                                <div class="duration">( {{item[0].allJourney.flights[1].elapsedTime | hourminute}} )
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!--FLIGHT PRICE & BOOKING-->
                <div class="col-12 col-md-3 col-lg-3 flightPriceContainer  bookWeb">
                    <div class="container">
                        <div class="row">
                            <div class="col-12 thePrice text-center">
                                <span class="code">{{item[0]['itinTotalFare']['currencyCode']|exchange:'code'}} </span>
                                <span class="price">{{item[0].itinTotalFare.amount|exchange:'value'}}</span>
                            </div>
                            <div class="col-12 priceText text-center">
                                <div>{{"SearchResult.Price"|translate}}</div>
                            </div>
                            <div class="col-10 col-md-12 col-lg-12 BOOK-Btn  text-center" *ngIf="!checkout">
                                <button mat-raised-button class="bookBtn"
                                    (click)="flight.toCheckout(flight.response.searchCriteria.searchId,item[0].sequenceNum,item[0].pKey,item[0].pcc)">{{"SearchResult.book"|translate}}
                                    <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 bookMob" *ngIf="!checkout">
                    <div class="section-wrap">
                     <button mat-raised-button class=" customized-price-button  d-flex"
                         (click)="flight.toCheckout(flight.response.searchCriteria.searchId,item[0].sequenceNum,item[0].pKey,item[0].pcc)">
                         <span class="arrow ">
                             <fa-icon [icon]="faChevronRight"
                             (click)="flight.toCheckout(flight.response.searchCriteria.searchId,item[0].sequenceNum,item[0].pKey,item[0].pcc)">
                         </fa-icon>
                     </span>
                         <span class="code ">{{item[0]['itinTotalFare']['currencyCode']|exchange:'code'}} </span>
                         <span class="price ">{{item[0].itinTotalFare.amount|exchange:'value'}}</span>
                       
                     </button>
                     <div class="refund-mobile">
                         <div class="refundable" *ngIf="item[0].isRefundable">
                             {{'SearchResult.refundable' | translate}}
                         </div>
                         <div class="refundable" *ngIf="!item[0].isRefundable">
                             {{'SearchResult.nonRefundable' | translate}}
                         </div>
                     </div>
                    </div>
                     
                 </div>
            </div>
        </div>
    </div>
    <div class="footerCard">
        <div class="container">
            <div class="viewDetails d-flex justify-content-between">
                <div class="col-6 col-lg-3 flightDetails" (click)="toggleDetails()">
                    <span matRipple matRippleRadius="30">
                        {{ "SearchResult.ViewDetails" | translate }}</span>
                    <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                </div>
                <div class="col-6 col-lg-4 seeMore" *ngIf="item.length > 1">
                    <span (click)="moreT = !moreT" [attr.aria-expanded]="!moreT" [attr.aria-controls]="'showTerm' + i">
                        + {{ item.length - 1 }}
                        {{ "SearchResult.more" | translate }}
                    </span>
                    <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                </div>
                <div class="col-2 refund">
                    <div class="refundable" *ngIf="item[0].isRefundable">
                        {{'SearchResult.refundable' | translate}}
                    </div>
                    <div class="refundable" *ngIf="!item[0].isRefundable">
                        {{'SearchResult.nonRefundable' | translate}}
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div>
<!-- Details Card -->
<div [ngClass]="showDetails==true? 'details-wrapper' : 'no-details' ">
    <div class="detailsCard">
        <!-- DEPARTURE -->
        <!-- Flight Date -->
        <div class="row mb-3">
            <span class="header-text"> {{"SearchResult.departure" | translate}}</span>
            <span class="subheader-text">({{ item[0].allJourney.flights[0].flightDTO[0].departureDate | date:'EE, d MMM
                y' }})</span>
        </div>
        <!-- AirLine -->
        <div class="row mb-4">
            <div class="col-1">

            </div>
            <div class="col-1 p-0 ">
                <img class="airlineLogo" src="{{
                  item[0].allJourney.flights[0].flightDTO[0]
                    .flightAirline.airlineLogo
                }}" />
            </div>

            <div class="col-9 d-flex align-items-center">
                <span class="airline-text"> {{
                    item[0].allJourney.flights[0].flightDTO[0]
                    .flightAirline.airlineName
                    }}</span>
            </div>
        </div>

        <!-- Flight info -->
        <div class="row">
            <div class="col-1"></div>

            <div class="col-11 col-md-5 d-flex mb-4 mb-md-0">

                <div class="stops-img">
                    <img *ngIf="item[0].allJourney.flights[0].stopsNum == 0"
                        src="../../../assets/img/albaraq/icons/details-stops-direct.svg" alt="" />

                    <img *ngIf="item[0].allJourney.flights[0].stopsNum > 0"
                        src="../../../assets/img/albaraq/icons/details-stops-stop.svg" alt="" />
                </div>

                <div class="total-time">
                    <span>
                        {{
                        item[0].allJourney.flights[0]
                        .elapsedTime | hourminute
                        }}
                    </span>
                </div>

                <div class="dep-data d-flex">
                    <div class="dep-time m-e-10">
                        <span class="subheader-text">
                            {{
                            item[0].allJourney.flights[0]
                            .flightDTO[0].departureDate
                            | date: "shortTime"
                            }}</span>
                    </div>


                    <div class="dep-airport">
                        <span class="subheader-text">
                            {{
                            item[0].allJourney.flights[0]
                            .flightDTO[0]
                            .departureTerminalAirport
                            .cityName
                            }}
                        </span>
                        <span class="subheader-text">
                            ({{
                            item[0].allJourney.flights[0]
                            .flightDTO[0]
                            .departureTerminalAirport
                            .airportCode
                            }})
                        </span>
                    </div>
                </div>

                <div class="arrival-data d-flex">
                    <div class="dep-time m-e-10">
                        <span class="subheader-text">
                            {{
                            item[0].allJourney.flights[0]
                            .flightDTO[item[0].allJourney.flights[0].flightDTO.length
                            -1].arrivalDate
                            | date: "shortTime"
                            }}</span>
                    </div>


                    <div class="dep-airport">
                        <span class="subheader-text">
                            {{
                            item[0].allJourney.flights[0]
                            .flightDTO[item[0].allJourney.flights[0].flightDTO.length
                            -1]
                            .arrivalTerminalAirport
                            .cityName
                            }}
                        </span>
                        <span class="subheader-text">
                            ({{
                            item[0].allJourney.flights[0]
                            .flightDTO[item[0].allJourney.flights[0].flightDTO.length
                            -1]
                            .arrivalTerminalAirport
                            .airportCode
                            }})
                        </span>
                    </div>
                </div>





            </div>

            <div class="col-1 d-md-none"> </div>
            <div class="col-11 col-md-6 d-flex align-items-center">
                <div class="stops-details " *ngIf="item[0].allJourney.flights[0].stopsNum > 0">
                    <span class="subheader-text">{{item[0].allJourney.flights[0].stopsNum}}
                        {{"SearchResult.stop" | translate}}
                        :</span>
                    <ng-container
                        *ngFor="let e of [].constructor(item[0].allJourney.flights[0].stopsNum); let i = index">
                        <span class="subheader-text">
                            {{item[0].allJourney.flights[0].flightDTO[i].transitTime | durationToHourMin}}
                            {{"SearchResult.layover" | translate}}
                            {{item[0].allJourney.flights[0].flightDTO[i].arrivalTerminalAirport.cityName}}
                            ({{item[0].allJourney.flights[0].flightDTO[i].arrivalTerminalAirport.airportCode}})

                        </span>
                        <br *ngIf="i+1 !=item[0].allJourney.flights[0].stopsNum">

                    </ng-container>

                </div>

            </div>

            
        </div>
        <div class="row mt-3" *ngIf="item[0].allJourney.flights[0].flightDTO[0].operatedAirline.airlineName">
                <div class="operatedByTxt">{{"SearchResult.operatedBy" | translate}} {{ item[0].allJourney.flights[0].flightDTO[0].operatedAirline.airlineName }}</div>
        </div>
        
        <!-- END DEPARTURE -->
        <!-- RETURN -->
        <div class="row mb-3 mt-5">
            <span class="header-text"> {{"SearchResult.return" | translate}}</span>
            <span class="subheader-text">({{ item[0].allJourney.flights[1].flightDTO[0].departureDate | date:'EE, d MMM
                y' }})</span>
        </div>
        <!-- AirLine -->
        <div class="row mb-4">
            <div class="col-1">

            </div>
            <div class="col-1 p-0 ">
                <img class="airlineLogo"  src="{{
                  item[0].allJourney.flights[1].flightDTO[0]
                    .flightAirline.airlineLogo
                }}" />
            </div>

            <div class="col-9 d-flex align-items-center">
                <span class="airline-text"> {{
                    item[0].allJourney.flights[1].flightDTO[0]
                    .flightAirline.airlineName
                    }}</span>
            </div>
        </div>

        <!-- Flight info -->
        <div class="row">
            <div class="col-1"></div>

            <div class="col-11 col-md-5 d-flex mb-4 mb-md-0">

                <div class="stops-img">
                    <img *ngIf="item[0].allJourney.flights[1].stopsNum == 0"
                        src="../../../assets/img/albaraq/icons/details-stops-direct.svg" alt="" />

                    <img *ngIf="item[0].allJourney.flights[1].stopsNum > 0"
                        src="../../../assets/img/albaraq/icons/details-stops-stop.svg" alt="" />
                </div>

                <div class="total-time">
                    <span>
                        {{
                        item[0].allJourney.flights[1]
                        .elapsedTime | hourminute
                        }}
                    </span>
                </div>


                <div class="dep-data d-flex">
                    <div class="dep-time m-e-10">
                        <span class="subheader-text">
                            {{
                            item[0].allJourney.flights[1]
                            .flightDTO[0].departureDate
                            | date: "shortTime"
                            }}</span>
                    </div>


                    <div class="dep-airport">
                        <span class="subheader-text">
                            {{
                            item[0].allJourney.flights[1]
                            .flightDTO[0]
                            .departureTerminalAirport
                            .cityName
                            }}
                        </span>
                        <span class="subheader-text">
                            ({{
                            item[0].allJourney.flights[1]
                            .flightDTO[0]
                            .departureTerminalAirport
                            .airportCode
                            }})
                        </span>
                    </div>
                </div>


                <div class="arrival-data d-flex">
                    <div class="dep-time m-e-10">
                        <span class="subheader-text">
                            {{
                            item[0].allJourney.flights[1]
                            .flightDTO[item[0].allJourney.flights[1].flightDTO.length
                            -1].arrivalDate
                            | date: "shortTime"
                            }}</span>
                    </div>


                    <div class="dep-airport">
                        <span class="subheader-text">
                            {{
                            item[0].allJourney.flights[1]
                            .flightDTO[item[0].allJourney.flights[1].flightDTO.length
                            -1]
                            .arrivalTerminalAirport
                            .cityName
                            }}
                        </span>
                        <span class="subheader-text">
                            ({{
                            item[0].allJourney.flights[1]
                            .flightDTO[item[0].allJourney.flights[1].flightDTO.length
                            -1]
                            .arrivalTerminalAirport
                            .airportCode
                            }})
                        </span>
                    </div>
                </div>





            </div>

            <div class="col-1 d-md-none"> </div>
            <div class="col-11 col-md-6 d-flex align-items-center">
                <div class="stops-details " *ngIf="item[0].allJourney.flights[1].stopsNum > 0">
                    <span class="subheader-text">{{item[0].allJourney.flights[1].stopsNum}}
                        {{"SearchResult.stop" | translate}}
                        :</span>
                    <ng-container
                        *ngFor="let e of [].constructor(item[0].allJourney.flights[1].stopsNum); let i = index">
                        <span class="subheader-text">
                            {{item[0].allJourney.flights[1].flightDTO[i].transitTime | durationToHourMin}}
                            {{"SearchResult.layover" | translate}}
                            {{item[0].allJourney.flights[1].flightDTO[i].arrivalTerminalAirport.cityName}}
                            ({{item[0].allJourney.flights[1].flightDTO[i].arrivalTerminalAirport.airportCode}})

                        </span>
                        <br *ngIf="i+1 !=item[0].allJourney.flights[1].stopsNum">

                    </ng-container>

                </div>

            </div>


        </div>
        <div class="row mt-3" *ngIf="item[0].allJourney.flights[1].flightDTO[0].operatedAirline.airlineName">
                <div class="operatedByTxt">{{"SearchResult.operatedBy" | translate}} {{ item[0].allJourney.flights[1].flightDTO[0].operatedAirline.airlineName }}</div>
        </div>
        <!-- END RETURN -->
    </div>
    <!-- more flights card  -->
    <ng-container *ngIf="!moreT">
        <div [id]="'showTerm' + i" class="col-12 p-0" [ngbCollapse]="moreT"
            *ngFor="let term of item | slice: 1; let m = index">
            <div [ngClass]="returnPassTotalFarDifferance(
                this.term.passengerFareBreakDownDTOs,
                this.term.itinTotalFare.amount,
                this.term.itinTotalFare.totalTaxes,
                this.term.itinTotalFare.currencyCode,
                this.calcEqfare,this.returnCorrectFare
                )[0]
                &&
                returnPassTotalFarDifferance(
                    this.term.passengerFareBreakDownDTOs,
                    this.term.itinTotalFare.amount,
                    this.term.itinTotalFare.totalTaxes,
                    this.term.itinTotalFare.currencyCode,
                    this.calcEqfare,this.returnCorrectFare
                    )[1] == 'Discount' ? 'roundCard my-3' : 'roundCard my-4' ">
                <div (click)="trueT[m + 1] = !trueT[m + 1]" [attr.aria-expanded]="!trueT[m + 1]"
                    [attr.aria-controls]="'detailsItem' + m + 1">
                    <div class="topCard">
                        <div class="container">
                            <div class="topCardWrapper" *ngIf="
                            returnPassTotalFarDifferance(
                                this.term.passengerFareBreakDownDTOs,
                                this.term.itinTotalFare.amount,
                                this.term.itinTotalFare.totalTaxes,
                                this.term.itinTotalFare.currencyCode,
                                this.calcEqfare,this.returnCorrectFare
                                )[0]
                                &&
                                returnPassTotalFarDifferance(
                                    this.term.passengerFareBreakDownDTOs,
                                    this.term.itinTotalFare.amount,
                                    this.term.itinTotalFare.totalTaxes,
                                    this.term.itinTotalFare.currencyCode,
                                    this.calcEqfare,this.returnCorrectFare
                                    )[1] == 'Discount'
                            ">
                                <h3> {{"SearchResult.save" | translate}}
    
                                    <span>
                                        {{term['itinTotalFare']['currencyCode']|exchange:'code'}}
                                        {{returnPassTotalFarDifferance(
                                        this.term.passengerFareBreakDownDTOs,
                                        this.term.itinTotalFare.amount,
                                        this.term.itinTotalFare.totalTaxes,
                                        this.term.itinTotalFare.currencyCode,
                                        this.calcEqfare,this.returnCorrectFare
                                        )[0] | exchange:'value' }}
                                    </span>
                                    {{"SearchResult.bySelecting" | translate}}
                                </h3>
    
                                <h3></h3>
    
                            </div>
                        </div>
                    </div>
                    <div class="bodyCard">
                        <div class="container">
                            <div class="row">
                                <!--FLIGHT INFORMATION-->
                                <div class="col-12 col-md-9 col-lg-9 flightInfoContainer">
                                    <div class="container">
                                        <!--FIRST FLIGHT (IN ROUND TRIP IT WILL BE ANOTHER ROW OF THAT)-->
                                        <div class="row mb-4">
                                            <!--ARILINE DETAILS-->
                                            <div class="col-5 col-md-5 col-lg-5">
                                                <div class="row">
                                                    <!-- CheckBOx-->
                                                    <input class="col-1 col-1 checkInput " type="checkbox"
                                                        (click)="selectReturnFlight(term,0)">
                                                    <!-- ARILINE IMAGE -->
                                                    <img class="col-4 col-lg-4 airlineLogo"
                                                        src="{{term.allJourney.flights[0].flightDTO[0].flightAirline.airlineLogo}}"
                                                        alt="airline logo" title="Image Title">
                                                    <!-- ARILINE NAME -->
                                                    <span
                                                        class="col-6 col-lg-7 airlineName">{{term.allJourney.flights[0].flightDTO[0].flightAirline.airlineName}}</span>
                                                </div>
                                            </div>
                                            <!--FLIGHT DETAILS-->
                                            <div class="col-7 col-md-5 col-lg-5 bodyCard_wrapper">
                                                <div class="row">
                                                    <!--DEPARTURE TIME-->
                                                    <div class="col-4 col-md-3 col-lg-3 departSec">
                                                        <div class="row">
                                                            <div class="col-12 col-lg-12 time">
                                                                {{term.allJourney.flights[0].flightDTO[0].departureDate
                                                                |
                                                                date:'shortTime'}}
                                                            </div>
                                                            <div class="col-12 col-lg-12 airport">
                                                                ({{term.allJourney.flights[0].flightDTO[0].departureTerminalAirport.airportCode}})
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--SEGMENT-->
                                                    <div class="col-4 col-md-5 col-lg-5 divSegment">
                                                        <div class="duration durationMob">(
                                                            {{term.allJourney.flights[0].elapsedTime |
                                                            hourminute}} )
                                                        </div>
                                                        <div class="segment">
                                                            <img src="../../../assets/img/albaraq/Plane.png">
                                                        </div>
                                                        <div class="stops" *ngIf="term.allJourney.flights[0].stopsNum > 0">
                                                            {{term.allJourney.flights[0].stopsNum}}
                                                            {{"SearchResult.Stops"|translate}}
                                                        </div>
                                                        <div class="stops" *ngIf="term.allJourney.flights[0].stopsNum == 0">
                                                           
                                                            {{"SearchResult.direct"|translate}}
                                                        </div>
                                                    </div>
                                                    <!--LANDING TIME-->
                                                    <div class="col-4 col-md-4 col-lg-4 landSec">
                                                        <div class="row">
                                                            <div class="col-12 col-lg-12 time">
                                                                {{term.allJourney.flights[0].flightDTO[item[0].allJourney.flights[0].flightDTO.length
                                                                -1].arrivalDate | date:'shortTime'}}
                                                            </div>
                                                            <div class="col-12 col-lg-12 airport">
                                                                ({{term.allJourney.flights[0].flightDTO[item[0].allJourney.flights[0].flightDTO.length
                                                                -1].arrivalTerminalAirport.airportCode}})
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Total Flight Duration -->
                                            <div class="col-0 col-md-2 col-lg-2 p-0 bodyCard_wrapper durationWeb">
                                                <div class="duration">( {{term.allJourney.flights[0].elapsedTime |
                                                    hourminute}} )
                                                </div>
                                            </div>
                                        </div>
    
                                        <!--SECOUND FLIGHT (IN ROUND TRIP IT WILL BE ANOTHER ROW OF THAT)-->
                                        <div class="row my-3" *ngIf="term.allJourney.flights[1]">
                                            <!--ARILINE DETAILS-->
                                            <div class="col-5 col-md-5 col-lg-5">
                                                <div class="row">
                                                    <!-- CheckBOx-->
                                                    <input class="col-1 col-lg-1 checkInput " type="checkbox"
                                                        (click)="selectReturnFlight(term,1)">
                                                    <!-- ARILINE IMAGE -->
                                                    <img class="col-4 col-lg-4 airlineLogo"
                                                        src="{{term.allJourney.flights[1].flightDTO[0].flightAirline.airlineLogo}}"
                                                        alt="airline logo" title="Image Title">
                                                    <!-- ARILINE NAME -->
                                                    <span
                                                        class="col-6 col-lg-7 airlineName">{{term.allJourney.flights[1].flightDTO[0].flightAirline.airlineName}}</span>
                                                </div>
                                            </div>
                                            <!--FLIGHT DETAILS-->
                                            <div class="col-7 col-md-5 col-lg-5 bodyCard_wrapper">
                                                <div class="row">
                                                    <!--DEPARTURE TIME-->
                                                    <div class="col-4 col-md-3 col-lg-3 departSec">
                                                        <div class="row">
                                                            <div class="col-12 col-lg-12 time">
                                                                {{term.allJourney.flights[1].flightDTO[0].departureDate
                                                                |
                                                                date:'shortTime'}}
                                                            </div>
                                                            <div class="col-12 col-lg-12 airport">
                                                                ({{term.allJourney.flights[1].flightDTO[0].departureTerminalAirport.airportCode}})
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!--SEGMENT-->
                                                    <div class="col-4 col-md-5 col-lg-5  divSegment">
                                                        <div class="duration durationMob">(
                                                            {{term.allJourney.flights[1].elapsedTime | hourminute}} )
                                                        </div>
                                                        <div class="segment">
                                                            <img src="../../../assets/img/albaraq/Plane.png">
                                                        </div>
                                                        <div class="stops" *ngIf="term.allJourney.flights[1].stopsNum > 0">
                                                            {{term.allJourney.flights[1].stopsNum}}
                                                            {{"SearchResult.Stops"|translate}}
                                                        </div>
                                                        <div class="stops" *ngIf="term.allJourney.flights[1].stopsNum == 0">
                                                           
                                                            {{"SearchResult.direct"|translate}}
                                                        </div>
                                                    </div>
                                                    <!--LANDING TIME-->
                                                    <div class="col-4 col-md-4 col-lg-4 landSec">
                                                        <div class="row">
                                                            <div class="col-12 col-lg-12 time">
                                                                {{term.allJourney.flights[1].flightDTO[item[0].allJourney.flights[1].flightDTO.length
                                                                -1].arrivalDate | date:'shortTime'}}
                                                            </div>
    
                                                            <div class="col-12 col-lg-12 airport">
                                                                ({{term.allJourney.flights[1].flightDTO[item[0].allJourney.flights[1].flightDTO.length
                                                                -1].arrivalTerminalAirport.airportCode}})
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Total Flight Duration -->
                                            <div class="col-0 col-md-2 col-lg-2 p-0 bodyCard_wrapper durationWeb">
                                                <div class="duration">( {{term.allJourney.flights[1].elapsedTime |
                                                    hourminute}} )
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--FLIGHT PRICE & BOOKING-->
                                <div class="col-12 col-md-3 col-lg-3 flightPriceContainer bookWeb">
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-12 thePrice text-center">
                                                <span class="code">{{term['itinTotalFare']['currencyCode']|exchange:'code'}}
                                                </span>
                                                <span class="price">{{term.itinTotalFare.amount|exchange:'value'}}</span>
                                            </div>
                                            <div class="col-12 priceText text-center">
                                                <div>{{"SearchResult.Price"|translate}}</div>
                                            </div>
                                            <div class="col-10 col-md-12 col-lg-12 BOOK-Btn  text-center" *ngIf="!checkout">
                                                <button mat-raised-button class="bookBtn"
                                                    (click)="flight.toCheckout(flight.response.searchCriteria.searchId,term.sequenceNum,term.pKey,term.pcc)">{{"SearchResult.book"|translate}}
                                                    <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 bookMob">
                                    <div class="section-wrap">
                                        <button mat-raised-button class=" customized-price-button  d-flex"
                         (click)="flight.toCheckout(flight.response.searchCriteria.searchId,item[0].sequenceNum,item[0].pKey,item[0].pcc)">
                         <span class="arrow ">
                             <fa-icon [icon]="faChevronRight"
                             (click)="flight.toCheckout(flight.response.searchCriteria.searchId,item[0].sequenceNum,item[0].pKey,item[0].pcc)">
                         </fa-icon>
                     </span>
                         <span class="code ">{{term['itinTotalFare']['currencyCode']|exchange:'code'}} </span>
                         <span class="price ">{{term.itinTotalFare.amount|exchange:'value'}}</span>
                       
                                       </button>
                                    <div class="refund-mobile">
                         <div class="refundable" *ngIf="term.isRefundable">
                             {{'SearchResult.refundable' | translate}}
                         </div>
                         <div class="refundable" *ngIf="!term.isRefundable">
                             {{'SearchResult.nonRefundable' | translate}}
                         </div>
                                            </div>
                                        </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="footerCard">
                        <div class="container">
                            <div class="viewDetails d-flex justify-content-between">
                            <div class="col-6 col-lg-3 flightDetails" (click)="toggleDetailsMore(m)">
                                <span matRipple matRippleRadius="30">
                                    {{ "SearchResult.ViewDetails" | translate }}</span>
                                <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                            </div>
                            <!-- SEE LESS-->
                            <div class="col-6 col-lg-4 seeMore">
                                <span (click)="moreT = !moreT" [attr.aria-expanded]="!moreT" aria-controls="!moreT">
                                    {{ "SearchResult.less" | translate }}
                                </span>
                                <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                   
                            </div>
                            <div class="col-2 refund">
                    <div class="refundable" *ngIf="term.isRefundable">
                        {{'SearchResult.refundable' | translate}}
                    </div>
                    <div class="refundable" *ngIf="!term.isRefundable">
                        {{'SearchResult.nonRefundable' | translate}}
                    </div>
                </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div [ngClass]="showDetailsMore[m]==true? 'details-wrapper' : 'no-details' ">
    
                <div class="detailsCard">
                    <!-- DEPARTURE -->
                    <!-- Flight Date -->
                    <div class="row mb-3">
                        <span class="header-text"> {{"SearchResult.departure" | translate}}</span>
                        <span class="subheader-text">({{ term.allJourney.flights[0].flightDTO[0].departureDate | date:'EE, d
                            MMM y' }})</span>
                    </div>
                    <!-- AirLine -->
                    <div class="row mb-4">
                        <div class="col-1">
    
                        </div>
                        <div class="col-1 p-0 ">
                            <img class="airlineLogo" src="{{
                             term.allJourney.flights[0].flightDTO[0]
                                .flightAirline.airlineLogo
                            }}" />
                        </div>
    
                        <div class="col-9 d-flex align-items-center">
                            <span class="airline-text"> {{
                                term.allJourney.flights[0].flightDTO[0]
                                .flightAirline.airlineName
                                }}</span>
                        </div>
                    </div>
    
                    <!-- Flight info -->
                    <div class="row">
                        <div class="col-1"></div>
    
                        <div class="col-11 col-md-5 d-flex mb-4 mb-md-0">
    
                            <div class="stops-img">
                                <img *ngIf="term.allJourney.flights[0].stopsNum == 0"
                                    src="../../../assets/img/albaraq/icons/details-stops-direct.svg" alt="" />
    
                                <img *ngIf="term.allJourney.flights[0].stopsNum > 0"
                                    src="../../../assets/img/albaraq/icons/details-stops-stop.svg" alt="" />
                            </div>
    
                            <div class="total-time">
                                <span>
                                    {{
                                    term.allJourney.flights[0]
                                    .elapsedTime | hourminute
                                    }}
                                </span>
                            </div>
    
                            <div class="dep-data d-flex">
                                <div class="dep-time m-e-10">
                                    <span class="subheader-text">
                                        {{
                                        term.allJourney.flights[0]
                                        .flightDTO[0].departureDate
                                        | date: "shortTime"
                                        }}</span>
                                </div>
    
    
                                <div class="dep-airport">
                                    <span class="subheader-text">
                                        {{
                                        term.allJourney.flights[0]
                                        .flightDTO[0]
                                        .departureTerminalAirport
                                        .cityName
                                        }}
                                    </span>
                                    <span class="subheader-text">
                                        ({{
                                        term.allJourney.flights[0]
                                        .flightDTO[0]
                                        .departureTerminalAirport
                                        .airportCode
                                        }})
                                    </span>
                                </div>
                            </div>
    
                            <div class="arrival-data d-flex">
                                <div class="dep-time m-e-10">
                                    <span class="subheader-text">
                                        {{
                                        term.allJourney.flights[0]
                                        .flightDTO[term.allJourney.flights[0].flightDTO.length
                                        -1].arrivalDate
                                        | date: "shortTime"
                                        }}</span>
                                </div>
    
    
                                <div class="dep-airport">
                                    <span class="subheader-text">
                                        {{
                                        term.allJourney.flights[0]
                                        .flightDTO[term.allJourney.flights[0].flightDTO.length
                                        -1]
                                        .arrivalTerminalAirport
                                        .cityName
                                        }}
                                    </span>
                                    <span class="subheader-text">
                                        ({{
                                        term.allJourney.flights[0]
                                        .flightDTO[term.allJourney.flights[0].flightDTO.length
                                        -1]
                                        .arrivalTerminalAirport
                                        .airportCode
                                        }})
                                    </span>
                                </div>
                            </div>
    
    
    
    
    
                        </div>
    
                        <div class="col-1 d-md-none"> </div>
                        <div class="col-11 col-md-6 d-flex align-items-center">
                            <div class="stops-details " *ngIf="term.allJourney.flights[0].stopsNum > 0">
                                <span class="subheader-text">{{term.allJourney.flights[0].stopsNum}}
                                    {{"SearchResult.stop" | translate}}
                                    :</span>
                                <ng-container
                                    *ngFor="let e of [].constructor(term.allJourney.flights[0].stopsNum); let i = index">
                                    <span class="subheader-text">
                                        {{term.allJourney.flights[0].flightDTO[i].transitTime | durationToHourMin}}
                                        {{"SearchResult.layover" | translate}}
                                        {{term.allJourney.flights[0].flightDTO[i].arrivalTerminalAirport.cityName}}
                                        ({{term.allJourney.flights[0].flightDTO[i].arrivalTerminalAirport.airportCode}})
    
                                    </span>
                                    <br *ngIf="i+1 !=term.allJourney.flights[0].stopsNum">
    
                                </ng-container>
    
                            </div>
    
                        </div>
    
    
                    </div>
                    <div class="row mt-3" *ngIf="term.allJourney.flights[0].flightDTO[0].operatedAirline.airlineName">
                         <div class="operatedByTxt">{{"SearchResult.operatedBy" | translate}} {{ term.allJourney.flights[0].flightDTO[0].operatedAirline.airlineName }}</div>
                    </div>
                    <!-- END DEPARTURE -->
                    <!-- RETURN -->
                    <div class="row mb-3 mt-5">
                        <span class="header-text"> {{"SearchResult.return" | translate}}</span>
                        <span class="subheader-text">({{ term.allJourney.flights[1].flightDTO[0].departureDate | date:'EE, d
                            MMM y' }})</span>
                    </div>
                    <!-- AirLine -->
                    <div class="row mb-4">
                        <div class="col-1">
    
                        </div>
                        <div class="col-1 p-0 ">
                            <img class="airlineLogo" src="{{
                                term.allJourney.flights[1].flightDTO[0]
                                .flightAirline.airlineLogo
                            }}" />
                        </div>
    
                        <div class="col-9 d-flex align-items-center">
                            <span class="airline-text"> {{
                                term.allJourney.flights[1].flightDTO[0]
                                .flightAirline.airlineName
                                }}</span>
                        </div>
                    </div>
    
                    <!-- Flight info -->
                    <div class="row">
                        <div class="col-1"></div>
    
                        <div class="col-11 col-md-5 d-flex mb-4 mb-md-0">
    
                            <div class="stops-img">
                                <img *ngIf="term.allJourney.flights[1].stopsNum == 0"
                                    src="../../../assets/img/albaraq/icons/details-stops-direct.svg" alt="" />
    
                                <img *ngIf="term.allJourney.flights[1].stopsNum > 0"
                                    src="../../../assets/img/albaraq/icons/details-stops-stop.svg" alt="" />
                            </div>
    
                            <div class="total-time">
                                <span>
                                    {{
                                    term.allJourney.flights[1]
                                    .elapsedTime | hourminute
                                    }}
                                </span>
                            </div>
    
    
                            <div class="dep-data d-flex">
                                <div class="dep-time m-e-10">
                                    <span class="subheader-text">
                                        {{
                                        term.allJourney.flights[1]
                                        .flightDTO[0].departureDate
                                        | date: "shortTime"
                                        }}</span>
                                </div>
    
    
                                <div class="dep-airport">
                                    <span class="subheader-text">
                                        {{
                                        term.allJourney.flights[1]
                                        .flightDTO[0]
                                        .departureTerminalAirport
                                        .cityName
                                        }}
                                    </span>
                                    <span class="subheader-text">
                                        ({{
                                        term.allJourney.flights[1]
                                        .flightDTO[0]
                                        .departureTerminalAirport
                                        .airportCode
                                        }})
                                    </span>
                                </div>
                            </div>
    
    
                            <div class="arrival-data d-flex">
                                <div class="dep-time m-e-10">
                                    <span class="subheader-text">
                                        {{
                                        term.allJourney.flights[1]
                                        .flightDTO[term.allJourney.flights[1].flightDTO.length
                                        -1].arrivalDate
                                        | date: "shortTime"
                                        }}</span>
                                </div>
    
    
                                <div class="dep-airport">
                                    <span class="subheader-text">
                                        {{
                                        term.allJourney.flights[1]
                                        .flightDTO[term.allJourney.flights[1].flightDTO.length
                                        -1]
                                        .arrivalTerminalAirport
                                        .cityName
                                        }}
                                    </span>
                                    <span class="subheader-text">
                                        ({{
                                        term.allJourney.flights[1]
                                        .flightDTO[term.allJourney.flights[1].flightDTO.length
                                        -1]
                                        .arrivalTerminalAirport
                                        .airportCode
                                        }})
                                    </span>
                                </div>
                            </div>
    
    
    
    
    
                        </div>
    
                        <div class="col-1 d-md-none"> </div>
                        <div class="col-11 col-md-6 d-flex align-items-center">
                            <div class="stops-details " *ngIf="term.allJourney.flights[1].stopsNum > 0">
                                <span class="subheader-text">{{term.allJourney.flights[1].stopsNum}}
                                    {{"SearchResult.stop" | translate}}
                                    :</span>
                                <ng-container
                                    *ngFor="let e of [].constructor(term.allJourney.flights[1].stopsNum); let i = index">
                                    <span class="subheader-text">
                                        {{term.allJourney.flights[1].flightDTO[i].transitTime | durationToHourMin}}
                                        {{"SearchResult.layover" | translate}}
                                        {{term.allJourney.flights[1].flightDTO[i].arrivalTerminalAirport.cityName}}
                                        ({{term.allJourney.flights[1].flightDTO[i].arrivalTerminalAirport.airportCode}})
    
                                    </span>
                                    <br *ngIf="i+1 !=term.allJourney.flights[1].stopsNum">
    
                                </ng-container>
    
                            </div>
    
                        </div>
    
    
                    </div>
                    <div class="row mt-3" *ngIf="term.allJourney.flights[1].flightDTO[0].operatedAirline.airlineName">
                         <div class="operatedByTxt">{{"SearchResult.operatedBy" | translate}} {{ term.allJourney.flights[1].flightDTO[0].operatedAirline.airlineName }}</div>
                    </div>
                    <!-- END RETURN -->
    
    
                </div>
    
            </div>
        </div>
    </ng-container>
    <!--end  more flights card  -->
</div>

 <!-- more flights card  -->
 <ng-container *ngIf="!moreT">
    <div [id]="'showTerm' + i" class="col-12 p-0" [ngbCollapse]="moreT"
        *ngFor="let term of item | slice: 1; let m = index">
        <div class="roundCard my-3">
            <div (click)="trueT[m + 1] = !trueT[m + 1]" [attr.aria-expanded]="!trueT[m + 1]"
                [attr.aria-controls]="'detailsItem' + m + 1">
                <div class="topCard">
                    <div class="container">
                        <!-- <div class="topCardWrapper">
                            <h3>Save $351.49 by selecting this alternate date.</h3>
                        </div> -->


 <div class="topCardWrapper" *ngIf="
            returnPassTotalFarDifferance(
                this.item[0].passengerFareBreakDownDTOs,
                this.item[0].itinTotalFare.amount,
                this.item[0].itinTotalFare.totalTaxes,
                this.item[0].itinTotalFare.currencyCode,
                this.calcEqfare,this.returnCorrectFare
                )[0]
                &&
                returnPassTotalFarDifferance(
                    this.item[0].passengerFareBreakDownDTOs,
                    this.item[0].itinTotalFare.amount,
                    this.item[0].itinTotalFare.totalTaxes,
                    this.item[0].itinTotalFare.currencyCode,
                    this.calcEqfare,this.returnCorrectFare
                    )[1] == 'Discount'
            ">
                <h3> {{"SearchResult.save" | translate}}

                    <span>
                        {{item[0]['itinTotalFare']['currencyCode']|exchange:'code'}}
                        {{returnPassTotalFarDifferance(
                        this.item[0].passengerFareBreakDownDTOs,
                        this.item[0].itinTotalFare.amount,
                        this.item[0].itinTotalFare.totalTaxes,
                        this.item[0].itinTotalFare.currencyCode,
                        this.calcEqfare,this.returnCorrectFare
                        )[0] | exchange:'value'}}
                    </span>
                    {{"SearchResult.bySelecting" | translate}}
                </h3>
            </div>

                        
                    </div>
                </div>
                <div class="bodyCard">
                    <div class="container">
                        <div class="row">
                            <!--FLIGHT INFORMATION-->
                            <div class="col-12 col-md-9 col-lg-9 flightInfoContainer">
                                <div class="container">
                                    <!--FIRST FLIGHT (IN ROUND TRIP IT WILL BE ANOTHER ROW OF THAT)-->
                                    <div class="row mb-4">
                                        <!--ARILINE DETAILS-->
                                        <div class="col-5 col-md-5 col-lg-5">
                                            <div class="row">
                                                <!-- CheckBOx-->
                                                <input class="col-1 col-1 checkInput " type="checkbox"  (click)="selectReturnFlight(term,0)">
                                                <!-- ARILINE IMAGE -->
                                                <img class="col-4 col-lg-4 airlineLogo"
                                                    src="{{term.allJourney.flights[0].flightDTO[0].flightAirline.airlineLogo}}"
                                                    alt="airline logo" title="Image Title">
                                                <!-- ARILINE NAME -->
                                                <span
                                                    class="col-6 col-lg-7 airlineName">{{term.allJourney.flights[0].flightDTO[0].flightAirline.airlineName}}</span>
                                            </div>
                                        </div>
                                        <!--FLIGHT DETAILS-->
                                        <div class="col-7 col-md-5 col-lg-5 bodyCard_wrapper">
                                            <div class="row">
                                                <!--DEPARTURE TIME-->
                                                <div class="col-4 col-md-3 col-lg-3 departSec">
                                                    <div class="row">
                                                        <div class="col-12 col-lg-12 time">
                                                            {{term.allJourney.flights[0].flightDTO[0].departureDate
                                                            |
                                                            date:'shortTime'}}
                                                        </div>
                                                        <div class="col-12 col-lg-12 airport">
                                                            ({{term.allJourney.flights[0].flightDTO[0].departureTerminalAirport.airportCode}})
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--SEGMENT-->
                                                <div class="col-4 col-md-5 col-lg-5 divSegment">
                                                     <div class="duration durationMob">( {{term.allJourney.flights[0].elapsedTime
                                            | hourminute}} )
                                        </div>
                                                    <div class="segment">
                                                        <img src="../../../assets/img/albaraq/Plane.png">
                                                    </div>
                                                    <div class="stops" *ngIf="term.allJourney.flights[0].stopsNum > 0">
                                                        {{term.allJourney.flights[0].stopsNum}}
                                                        {{"SearchResult.Stops"|translate}}
                                                    </div>
                                                    <div class="stops" *ngIf="term.allJourney.flights[0].stopsNum == 0">
                                                       
                                                        {{"SearchResult.direct"|translate}}
                                                    </div>
                                                </div>
                                                <!--LANDING TIME-->
                                                <div class="col-4 col-md-4 col-lg-4 landSec">
                                                    <div class="row">
                                                        <div class="col-12 col-lg-12 time">
                                                            {{term.allJourney.flights[0].flightDTO[term.allJourney.flights[0].flightDTO.length
                                                            -1].arrivalDate | date:'shortTime'}}
                                                        </div>
                                                        <div class="col-12 col-lg-12 airport">
                                                            ({{term.allJourney.flights[0].flightDTO[term.allJourney.flights[0].flightDTO.length
                                                            -1].arrivalTerminalAirport.airportCode}})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Total Flight Duration -->
                                        <div class="col-0 col-md-2 col-lg-2 p-0 bodyCard_wrapper durationWeb">
                                            <div class="duration">( {{term.allJourney.flights[0].elapsedTime |
                                                hourminute}} )
                                            </div>
                                        </div>
                                    </div>

                                    <!--SECOUND FLIGHT (IN ROUND TRIP IT WILL BE ANOTHER ROW OF THAT)-->
                                    <div class="row my-3" *ngIf="term.allJourney.flights[1]">
                                        <!--ARILINE DETAILS-->
                                        <div class="col-5 col-md-5 col-lg-5">
                                            <div class="row">
                                                <!-- CheckBOx-->
                                                <input class="col-1 col-lg-1 checkInput " (click)="selectReturnFlight(term,1)" type="checkbox">
                                                <!-- ARILINE IMAGE -->
                                                <img class="col-4 col-lg-4 airlineLogo"
                                                    src="{{term.allJourney.flights[1].flightDTO[0].flightAirline.airlineLogo}}"
                                                    alt="airline logo" title="Image Title">
                                                <!-- ARILINE NAME -->
                                                <span
                                                    class="col-6 col-lg-7 airlineName">{{term.allJourney.flights[1].flightDTO[0].flightAirline.airlineName}}</span>
                                            </div>
                                        </div>
                                        <!--FLIGHT DETAILS-->
                                        <div class="col-7 col-md-5 col-lg-5 bodyCard_wrapper">
                                            <div class="row">
                                                <!--DEPARTURE TIME-->
                                                <div class="col-4 col-md-3 col-lg-3 departSec">
                                                    <div class="row">
                                                        <div class="col-12 col-lg-12 time">
                                                            {{term.allJourney.flights[1].flightDTO[0].departureDate
                                                            |
                                                            date:'shortTime'}}
                                                        </div>
                                                        <div class="col-12 col-lg-12 airport">
                                                            ({{term.allJourney.flights[1].flightDTO[0].departureTerminalAirport.airportCode}})
                                                        </div>
                                                    </div>
                                                </div>
                                                <!--SEGMENT-->
                                                <div class="col-4 col-md-5 col-lg-5 divSegment">
                                                    <div class="duration durationMob">( {{term.allJourney.flights[0].elapsedTime
                                                        | hourminute}} )
                                                    </div>
                                                    <div class="segment">
                                                        <img src="../../../assets/img/albaraq/Plane.png">
                                                    </div>
                                                    <div class="stops" *ngIf="term.allJourney.flights[1].stopsNum > 0">
                                                        {{term.allJourney.flights[1].stopsNum}}
                                                        {{"SearchResult.Stops"|translate}}
                                                    </div>
                                                    <div class="stops" *ngIf="term.allJourney.flights[1].stopsNum == 0">
                                                       
                                                        {{"SearchResult.direct"|translate}}
                                                    </div>
                                                </div>
                                                <!--LANDING TIME-->
                                                <div class="col-4 col-md-4 col-lg-4 landSec">
                                                    <div class="row">
                                                        <div class="col-12 col-lg-12 time">
                                                            {{term.allJourney.flights[1].flightDTO[term.allJourney.flights[1].flightDTO.length
                                                            -1].arrivalDate | date:'shortTime'}}
                                                        </div>

                                                        <div class="col-12 col-lg-12 airport">
                                                            ({{term.allJourney.flights[1].flightDTO[term.allJourney.flights[1].flightDTO.length
                                                            -1].arrivalTerminalAirport.airportCode}})
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Total Flight Duration -->
                                        <div class="col-0 col-md-2 col-lg-2 p-0 bodyCard_wrapper durationWeb">
                                            <div class="duration">( {{term.allJourney.flights[1].elapsedTime |
                                                hourminute}} )
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--FLIGHT PRICE & BOOKING-->
                            <div class="col-12 col-md-3 col-lg-3 flightPriceContainer bookWeb">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-12 thePrice text-center">
                                            <span
                                                class="code">{{term['itinTotalFare']['currencyCode']|exchange:'code'}}
                                            </span>
                                            <span
                                                class="price">{{term.itinTotalFare.amount|exchange:'value'}}</span>
                                        </div>
                                        <div class="col-12 priceText text-center">
                                            <div>{{"SearchResult.Price"|translate}}</div>
                                        </div>
                                        <div class="col-10 col-md-12 col-lg-12 BOOK-Btn  text-center"
                                            *ngIf="!checkout">
                                            <button mat-raised-button class="bookBtn"
                                                (click)="flight.toCheckout(flight.response.searchCriteria.searchId,term.sequenceNum,term.pKey,term.pcc)">{{"SearchResult.book"|translate}}
                                                <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 bookMob">
                                    <div class="section-wrap">
                                        <button mat-raised-button class=" customized-price-button  d-flex"
                         (click)="flight.toCheckout(flight.response.searchCriteria.searchId,item[0].sequenceNum,item[0].pKey,item[0].pcc)">
                         <span class="arrow ">
                             <fa-icon [icon]="faChevronRight"
                             (click)="flight.toCheckout(flight.response.searchCriteria.searchId,item[0].sequenceNum,item[0].pKey,item[0].pcc)">
                         </fa-icon>
                     </span>
                         <span class="code ">{{term['itinTotalFare']['currencyCode']|exchange:'code'}} </span>
                         <span class="price ">{{term.itinTotalFare.amount|exchange:'value'}}</span>
                       
                                       </button>
                                    <div class="refund-mobile">
                         <div class="refundable" *ngIf="term.isRefundable">
                             {{'SearchResult.refundable' | translate}}
                         </div>
                         <div class="refundable" *ngIf="!term.isRefundable">
                             {{'SearchResult.nonRefundable' | translate}}
                         </div>
                                            </div>
                                        </div>
                                   
                                </div>
                        </div>
                    </div>
                </div>
                <div class="footerCard">
                    <div class="container">
                        <div class="viewDetails d-flex justify-content-between">
                            <div class="col-6 col-lg-3 flightDetails" (click)="toggleDetailsMore(m)">
                                <span matRipple matRippleRadius="30">
                                    {{ "SearchResult.ViewDetails" | translate }}</span>
                                <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                            </div>
                            <!-- SEE LESS-->
                            <div class="col-6 col-lg-4 seeMore">
                                <span (click)="moreT = !moreT" [attr.aria-expanded]="!moreT" aria-controls="!moreT">
                                    {{ "SearchResult.less" | translate }}
                                </span>
                                <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                   
                            </div>
                            <div class="col-2 refund">
                    <div class="refundable" *ngIf="term.isRefundable">
                        {{'SearchResult.refundable' | translate}}
                    </div>
                    <div class="refundable" *ngIf="!term.isRefundable">
                        {{'SearchResult.nonRefundable' | translate}}
                    </div>
                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div [ngClass]="showDetailsMore[m]==true? 'details-wrapper' : 'no-details' ">

            <div class="detailsCard">
                <!-- DEPARTURE -->
                <!-- Flight Date -->
                <div class="row mb-3">
                    <span class="header-text"> {{"SearchResult.departure" | translate}}</span>
                    <span class="subheader-text">({{ term.allJourney.flights[0].flightDTO[0].departureDate | date:'EE, d MMM y' }})</span>
                </div>
                <!-- AirLine -->
                <div class="row mb-4">
                    <div class="col-1">
        
                    </div>
                    <div class="col-1 p-0 ">
                        <img class="airlineLogo" src="{{
                            term.allJourney.flights[0].flightDTO[0]
                            .flightAirline.airlineLogo
                        }}" />
                    </div>
        
                    <div class="col-9 d-flex align-items-center">
                        <span class="airline-text"> {{
                            term.allJourney.flights[0].flightDTO[0]
                            .flightAirline.airlineName
                            }}</span>
                    </div>
                </div>
        
                <!-- Flight info -->
                <div class="row">
                    <div class="col-1"></div>
        
                    <div class="col-11 col-md-5 d-flex mb-4 mb-md-0">
        
                        <div class="stops-img">
                            <img *ngIf="term.allJourney.flights[0].stopsNum == 0"
                                src="../../../assets/img/albaraq/icons/details-stops-direct.svg" alt="" />
        
                            <img *ngIf="term.allJourney.flights[0].stopsNum > 0"
                                src="../../../assets/img/albaraq/icons/details-stops-stop.svg" alt="" />
                        </div>
        
                        <div class="total-time">
                            <span>
                                {{
                                    term.allJourney.flights[0]
                                .elapsedTime | hourminute
                                }}
                            </span>
                        </div>
        
                        <div class="dep-data d-flex">
                            <div class="dep-time m-e-10">
                                <span class="subheader-text">
                                    {{
                                        term.allJourney.flights[0]
                                    .flightDTO[0].departureDate
                                    | date: "shortTime"
                                    }}</span>
                            </div>
        
        
                            <div class="dep-airport">
                                <span class="subheader-text">
                                    {{
                                        term.allJourney.flights[0]
                                    .flightDTO[0]
                                    .departureTerminalAirport
                                    .cityName
                                    }}
                                </span>
                                <span class="subheader-text">
                                    ({{
                                        term.allJourney.flights[0]
                                    .flightDTO[0]
                                    .departureTerminalAirport
                                    .airportCode
                                    }})
                                </span>
                            </div>
                        </div>
        
                        <div class="arrival-data d-flex">
                            <div class="dep-time m-e-10">
                                <span class="subheader-text">
                                    {{
                                        term.allJourney.flights[0]
                                    .flightDTO[term.allJourney.flights[0].flightDTO.length
                                    -1].arrivalDate
                                    | date: "shortTime"
                                    }}</span>
                            </div>
        
        
                            <div class="dep-airport">
                                <span class="subheader-text">
                                    {{
                                        term.allJourney.flights[0]
                                    .flightDTO[term.allJourney.flights[0].flightDTO.length
                                    -1]
                                    .arrivalTerminalAirport
                                    .cityName
                                    }}
                                </span>
                                <span class="subheader-text">
                                    ({{
                                        term.allJourney.flights[0]
                                    .flightDTO[term.allJourney.flights[0].flightDTO.length
                                    -1]
                                    .arrivalTerminalAirport
                                    .airportCode
                                    }})
                                </span>
                            </div>
                        </div>
        
        
        
        
        
                    </div>
        
                    <div class="col-1 d-md-none"> </div>
                    <div class="col-11 col-md-6 d-flex align-items-center">
                        <div class="stops-details " *ngIf="term.allJourney.flights[0].stopsNum > 0">
                            <span class="subheader-text">{{term.allJourney.flights[0].stopsNum}}
                                {{"SearchResult.stop" | translate}}
                                :</span>
                            <ng-container
                                *ngFor="let e of [].constructor(term.allJourney.flights[0].stopsNum); let i = index">
                                <span class="subheader-text">
                                    {{term.allJourney.flights[0].flightDTO[i].transitTime | durationToHourMin}} 
                                    {{"SearchResult.layover" | translate}}
                                    {{term.allJourney.flights[0].flightDTO[i].arrivalTerminalAirport.cityName}}
                                    ({{term.allJourney.flights[0].flightDTO[i].arrivalTerminalAirport.airportCode}})
                                    
                                </span>
                                <br *ngIf="i+1 !=term.allJourney.flights[0].stopsNum">
        
                            </ng-container>
        
                        </div>
        
                    </div>
        
        
                </div>
                <div class="row mt-3" *ngIf="term.allJourney.flights[0].flightDTO[0].operatedAirline.airlineName">
                         <div class="operatedByTxt">{{"SearchResult.operatedBy" | translate}} {{ term.allJourney.flights[0].flightDTO[0].operatedAirline.airlineName }}</div>
                    </div>
                <!-- END DEPARTURE -->
                <!-- RETURN -->
                <div class="row mb-3 mt-5">
                    <span class="header-text"> {{"SearchResult.return" | translate}}</span>
                    <span class="subheader-text">({{ term.allJourney.flights[1].flightDTO[0].departureDate | date:'EE, d MMM y' }})</span>
                </div>
                <!-- AirLine -->
                <div class="row mb-4">
                    <div class="col-1">
        
                    </div>
                    <div class="col-1 p-0">
                        <img src="{{
                            term.allJourney.flights[1].flightDTO[0]
                            .flightAirline.airlineLogo
                        }}" />
                    </div>
        
                    <div class="col-9 d-flex align-items-center">
                        <span class="airline-text"> {{
                            term.allJourney.flights[1].flightDTO[0]
                            .flightAirline.airlineName
                            }}</span>
                    </div>
                </div>
        
                <!-- Flight info -->
                <div class="row">
                    <div class="col-1"></div>
        
                    <div class="col-11 col-md-5 d-flex mb-4 mb-md-0">
        
                        <div class="stops-img">
                            <img *ngIf="term.allJourney.flights[1].stopsNum == 0"
                                src="../../../assets/img/albaraq/icons/details-stops-direct.svg" alt="" />
        
                            <img *ngIf="term.allJourney.flights[1].stopsNum > 0"
                                src="../../../assets/img/albaraq/icons/details-stops-stop.svg" alt="" />
                        </div>
        
                        <div class="total-time">
                            <span>
                                {{
                                    term.allJourney.flights[1]
                                .elapsedTime | hourminute
                                }}
                            </span>
                        </div>
                 
        
                        <div class="dep-data d-flex">
                            <div class="dep-time m-e-10">
                                <span class="subheader-text">
                                    {{
                                        term.allJourney.flights[1]
                                    .flightDTO[0].departureDate
                                    | date: "shortTime"
                                    }}</span>
                            </div>
        
        
                            <div class="dep-airport">
                                <span class="subheader-text">
                                    {{
                                        term.allJourney.flights[1]
                                    .flightDTO[0]
                                    .departureTerminalAirport
                                    .cityName
                                    }}
                                </span>
                                <span class="subheader-text">
                                    ({{
                                        term.allJourney.flights[1]
                                    .flightDTO[0]
                                    .departureTerminalAirport
                                    .airportCode
                                    }})
                                </span>
                            </div>
                        </div>
        
                
                        <div class="arrival-data d-flex">
                            <div class="dep-time m-e-10">
                                <span class="subheader-text">
                                    {{
                                        term.allJourney.flights[1]
                                    .flightDTO[term.allJourney.flights[1].flightDTO.length
                                    -1].arrivalDate
                                    | date: "shortTime"
                                    }}</span>
                            </div>
        
        
                            <div class="dep-airport">
                                <span class="subheader-text">
                                    {{
                                        term.allJourney.flights[1]
                                    .flightDTO[term.allJourney.flights[1].flightDTO.length
                                    -1]
                                    .arrivalTerminalAirport
                                    .cityName
                                    }}
                                </span>
                                <span class="subheader-text">
                                    ({{
                                        term.allJourney.flights[1]
                                    .flightDTO[term.allJourney.flights[1].flightDTO.length
                                    -1]
                                    .arrivalTerminalAirport
                                    .airportCode
                                    }})
                                </span>
                            </div>
                        </div>
        
        
        
        
        
                    </div>
        
                    <div class="col-1 d-md-none"> </div>
                    <div class="col-11 col-md-6 d-flex align-items-center">
                        <div class="stops-details " *ngIf="term.allJourney.flights[1].stopsNum > 0">
                            <span class="subheader-text">{{term.allJourney.flights[1].stopsNum}}
                                {{"SearchResult.stop" | translate}}
                                :</span>
                            <ng-container
                                *ngFor="let e of [].constructor(term.allJourney.flights[1].stopsNum); let i = index">
                                <span class="subheader-text">
                                    {{term.allJourney.flights[1].flightDTO[i].transitTime | durationToHourMin}} 
                                    {{"SearchResult.layover" | translate}}
                                    {{term.allJourney.flights[1].flightDTO[i].arrivalTerminalAirport.cityName}}
                                    ({{term.allJourney.flights[1].flightDTO[i].arrivalTerminalAirport.airportCode}})
                                    
                                </span>
                                <br *ngIf="i+1 !=term.allJourney.flights[1].stopsNum">
        
                            </ng-container>
        
                        </div>
        
                    </div>
        
        
                </div>
                <div class="row mt-3" *ngIf="term.allJourney.flights[1].flightDTO[0].operatedAirline.airlineName">
                         <div class="operatedByTxt">{{"SearchResult.operatedBy" | translate}} {{ term.allJourney.flights[1].flightDTO[0].operatedAirline.airlineName }}</div>
                    </div>
                <!-- END RETURN -->
        
        
            </div>
        
        </div>
    </div>
</ng-container>
<!--end  more flights card  -->

