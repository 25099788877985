import { GetHotelModule } from './../../models/search-hotel/get-hotels.module';
import { createAction, props } from '@ngrx/store';
import { SearchHoteltModule } from '@src/app/models/search-hotel/search-hotel.module';
import { HotelRoom, Room, RoomResult } from '@src/app/models/HotelRooms/HotelRooms';
import { BookingObject } from '@src/app/models/search-hotel/hotel-booking-model';
import { HotelResult, HotelSearchResult } from '@src/app/models/search-hotel/search-hotel-result';
import { RequiredHotel } from '@src/app/models/HotelBooking/RequiredHotelData';

export const loadHotelSearchs = createAction(
  '[HotelSearch] Load HotelSearchs',
  props<{ data: SearchHoteltModule }>()
);

export const loadHotelSearchsSuccess = createAction(
  '[HotelSearch] Load HotelSearchs Success',
  props<{ data: HotelResult }>()
);

export const loadHotelSearchsFailure = createAction(
  '[HotelSearch] Load HotelSearchs Failure',
  props<{ error: any }>()
);

export const updateHotelSearchCretaria = createAction(
  '[HotelSearch] Update HotelSearchsCriteria',
  props<{ data: SearchHoteltModule }>()
);

export const updateFiltiredHotels = createAction(
  '[HotelSearch] Update Filtired Hotels',
  props<{ data: HotelSearchResult[] }>() 
);

export const loadHotelRooms = createAction(
  '[HotelSearch] Load Hotel Rooms',
  props<{ pId: any, sId : any , hotelID:any }>()
);

export const loadHotelRoomsSuccess = createAction(
  '[HotelSearch] Load Hotel Rooms Success',
  props<{ data: HotelRoom }>()
);

export const loadHotelRoomsFailure = createAction(
  '[HotelSearch] Load Hotel Rooms Failure',
  props<{ error: any }>()
);

export const updateSelectedRooms = createAction(
  '[HotelSearch] Update Selected Rooms',
  props<{ data: RoomResult }>()
);

export const loadHotelAmenties = createAction(
  '[HotelSearch] Load Hotel Amenties',
  props<{ hotelID:any }>()
);

export const loadHotelAmentiesSuccess = createAction(
  '[HotelSearch] Load Hotel Amenties Success',
  props<{ data: any }>()
);

export const loadHotelAmentiesFailure = createAction(
  '[HotelSearch] Load Hotel Amenties Failure',
  props<{ error: any }>()
);


export const hotelSaveBooking = createAction(
  '[HotelSearch] Hotel Save Booking',
  props<{ data:BookingObject , ip:any , ipLocation:any , searchId:any  , lang:string}>()
);

export const hotelSaveBookingSuccess = createAction(
  '[HotelSearch] Hotel Save Booking Success',
  props<{ data: BookingObject }>()
);

export const hotelSaveBookingFailure = createAction(
  '[HotelSearch]  Hotel Save Booking Failure',
  props<{ error: any }>()
);

export const loadRoomCancellation = createAction(
  '[HotelSearch] Load Room Cancellation',
  props<{ sid: string, hotelcode: any,  PId: any , roomindex: any}>()
);

export const loadRoomCancellationSuccess = createAction(
  '[HotelSearch] Load Room Cancellation Success',
  props<{ data: any }>()
);

export const loadRoomCancellationFailure = createAction(
  '[HotelSearch] Load Room Cancellation Failure',
  props<{ error: any }>()
);

export const loadSelectedHotel = createAction(
  '[HotelSearch] Load Serlected Hotel',
  props<{ sid:any, hotelID:any, Pid:any, rooms:any}>()
);

export const loadSelectedHotelSuccess = createAction(
  '[HotelSearch] Load Serlected Hotel Success',
  props<{ data : RequiredHotel }>()
);

export const loadSelectedHotelFailure = createAction(
  '[HotelSearch] Load Serlected Hotel Failure',
  props<{ error : any }>()
);

export const updateRoomImages = createAction(
  '[HotelSearch] Update Room Images',
  props<{ data: string[] }>() 
);

