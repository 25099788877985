import { PointOfsaleModule } from 'src/app/models/point-ofsale/point-ofsale.module';
import { CurrencyModule } from 'src/app/models/currency/currency.module';
import { createAction, props } from '@ngrx/store';
import { AirPorts } from '@src/app/interface/flight-search-result';
import { CountriescodeModule } from '@src/app/models/countriescode/countriescode.module';
import { HotelsCitiesModule } from '@src/app/models/hotels-cities/hotels-cities.module';
import { TopCityFlight, tripideas } from '@src/app/interface/Home-content';

export const loadUtilitysAirports = createAction(
  '[UtilitysAirports] Load UtilitysAirports',
  props <{ lang: string }>()
);

export const loadUtilitysAirportsSuccess = createAction(
  '[UtilitysAirports] Load UtilitysAirports Success',
  props<{ airports: AirPorts[] }>()
);

export const loadUtilitysAirportsFailure = createAction(
  '[UtilitysAirports] Load UtilitysAirports Failure',
  props<{ error: any }>()
);

export const loadUtilitysCurrency = createAction(
  '[UtilitysCurrency] Load UtilitysCurrency'
);

export const loadFlightTopCities = createAction(
  '[TopCities] Load FTopCities'
);

export const loadFlightTopCitiesSuccess = createAction(
  '[TopCities] Load FTopCities Success',
  props<{ Tcities: TopCityFlight[][] }>()
);

export const loadFlightTopCitiesFailure = createAction(
  '[TopCities] Load FTopCities Failure',
  props<{ error: any }>()
); 
export const loadFlightTripIdeas= createAction(
  '[TopCities] Load TripIdeas',
  props<{ Tcities: TopCityFlight[] }>()
);

export const loadFlightTripIdeasSuccess = createAction(
  '[TopCities] Load TripIdeas Success',
  props<{ ideas: tripideas[] }>()
);

export const loadFlightTripIdeasFailure = createAction(
  '[TopCities] Load TripIdeas Failure',
  props<{ error: any }>()
); 

export const loadUtilitysCurrencySuccess = createAction(
  '[UtilitysCurrency] Load UtilitysCurrency Success',
  props<{ Currency: CurrencyModule[] }>()
);

export const loadUtilitysCurrencyFailure = createAction(
  '[UtilitysCurrency] Load UtilitysCurrency Failure',
  props<{ error: any }>()
);

export const loadUtilitysPOS = createAction(
  '[UtilitysPOS] Load UtilitysPOS'
);

export const loadUtilitysPOSSuccess = createAction(
  '[UtilitysPOS] Load UtilitysPOS Success',
  props<{ POS: PointOfsaleModule }>()
);

export const loadUtilitysPOSFailure = createAction(
  '[UtilitysPOS] Load UtilitysPOS Failure',
  props<{ error: any }>()
);

export const loadUtilitysCountries= createAction(
  '[Countries] Load Countries',
  props <{ lang: string }>()
);

export const loadUtilitysCountriesSuccess = createAction(
  '[Countries] Load Countries Success',
  props<{ countries: CountriescodeModule[] }>()
);

export const loadUtilitysCountriesFailure = createAction(
  '[Countries] Load Countries Failure',
  props<{ error: any }>()
);

export const loadUtilitysCities= createAction(
  '[Cities] Load Cities'
);

export const loadUtilitysCitiesSuccess = createAction(
  '[Cities] Load Cities Success',
  props<{ cities: HotelsCitiesModule[] }>()
);

export const loadUtilitysCitiesFailure = createAction(
  '[Cities] Load Cities Failure',
  props<{ error: any }>()
);

export const contactUs = createAction(
  '[Contact] Contact Us',
  props<{ data: any }>()
);

export const contactUsSuccess= createAction(
  '[Contact] Contact Us Success',
  props<{ data: any }>()
);

export const contactUsFailure= createAction(
  '[Contact] Contact Us Failure',
  props<{ err: any }>()
);



