import { Component, OnInit,ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from 'angular-google-tag-manager';


@Component({
  selector: 'app-term-ofuse',
  templateUrl: './term-ofuse.component.html',
  styleUrls: ['./term-ofuse.component.scss']
})
export class TermOfuseComponent implements OnInit {
 

  constructor(
    public translate: TranslateService,
    private gtmService: GoogleTagManagerService,
    private router: Router,) { 
    const gtmTag = {
      event: 'Page View',
      pageName: 'Terms Of Use',
      language: this.translate.currentLang, 
      url:this.router.url
    };
    this.gtmService.pushTag(gtmTag);
  }

  ngOnInit() {
  }

  
}
