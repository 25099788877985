import { Component, OnInit, Input ,OnDestroy} from '@angular/core';
import { FlightServiceService } from '../../services/flight-service.service';
import { MyapiService } from '../../services/myapi.service';
import {
  airItineraries,
  fare,
  FareRules,
  flight,
  flightOfflineService,
  passengerFareBreakDownDTOs,
  Selectedflight,
} from '../../interface/flight-search-result';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FarRulesComponent } from '../far-rules/far-rules.component';
import {faLuggageCart,faAngleDoubleDown, faForward, faCaretRight, faInfoCircle, faArrowRight, faArrowDown, faSuitcaseRolling, faPlane, faGripVertical, faInfo, faUtensils, faWifi, faChevronDown, faMoon, faHourglassEnd, faChevronUp, faAngleRight, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import { IconProp } from '@fortawesome/fontawesome-svg-core';

type fareCalc = (fare:fare[])=>number;
type calcEqfare =(flightFaresDTO: passengerFareBreakDownDTOs[],type:string,farecalc:fareCalc)=>number;
declare var $: any;

export interface BreakDownView{
  ADT:{
    totalFare:[number,string],
    ScFare:[number,string,number]
  },
  CNN:{
    totalFare:[number,string],
    ScFare:[number,string,number]
  },
  INF:{
    totalFare:[number,string],
    ScFare:[number,string,number]
  }
}
@Component({
  selector: 'app-one-way',
  templateUrl: './one-way.component.html',
  styleUrls: ['./one-way.component.scss']
})
export class OneWayComponent implements OnInit ,OnDestroy {
  subscribtions:Subscription = new Subscription() ;
  faLuggageCart = faLuggageCart as IconProp;
  faChevronDown = faChevronDown as IconProp;
  faForward=faForward as IconProp;
  faMoon=faMoon as IconProp; 
  faCaretRight=faCaretRight as IconProp;
  faAngleRight= faAngleRight as IconProp
  faInfoCircle=faInfoCircle as IconProp;
  faArrowRight = faArrowRight as IconProp;
   faArrowDown = faArrowDown as IconProp;
   faChevronRight= faChevronRight as IconProp
   faHourglassEnd=faHourglassEnd as IconProp;
   faSuitcaseRolling=faSuitcaseRolling as IconProp;
   faPlane=faPlane as IconProp;
   faGripVertical = faGripVertical as IconProp;
   faInfo=faInfo as IconProp;
   faUtensils = faUtensils as IconProp;
   faYoutubeSquare= faYoutubeSquare as IconProp;
   faWifi=faWifi as IconProp;
   faChevronUp=faChevronUp as IconProp;
  @Input() item: any[] = [];
  @Input() i: number = 0;
  @Input() over: boolean = false;
  @Input () response: any;
  @Input() checkout: boolean;
  @Input() Selected:Selectedflight;
  Loading:boolean= false
  FareLoading: boolean = true;
  showDetails:boolean=false;
  showDetailsMore:boolean[]=[];
  disc: [number, string, string];

  fare: FareRules[];
  trueT: boolean[] = [];
  moreT: boolean = true;
  closeResult = '';
  term:any;

  public show:boolean = false;
  constructor(
    public flight: FlightServiceService,
    private search: MyapiService, 
    private modalService: NgbModal,
    public translate : TranslateService) { }

  ngOnInit() {
    this.trueT = this.flight.valuesoftrue(this.item);
    if(this.Selected){
      this.item = this.mapSelected(this.Selected);
    }
  }
  toggleDetails(){
    this.showDetails = !this.showDetails;
  }
  toggleDetailsMore(index:number){
    this.showDetailsMore[index] = !this.showDetailsMore[index];
  }
  toggle() {
    this.show = !this.show;

    // CHANGE THE NAME OF THE BUTTON.
    
  }
  openLink(link:string){
    this.Loading= true;
        // this.Loading.emit(true);
          setTimeout(()=>{
    
            window.open(link);
            this.Loading= false;
    
            // this.Loading.emit(false);
    
          }, 10000);
    
      
      }
  showFareRules(squencNumber: number, pKey: string) {

 
    this.FareLoading = true;
    this.subscribtions.add(
    this.subscribtions.add(this.search.fareRules(this.flight.serachId, squencNumber, pKey).subscribe(
      (result) => {
        this.FareLoading = false;
        this.fare = result;

      }

    )));
  }


  togglemodel(index: number, sequenceNum: number, pKey: string) {
    let id = '#fareModal' + index;
    $(id).modal('toggle');
    this.showFareRules(sequenceNum, pKey);
  }

  togglemodelt(i: number, m: number, sequenceNum: number, pKey: string) {
    let id = '#fareModal' + i + 'l' + m;
    $(id).modal('toggle');
    this.showFareRules(sequenceNum, pKey);
  }



  open(sequenceNum: number, pKey: string) {

    const modalRef = this.modalService.open(FarRulesComponent);
    modalRef.componentInstance.serachId = this.flight.serachId;
    modalRef.componentInstance.squencNumber = sequenceNum;
    modalRef.componentInstance.providerKey = pKey;



  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  // openmodel(content,term='') {
  //   this.term = term;
  //   this.offcanvasService.open(content, { position: 'end' });
  // }

    /**
  * 
  * @param flightFaresDTO 
  * @param passNumber 
  * @returns [total value ,curruncy code]
  */
     returnPassTotalFar(flightFaresDTO: fare[], passNumber: number, calcfare: fareCalc): [number, string] {
      let Total: fare = flightFaresDTO.filter(v => v.fareType.toLowerCase() === 'equivfare')[0];
      return Total ? [calcfare(flightFaresDTO) * passNumber, Total.currencyCode] : [NaN, 'KWD'];
    }
    /**
     * 
     * @param flightFaresDTO 
     * @param passNumber 
     * @returns [total value per passenger ,curruncy code , number of passenger]
     */
    returnPassFareScatterd(flightFaresDTO: fare[], passNumber: number, calcfare: fareCalc): [number, string, number] {
      let Total: fare = flightFaresDTO.filter(v => v.fareType.toLowerCase() === 'equivfare')[0];
      return Total ? [calcfare(flightFaresDTO), Total.currencyCode, passNumber] : [NaN, 'KWD', NaN];
    }
    /**
     * 
     * @param flightFaresDTO 
     * @param totalAmount 
     * @param totalTax 
     * @param curruncy 
     * @param calcEqfare 
     * @param fareCalc 
     * @returns value of discount or service fees
     */
    returnPassTotalFarDifferance(flightFaresDTO: passengerFareBreakDownDTOs[], totalAmount: number, totalTax: number, curruncy: string, calcEqfare: calcEqfare, fareCalc: fareCalc): [number, string, string] {
      if(!flightFaresDTO){
        return  [0, '', 'KWD'];
      }
      let AdtFares = calcEqfare(flightFaresDTO, 'ADT', fareCalc);
      let childFare = calcEqfare(flightFaresDTO, 'CNN', fareCalc);
      let infentFare = calcEqfare(flightFaresDTO, 'INF', fareCalc);
      let TotalFare = AdtFares + childFare + infentFare + totalTax;
      let fareDiff = totalAmount - TotalFare;
      if (fareDiff > 0) {
        return [Math.round(fareDiff), 'Service Fees', curruncy]
      } else if (fareDiff < 0) {
        return [Math.round(-1 * fareDiff), 'Discount', curruncy]
      } else {
        return [0, '', 'KWD'];
      }
  
    }
  
    /**
     * 
     * @param flightFaresDTO 
     * @param type 
     * @param farecalc 
     * @returns numer of passenger * fare of passenger
     */
    calcEqfare(flightFaresDTO: passengerFareBreakDownDTOs[], type: string, farecalc: fareCalc): number {
      let fare = farecalc(flightFaresDTO.filter((v) => v.passengerType === type)[0]?.flightFaresDTOs);
      // let fare = flightFaresDTO.filter((v)=>v.passengerType === type)[0]?.flightFaresDTOs.filter(v=>v.fareType.toLowerCase() === 'equivfare')[0].fareAmount;
      let quntity = flightFaresDTO.find((v) => v.passengerType === type)?.passengerQuantity;
      return fare && quntity ? fare * quntity : 0;
    }
  
    /**
     * 
     * @param fare 
     * @returns validate equivelent fare
     */
  
    returnCorrectFare(fare: fare[]): number {
      if (fare) {
        let equivfare = fare.find(v => v.fareType.toLowerCase() === 'equivfare').fareAmount;
        let totalFare = fare.find(v => v.fareType.toLowerCase() === 'totalfare').fareAmount;
        let totalTax = fare.find(v => v.fareType.toLowerCase() === 'totaltax').fareAmount;
        return equivfare > 0 ? equivfare : totalFare - totalTax;
      } else {
        return 0
      }
  
    }

    mapSelected(selected:Selectedflight):airItineraries[] {
      let out:airItineraries = {
        sequenceNum: selected.airItineraryDTO.sequenceNum,
        pKey: selected.airItineraryDTO.pKey,
        isRefundable: selected.airItineraryDTO.isRefundable,
        itinTotalFare: selected.airItineraryDTO.itinTotalFare,
        totalDuration: selected.airItineraryDTO.totalDuration,
        deptDate: selected.airItineraryDTO.deptDate,
        arrivalDate: selected.airItineraryDTO.arrivalDate,
        cabinClass: selected.airItineraryDTO.cabinClass,
        flightType: selected.airItineraryDTO.flightType,
        pcc: selected.airItineraryDTO.pcc,
        allJourney: {
          flights: selected.airItineraryDTO.allJourney.flights
        },
        baggageInformation:selected.airItineraryDTO.baggageInformation
      }
  
      return [out]
    }
  
  ngOnDestroy(): void {
    this.subscribtions.unsubscribe();
  }
}
