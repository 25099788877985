import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyapiService } from '@src/app/services/myapi.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-confirm-payment',
  templateUrl: './confirm-payment.component.html',
  styleUrls: ['./confirm-payment.component.scss']
})
export class ConfirmPaymentComponent implements OnInit {
  lang: string = this.translate.currentLang;
  link: string = "";
  mysafeurl: SafeUrl;
  normalErrorStatus:boolean=false;
  private subscription: Subscription = new Subscription();
  constructor(public translate: TranslateService,private sanitizer: DomSanitizer, public router: Router, private myApi :MyapiService) { }

  ngOnInit(): void {
  }
  Next(){
    this.subscription.add(this.myApi.GetPaymentView(this.myApi.ip, this.myApi.iplocation, this.myApi.hgNumber, this.myApi.searchId, '', this.myApi.selectedserv).subscribe(
      (result) => {
        this.link = result.link; 
        window.location.href = this.link;
        
        this.mysafeurl = this.sanitizer.bypassSecurityTrustResourceUrl(this.link);
        if (this.link.length < 5) {
          this.normalErrorStatus = true;

        }
      },
      (error) => {
        this.normalErrorStatus = true;
      }
    ));
  }
  Back(){
this.router.navigate(['/home'])
  }
}
