import { ImagesDTO } from './../../interface/offers';
import { createAction, props } from "@ngrx/store";
import { BookedOffer } from "@src/app/interface/BookOffer";
import { OfferDTO } from "@src/app/interface/offers";
import { PointOfsaleModule } from "@src/app/models/point-ofsale/point-ofsale.module";
import { Itinerary } from '@src/app/interface/offlineSeats';

export const loadAllOffers = createAction(
    '[OFFERS] Load Offers',
    props<{pos : string}>()
);
  
  export const loadAllOffersSuccess = createAction(
    '[OFFERS] Load Offers Success',
    props<{ data: OfferDTO[] }>()
  );
  
  export const loadAllOffersFailure = createAction(
    '[OFFERS] Load Offers Failure',
    props<{err:any}>()
  );

  export const loadOfferById = createAction(
    '[OFFERS] Load Offer By ID',
    props<{id : number | string}>()
);
  
  export const loadOfferByIdSuccess = createAction(
    '[OFFERS] Load Offer By ID Success',
    props<{ data: OfferDTO }>()
  );
  
  export const loadOfferByIdFailure = createAction(
    '[OFFERS] Load Offer By ID Failure',
    props<{err:any}>()
  );

  export const BookOffers = createAction(
    '[OFFERS] Book Offers',
    props<{Source : string ,LanguageCode:string, searchID:string ,body:BookedOffer}>()
);
  
  export const BookOffersSuccess = createAction(
    '[OFFERS] Book Offers Success',
    props<{ data: BookedOffer}>()
  );
  
  export const BookOffersFailure = createAction(
    '[OFFERS] Book Offers Failure',
    props<{err:any}>()
  );

  export const loadServicesImages = createAction(
    '[OFFERS] Load Services Images',
    props<{data : ImagesDTO[]}>()
);

export const retrieveItinerary = createAction(
  '[OFFERS] Load Offline Itinerary',
  props<{id : number | string}>()
);

export const retrieveItinerarySuccess = createAction(
  '[OFFERS] Load Offline Itinerary Success',
  props<{data : Itinerary}>()
);

export const retrieveItineraryFailure = createAction(
  '[OFFERS] Load Offline Itinerary Failure',
  props<{err : any}>()
);



  
