<div class="container-fluid mobF" [ngClass]="translate.currentLang =='ar'? 'ar' : ''">
    <div class="row">
        <!-- filter -->
       <div class="col-12 ">
        <div class="row ButtonFilterSortSelec">
            <div class="col-6" mat-button [matMenuTriggerFor]="mobileSortMenu">
              <span>
                <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.875 10.0938C0.875 9.90313 0.950725 9.72031 1.08552 9.58552C1.22031 9.45072 1.40313 9.375 1.59375 9.375H5.90625C6.09687 9.375 6.27969 9.45072 6.41448 9.58552C6.54927 9.72031 6.625 9.90313 6.625 10.0938C6.625 10.2844 6.54927 10.4672 6.41448 10.602C6.27969 10.7368 6.09687 10.8125 5.90625 10.8125H1.59375C1.40313 10.8125 1.22031 10.7368 1.08552 10.602C0.950725 10.4672 0.875 10.2844 0.875 10.0938ZM0.875 5.78125C0.875 5.59063 0.950725 5.40781 1.08552 5.27302C1.22031 5.13823 1.40313 5.0625 1.59375 5.0625H11.6562C11.8469 5.0625 12.0297 5.13823 12.1645 5.27302C12.2993 5.40781 12.375 5.59063 12.375 5.78125C12.375 5.97187 12.2993 6.15469 12.1645 6.28948C12.0297 6.42427 11.8469 6.5 11.6562 6.5H1.59375C1.40313 6.5 1.22031 6.42427 1.08552 6.28948C0.950725 6.15469 0.875 5.97187 0.875 5.78125ZM0.875 1.46875C0.875 1.27813 0.950725 1.09531 1.08552 0.960517C1.22031 0.825725 1.40313 0.75 1.59375 0.75H17.4062C17.5969 0.75 17.7797 0.825725 17.9145 0.960517C18.0493 1.09531 18.125 1.27813 18.125 1.46875C18.125 1.65937 18.0493 1.84219 17.9145 1.97698C17.7797 2.11177 17.5969 2.1875 17.4062 2.1875H1.59375C1.40313 2.1875 1.22031 2.11177 1.08552 1.97698C0.950725 1.84219 0.875 1.65937 0.875 1.46875Z"
                    fill="#043263" />
                </svg>
                {{'searchFlight.sort' | translate}}
              </span>
            </div>
            <!--sort menu-->
            <mat-menu #mobileSortMenu="matMenu" class="classMenu" >
              <div mat-menu-item [matMenuTriggerFor]="timeSortMenu" [ngClass]="{'selectedClass': selectedSortingValue=='3' || selectedSortingValue=='4' ||selectedSortingValue=='5' ||selectedSortingValue=='6'}"
                class="classOption">
              {{"SearchResult.sort.text"|translate}}
              </div>
        
              <div (click)="sortMyResult(1)" mat-menu-item [ngClass]="{'selectedClass': selectedSortingValue=='1'}"
                class="classOption">
              {{"SearchResult.sort.price"|translate}}
              </div>
        
              <div (click)="sortMyResult(2)" mat-menu-item [ngClass]="{'selectedClass': selectedSortingValue=='2'}"
                class="classOption">
              {{"SearchResult.sort.duration"|translate}}
              </div>
            </mat-menu>
        
            <mat-menu #timeSortMenu="matMenu" class="classMenu">
              <div mat-menu-item (click)="sortMyResult(3)" [ngClass]="{'selectedClass': selectedSortingValue=='3'}"
                class="classOption">
                {{"SearchResult.sort.time.depEarly" | translate}}
              </div>
              <div mat-menu-item (click)="sortMyResult(4)" [ngClass]="{'selectedClass': selectedSortingValue=='4'}"
                class="classOption">
                {{"SearchResult.sort.time.depLate" | translate}}
              </div>
              <div *ngIf="flightType == 'RoundTrip' ||flightType == 'roundtrip'" mat-menu-item (click)="sortMyResult(5)" [ngClass]="{'selectedClass': selectedSortingValue=='5'}"
                class="classOption">
                {{"SearchResult.sort.time.retEarly" | translate}}
              </div>
              <div *ngIf="flightType == 'RoundTrip' ||flightType == 'roundtrip'" mat-menu-item (click)="sortMyResult(6)" [ngClass]="{'selectedClass': selectedSortingValue=='6'}"
                class="classOption">
                {{"SearchResult.sort.time.retLate" | translate}}
              </div>
            </mat-menu>
        
        
        
            <div class="col-6">
            <span (click)="ShowFilter()">
              <svg width="19" height="11" viewBox="0 0 19 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.625 10.0938C6.625 9.90313 6.70073 9.72031 6.83552 9.58552C6.97031 9.45072 7.15313 9.375 7.34375 9.375H11.6562C11.8469 9.375 12.0297 9.45072 12.1645 9.58552C12.2993 9.72031 12.375 9.90313 12.375 10.0938C12.375 10.2844 12.2993 10.4672 12.1645 10.602C12.0297 10.7368 11.8469 10.8125 11.6562 10.8125H7.34375C7.15313 10.8125 6.97031 10.7368 6.83552 10.602C6.70073 10.4672 6.625 10.2844 6.625 10.0938ZM3.75 5.78125C3.75 5.59063 3.82573 5.40781 3.96052 5.27302C4.09531 5.13823 4.27813 5.0625 4.46875 5.0625H14.5312C14.7219 5.0625 14.9047 5.13823 15.0395 5.27302C15.1743 5.40781 15.25 5.59063 15.25 5.78125C15.25 5.97187 15.1743 6.15469 15.0395 6.28948C14.9047 6.42427 14.7219 6.5 14.5312 6.5H4.46875C4.27813 6.5 4.09531 6.42427 3.96052 6.28948C3.82573 6.15469 3.75 5.97187 3.75 5.78125ZM0.875 1.46875C0.875 1.27813 0.950725 1.09531 1.08552 0.960517C1.22031 0.825725 1.40313 0.75 1.59375 0.75H17.4062C17.5969 0.75 17.7797 0.825725 17.9145 0.960517C18.0493 1.09531 18.125 1.27813 18.125 1.46875C18.125 1.65937 18.0493 1.84219 17.9145 1.97698C17.7797 2.11177 17.5969 2.1875 17.4062 2.1875H1.59375C1.40313 2.1875 1.22031 2.11177 1.08552 1.97698C0.950725 1.84219 0.875 1.65937 0.875 1.46875Z"
                  fill="#043263" />
              </svg>
        
              {{'searchFlight.filter' | translate}}
            </span>
            </div>
          </div>
       </div>
        <div class="col-12 col-md-12 col-lg-3" [ngClass]="{mobileFilterRR: this.flight.fSelect == false}">
           <app-filter-selected-flight></app-filter-selected-flight>
        </div>
        <!-- content -->
        <div class="col-12 col-md-12 col-lg-9">
            <div class="selectReturn">
                <!-- flight selected -->
                             <div *ngIf="selectedpickySelectionFlight$ |async as select">
                                 <div >
                                     <div class="container borderCard">
                                         <div class="row">
                                             <!--FLIGHT INFORMATION-->
                                             <div class="col-12 col-md-12 col-lg-9">
                                                 <div class="container">
                                                     <!--FIRST FLIGHT (IN ROUND TRIP IT WILL BE ANOTHER ROW OF THAT)-->
                                                     <div class="row">
                                                         <!--ARILINE DETAILS-->
                                                         <div class="col-5 col-md-3 col-lg-4">
                                                             <div class="row">
                                                                 <!-- CheckBOx-->
                                                                 <input class="col-1 col-lg-1 checkInput " (click)="ReturnToFlightResult()" type="checkbox" checked>
                                                                 <!-- ARILINE IMAGE -->
                                                                 <img class="col-5 col-lg-5 airlineLogo"
                                                                     src="{{select.selectedFlight.flightDTO[0].flightAirline.airlineLogo}}"
                                                                     alt="airline logo" title="Image Title">
                                                                 <!-- ARILINE NAME -->
                                                                 <span
                                                                     class="col-6 col-lg-6 airlineName">{{select.selectedFlight.flightDTO[0].flightAirline.airlineName}}</span>
                                                             </div>
                                                         </div>
                                                         <!--FLIGHT DETAILS-->
                                                         <div class="col-7 col-md-7 col-lg-6 p-0 bodyCard_wrapper">
                                                             <div class="row">
                                                                 <!--DEPARTURE TIME-->
                                                                 <div class="col-4 col-md-4 col-lg-4 departSec">
                                                                     <div class="row">
                                                                         <div class="col-12 col-lg-12 time">
                                                                             {{select.selectedFlight.flightDTO[0].departureDate |
                                                                             date:'shortTime'}}
                                                                         </div>
                             
                                                                         <div class="col-12 col-lg-12 airport">
                                                                             ({{select.selectedFlight.flightDTO[0].departureTerminalAirport.airportCode}})
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                                 <!--SEGMENT-->
                                                                 <div class="col-4 col-md-4 col-lg-4 divSegment">
                                                                    <div  class="duration durationMob">( {{select.selectedFlight.elapsedTime | hourminute}} )
                                                                    </div>
                                                                     <div class="segment">
                                                                         <img class="w-100" src="../../../assets/img/albaraq/Plane.png">
                                                                     </div>
                                                                     <div class="stops">
                                                                         {{select.selectedFlight.stopsNum}}
                                                                         {{"SearchResult.Stops"|translate}}
                                                                     </div>
                                                                 </div>
                                                                 <!--LANDING TIME-->
                                                                 <div class="col-4 col-md-4 col-lg-4 landSec">
                                                                     <div class="row">
                                                                         <div class="col-12 col-lg-12 time">
                                                                            
                                                                             {{select.selectedFlight.flightDTO[ select.selectedFlight.flightDTO.length
                                                                                 -1].arrivalDate | date:'shortTime'}}
                                                                         </div>
                                                                         <div class="col-12 col-lg-12 airport">
                                                                             ({{select.selectedFlight.flightDTO[ select.selectedFlight.flightDTO.length
                                                                                 -1].arrivalTerminalAirport.airportCode}})
                                                                         </div>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                         </div>
                                                         <!-- Total Flight Duration -->
                                                         <div class="col-0 col-md-2 col-lg-2 p-0 bodyCard_wrapper durationWeb">
                                                             <div  class="duration">( {{select.selectedFlight.elapsedTime | hourminute}} )
                                                             </div>
                                                         </div>
                                                     </div>
                                                 </div>
                                             </div>
                                             <!--FLIGHT PRICE & BOOKING-->
                                             <div class="col-12 col-md-12 col-lg-3">
                                                 <div class="container">
                                                     <div class="row">
                                                         <div class="col-12 thePrice text-center">
                                                            <span class="code">{{select.selectedFlightItinerary.itinTotalFare.currencyCode | exchange:'code'}}</span>

                                                             <!-- <span class="code">{{select.selectedFlightItinerary.['itinTotalFare']['currencyCode']|exchange:'code'}} </span> -->
                                                             <span class="price">{{select.selectedFlightItinerary.itinTotalFare.amount|exchange:'value'}}</span>
                                                         </div>
                                                         <div class="col-12 priceText text-center bookWeb">
                                                            <div class="priceText">{{"SearchResult.Price"|translate}}</div>
                                                        </div>
                                                     </div>
                                                 </div>
                                                 <!-- <div class="container">
                                                     <div class="row">
                                                        
                                                         <div class="col-12 priceText text-center">
                                                             <div>{{"SearchResult.Price"|translate}}</div>
                                                         </div>
                                                         <div class="col-10 col-md-12 col-lg-12 BOOK-Btn  text-center" *ngIf="!checkout">
                                                             <button mat-raised-button class="bookBtn"
                                                                 (click)="flight.toCheckout(flight.response.searchCriteria.searchId,item.sequenceNum,item.pKey)">{{"SearchResult.book"|translate}}
                                                                 <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                                                             </button>
                                                         </div>
                                                     </div>
                                                 </div> -->
                                             </div>
                                         </div>
                                     </div>
                                 </div>        
                             </div>
                         
                         
                         
                         
                         
                         
                         
                         <!-- sorting -->
                         <div class="col-12 mt-4 WebSort">
                            <div class="row px-3 ">
                                <button class="sortBtn col-6 col-lg-3" mat-button [matMenuTriggerFor]="timeSort">
                                  <div class="sortBtn_wrapper">
                                    <p class="sortBtnTitle">{{'SearchResult.sort.text' | translate}}</p>
                                  </div>
                                </button>
                                <!-- Sort Time Option-->
                                <mat-menu #timeSort="matMenu">
                                  <button (click)="sortMyResult(3)" mat-menu-item>{{"SearchResult.sort.time.depEarly" |
                                    translate}}</button>
                                  <button (click)="sortMyResult(4)" mat-menu-item>{{"SearchResult.sort.time.depLate" |
                                    translate}}</button>
                                  <button  (click)="sortMyResult(5)"
                                    mat-menu-item>{{"SearchResult.sort.time.retEarly" | translate}}</button>
                                  <button  (click)="sortMyResult(6)"
                                    mat-menu-item>{{"SearchResult.sort.time.retLate" | translate}}</button>
                                </mat-menu>
                                <button class="sortBtn col-6 col-lg-3" (click)="sortMyResult(1)" mat-button >
                                  <div class="sortBtn_wrapper">
                                    <p class="sortBtnTitle"> {{"SearchResult.sort.price" | translate}} </p>
                                    <!-- <h4 class="sortBtnPrice"> {{responseCurrency | exchange:'code'}} {{priceLowestFare |
                                      number: '1.0-0' | exchange:'value'}}
                                    </h4> -->
                                  </div>
                                </button>
                                <!--------------------->
                                <button class="sortBtn col-6 col-lg-3" (click)="sortMyResult(2)" mat-button >
                                  <div class="sortBtn_wrapper">
                                    <p class="sortBtnTitle"> {{"SearchResult.sort.duration" | translate}}</p>
                                    <!-- <h4 class="sortBtnPrice">{{responseCurrency | exchange:'code'}} {{durationLowesrFare | number: '1.0-0' |
                                      exchange:'value'}}
                                    </h4> -->
                                  </div>
                                </button>
                                <!--------------------->
                                <button class="sortBtn col-6 col-lg-3" (click)="sortMyResult(1)" mat-button >
                                  <div class="sortBtn_wrapper">
                                    <p class="sortBtnTitle"> {{"SearchResult.Price" | translate}}</p>
                                    <!-- <h4 class="sortBtnPrice">{{responseCurrency | exchange:'code'}} {{durationLowesrFare | number: '1.0-0' |
                                      exchange:'value'}}
                                    </h4> -->
                                  </div>
                                </button>
                                <!--------------------->
                              </div>
                         </div>
                         
                         
                         
                         
                         
                         
                         <!-- depart or return based on flightN  -->
                         
                         <div class="scrolling">
                            <div *ngIf="selectedpickySelectionFlight$ |async as select">
                                <div *ngFor="let item of select.otherOptions; let index=i">
                            
                            
                               <div *ngIf="select.flightIndex == 0">
                                    <div class="container mt-4 borderSelectedCard" >
                                        <div class="row">
                                            <!--FLIGHT INFORMATION-->
                                            <div class="col-12 col-md-9 col-lg-9 pt-4 ">
                                                <div class="container">
                                                    <!--FIRST FLIGHT (IN ROUND TRIP IT WILL BE ANOTHER ROW OF THAT)-->
                                                    <div class="row" >
                                                        <!--ARILINE DETAILS-->
                                                        <div class="col-5 col-md-3 col-lg-3">
                                                            <div class="row">
                                                                <!-- CheckBOx-->
                            
                                                                <!-- ARILINE IMAGE -->
                                                                <img class="col-5 col-lg-5 airlineLogo"
                                                                    src="{{item.allJourney.flights[1].flightDTO[0].flightAirline.airlineLogo}}"
                                                                    alt="airline logo" title="Image Title">
                                                                <!-- ARILINE NAME -->
                                                                <span
                                                                    class="col-7 col-lg-7 airlineName">{{item.allJourney.flights[1].flightDTO[0].flightAirline.airlineName}}</span>
                                                            </div>
                                                        </div>
                                                        <!--FLIGHT DETAILS-->
                                                        <div class="col-7 col-md-7 col-lg-7 p-0 bodyCard_wrapper">
                                                            <div class="row">
                                                                <!--DEPARTURE TIME-->
                                                                <div class="col-4 col-md-4 col-lg-4 departSec">
                                                                    <div class="row">
                                                                        <div class="col-12 col-lg-12 time">
                                                                            {{item.allJourney.flights[1].flightDTO[0].departureDate |
                                                                            date:'shortTime'}}
                                                                        </div>
                            
                                                                        <div class="col-12 col-lg-12 airport">
                                                                            ({{item.allJourney.flights[1].flightDTO[0].departureTerminalAirport.airportCode}})
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!--SEGMENT-->
                                                                <div class="col-4 col-md-4 col-lg-4 divSegment">
                                                                    <div class="duration durationMob">( {{item.allJourney.flights[1].elapsedTime | hourminute}} )
                                                                    </div>
                                                                    <div class="segment">
                                                                        <img class="w-100"  src="../../../assets/img/albaraq/Plane.png">
                                                                    </div>
                                                                    <div class="stops">
                                                                        {{item.allJourney.flights[1].stopsNum}}
                                                                        {{"SearchResult.Stops"|translate}}
                                                                    </div>
                                                                </div>
                                                                <!--LANDING TIME-->
                                                                <div class="col-4 col-md-4 col-lg-4 landSec">
                                                                    <div class="row">
                                                                        <div class="col-12 col-lg-12 time">
                                                                            {{item.allJourney.flights[1].flightDTO[item.allJourney.flights[1].flightDTO.length
                                                                            -1].arrivalDate | date:'shortTime'}}
                                                                        </div>
                                                                        <div class="col-12 col-lg-12 airport">
                                                                            ({{item.allJourney.flights[1].flightDTO[item.allJourney.flights[1].flightDTO.length
                                                                            -1].arrivalTerminalAirport.airportCode}})
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Total Flight Duration -->
                                                        <div class="col-0 col-md-2 col-lg-2 p-0 bodyCard_wrapper durationWeb">
                                                            <div class="duration">( {{item.allJourney.flights[1].elapsedTime | hourminute}} )
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--FLIGHT PRICE & BOOKING-->
                                            <div class="col-12 col-md-3 col-lg-3 bookWeb">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-12 thePrice text-center">
                                                           <span  class="code"  *ngIf=" item.itinTotalFare.amount >= select.selectedFlightItinerary.itinTotalFare.amount "> + </span>
                                                           <span  class="code"  *ngIf=" item.itinTotalFare.amount < select.selectedFlightItinerary.itinTotalFare.amount "> - </span>
                                                            <span class="code">  {{item['itinTotalFare']['currencyCode']|exchange:'code'}} </span>
                                                            <span class="price" *ngIf="select.selectedFlightItinerary.itinTotalFare.amount == item.itinTotalFare.amount"> 0</span>
                                                            <span class="price" *ngIf="select.selectedFlightItinerary.itinTotalFare.amount > item.itinTotalFare.amount">{{(select.selectedFlightItinerary.itinTotalFare.amount - item.itinTotalFare.amount ) |exchange:'value'}}</span>
   
                                                            <span class="price" *ngIf="select.selectedFlightItinerary.itinTotalFare.amount < item.itinTotalFare.amount">{{(item.itinTotalFare.amount - select.selectedFlightItinerary.itinTotalFare.amount) |exchange:'value'}}</span>
                                                        </div>
                                                        <div class="col-12 priceText text-center">
                                                           <div class="priceText">{{"SearchResult.Price"|translate}}</div>
                                                       </div>
                                                        <div class="col-12 col-md-12 col-lg-12 BOOK-Btn  text-center" *ngIf="!checkout">
                                                            <button mat-raised-button class="bookBtn"
                                                                (click)="flight.toCheckout(this.serachId,item.sequenceNum,item.pKey)">{{"SearchResult.book"|translate}}
                                                                <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                                                            </button>
                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 bookMob">
                                                <div class=" thePrice "  (click)="flight.toCheckout(this.serachId,item.sequenceNum,item.pKey)">
                                                    <span  class="code"  *ngIf=" item.itinTotalFare.amount >= select.selectedFlightItinerary.itinTotalFare.amount "> + </span>
                                                           <span  class="code"  *ngIf=" item.itinTotalFare.amount < select.selectedFlightItinerary.itinTotalFare.amount "> - </span>
                                                            <span class="code">  {{item['itinTotalFare']['currencyCode']|exchange:'code'}} </span>
                                                            <span class="price" *ngIf="select.selectedFlightItinerary.itinTotalFare.amount == item.itinTotalFare.amount"> 0</span>
                                                            <span class="price" *ngIf="select.selectedFlightItinerary.itinTotalFare.amount > item.itinTotalFare.amount">{{(select.selectedFlightItinerary.itinTotalFare.amount - item.itinTotalFare.amount ) |exchange:'value'}}</span>
   
                                                            <span class="price" *ngIf="select.selectedFlightItinerary.itinTotalFare.amount < item.itinTotalFare.amount">{{(item.itinTotalFare.amount - select.selectedFlightItinerary.itinTotalFare.amount) |exchange:'value'}}</span>
                                                    <fa-icon [icon]="faChevronDown"  (click)="flight.toCheckout(this.serachId,item.sequenceNum,item.pKey)"></fa-icon>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="footerCard">
                                                    <div class="container">
                                                        <div class="viewDetails d-flex">
                                                            <div class="col-12 col-md-7 col-lg-7 flightDetails" (click)="toggleDetails()">
                                                                <span matRipple matRippleRadius="30">
                                                                    {{ "SearchResult.ViewDetails" | translate }}</span>
                                                                <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                                                            </div>
                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                             </div>
                                        </div>
                                    </div>
                                    <div *ngIf="showDetails">
   
   
   
   
                                        <div class="detailsCard col-12 ">
                                            <!-- DEPARTURE -->
                                            <!-- Flight Date -->
                                            <div class="row mb-3">
                                                <span class="header-text"> {{"SearchResult.departure" | translate}}</span>
                                                <span class="subheader-text">({{ select.selectedFlightItinerary.allJourney.flights[0].flightDTO[0].departureDate | date:'EE, d MMM y' }})</span>
                                            </div>
                                            <!-- AirLine -->
                                           <!-- AirLine -->
            <div class="row mb-4">
                <div class="col-0 col-md-0 col-lg-1">
    
                </div>
                <div class="col-3 col-lg-1 p-0">
                    <img  class="w-100" src="{{
                        select.selectedFlightItinerary.allJourney.flights[0].flightDTO[0]
                        .flightAirline.airlineLogo
                    }}" />
                </div>
    
                <div class="col-9 d-flex align-items-center">
                    <span class="airline-text"> {{
                        select.selectedFlightItinerary.allJourney.flights[0].flightDTO[0]
                        .flightAirline.airlineName
                        }}</span>
                </div>
            </div>
    
            <div class="row">
                <div class="col-3 col-lg-1"></div>
    
                <div class="col-9 col-md-5 d-flex mb-4 mb-md-0">
    
                    <div class="stops-img">
                        <img *ngIf="select.selectedFlightItinerary.allJourney.flights[0].stopsNum == 0"
                            src="../../../assets/img/albaraq/icons/details-stops-direct.svg" alt="" />
    
                        <img *ngIf="select.selectedFlightItinerary.allJourney.flights[0].stopsNum > 0"
                            src="../../../assets/img/albaraq/icons/details-stops-stop.svg" alt="" />
                    </div>
    
                    <div class="total-time">
                        <span>
                            {{
                                select.selectedFlightItinerary.allJourney.flights[0]
                            .elapsedTime | hourminute
                            }}
                        </span>
                    </div>
    
                    <div class="dep-data d-flex">
                        <div class="dep-time m-e-10">
                            <span class="subheader-text">
                                {{
                                    select.selectedFlightItinerary.allJourney.flights[0]
                                .flightDTO[0].departureDate
                                | date: "shortTime"
                                }}</span>
                        </div>
    
    
                        <div class="dep-airport">
                            <span class="subheader-text">
                                {{
                                    select.selectedFlightItinerary.allJourney.flights[0]
                                .flightDTO[0]
                                .departureTerminalAirport
                                .cityName
                                }}
                            </span>
                            <span class="subheader-text">
                                ({{
                                    select.selectedFlightItinerary.allJourney.flights[0]
                                .flightDTO[0]
                                .departureTerminalAirport
                                .airportCode
                                }})
                            </span>
                        </div>
                    </div>
    
                    <div class="arrival-data d-flex">
                        <div class="dep-time m-e-10">
                            <span class="subheader-text">
                                {{
                                    select.selectedFlightItinerary.allJourney.flights[0]
                                .flightDTO[ select.selectedFlightItinerary.allJourney.flights[0].flightDTO.length
                                -1].arrivalDate
                                | date: "shortTime"
                                }}</span>
                        </div>
    
    
                        <div class="dep-airport">
                            <span class="subheader-text">
                                {{
                                    select.selectedFlightItinerary.allJourney.flights[0]
                                .flightDTO[ select.selectedFlightItinerary.allJourney.flights[0].flightDTO.length
                                -1]
                                .arrivalTerminalAirport
                                .cityName
                                }}
                            </span>
                            <span class="subheader-text">
                                ({{
                                    select.selectedFlightItinerary.allJourney.flights[0]
                                .flightDTO[ select.selectedFlightItinerary.allJourney.flights[0].flightDTO.length
                                -1]
                                .arrivalTerminalAirport
                                .airportCode
                                }})
                            </span>
                        </div>
                    </div>
    
    
    
    
    
                </div>
    
                <div class="col-1 d-md-none"> </div>
                <div class="col-11 col-md-6 d-flex align-items-center">
                    <div class="stops-details " *ngIf=" select.selectedFlightItinerary.allJourney.flights[0].stopsNum > 0">
                        <span class="subheader-text">{{ select.selectedFlightItinerary.allJourney.flights[0].stopsNum}}
                            {{"SearchResult.stop" | translate}}
                            :</span>
                        <ng-container
                            *ngFor="let e of [].constructor( select.selectedFlightItinerary.allJourney.flights[0].stopsNum); let i = index">
                            <span class="subheader-text">
                                {{ select.selectedFlightItinerary.allJourney.flights[0].flightDTO[i].transitTime | durationToHourMin}} 
                                {{"SearchResult.layover" | translate}}
                                {{ select.selectedFlightItinerary.allJourney.flights[0].flightDTO[i].arrivalTerminalAirport.cityName}}
                                ({{ select.selectedFlightItinerary.allJourney.flights[0].flightDTO[i].arrivalTerminalAirport.airportCode}})
                                
                            </span>
                            <br *ngIf="i+1 != select.selectedFlightItinerary.allJourney.flights[0].stopsNum">
    
                        </ng-container>
    
                    </div>
    
                </div>
    
    
            </div>
                                            <!-- END DEPARTURE -->
                                            <!-- RETURN -->
                                              <!-- RETURN -->
            <div class="row mb-3 mt-5">
                <span class="header-text"> {{"SearchResult.return" | translate}}</span>
                <span class="subheader-text">({{ select.selectedFlightItinerary.allJourney.flights[1].flightDTO[0].departureDate | date:'EE, d MMM y' }})</span>
            </div>
            <!-- AirLine -->
            <div class="row mb-4">
                <div class="col-1">
    
                </div>
                <div class="col-3 col-lg-1 p-0">
                    <img class="w-100" src="{{
                        select.selectedFlightItinerary.allJourney.flights[1].flightDTO[0]
                        .flightAirline.airlineLogo
                    }}" />
                </div>
    
                <div class="col-9  d-flex align-items-center">
                    <span class="airline-text"> {{
                        select.selectedFlightItinerary.allJourney.flights[1].flightDTO[0]
                        .flightAirline.airlineName
                        }}</span>
                </div>
            </div>
    
            <!-- Flight info -->
            <div class="row">
                <div class="col-3 col-lg-1"></div>
    
                <div class="col-9  col-md-5 d-flex mb-4 mb-md-0">
    
                    <div class="stops-img">
                        <img *ngIf="select.selectedFlightItinerary.allJourney.flights[1].stopsNum == 0"
                            src="../../../assets/img/albaraq/icons/details-stops-direct.svg" alt="" />
    
                        <img *ngIf="select.selectedFlightItinerary.allJourney.flights[1].stopsNum > 0"
                            src="../../../assets/img/albaraq/icons/details-stops-stop.svg" alt="" />
                    </div>
    
                    <div class="total-time">
                        <span>
                            {{
                                select.selectedFlightItinerary.allJourney.flights[1]
                            .elapsedTime | hourminute
                            }}
                        </span>
                    </div>
             
    
                    <div class="dep-data d-flex">
                        <div class="dep-time m-e-10">
                            <span class="subheader-text">
                                {{
                                    select.selectedFlightItinerary.allJourney.flights[1]
                                .flightDTO[0].departureDate
                                | date: "shortTime"
                                }}</span>
                        </div>
    
    
                        <div class="dep-airport">
                            <span class="subheader-text">
                                {{
                                    select.selectedFlightItinerary.allJourney.flights[1]
                                .flightDTO[0]
                                .departureTerminalAirport
                                .cityName
                                }}
                            </span>
                            <span class="subheader-text">
                                ({{
                                    select.selectedFlightItinerary.allJourney.flights[1]
                                .flightDTO[0]
                                .departureTerminalAirport
                                .airportCode
                                }})
                            </span>
                        </div>
                    </div>
    
            
                    <div class="arrival-data d-flex">
                        <div class="dep-time m-e-10">
                            <span class="subheader-text">
                                {{
                                    select.selectedFlightItinerary.allJourney.flights[1]
                                .flightDTO[select.selectedFlightItinerary.allJourney.flights[1].flightDTO.length
                                -1].arrivalDate
                                | date: "shortTime"
                                }}</span>
                        </div>
    
    
                        <div class="dep-airport">
                            <span class="subheader-text">
                                {{
                                    select.selectedFlightItinerary.allJourney.flights[1]
                                .flightDTO[select.selectedFlightItinerary.allJourney.flights[1].flightDTO.length
                                -1]
                                .arrivalTerminalAirport
                                .cityName
                                }}
                            </span>
                            <span class="subheader-text">
                                ({{
                                    select.selectedFlightItinerary.allJourney.flights[1]
                                .flightDTO[select.selectedFlightItinerary.allJourney.flights[1].flightDTO.length
                                -1]
                                .arrivalTerminalAirport
                                .airportCode
                                }})
                            </span>
                        </div>
                    </div>
    
    
    
    
    
                </div>
    
                <div class="col-1 d-md-none"> </div>
                <div class="col-11 col-md-6 d-flex align-items-center">
                    <div class="stops-details " *ngIf="select.selectedFlightItinerary.allJourney.flights[1].stopsNum > 0">
                        <span class="subheader-text">{{select.selectedFlightItinerary.allJourney.flights[1].stopsNum}}
                            {{"SearchResult.stop" | translate}}
                            :</span>
                        <ng-container
                            *ngFor="let e of [].constructor(select.selectedFlightItinerary.allJourney.flights[1].stopsNum); let i = index">
                            <span class="subheader-text">
                                {{select.selectedFlightItinerary.allJourney.flights[1].flightDTO[i].transitTime | durationToHourMin}} 
                                {{"SearchResult.layover" | translate}}
                                {{select.selectedFlightItinerary.allJourney.flights[1].flightDTO[i].arrivalTerminalAirport.cityName}}
                                ({{select.selectedFlightItinerary.allJourney.flights[1].flightDTO[i].arrivalTerminalAirport.airportCode}})
                                
                            </span>
                            <br *ngIf="i+1 !=select.selectedFlightItinerary.allJourney.flights[1].stopsNum">
    
                        </ng-container>
    
                    </div>
    
                </div>
    
    
            </div>
                                            <!-- AirLine -->
                                           
                                    
                                            <!-- Flight info -->
                                            
                                            <!-- END RETURN -->
                                    
                                    
                                        </div>
    
    
    
    
    
    
    
    
    
    
    
                                       
                                     
                                     </div>
                                </div>
                            
                                    <div *ngIf="select.flightIndex == 1">
                                    <div class="container mt-4 borderSelectedCard" >
                                        <div class="row" >
                                            <!--FLIGHT INFORMATION-->
                                            <div class="col-12 col-md-9 col-lg-9 pt-4">
                                                <div class="container">
                                                    <!--FIRST FLIGHT (IN ROUND TRIP IT WILL BE ANOTHER ROW OF THAT)-->
                                                    <div class="row" >
                                                        <!--ARILINE DETAILS-->
                                                        <div class="col-5 col-md-3 col-lg-3">
                                                            <div class="row">
                                                                <!-- CheckBOx-->
                            
                                                                <!-- ARILINE IMAGE -->
                                                                <img class="col-4 col-lg-5 airlineLogo"
                                                                    src="{{item.allJourney.flights[0].flightDTO[0].flightAirline.airlineLogo}}"
                                                                    alt="airline logo" title="Image Title">
                                                                <!-- ARILINE NAME -->
                                                                <span
                                                                    class="col-8 col-lg-7 airlineName">{{item.allJourney.flights[0].flightDTO[0].flightAirline.airlineName}}</span>
                                                            </div>
                                                        </div>
                                                        <!--FLIGHT DETAILS-->
                                                        <div class="col-7 col-md-7 col-lg-7 p-0 bodyCard_wrapper">
                                                            <div class="row">
                                                                <!--DEPARTURE TIME-->
                                                                <div class="col-4 col-md-4 col-lg-4 departSec">
                                                                    <div class="row">
                                                                        <div class="col-12 col-lg-12 time">
                                                                            {{item.allJourney.flights[0].flightDTO[0].departureDate |
                                                                            date:'shortTime'}}
                                                                        </div>
                            
                                                                        <div class="col-12 col-lg-12 airport">
                                                                            ({{item.allJourney.flights[0].flightDTO[0].departureTerminalAirport.airportCode}})
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!--SEGMENT-->
                                                                <div class="col-4 col-md-4 col-lg-4 divSegment">
                                                                    <div class="duration durationMob">( {{item.allJourney.flights[1].elapsedTime | hourminute}} )
                                                                    </div>
                                                                    <div class="segment">
                                                                        <img class="w-100"  src="../../../assets/img/albaraq/Plane.png">
                                                                    </div>
                                                                    <div class="stops">
                                                                        {{item.allJourney.flights[0].stopsNum}}
                                                                        {{"SearchResult.Stops"|translate}}
                                                                    </div>
                                                                </div>
                                                                <!--LANDING TIME-->
                                                                <div class="col-4 col-md-4 col-lg-4 landSec">
                                                                    <div class="row">
                                                                        <div class="col-12 col-lg-12 time">
                                                                            {{item.allJourney.flights[0].flightDTO[item.allJourney.flights[0].flightDTO.length
                                                                            -1].arrivalDate | date:'shortTime'}}
                                                                        </div>
                                                                        <div class="col-12 col-lg-12 airport">
                                                                            ({{item.allJourney.flights[0].flightDTO[item.allJourney.flights[0].flightDTO.length
                                                                            -1].arrivalTerminalAirport.airportCode}})
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Total Flight Duration -->
                                                        <div class="col-12 col-md-2 col-lg-2 p-0 bodyCard_wrapper durationWeb">
                                                            <div class="duration">( {{item.allJourney.flights[0].elapsedTime | hourminute}} )
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--FLIGHT PRICE & BOOKING-->
                                            <div class="col-12 col-md-3 col-lg-3 bookWeb">
                                                <div class="container">
                                                    <div class="row">
                                                        <div class="col-12 thePrice text-center">
                                                           <span  class="code"  *ngIf=" item.itinTotalFare.amount >= select.selectedFlightItinerary.itinTotalFare.amount "> + </span>
                                                           <span  class="code"  *ngIf=" item.itinTotalFare.amount < select.selectedFlightItinerary.itinTotalFare.amount "> - </span>
                                                            <span class="code">  {{item['itinTotalFare']['currencyCode']|exchange:'code'}} </span>
                                                            <span class="price" *ngIf="select.selectedFlightItinerary.itinTotalFare.amount == item.itinTotalFare.amount"> 0</span>
                                                            <span class="price" *ngIf="select.selectedFlightItinerary.itinTotalFare.amount > item.itinTotalFare.amount">{{( select.selectedFlightItinerary.itinTotalFare.amount - item.itinTotalFare.amount) |exchange:'value'}}</span>
   
                                                            <span class="price" *ngIf="select.selectedFlightItinerary.itinTotalFare.amount < item.itinTotalFare.amount">{{(item.itinTotalFare.amount - select.selectedFlightItinerary.itinTotalFare.amount) |exchange:'value'}}</span>
                                                        </div>
                                                        <div class="col-12 priceText text-center">
                                                           <div class="priceText">{{"SearchResult.Price"|translate}}</div>
                                                       </div>
                                                        <div class="col-12 col-md-12 col-lg-12 BOOK-Btn  text-center" *ngIf="!checkout">
                                                            <button mat-raised-button class="bookBtn"
                                                                (click)="flight.toCheckout(this.serachId,item.sequenceNum,item.pKey)">{{"SearchResult.book"|translate}}
                                                                <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 bookMob">
                                                <div class=" thePrice "  (click)="flight.toCheckout(this.serachId,item.sequenceNum,item.pKey)">
                                                    <span  class="code"  *ngIf=" item.itinTotalFare.amount >= select.selectedFlightItinerary.itinTotalFare.amount "> + </span>
                                                           <span  class="code"  *ngIf=" item.itinTotalFare.amount < select.selectedFlightItinerary.itinTotalFare.amount "> - </span>
                                                            <span class="code">  {{item['itinTotalFare']['currencyCode']|exchange:'code'}} </span>
                                                            <span class="price" *ngIf="select.selectedFlightItinerary.itinTotalFare.amount == item.itinTotalFare.amount"> 0</span>
                                                            <span class="price" *ngIf="select.selectedFlightItinerary.itinTotalFare.amount > item.itinTotalFare.amount">{{(select.selectedFlightItinerary.itinTotalFare.amount - item.itinTotalFare.amount ) |exchange:'value'}}</span>
   
                                                            <span class="price" *ngIf="select.selectedFlightItinerary.itinTotalFare.amount < item.itinTotalFare.amount">{{(item.itinTotalFare.amount - select.selectedFlightItinerary.itinTotalFare.amount) |exchange:'value'}}</span>
                                                    <fa-icon [icon]="faChevronDown"  (click)="flight.toCheckout(this.serachId,item.sequenceNum,item.pKey)"></fa-icon>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                               <div class="footerCard">
                                                   <div class="container">
                                                       <div class="viewDetails d-flex">
                                                           <div class="col-12 col-md-7 col-lg-7 flightDetails" (click)="toggleDetails()">
                                                               <span matRipple matRippleRadius="30">
                                                                   {{ "SearchResult.ViewDetails" | translate }}</span>
                                                               <fa-icon class="arrowIcon" [icon]="faChevronDown"></fa-icon>
                                                           </div>
                                                         
                                                       </div>
                                                   </div>
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div *ngIf="showDetails">
   
   
   
   
                                       <div class="detailsCard col-12 ">
                                           <!-- DEPARTURE -->
                                           <!-- Flight Date -->
                                           <div class="row mb-3">
                                               <span class="header-text"> {{"SearchResult.departure" | translate}}</span>
                                               <span class="subheader-text">({{ select.selectedFlightItinerary.allJourney.flights[0].flightDTO[0].departureDate | date:'EE, d MMM y' }})</span>
                                           </div>
                                           <!-- AirLine -->
                                          <!-- AirLine -->
           <div class="row mb-4">
               <div class="col-0 col-md-0 col-lg-1">
   
               </div>
               <div class="col-3 col-lg-1 p-0">
                   <img  class="w-100" src="{{
                       select.selectedFlightItinerary.allJourney.flights[0].flightDTO[0]
                       .flightAirline.airlineLogo
                   }}" />
               </div>
   
               <div class="col-9 d-flex align-items-center">
                   <span class="airline-text"> {{
                       select.selectedFlightItinerary.allJourney.flights[0].flightDTO[0]
                       .flightAirline.airlineName
                       }}</span>
               </div>
           </div>
   
           <div class="row">
               <div class="col-3 col-lg-1"></div>
   
               <div class="col-9 col-md-5 d-flex mb-4 mb-md-0">
   
                   <div class="stops-img">
                       <img *ngIf="select.selectedFlightItinerary.allJourney.flights[0].stopsNum == 0"
                           src="../../../assets/img/albaraq/icons/details-stops-direct.svg" alt="" />
   
                       <img *ngIf="select.selectedFlightItinerary.allJourney.flights[0].stopsNum > 0"
                           src="../../../assets/img/albaraq/icons/details-stops-stop.svg" alt="" />
                   </div>
   
                   <div class="total-time">
                       <span>
                           {{
                               select.selectedFlightItinerary.allJourney.flights[0]
                           .elapsedTime | hourminute
                           }}
                       </span>
                   </div>
   
                   <div class="dep-data d-flex">
                       <div class="dep-time m-e-10">
                           <span class="subheader-text">
                               {{
                                   select.selectedFlightItinerary.allJourney.flights[0]
                               .flightDTO[0].departureDate
                               | date: "shortTime"
                               }}</span>
                       </div>
   
   
                       <div class="dep-airport">
                           <span class="subheader-text">
                               {{
                                   select.selectedFlightItinerary.allJourney.flights[0]
                               .flightDTO[0]
                               .departureTerminalAirport
                               .cityName
                               }}
                           </span>
                           <span class="subheader-text">
                               ({{
                                   select.selectedFlightItinerary.allJourney.flights[0]
                               .flightDTO[0]
                               .departureTerminalAirport
                               .airportCode
                               }})
                           </span>
                       </div>
                   </div>
   
                   <div class="arrival-data d-flex">
                       <div class="dep-time m-e-10">
                           <span class="subheader-text">
                               {{
                                   select.selectedFlightItinerary.allJourney.flights[0]
                               .flightDTO[ select.selectedFlightItinerary.allJourney.flights[0].flightDTO.length
                               -1].arrivalDate
                               | date: "shortTime"
                               }}</span>
                       </div>
   
   
                       <div class="dep-airport">
                           <span class="subheader-text">
                               {{
                                   select.selectedFlightItinerary.allJourney.flights[0]
                               .flightDTO[ select.selectedFlightItinerary.allJourney.flights[0].flightDTO.length
                               -1]
                               .arrivalTerminalAirport
                               .cityName
                               }}
                           </span>
                           <span class="subheader-text">
                               ({{
                                   select.selectedFlightItinerary.allJourney.flights[0]
                               .flightDTO[ select.selectedFlightItinerary.allJourney.flights[0].flightDTO.length
                               -1]
                               .arrivalTerminalAirport
                               .airportCode
                               }})
                           </span>
                       </div>
                   </div>
   
   
   
   
   
               </div>
   
               <div class="col-1 d-md-none"> </div>
               <div class="col-11 col-md-6 d-flex align-items-center">
                   <div class="stops-details " *ngIf=" select.selectedFlightItinerary.allJourney.flights[0].stopsNum > 0">
                       <span class="subheader-text">{{ select.selectedFlightItinerary.allJourney.flights[0].stopsNum}}
                           {{"SearchResult.stop" | translate}}
                           :</span>
                       <ng-container
                           *ngFor="let e of [].constructor( select.selectedFlightItinerary.allJourney.flights[0].stopsNum); let i = index">
                           <span class="subheader-text">
                               {{ select.selectedFlightItinerary.allJourney.flights[0].flightDTO[i].transitTime | durationToHourMin}} 
                               {{"SearchResult.layover" | translate}}
                               {{ select.selectedFlightItinerary.allJourney.flights[0].flightDTO[i].arrivalTerminalAirport.cityName}}
                               ({{ select.selectedFlightItinerary.allJourney.flights[0].flightDTO[i].arrivalTerminalAirport.airportCode}})
                               
                           </span>
                           <br *ngIf="i+1 != select.selectedFlightItinerary.allJourney.flights[0].stopsNum">
   
                       </ng-container>
   
                   </div>
   
               </div>
   
   
           </div>
                                           <!-- END DEPARTURE -->
                                           <!-- RETURN -->
                                             <!-- RETURN -->
           <div class="row mb-3 mt-5">
               <span class="header-text"> {{"SearchResult.return" | translate}}</span>
               <span class="subheader-text">({{ select.selectedFlightItinerary.allJourney.flights[1].flightDTO[0].departureDate | date:'EE, d MMM y' }})</span>
           </div>
           <!-- AirLine -->
           <div class="row mb-4">
               <div class="col-1">
   
               </div>
               <div class="col-3 col-lg-1 p-0">
                   <img class="w-100" src="{{
                       select.selectedFlightItinerary.allJourney.flights[1].flightDTO[0]
                       .flightAirline.airlineLogo
                   }}" />
               </div>
   
               <div class="col-9  d-flex align-items-center">
                   <span class="airline-text"> {{
                       select.selectedFlightItinerary.allJourney.flights[1].flightDTO[0]
                       .flightAirline.airlineName
                       }}</span>
               </div>
           </div>
   
           <!-- Flight info -->
           <div class="row">
               <div class="col-3 col-lg-1"></div>
   
               <div class="col-9  col-md-5 d-flex mb-4 mb-md-0">
   
                   <div class="stops-img">
                       <img *ngIf="select.selectedFlightItinerary.allJourney.flights[1].stopsNum == 0"
                           src="../../../assets/img/albaraq/icons/details-stops-direct.svg" alt="" />
   
                       <img *ngIf="select.selectedFlightItinerary.allJourney.flights[1].stopsNum > 0"
                           src="../../../assets/img/albaraq/icons/details-stops-stop.svg" alt="" />
                   </div>
   
                   <div class="total-time">
                       <span>
                           {{
                               select.selectedFlightItinerary.allJourney.flights[1]
                           .elapsedTime | hourminute
                           }}
                       </span>
                   </div>
            
   
                   <div class="dep-data d-flex">
                       <div class="dep-time m-e-10">
                           <span class="subheader-text">
                               {{
                                   select.selectedFlightItinerary.allJourney.flights[1]
                               .flightDTO[0].departureDate
                               | date: "shortTime"
                               }}</span>
                       </div>
   
   
                       <div class="dep-airport">
                           <span class="subheader-text">
                               {{
                                   select.selectedFlightItinerary.allJourney.flights[1]
                               .flightDTO[0]
                               .departureTerminalAirport
                               .cityName
                               }}
                           </span>
                           <span class="subheader-text">
                               ({{
                                   select.selectedFlightItinerary.allJourney.flights[1]
                               .flightDTO[0]
                               .departureTerminalAirport
                               .airportCode
                               }})
                           </span>
                       </div>
                   </div>
   
           
                   <div class="arrival-data d-flex">
                       <div class="dep-time m-e-10">
                           <span class="subheader-text">
                               {{
                                   select.selectedFlightItinerary.allJourney.flights[1]
                               .flightDTO[select.selectedFlightItinerary.allJourney.flights[1].flightDTO.length
                               -1].arrivalDate
                               | date: "shortTime"
                               }}</span>
                       </div>
   
   
                       <div class="dep-airport">
                           <span class="subheader-text">
                               {{
                                   select.selectedFlightItinerary.allJourney.flights[1]
                               .flightDTO[select.selectedFlightItinerary.allJourney.flights[1].flightDTO.length
                               -1]
                               .arrivalTerminalAirport
                               .cityName
                               }}
                           </span>
                           <span class="subheader-text">
                               ({{
                                   select.selectedFlightItinerary.allJourney.flights[1]
                               .flightDTO[select.selectedFlightItinerary.allJourney.flights[1].flightDTO.length
                               -1]
                               .arrivalTerminalAirport
                               .airportCode
                               }})
                           </span>
                       </div>
                   </div>
   
   
   
   
   
               </div>
   
               <div class="col-1 d-md-none"> </div>
               <div class="col-11 col-md-6 d-flex align-items-center">
                   <div class="stops-details " *ngIf="select.selectedFlightItinerary.allJourney.flights[1].stopsNum > 0">
                       <span class="subheader-text">{{select.selectedFlightItinerary.allJourney.flights[1].stopsNum}}
                           {{"SearchResult.stop" | translate}}
                           :</span>
                       <ng-container
                           *ngFor="let e of [].constructor(select.selectedFlightItinerary.allJourney.flights[1].stopsNum); let i = index">
                           <span class="subheader-text">
                               {{select.selectedFlightItinerary.allJourney.flights[1].flightDTO[i].transitTime | durationToHourMin}} 
                               {{"SearchResult.layover" | translate}}
                               {{select.selectedFlightItinerary.allJourney.flights[1].flightDTO[i].arrivalTerminalAirport.cityName}}
                               ({{select.selectedFlightItinerary.allJourney.flights[1].flightDTO[i].arrivalTerminalAirport.airportCode}})
                               
                           </span>
                           <br *ngIf="i+1 !=select.selectedFlightItinerary.allJourney.flights[1].stopsNum">
   
                       </ng-container>
   
                   </div>
   
               </div>
   
   
           </div>
                                           <!-- AirLine -->
                                          
                                   
                                           <!-- Flight info -->
                                           
                                           <!-- END RETURN -->
                                   
                                   
                                       </div>
   
   
   
   
   
   
   
   
   
   
   
                                      
                                    
                                    </div>
                                </div>
                                </div>
                            
                            
                            
                            
                            
                            
                             
                            <!-- <p>{{item.cabinClass}}</p> -->
                                </div>
                         </div>
                            
                         
                         
                         <!-- Details Card -->

                         
                         
                         
                         
                         
                         
                         
                         
                            
                         
                         </div>
        </div>
    </div>
</div>