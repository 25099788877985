<mat-dialog-content class="signUp">
    <div>
        <div class="closeHeader">
            <div class="row">
                <div class="col-5 logo">
                    <img src="../../../assets/img/albaraq/TAZKRTI-dark 2.svg" alt="logo">
                </div>
                <div class="col-7 closeIcon">
                    <span class="" (click)="closeDialog()">X</span>
                </div>
            </div>
        </div>
        <div class="" [ngClass]="{ar: translate.currentLang =='ar'}">

            <div class="row m-0">
                <div class="col-12 col-md-4 p-0 img-section d-none d-md-flex">
                    <img src="assets/img/albaraq/TAZKRTI 2.svg" alt="">
                    <p class="img-text"> {{translate.currentLang=='en'?
                        'Explore the world deeper with us':
                        'اكتشف العالم أعمق معنا'}}
                    </p>

                </div>

                <div class="col-12 col-md-8 p-0">
                    <div class="closeIconWeb">
                        <span class="" (click)="closeDialog()">X</span>
                    </div>
                    <form [formGroup]="signupform" class="form-container" (ngSubmit)="onSubmit()">
                        <div class="row">

                            <div class="col-12 mb-3">
                                <h1 class="header-text">{{translate.currentLang=='en'?'Sign up':'إنشاء حساب'}}</h1>
                            </div>




                            <div class="col-12 col-lg-6 col-md-6">
                                <mat-form-field color="accent" appearance="fill" class="authForm">
                                    <input matInput
                                        [placeholder]="translate.currentLang == 'en'?'First Name' : 'الاسم الاول'"
                                        required formControlName="FirstName" type="text" />
                                </mat-form-field>

                            </div>

                            <div class="col-12 col-lg-6 col-md-6">
                                <mat-form-field color="accent" appearance="fill" class="authForm">
                                    <input matInput
                                        [placeholder]="translate.currentLang=='en'?'Last Name':'الاسم الاخير'" required
                                        formControlName="LastName" type="text" />
                                </mat-form-field>
                            </div>

                            <div class="col-12">
                                <mat-form-field color="accent" appearance="fill" class="authForm">
                                    <input matInput
                                        [placeholder]="translate.currentLang == 'en'?'Phone Number':'رقم الهاتف'"
                                        required formControlName="PhoneNumber" type="number" />
                                </mat-form-field>
                            </div>


                            <div class="col-12">
                                <mat-form-field color="accent" appearance="fill" class="authForm">

                                    <input matInput
                                        [placeholder]="translate.currentLang == 'en'?'Email Address':'البريد الالكتروني'"
                                        required formControlName='Email' type="email"
                                        pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                                </mat-form-field>
                            </div>
                            <div class="col-12">
                                <mat-form-field color="accent" appearance="fill" class="authForm">

                                    <input matInput [placeholder]="translate.currentLang=='en'?'Password':'كلمة السر'"
                                        required formControlName='Password' [type]="showPassword?'text':'password'">
                                    <img (click)="toggleShowPassword()" class="forget-password-icon"
                                        src="assets/img/albaraq/icons/forget-password.svg" alt="">
                                </mat-form-field>
                            </div>

                            <div class="col-12">
                                <mat-form-field color="accent" appearance="fill" class="authForm">
                                    <input matInput
                                        [placeholder]="translate.currentLang == 'en'?'Confirm Password':'اعد كتابة كلمة السر'"
                                        required formControlName="ConfirmPassword"
                                        [type]="showConfirmPassword?'text':'password'" />
                                    <img (click)="toggleShowConfirmPassword()" class="forget-password-icon"
                                        src="assets/img/albaraq/icons/forget-password.svg" alt="">

                                </mat-form-field>
                            </div>



                            <div class="col-12 mt-5" (click)="onSubmit()">
                                <button *ngIf="!userLoader" class="submitBtn" mat-button color="primary">
                                    {{translate.currentLang=='en'?'Sign up':'تسجيل'}}</button>
                                <mat-spinner *ngIf="userLoader"></mat-spinner>
                            </div>

                            <div class="col-12 mt-3" style="text-align:start ;">
                                <span class="noAccount">{{translate.currentLang=='en'?'Already have an account?':'مستخدم
                                    حالي؟'}}</span>
                                <span class="crateAccount" (click)="toLogin()">
                                    {{translate.currentLang=='en'?'Sign in':'سجل دخول'}}
                                </span>
                            </div>
                            <!-- <div class="col-12 breakLine">
                                <div class="d-flex">
                                    <div class="line"></div>
                                    <span class="or-text">{{translate.currentLang=='en'?'OR':'أو'}}</span>
                                    <div class="line"></div>
                                </div>
                            </div> -->

                            <!-- <div (click)="submitFbLogin()" class="col-12 col-xl-6 mb-3">
                                <button class="social-login" mat-button>
                                    <img class="social-login-icon" src="assets/img/albaraq/icons/facebook.svg" alt="">
                                    <span class="social-login-text">{{translate.currentLang=='en'?'Sign in with
                                        Facebook':'تسجيل بواسطة فيسبوك'}}</span>
                                </button>
                            </div> -->

                            <!-- <div class="col-12 col-xl-6">
                                <button class="social-login" mat-button>
                                    <img class="social-login-icon" src="assets/img/albaraq/icons/google.svg" alt="">
                                    <span class="social-login-text">{{translate.currentLang=='en'?'Sign in with
                                        Google':'تسجيل بواسطة جوجل'}}</span>
                                </button>
                            </div> -->


                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>

</mat-dialog-content>