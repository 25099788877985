<div class="container">
    <div class="row justify-content-center">
        <h3 class="title mt-3 mb-3">{{'user.myOrders'|translate}}</h3>
    </div>
    <div class="row justify-content-center">
        <div class="col-12">
            <app-upcoming-ahistory [email]="email" *ngIf="isFlight"></app-upcoming-ahistory>
        </div>
        <!-- <div class="col-12 col-md-5">
            <div class="big-nav-button" (click)="switch('hotel')" [ngClass]="{'active': !isFlight}">
                <img src="../../../../assets/img/hotelIcon.svg" alt="hotels">
            </div>
        </div> -->
        <!-- <div class="col-12 col-md-5">
            <div class="big-nav-button" (click)="switch('flight')" [ngClass]="{'active': isFlight}">
                <img src="../../../../assets/img/FlightIcon.svg" alt="flights">
            </div>
        </div> -->
    </div>
    <!-- <div class="row justify-content-center mt-3">
        <app-upcoming-ahistory [email]="email" *ngIf="isFlight"></app-upcoming-ahistory>
        <app-upcoming-ahistory-h [email]="email" *ngIf="!isFlight"></app-upcoming-ahistory-h>
    </div> -->
</div>