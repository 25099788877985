import { MatSnackBarModule } from '@angular/material/snack-bar';
import { NgModule, Directive,OnInit, EventEmitter, Output, OnDestroy, Input,ElementRef } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { SearchflightComponent } from './searchflight/searchflight.component';
import {MatRadioModule} from '@angular/material/radio';
// import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import{TranslateHttpLoader} from '@ngx-translate/http-loader'
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import{FilterCityPipe} from './pipes/filter-city.pipe';
import { LimitToPipe } from './pipes/limit-to.pipe';
import { HighlighterPipe } from './pipes/highlighter.pipe';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import {BidiModule} from '@angular/cdk/bidi';
import { MatFormFieldModule } from '@angular/material/form-field';
import { OfflineFilterPipe } from './pipes/offline-filter.pipe';
import { CouncodePipe } from './pipes/councode.pipe';
import { DurationToHourMinPipe } from './pipes/duration-to-hour-min.pipe';
import { HourMinutePipe } from './pipes/hour-minute.pipe';
import { ExchangePipe } from './pipes/exchange.pipe';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactComponent } from './contact/contact.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks } from 'ng-lazyload-image';
import {NgbCollapseModule, NgbDatepicker, NgbDatepickerI18n, NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';
import {MatMenuModule} from '@angular/material/menu';
import {MatDialogModule} from '@angular/material/dialog';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
// import { HotelecitesPipe } from './pipes/hotelecites.pipe';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCardModule} from '@angular/material/card';
import { RoundTripComponent } from '../app/flight/round-trip/round-trip.component';
import { FlightComponent } from './flight/flight.component';
import { SegComponent } from './flight/seg/seg.component';
import { OneWayComponent } from './flight/one-way/one-way.component';
import { MultiTripComponent } from './flight/multi-trip/multi-trip.component';
import {MatListModule} from '@angular/material/list';
import { MatTooltipModule} from '@angular/material/tooltip';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { VirtualScrollModule } from 'ngx-virtual-scroll-plus';
import { RouterModule } from '@angular/router';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatTabsModule} from '@angular/material/tabs';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { UserLoginComponent } from './users/user-login/user-login.component';
import { UserSignupComponent } from './users/user-signup/user-signup.component';
import { ForgetPasswordComponent } from './users/forget-password/forget-password.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NavlistComponent } from './navlist/navlist.component';
import { ChangePasswordComponent } from './users/change-password/change-password.component';
import { DatepickerI18nService } from './services/datepicker-i18n.service';
import { ConfirmPaymentComponent } from './checkout/confirm-payment/confirm-payment.component';
import { HotelsearchComponent } from './hotelsearch/hotelsearch.component';
import { HotelecitesPipe } from './pipes/hotelecites.pipe';



export function rootloaderFactory(http:HttpClient){
    return new TranslateHttpLoader  (http,'./assets/i18n/', '.json')
}
@NgModule({
  imports: [ FontAwesomeModule,HttpClientModule,CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatInputModule,
    MatButtonModule,
    MatRippleModule,
    BidiModule,
    NgbModule,
    MatRadioModule,
    MatExpansionModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatNativeDateModule,
    NgxSliderModule,
    MatSnackBarModule,
    TranslateModule, 
    MatFormFieldModule,
    FormsModule,
    MatListModule,
    MatStepperModule,
    MatCardModule,
    MatDatepickerModule,
    MatPaginatorModule,
    NgbCollapseModule,
    MatMenuModule,
    VirtualScrollerModule,
    VirtualScrollModule,
    RouterModule,
    MatSidenavModule,
    MatProgressBarModule,
    MatTabsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    
  
  ],
  declarations: [
    HeaderComponent,
    NavlistComponent,
    FooterComponent,
    FilterCityPipe,
    LimitToPipe,
    SearchflightComponent,
    HotelsearchComponent,
    CouncodePipe,
    DurationToHourMinPipe,
    HotelecitesPipe,
    HighlighterPipe,
    OfflineFilterPipe,
    HourMinutePipe,
    ExchangePipe,
    SegComponent,
    RoundTripComponent,
    OneWayComponent,
    MultiTripComponent,
    UserLoginComponent,
    UserSignupComponent,
    ConfirmPaymentComponent,
    ForgetPasswordComponent,
    ChangePasswordComponent
    
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    NavlistComponent,
    ConfirmPaymentComponent,
    UserLoginComponent,
    UserSignupComponent,
    ForgetPasswordComponent,
    ChangePasswordComponent,
    CommonModule,
    CouncodePipe,
    DurationToHourMinPipe,
    MatButtonModule,
    SearchflightComponent,
    HotelsearchComponent,
    MatMenuModule,
    TranslateModule,
    MatCardModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatDialogModule,
    NgxIntlTelInputModule,
    MatListModule,
    FilterCityPipe,
    NgxSliderModule,
    LimitToPipe,
    FormsModule,
    MatSnackBarModule,
    MatExpansionModule,
    MatNativeDateModule,
    HighlighterPipe,
    MatInputModule,
    OfflineFilterPipe,
    HourMinutePipe,
    MatFormFieldModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatSelectModule,
    MatPaginatorModule,
    LazyLoadImageModule,
    ExchangePipe,
    HotelecitesPipe,
    SegComponent,
    NgbCollapseModule,
    RoundTripComponent,
    OneWayComponent,
    MultiTripComponent,
    VirtualScrollerModule,
    VirtualScrollModule,
    RouterModule,
    MatProgressBarModule,
    MatSidenavModule,
    MatTabsModule,
    NgbModule,
    MatCheckboxModule,
    MatProgressSpinnerModule
  ],providers:[
  ,
    HourMinutePipe,ExchangePipe,{ provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks },{provide: MatPaginatorIntl}]
})

export class SharedModule { }