<div class="headerContainer" [ngClass]="{ar: translate.currentLang =='ar' , bgHeader:bg}">
  <div class="container">
    <div class="row">
      <div class="col-2 d-flex justify-content-start align-items-center logo">

        <img *ngIf="translate.currentLang == 'en'" (click)="goToHome()" class="logoImg w-100 mt-4" src="../../assets/img/albaraq/TazkrtiB.svg" width="75rem" height="75rem">
        <img *ngIf="translate.currentLang == 'ar'" (click)="goToHome()" class="logoImg w-100 mt-4" style="height:60px" src=" ../../assets/img/albaraq/TazkrtiArabicLogo.svg" width="75rem" height="75rem">

      </div>
 
      <div class="col-10" style="margin-top:10px;">
        <div class="row d-flex justify-content-end align-items-center">

          <div class="phone" (click)="whatsApp()">
            <img [src]="bg?'assets/img/albaraq/icons/chat-dark.svg':'assets/img/albaraq/icons/chat.svg'">
            <a target="_blank" href="https://api.whatsapp.com/send?phone=96522280802">
              <span class="text" style="cursor: pointer;"> {{ "header.chat" | translate }}</span>
            </a>
          </div>

          <span class="verticalLine"></span>

          <div class="phone m-e-2" (click)="call()" >
            <img [src]="bg?'assets/img/albaraq/icons/phone-dark.svg':'assets/img/albaraq/icons/phone.svg'">
            <a href="tel:96522280802">
              <span class="text" style="cursor: pointer;">+96522280802</span>
            </a>
          </div>




          <div class="currency" [matMenuTriggerFor]="menu">
            <ng-container *ngIf="selectedCurrency$ |async as curruncey">
              <img class="currencyImg" aria-hidden [src]="curruncey.Image_Url"  loading="lazy"/>
            </ng-container>
          </div>

          <div class="lang" (click)="langSwitch()">
            <a>
              {{ "header.lang" | translate }}
            </a>

            <img [src]="bg?'assets/img/albaraq/icons/arrow-down-dark.svg':'assets/img/albaraq/icons/arrow-down.svg'">
          </div>

          <div class="signin" *ngIf="!appUser">
            <span class="text1" (click)="goToLogin()" *ngIf="!authinticated">{{'header.login' | translate}}</span>
            <span class="text1"  (click)="goToProfile()" *ngIf="authinticated" >{{'header.profile' | translate}}</span>
          </div>

          <button  *ngIf="appUser" class="signout" mat-button [matMenuTriggerFor]="usermenu">
            {{appUser.applicationUser.FirstName}}
            <img [src]="bg?'assets/img/albaraq/icons/arrow-down-dark.svg':'assets/img/albaraq/icons/arrow-down.svg'">
          </button>
          <mat-menu  #usermenu="matMenu">
            <button (click)="logout()" mat-menu-item>{{'header.logout' | translate}}</button>
          </mat-menu>



        </div>

      </div>
    </div>
  </div>
</div>





<mat-drawer-container class="header-container" hasBackdrop="true" [ngClass]="{ar: translate.currentLang =='ar'}">
  <mat-drawer #drawer mode="over" style="width: 70%;">
    
    
    <div class="logoInMobileHeader">
      <div class="logo">
        
        <img *ngIf="translate.currentLang == 'en'" src="../../assets/img/albaraq/TazkrtiB.svg"  width="25" height="25">
        <img *ngIf="translate.currentLang == 'ar'" src=" ../../assets/img/albaraq/TazkrtiArabicLogo.svg" width="25" height="25">

      </div>
      <div>
        <img width="35" height="20" (click)="drawer.toggle()"  src="assets/img/albaraq/icons/x.png">
      </div>
    </div>

    <div class="menuLinks mt-4">
      <div class="phoneMobile" (click)="goToLogin()">
        <img src="assets/img/albaraq/icons/mobileLogin.svg" height="25" width="25">
        <a>
          <span style="cursor: pointer;"> {{ "header.mobileSignIn" | translate }}</span>
        </a>
      </div>
      
      <div class="phoneMobile">
        <img src="assets/img/albaraq/icons/langSwtch.svg" height="25" width="25">
        <a (click)="langSwitch()">
          {{ "header.mobLang" | translate }}
        </a>
      </div>

      <div class="phoneMobile">
        <img src="assets/img/albaraq/icons/mobileWhatsApp.svg" height="25" width="25">
        <a target="_blank" href="https://api.whatsapp.com/send?phone=96522280802"> 
          <span style="cursor: pointer;"> {{ "header.chat" | translate }}</span>
        </a>
      </div>

      <div class="phoneMobile">
        <img src="assets/img/albaraq/icons/phoneWhatsApp.svg" height="25" width="25">
        <a href="tel:96522280802">
          <span style="cursor: pointer;">+96522280802</span>
        </a>
      </div>

      <div class="mobileBtns">

        <button (click)="logout()"  *ngIf="appUser" mat-stroked-button class="mobileBtn">
          <fa-icon class="btnMobile" [icon]="faLock" aria-hidden="true"></fa-icon>
          {{"header.logout" | translate}}
        </button>
      
  
        <button mat-stroked-button *ngIf="selectedCurrency$ |async as curruncey" [matMenuTriggerFor]="menu"
          class="mobileBtn">
          <fa-icon class="btnMobile" [icon]="faAngleDown" aria-hidden="true"></fa-icon>
          {{ curruncey["Currency_Code"] }}
        </button>
      </div>




    </div>


  </mat-drawer>
  <mat-drawer-content style="overflow: hidden !important;">
    <div class="mobileHeader">
      <div class="container-fluid">
        <div class="row">
          <div class="col-10 mobileLogo" style="text-align: start; ">
            <img *ngIf="getScreenWidth > 769" src="../../assets/img/albaraq/TAZKRTI-dark 2.svg" width="25" height="25">
            <img *ngIf="translate.currentLang == 'en'" (click)="goToHome()" class="logoImg w-100 mt-2" src="../../assets/img/albaraq/TazkrtiB.svg" width="37" height="30">
            <img *ngIf="translate.currentLang == 'ar' && getScreenWidth <= 769" (click)="goToHome()" class="logoImg w-100 mt-2"  src=" ../../assets/img/albaraq/TazkrtiArabicLogo.svg" width="45" height="40">
          </div>

          <div class="col-2" style="text-align: end;">
            <button class="menuBtn" mat-raised-button (click)="drawer.toggle()">
              <img style="width: 35%;" src="assets/img/albaraq/menu.svg" width="35" height="20">
            </button>
          </div>
        </div>
      </div>
    </div>

  </mat-drawer-content>
</mat-drawer-container>
<mat-menu #menu="matMenu" class="currencyMenu">
  <ng-container *ngFor="let curruncy of Currencies$ |async">
    <button mat-menu-item (click)="changeCurrency(curruncy)"> <img class="example-option-img" aria-hidden
        [src]="curruncy.Image_Url" height="25" width="25" />
      <span class="codeInsinde"> {{ curruncy["Currency_Code"] }} </span></button>
  </ng-container>

</mat-menu>