import { Injectable } from "@angular/core";

@Injectable({
  providedIn:"root"
})
export class SearchFlightModule { 
  
  constructor (
    public lan:string,
    public Currency:string,
    public pointOfReservation:string,
    public flightType:string,
    public flightsInfo:string,
    public passengers:string,
    public Cclass:string,
    public serachId:any,
    public showDirect :boolean,
    public preferredAirLine :string,
    ){} 

}
