<div class="footerContainer" [ngClass]="{ar: translate.currentLang =='ar'}">
    <div class="container">
        <div class="topFooter">
            <div class="row">
                <div class=" col-12 col-md-6 col-lg-3"> 
                    <div class="logo">
                        <img src="../../assets/img//albaraq/TAZKRTI 2.svg" width="75" height="85">
                    </div>
                    <p class="Logo__wrapper">{{'footer.text' | translate}}</p>
                    <span class="linkTitle">{{'footer.follow' | translate}}</span>
                    <div class="links d-flex justify-content-start">
                        <a href="https://facebook.com/TazkrtiCom"  target="_blank" class="facebookIcon">
                            <img src="../../assets/img/albaraq/facebook.png" width="25" height="42">
                        </a> 
                        <a href="https://twitter.com/TazkrtiApp" target="_blank" class="twitterIcon">
                            <fa-icon class="icon" [icon]="faTwitter"></fa-icon>
                        </a>
                        <a href="https://instagram.com/tazkrtiapp" target="_blank">
                            <img src="../../assets/img/albaraq/Instgram.png" width="25" height="42">
                        </a>
                        <a href="https://linkedin.com/company/tazkrticom" target="_blank">
                            <img src="../../assets/img/albaraq/linkedIn.png" width="25" height="42">
                        </a>
                    </div>
                </div>
                <div class=" col-12 col-md-6 col-lg-3 contactUs">
                    <h3 [routerLink]="['/Contact']">{{'footer.contactUs' | translate}}</h3>
                    <div class="contactUs__wrapper d-flex">
                        <div class="contact_Link d-flex" (click)="call()">
                            <fa-icon class="icon phone" [icon]="faPhone"></fa-icon>
                            <a target="_blank" href="tel:96522280802">{{"footer.phone" | translate}}</a>
                        </div>
                        <div class="contact_Link d-flex">
                            <fa-icon class="icon" [icon]="faMapMarkerAlt"></fa-icon>
                            <a [routerLink]="['/Contact']">{{"footer.address" | translate}}</a>
                        </div>
                      

                        <div class="contact_Link d-flex">
                            <fa-icon class="icon" [icon]="faEnvelope"></fa-icon>
                            <a href="mailto: Support@Tazkrti.com">{{"footer.email" | translate}}</a>
                        </div>
                        <div class="contact_Link d-flex" (click)="whatsApp()">
                            <fa-icon class="icon" [icon]="faWhatsapp"></fa-icon>
                            <a target="_blank" href="https://api.whatsapp.com/send?phone=96522280802">{{"footer.whatsApp" | translate}}</a>
                        </div>
                    </div>
                </div>
                <div class=" col-12 col-md-6 col-lg-3 support">
                    <h3>{{'footer.support' | translate}}</h3>
                    <div class="support__wrapper d-flex">
                        <a class="support_link" [routerLink]="['/aboutUs']">{{"aboutUs.aboutTxt" | translate}}</a>

                        <a class="support_link" [routerLink]="['/Contact']">{{'footer.contactUs' | translate}}</a>
                       

                        <a class="support_link" [routerLink]="['/privacyPolicy']">{{"footer.privacyPolicy" |
                            translate}}</a>
                        <a class="support_link" [routerLink]="['/termsOfuse']">{{"footer.terms" | translate}}</a>
                    </div>
                </div>
                <div class=" col-12 col-md-6 col-lg-3 payment">
                    <h3>{{'footer.payment' | translate}}</h3>
                    <div class="payment__wrapper">
                        <div class="row">
                            <button class="payment_Links visaBtn btn col-3">
                                <img src="../../assets/footer/Visa.svg" width="25" height="35"/>
                            </button>
                            <button class="payment_Links btn col-3">
                                <img src="../../assets/footer/MasterCard.svg" width="25" height="35"/>
                            </button>
                            <button class="payment_Links btn col-3">
                                <img src="../../assets/footer/knet.png" width="25" height="35"/>
                            </button>
                        </div>
                    </div> 
                </div>
            </div>
        </div>
    </div>
    <div class="bottomFooter">
        <div class="container">
            <span>{{'footer.copyRight' | translate}}</span>
        </div>
    </div>
</div>