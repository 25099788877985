import {
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import {
  faAngleDown,
  faCalendar,
  faCheckCircle,
  faLock,
  faPhone,
  faUser,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { GoogleTagManagerService } from "angular-google-tag-manager";
import { SocialAuthService } from "angularx-social-login";
import { Observable, Subscription } from "rxjs";
import { CurrencyModule } from "../models/currency/currency.module";
import { PointOfsaleModule } from "../models/point-ofsale/point-ofsale.module";
import { AuthUser } from "../models/user/user";
import {
  updateAppCurrency,
  updateAppLang,
} from "../store/app-setting/app-setting.actions";
import {
  loadUtilitysCurrency,
  loadUtilitysPOS,
} from "../store/app-utilities/app-utilities.actions";
import {
  selectAllCurrency,
  selectAppCurrency,
  selectAppLang,
  selectAppPOS,
  selectAppUser,
  selectUserLoader,
  State,
} from "../store/selectors";
import { selectSettingsFeature } from "../store/selectors";
import { ResetUserState } from "../store/user/user.actions";
import { UserLoginComponent } from "../users/user-login/user-login.component";
import { UserSignupComponent } from "../users/user-signup/user-signup.component";
declare var $: any;
@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() bg = false;
  faLock = faLock;
  faUserPlus = faUserPlus;
  faAngleDown = faAngleDown;
  faPhone = faPhone;
  faCalendar = faCalendar;
  faCheckCircle = faCheckCircle;
  faUser = faUser;

  pointofSale: PointOfsaleModule;
  flags: CurrencyModule[] = [];
  toggleHF: boolean = true;
  logedin: boolean = false;
  authinticated:boolean = false;
  public getScreenWidth: any;

  appSetting$ = this.store.select(selectSettingsFeature);
  POS$ = this.store.select(selectAppPOS);
  lang$ = this.store.select(selectAppLang);
  
  appUser$ = this.store.select(selectAppUser);
  appUser:AuthUser;

  lang: string = this.translate.currentLang;

  selectedCurrency$: Observable<CurrencyModule> =
    this.store.select(selectAppCurrency);
  Currencies$: Observable<CurrencyModule[]> =
    this.store.select(selectAllCurrency);

  currencyValue: any;
  private subscription: Subscription = new Subscription();

  constructor(
    public translate: TranslateService,
    private readonly store: Store<State>,
    private router: Router,
    public dialog: MatDialog,
    private readonly authService :SocialAuthService,
    private gtmService: GoogleTagManagerService,
  ) {}

  ngOnInit() {
    this.getScreenWidth = window.innerWidth;
    if(sessionStorage.getItem("cls")){
      this.authinticated = true;
    }
    else{
      this.authinticated = false;
    }
    if(localStorage.getItem('currency')){
      this.store.dispatch(updateAppCurrency({ Currency: JSON.parse(localStorage.getItem('currency'))}));
    }
    this.appUser$.subscribe((res)=>{
      this.appUser = res;
    })
    this.store.dispatch(loadUtilitysCurrency());
    this.store.dispatch(loadUtilitysPOS());
    if (sessionStorage.getItem("type") == "flights") {
      this.toggleHF = false;
    }
    if (localStorage.getItem("country")) {
      this.subscription.add(
        this.POS$.subscribe((result: PointOfsaleModule) => {
          this.pointofSale = result;
        })
      );
    }
    if (
      this.translate.currentLang == "ar" ||
      localStorage.getItem("lang") == "ar"
    ) {
      this.switchtoAr();
    }

    if (sessionStorage.getItem("cls")) {
      this.logedin = true;
    }
    this.lang$.subscribe((v) => {
        if(v){
          this.lang = v;
          this.translate.use(this.lang);
        }
        
      // }
      
    });
  }

  filterCurrencies: Observable<CurrencyModule[]>;

  switchtoEn() {
    this.store.dispatch(updateAppLang({ Lang: "en" }));
  }

  switchtoAr() {
    this.store.dispatch(updateAppLang({ Lang: "ar" }));
  }

  /**
   * changing the current currency in the whole app
   */
  changeCurrency(curr: CurrencyModule) {
    localStorage.setItem('currency' , JSON.stringify(curr));
    this.store.dispatch(updateAppCurrency({ Currency: curr }));
  }

  /**
   * routing to login view
   */
  goToLogin() {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(UserLoginComponent,{ panelClass: 'custom-dialog-container'});
  }

  /**
   * routing to signup view
   */
  goToSignUp() {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(UserSignupComponent);
  }

  /**
   * routing to profile view
   */
  goToProfile() {
    this.router.navigate(["/profile"]);
  }

  /**
   * routing to home view
   */
  goToHome() {
    this.router.navigate(["/home"]);
  }

  /**
   * unsubscripe for all the observables
   */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  /**
   * Switching between english lang and arabic lang for the whole website
   */
  langSwitch() {
    this.lang === "en" ? this.switchtoAr() : this.switchtoEn();
  }

  logout(){
    this.store.dispatch(ResetUserState())
    sessionStorage.clear();
    this.authService.signOut();
    localStorage.removeItem('Google-auth');
    localStorage.removeItem('Facebook-auth');
  }
  @HostListener("window:resize", ["$event"]) onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }
 whatsApp(){
  const whatsAPP = {
    event: 'Whatsapp Button',
    position: 'from headder',
    language: this.translate.currentLang,
    url:this.router.url
  };
  this.gtmService.pushTag(whatsAPP);
 }
 call(){
  const PhoneCAll = {
    event: 'Call Button',
    position: 'from headder',
    language: this.translate.currentLang,
    url:this.router.url
  };
  this.gtmService.pushTag(PhoneCAll);
 }
}
