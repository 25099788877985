import { CurrencyModule } from 'src/app/models/currency/currency.module';
import { Action, createReducer, on } from '@ngrx/store';
import { AirPorts } from '@src/app/interface/flight-search-result';
import * as utilities from '../app-utilities/app-utilities.actions';
import { PointOfsaleModule } from '@src/app/models/point-ofsale/point-ofsale.module';
import { CountriescodeModule } from '@src/app/models/countriescode/countriescode.module';
import { HotelsCitiesModule } from '@src/app/models/hotels-cities/hotels-cities.module';
import { TopCityFlight, tripideas } from '@src/app/interface/Home-content';

export const appUtilitiesFeatureKey = 'appUtilities';

export interface State {
  loading   : boolean,
  error     : any,
  airports  : AirPorts[],
  currency  : CurrencyModule[],
  pos       : PointOfsaleModule,
  countryies: CountriescodeModule[],
  FTcities  : TopCityFlight[][],
  cities    : HotelsCitiesModule[],
  FTIdeas   : tripideas[],
}

export const initialState: State = {
  loading   : false,
  airports  : undefined,
  currency  : undefined,
  pos       : undefined,
  countryies: undefined,
  cities    : undefined,
  FTcities  : undefined,
  error     : undefined,
  FTIdeas:undefined
};


export const reducer = createReducer(
  initialState,
  on(utilities.loadUtilitysAirports , (state)=>({...state , loading:true})),
  on(utilities.loadUtilitysAirportsSuccess , (state , allAirports)=>({...state , loading : false , airports:allAirports.airports})),
  on(utilities.loadUtilitysAirportsFailure , (state)=>({...state , loading: false})),
  on(utilities.loadUtilitysPOS , (state)=>({...state , loading:true})),
  on(utilities.loadUtilitysPOSSuccess , (state , allPOS)=>({...state , loading : false , pos:allPOS.POS})),
  on(utilities.loadUtilitysPOSFailure , (state)=>({...state , loading: false})),
  on(utilities.loadUtilitysCurrency , (state)=>({...state , loading:true})),
  on(utilities.loadUtilitysCurrencySuccess , (state , allCurrency)=>({...state , loading : false , currency:allCurrency.Currency})),
  on(utilities.loadUtilitysCurrencyFailure , (state)=>({...state , loading: false})),
  on(utilities.loadUtilitysCountries, (state)=>({...state , loading:true})),
  on(utilities.loadUtilitysCountriesSuccess , (state , allCountries)=>({...state , loading : false , countryies:allCountries.countries})),
  on(utilities.loadUtilitysCountriesFailure , (state)=>({...state , loading: false})),
  on(utilities.loadUtilitysCities, (state)=>({...state , loading:true})),
  on(utilities.loadUtilitysCitiesSuccess , (state , allcities)=>({...state , loading : false , cities:allcities.cities})),
  on(utilities.loadFlightTopCitiesSuccess , (state , fTC)=>({...state , loading : false ,FTcities:fTC.Tcities})),
  on(utilities.loadFlightTopCitiesFailure , (state,err)=>({...state , loading: false,error:err})),
  on(utilities.loadFlightTripIdeas, (state)=>({...state , loading:true})),
  on(utilities.loadFlightTripIdeasSuccess , (state , fTI)=>({...state , loading : false ,FTIdeas:fTI.ideas})),
  on(utilities.loadFlightTripIdeasFailure , (state,err)=>({...state , loading: false,error:err})),
  on(utilities.loadUtilitysCitiesFailure , (state)=>({...state , loading: false})),
  on(utilities.contactUs , (state)=>({...state , loading: true})),
  on(utilities.contactUsSuccess , (state)=>({...state , loading: true})),
  on(utilities.contactUsFailure , (state , param)=>({...state , loading: true , error:param.err}))
);

export function UtilitiesReducer(
  state: State | undefined,
  action: Action) {
  return reducer(state, action);
}

