<div class="container" [ngClass]="{ar: translate.currentLang =='ar'}">
  <div class="form">
      <h2 class="title">{{'password.change'| translate}}</h2>
      <div class="row container rowStyle">
          <div class="col-12 passInput">
            <fa-icon class="passIcon" [icon]="faUserLock"></fa-icon>
              <input type="password" placeholder="Old Password">
          </div>
          <div class="col-12 passInput">
            <fa-icon class="passIcon" [icon]="faUserLock"></fa-icon>
              <input  type="password" placeholder="New Password">
          </div>
          <div class="col-12 passInput">
            <fa-icon class="passIcon" [icon]="faUserLock"></fa-icon>
              <input  type="password" placeholder="Confirm Password">
          </div>
          <div class="col-12 singInBtn">
              <button class="">{{'password.confirm' | translate}}</button>
          </div>
          <div class="col-12 verCode d-flex">
              <fa-icon class="checkIcon" [icon]="faCheckCircle"></fa-icon>
              <p>{{'password.verCode'| translate}}</p>
          </div>
          <div class="col-12 d-flex ">
              <div class="col-6">
                  <hr>
              </div>
              <div class="orTxt">
                  <h3>{{'password.or'| translate}}</h3>
              </div>
              <div class="col-6">
                  <hr>
              </div>
          </div>
      </div>
      <div class="row container rowStyle mb-5">
          <div class="col-12 col-lg-5 socialBtn" (click)="submitGoogleLogin()">
              <div class="d-flex">
                  <div class="socialBtnImg">
                      <img src="../../../assets//img/albaraq/GoogleIcon.svg">
                  </div>
                  <h3 class="socialBtnTxt">{{'password.google' | translate}}</h3>
              </div>
          </div>
          <div class="col-0 col-lg-2"></div>
          <div class="col-12 col-lg-5 socialBtn" (click)="submitFbLogin()">
              <div class="d-flex">
                  <div class="socialBtnImg">
                      <img src="../../../assets//img/albaraq/Facebook.svg">
                  </div>
                  <h3 class="socialBtnTxt">{{'password.facebook' | translate}}</h3>
              </div>
          </div>
      </div>
  </div>
</div>