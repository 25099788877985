import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { airItineraries, flight, pickySelection } from '@src/app/interface/flight-search-result';
import { FlightServiceService } from '@src/app/services/flight-service.service';
import { pickySelectionAction, resetSelectedFlight, sortFlight, sortSelectedFlight } from '@src/app/store/flight-search/flight-search.actions';

import { selectFlightType, selectPickySelection, State } from '@src/app/store/selectors';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
// import { arrowIcon } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-select-return-flight',
  templateUrl: './select-return-flight.component.html',
  styleUrls: ['./select-return-flight.component.scss','../flight.component.scss']
})
export class SelectReturnFlightComponent implements OnInit ,OnDestroy{
  selectedpickySelectionFlight$:Observable<pickySelection> = this.store.select((statse)=>statse.flight.pickySelection);
  @Input() FlightIndex: number;
  @Input() FlightData :flight;
  @Input() item:airItineraries;
  faChevronDown=faChevronDown as IconProp;
  flightType$ = this.store.select(selectFlightType);
  faChevronRight= faChevronRight as IconProp;
  otherOptionsData;
  OpenRefined: boolean  ;
  private subscription: Subscription = new Subscription();
  serachId: string = "";
  flightType: string = "";
  Data:pickySelection;
  Index0=0;
  Index1=1;
  showDetails: boolean = false;
  isShow:boolean= false
  selectedSortingValue = "1";
  priceLowestFare: number = 0;
  durationLowesrFare: number = 0;
  experienceLowestFare: number = 0;
  responseCurrency:string='';
 
  constructor(  private store: Store<State> , private router: Router ,     private route: ActivatedRoute,       public translate : TranslateService,

    public flight: FlightServiceService,) { }

    indexx:number = 1
  ngOnInit(): void {
    this.flight.Select= false;

    // this.store.dispatch(pickySelectionAction({flight:this.FlightData, airItinerary:this.item ,flightIndex:this.FlightIndex}))
    this.OpenRefined =this.flight.OpenDiv
    this.subscription.add(
    this.selectedpickySelectionFlight$.subscribe((v)=>{
     this.Data= v
      this.indexx++;
    }))




    this.subscription.add(
      this.route.params.pipe(take(1)).subscribe((params: Params) => {
       

    

        this.serachId = params["searchId"];
        this.flight.serachId = this.serachId;
        let passengers = params["passengers"];
        let Cclass = params["Cclass"];
        let showDirect: boolean;

        if (params["directOnly"] == "false") {
          showDirect = false;
        } else {
          showDirect = true;
        }
       
      
      })
    );
    this.subscription.add(
this.flightType$.subscribe((v)=>{
  this.flightType = v;
}
  
)
    )
    
  }
  ShowFilter(){
    this.isShow = !this.isShow; 
    this.flight.fSelect = this.isShow

  }
  
  ReturnToFlightResult(){
    this.flight.SelectOneFlight = false;
  }
  setLowestFares() {
    if (this.Data.otherOptions.length != 0) {
      this.responseCurrency = this.Data.selectedFlightItinerary.itinTotalFare.currencyCode;

      var price = [...this.Data.otherOptions].sort((a, b) => { return a.itinTotalFare.amount - b.itinTotalFare.amount });
      this.priceLowestFare = Math.round(price[0].itinTotalFare.amount);

      var duration = [...this.Data.otherOptions].sort((a, b) => { return a.totalDuration - b.totalDuration });
      this.durationLowesrFare = Math.round(duration[0].itinTotalFare.amount);

    }

  }

  sortMyResult(val: number) {
    this.selectedSortingValue = val.toString()
    this.store.dispatch(sortSelectedFlight({
      airItineraries:this.Data.otherOptions,
      sortType: val
    })
    );
  }
  toggleDetails() {
    this.showDetails = !this.showDetails;
  }
  toCheckout(sid: string, sequenceNum: number) {
    this.store.dispatch(resetSelectedFlight());
    this.router.navigate(["/checkout"], {
      queryParams: { sid: sid, sequenceNum: sequenceNum },
    });
  }
  ngOnDestroy(): void {
    this.flight.SelectOneFlight = false;
    this.subscription.unsubscribe();
  }
}
