import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faAngleRight, faHome } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

declare var $: any;
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUsComponent implements OnInit {
  scrolled:boolean = false;
  faHome = faHome;
  faAngleRight =  faAngleRight;
  public getScreenWidth :any;
   constructor(
    public translate : TranslateService,
    private gtmService: GoogleTagManagerService,
    private router: Router,) {
    const gtmTag = {
      event: 'Page View',
      pageName: 'About Us',
      language: this.translate.currentLang, 
      url:this.router.url
    };
    this.gtmService.pushTag(gtmTag);
    }
 
   ngOnInit() {
    this.getScreenWidth = window.innerWidth;
   }
   @HostListener('window:resize',['$event'])onWindowResize(){
    this.getScreenWidth = window.innerWidth;
   }
 }