export class CurrencyModule {
  constructor(
    public ID:number,
    public Currency_Code:string,
    public Currency_Name:string,
    public Is_Base_Currency:boolean,
    public Image_Url:string,
    public rate : number
  ){}
 }

 export interface CurrencyModule {
    ID:number,
    Currency_Code:string,
    Currency_Name:string,
    Is_Base_Currency:boolean,
    Image_Url:string,
    rate : number
 }
