<mat-dialog-content class="login">
    <div classs="container">
        <div class="closeHeader">
            <div class="row">
                <div class="col-5 logo">
                    <img src="../../../assets/img/albaraq/TAZKRTI-dark 2.svg" alt="logo">
                </div>
                <div class="col-7 closeIcon">
                    <span class="" (click)="closeDialog()">X</span>
                </div>
            </div>
        </div>
    </div>
    <div class="" [ngClass]="{ar: translate.currentLang =='ar'}">

        <div class="row m-0">
            <div class="col-12 col-md-4 p-0 img-section d-none d-md-flex">
                <img src="assets/img/albaraq/TAZKRTI 2.svg" alt="">
                <p class="img-text">{{translate.currentLang=='en'?
                    'Explore the world deeper with us':
                    'اكتشف العالم أعمق معنا'}}
                </p>

            </div>

            <div class="col-12 col-md-8 p-0">
                <div class="closeIconWeb">
                    <span class="" (click)="closeDialog()">X</span>
                </div>
                <form [formGroup]="loginform" class="form-container">
                    <div class="row">

                        <div class="col-12 mb-3">
                            <h1 class="header-text">{{translate.currentLang=='en'?'Sign in':'تسجيل دخول'}}</h1>
                        </div>

                        <div class="col-12">
                            <mat-form-field color="accent" appearance="fill" class="authForm">
                                <i class="fas fa-envelope feildicon-login" matPrefix></i>
                                <input matInput
                                    [placeholder]="translate.currentLang == 'en'?'Email Address':'البريد الالكتروني'"
                                    required formControlName='Email' type="email"
                                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                            </mat-form-field>
                        </div>
                        <div class="col-12">
                            <mat-form-field color="accent" appearance="fill" class="authForm">
                                <i class="fas fa-lock feildicon-login" matPrefix></i>
                                <input matInput [placeholder]="translate.currentLang=='en'?'Password':'كلمة السر'"
                                    required formControlName='Password' [type]="showPassword?'text':'password'">
                                <img (click)="toggleShowPassword()" class="forget-password-icon"
                                    src="assets/img/albaraq/icons/forget-password.svg" alt="">

                            </mat-form-field>
                        </div>

                        <div class="col-12 mt-2" (click)="toForgetPassword()" style="text-align:start ;">
                            <span class="forgetPass">{{translate.currentLang=='en'?'Forget Password?':'نسيت كلمة
                                السر؟'}}</span>
                        </div>

                        <div class="col-12 mt-5" (click)="onSubmit()">
                            <button *ngIf="!userLoader" class="submitBtn" mat-button color="primary">
                                {{translate.currentLang=='en'?'Sign in':'تسجيل دخول'}}</button>
                            <mat-spinner *ngIf="userLoader"></mat-spinner>
                        </div>

                        <div class="col-12 mt-3" style="text-align:start ;">
                            <span class="noAccount">{{translate.currentLang=='en'?'Don’t have an account?':'ليس لديك
                                حساب؟'}}</span>
                            <span class="crateAccount" (click)="toSignUp()">
                                {{translate.currentLang=='en'?'Sign up':'انشاء حساب جديد'}}
                            </span>
                            <!-- <span *ngIf="getScreenWidth<=500" class="crateAccount" (click)="toSignUpMob()">
                                {{translate.currentLang=='en'?'Sign up':'انشاء حساب جديد'}}
                            </span> -->
                        </div>


                        <!-- <div class="col-12 breakLine">
                            <div class="d-flex">
                                <div class="line"></div>
                                <span class="or-text">{{translate.currentLang=='en'?'OR':'أو'}}</span>
                                <div class="line"></div>
                            </div>
                        </div> -->

                        <!-- <div (click)="submitFbLogin()" class="col-12 col-xl-6 mb-3">
                            <button class="social-login" mat-button>
                                <img class="social-login-icon" src="assets/img/albaraq/icons/facebook.svg" alt="">
                                <span class="social-login-text">{{translate.currentLang=='en'?'Sign in with
                                    Facebook':'تسجيل بواسطة فيسبوك'}}</span>
                            </button>
                        </div> -->

                        <!-- <div (click)="submitGoogleLogin()" class="col-12 col-xl-6">
                            <button class="social-login" mat-button>
                                <img class="social-login-icon" src="assets/img/albaraq/icons/google.svg" alt="">
                                <span class="social-login-text">{{translate.currentLang=='en'?'Sign in with
                                    Google':'تسجيل بواسطة جوجل'}}</span>
                            </button>
                        </div> -->
                    </div>
                </form>
            </div>
        </div>
    </div>
</mat-dialog-content>