import { createAction, props } from '@ngrx/store';
import { CurrencyModule } from '@src/app/models/currency/currency.module';
import { PointOfsaleModule } from '@src/app/models/point-ofsale/point-ofsale.module';

export const loadAppSettings = createAction(
  '[AppSetting] Load AppSettings'
);

export const loadAppSettingsSuccess = createAction(
  '[AppSetting] Load AppSettings Success',
  props<{ data: any }>()
);

export const loadAppSettingsFailure = createAction(
  '[AppSetting] Load AppSettings Failure',
  props<{ error: any }>()
);

export const updateAppCurrency = createAction(
  '[AppSetting] update App Currency',
  props<{ Currency: CurrencyModule }>()
);

export const updateAppPOS = createAction(
  '[AppSetting] update App POS',
  props<{ POS: PointOfsaleModule }>()
);

export const updateAppLang = createAction(
  '[AppSetting] update App Lang',
  props<{ Lang: string }>()
);
export const updateAppLangSuccess = createAction(
  '[AppSetting] update App Lang Success',
  props<{ Lang: string }>()
);
//  set point of sale airport
export const setAirport = createAction(
  '[AppSetting] Set POS Airport ',
  props<{ data: string }>()
);