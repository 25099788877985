import { TranslateService } from '@ngx-translate/core';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MyapiService } from '../../../app/services/myapi.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { MatDialog } from '@angular/material/dialog';
import { UserSignupComponent } from '../user-signup/user-signup.component';
import { ForgetPasswordComponent } from '../forget-password/forget-password.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { Store } from '@ngrx/store';
import { loadAuthUser, loadAuthUserFailure, loadAuthUserSuccess } from '@src/app/store/user/user.actions';
import { AuthUser } from '@src/app/models/user/user';
import { selectUserLoader } from '@src/app/store/selectors';
import { Subscription } from 'rxjs/internal/Subscription';
export interface userdata {
  AccessToken: string,
  Comment: number,
  Message: string,
  applicationUser: {
    AccessFailedCount: number,
    AccessToken: string,
    Email: string,
    EmailConfirmed: boolean,
    Id: string,
    LockoutEnabled: boolean,
    LockoutEndDateUtc: any,
    Logins: [],
    PhoneNumber: string,
    PhoneNumberConfirmed: false,
    User_Name: string,
    FirstName: string,
    LastName: string
  }
}

// const LoginWithTwitter = require('login-with-twitter')

// const tw = new LoginWithTwitter({
//   consumerKey: 'TD3a2T5PUYt6JUzqwWwvxeMaH',
//   consumerSecret: '8dVlmCWquV6fUqK4ovivPdj3pRPUcmWj9Ps04kAFo9seub29V2',
//   callbackUrl: 'https://example.com/twitter/callback'
// })

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  public getScreenWidth: any;
  preloader: boolean = true;
  loginform: FormGroup;
  faFacebook = faFacebook;
  faTwitter = faTwitter;

  userLoader$ = this.store.select(selectUserLoader);
  userLoader: boolean = false;
  showPassword = false;
  socialUser!: SocialUser;
  isLoggedin?: boolean = undefined;

  constructor(
    private myapi: MyapiService,
    private _snackBar: MatSnackBar,
    private Router: Router,
    public translate: TranslateService,
    public dialog: MatDialog,
    private authService: SocialAuthService,
    private readonly store: Store) { }

  ngOnInit() {
    // this.subscription.add(this.userLoader$.subscribe((res)=>{
    //   this.userLoader = res;
    // }))
    this.getScreenWidth = window.innerWidth;
    console.log("*****Width*****", this.getScreenWidth);
    this.authService.authState.subscribe((res) => {
      this.socialUser = res;
      this.isLoggedin = res != null;
      console.log("SOCIAL AUTH STATE", res);
    })

    this.loginform = new FormGroup({
      'Email': new FormControl('', [Validators.email, Validators.minLength(8), Validators.required]),
      'Password': new FormControl('', [Validators.required, Validators.minLength(6)]),
      'Isbase': new FormControl(0)
    })
    this.preloader = false;
  }
  onSubmit() {
    if (this.loginform.invalid) return;
    console.log("form", this.loginform);
    this.preloader = true;
    this.userLoader = true;
    this.myapi.login(this.loginform.value).subscribe(
      (result: AuthUser) => {
        this.preloader = false;
        console.log(result);
        switch (result.Comment) {
          case 0: {
            this.userLoader = false;
            console.log('somthing went wrong');
            this.openSnackBar("Sorry user does not exist", "Login faield", "snakFaild");
            this.store.dispatch(loadAuthUserFailure({ error: 'Sorry user does not exist' }))
            break
          }
          case 1: {
            this.userLoader = false;
            sessionStorage.setItem('cls', result.AccessToken);
            // sessionStorage.setItem('cls',result.applicationUser.AccessToken);
            console.log(result, "RESULTTT TESTTT")
            this.store.dispatch(loadAuthUserSuccess({ user: result }))
            debugger
            this.openSnackBar("Welcome Back " + result.applicationUser.FirstName, 'ok', "snakSuccess");
            this.closeDialog();
            break
          }
          case -2: {
            this.userLoader = false;
            this.openSnackBar("Sorry user does not exist", "Login faield", "snakFaild");
            this.loginform.reset();
            this.loginform.updateValueAndValidity();
            this.store.dispatch(loadAuthUserFailure({ error: 'Sorry user does not exist' }))
            break
          }
          case -1: {
            this.userLoader = false;
            this.openSnackBar("User name or password is incorrect", "Login faield", 'snakFaild');
            this.store.dispatch(loadAuthUserFailure({ error: 'User name or password is incorrect' }))
          }
        }
      }
    );

  }
  openSnackBar(message: string, action: string, Staus: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
      panelClass: [Staus]
    });
  }
  toHomepage() {
    this.Router.navigate(['/']);
  }
  toSignUp() {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(UserSignupComponent, { panelClass: 'custom-dialog-container' });
  }
  toSignUpMob() {
    this.dialog.closeAll();
    this.Router.navigate(['/signup']);
  }

  toForgetPassword() {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(ForgetPasswordComponent);
    // this.Router.navigate(['/forgetPassword']);
  }

  closeDialog() {
    this.dialog.closeAll()
  }

  submitFbLogin() {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((data)=>{
      console.log("hhhh", data.email);
      localStorage.setItem('Facebook-auth', JSON.stringify(data));
      this.loginform.get('Email').setValue(data.email);
      sessionStorage.setItem('cls', data.authToken);
      // this.dialog.closeAll();
    });
  }
  submitGoogleLogin() {
    console.log("hereeeeeee");
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((data)=>{
      console.log("hhhh", data.email);
      localStorage.setItem('Google-auth', JSON.stringify(data));
      this.loginform.get('Email').setValue(data.email);
      sessionStorage.setItem('cls', data.authToken);
      // this.dialog.closeAll();
    })

  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    this.getScreenWidth = window.innerWidth;
  }
}