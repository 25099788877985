import { Injectable, EventEmitter } from '@angular/core';
import { MyapiService } from './myapi.service';
import { CurrencyModule } from '../models/currency/currency.module';

@Injectable({
  providedIn: 'root'
})
export class CurruncyServiceService {
 curruncyArray:CurrencyModule[] =[];
 currentCurruncy:CurrencyModule = new CurrencyModule(2027,'KWD','Kuwait Dinar',true,"https://images.triphands.com/Content/Currencies/KWD.JPG",1);
 currentRate:number = 1;
 baseCurruncy:string = 'KWD';
 CurruncyChange:EventEmitter<CurrencyModule> = new EventEmitter();
 CurruncyArrayupdate:EventEmitter<CurrencyModule[]> = new EventEmitter();
//  BaseCurruncyChange:EventEmitter<string> = new EventEmitter();
 constructor(private myApi: MyapiService) { 
   
  }
  // call curruncy api
  GetMyCurruncy(){
    this.myApi.currencyApi(this.baseCurruncy).subscribe(
      (result)=>{this.curruncyArray=result;console.log(this.curruncyArray,"hollay");this.CurruncyArrayupdate.emit(this.curruncyArray);this.currentRate = this.curruncyArray.filter(val => val.Currency_Code = this.currentCurruncy.Currency_Code)[0]['rate']},
      (error)=>{console.log(error)},
    )
  }
  // change currency and emit currency event
  ChangeMyCurruncy(currency:CurrencyModule,rate:number){
   this.currentCurruncy = currency;
   this.currentRate = rate;
   this.CurruncyChange.emit(this.currentCurruncy);
   console.log(this.currentCurruncy,this.currentRate);
  }

 

   
 
}
