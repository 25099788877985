<app-header [bg]="true"></app-header>
<!-- <app-navlist></app-navlist> -->
<div class="wrapper" [ngClass]="{ar: translate.currentLang =='ar'}" >
    <div class="container">

        <div class="privacy">
            <div class="title">
                <h1>{{"privacyPolicy.title" | translate}}</h1>
            </div>

            <div class="policies">
                <div class="policy">
                    <p>{{"privacyPolicy.item" | translate}}</p>
                </div>
            </div>

            <div class="title">
                <h1>{{"privacyPolicy.title2" | translate}}</h1>
            </div>

            <div class="policies">
                <div class="policy">
                    <p>{{"privacyPolicy.item2" | translate}}</p>
                </div>
            </div>

            <div class="title">
                <h1>{{"privacyPolicy.title3" | translate}}</h1>
            </div>

            <div class="policies">
                <div class="policy">
                    <p>{{"privacyPolicy.item3" | translate}}</p>
                </div>
            </div>

            <div class="title">
                <h1>{{"privacyPolicy.title4" | translate}}</h1>
            </div>

            <div class="policies">
                <div class="policy">
                    <p>{{"privacyPolicy.item4" | translate}}</p>
                </div>
            </div>

            <div class="title">
                <h1>{{"privacyPolicy.title5" | translate}}</h1>
            </div>

            <div class="policies">
                <div class="policy">
                    <p>{{"privacyPolicy.item5" | translate}}</p>
                </div>
            </div>

            <div class="title">
                <h1>{{"privacyPolicy.title6" | translate}}</h1>
            </div>

            <div class="policies">
                <div class="policy">
                    <p>{{"privacyPolicy.item6" | translate}}</p>
                </div>
            </div>

            <div class="title">
                <h1>{{"privacyPolicy.title7" | translate}}</h1>
            </div>

            <div class="policies">
                <div class="policy">
                    <p>{{"privacyPolicy.item7" | translate}}</p>
                </div>
            </div>

            
        </div>
    </div>

   
</div>

<app-footer></app-footer>