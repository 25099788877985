<app-header [bg]=true></app-header>
<!-- <app-navlist></app-navlist> -->

<div class="header">
    <h1>{{"aboutUs.aboutTxt" | translate }}</h1>
</div>

<div [ngClass]="{ar: translate.currentLang =='ar'}">

    <div class="about">
        <div class="container">
            <div class="row">
                <div *ngIf="getScreenWidth > 500" class="col-12 col-lg-6 mb-4">
                    <div class="images px-4">
                        <div class="img1 d-flex justify-content-center justify-content-sm-end  mb-2">
                            <img src="assets/img/albaraq/aboutus-img1.png" alt="">
                        </div>

                        <div class="img2  d-flex justify-content-center justify-content-sm-start">
                            <img src="assets/img/albaraq/aboutus-img2.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="about-albaraq col-12 col-lg-6 ">
                    <h4>{{"aboutUs.aboutTxtBayraq" | translate }}</h4>
                    <h2>{{"aboutUs.headerText" | translate }}</h2>
                    <p style="margin: 2rem 0 ;">
                        {{"aboutUs.firstP" | translate}}
                    </p>
                    <p class="mt-2">
                        {{"aboutUs.secoundP" | translate}}
                    </p>
                    <p class="mt-2">
                        {{"aboutUs.thirdP" | translate}}
                    </p>
                    <p class="mt-2">
                        {{"aboutUs.fourthP" | translate}}
                    </p>
                    <p class="mt-4">{{"aboutUs.fifthP" | translate}} </p>
                    <p>{{"aboutUs.sixthP" | translate}}</p>
                    <p>{{"aboutUs.seventhP" | translate}}</p>

                    <p class="mt-4">
                        {{"aboutUs.eigthP" | translate}}
                    </p>

                    <!-- <div class="features ">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-6 features__wrapper">
                                        <img src="assets/img/albaraq/icons/check.svg" alt="">
                                        <span> {{"aboutUs.features.f1" | translate}}</span>
                                    </div>
                                    <div class="col-6 features__wrapperEnd">
                                        <img src="assets/img/albaraq/icons/check.svg" alt="">
                                        <span> {{"aboutUs.features.f2" | translate}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-6 features__wrapper">
                                        <img src="assets/img/albaraq/icons/check.svg" alt="">
                                        <span> {{"aboutUs.features.f1" | translate}}</span>
                                    </div>
                                    <div class="col-6 features__wrapperEnd">
                                        <img src="assets/img/albaraq/icons/check.svg" alt="">
                                        <span> {{"aboutUs.features.f2" | translate}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-6 features__wrapper">
                                        <img src="assets/img/albaraq/icons/check.svg" alt="">
                                        <span> {{"aboutUs.features.f1" | translate}}</span>
                                    </div>
                                    <div class="col-6 features__wrapperEnd">
                                        <img src="assets/img/albaraq/icons/check.svg" alt="">
                                        <span> {{"aboutUs.features.f2" | translate}}</span>
                                    </div>
                                </div>
                            </div>
                        
                        </div>

                    </div> -->

                </div>
            </div>

        </div>
    </div>

    <div class="why">
        <div class="container">
            <h4> {{"aboutUs.whyHeader" | translate}}</h4>
            <h2> {{"aboutUs.whyText" | translate}}</h2>
            <div class="row mt-5">
                <div class="col-12 col-md-6 col-lg-3 pb-4">
                    <div class="why-card">
                        <img src="assets/img/albaraq/icons/why1.svg" alt="">
                        <p class="why-text"> {{"aboutUs.whyFeatures.why1" | translate}}</p>
                    </div>
                 
                </div>
                <div class="col-12 col-md-6 col-lg-3 pb-4">
                    <div class="why-card">
                        <img src="assets/img/albaraq/icons/why2.svg" alt="">
                        <p class="why-text">{{"aboutUs.whyFeatures.why2" | translate}}</p>
                    </div>
                 
                </div>
                <div class="col-12 col-md-6 col-lg-3 pb-4">
                    <div class="why-card">
                        <img src="assets/img/albaraq/icons/why3.svg" alt="">
                        <p class="why-text">{{"aboutUs.whyFeatures.why3" | translate}}</p>
                    </div>
                 
                </div>
                <div class="col-12 col-md-6 col-lg-3 pb-4">
                    <div class="why-card">
                        <img src="assets/img/albaraq/icons/why4.svg" alt="">
                        <p class="why-text">{{"aboutUs.whyFeatures.why4" | translate}} </p>
                    </div>
                 
                </div>
     
            </div>

        </div>

    </div>


</div>

<app-footer></app-footer>