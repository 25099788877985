<div class="confirmPayment">
    <div class="container">
            
                <div class="row">
                    <div class="col-6 col-lg-2 col-xl-2 d-flex justify-content-start align-items-center logo">
        
               
        
                        <img *ngIf="translate.currentLang == 'en'" (click)="goToHome()" class="logoImg w-100 mt-4" src="../../assets/img/albaraq/TazkrtiB.svg" width="75rem" height="75rem">
                        <img *ngIf="translate.currentLang == 'ar'" (click)="goToHome()" class="logoImg w-100 mt-4" style="height:60px" src=" ../../assets/img/albaraq/TazkrtiArabicLogo.svg" width="75rem" height="75rem">
                
                      </div>
                </div>
        
                <div class="row">
                    <div class="col-12 col-12 col-lg-9 col-xl-9" style="margin: auto;">
                        <div class="info">
                            <h3>
                                {{ "ConfirmPayment.title" | translate }}
                     
                            </h3>
                            <h3>
                                {{ "ConfirmPayment.title2" | translate }}
                     
                            </h3>
                          <ul>
                            <li>
                                <h4>   {{ "ConfirmPayment.termsOf" | translate }}</h4>
                            </li>
                            <li>
                                <p>
                                    {{ "ConfirmPayment.terms1" | translate }}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{ "ConfirmPayment.terms2" | translate }}
                                </p>
                            </li>
                            <li>
                                <p>
                                    {{ "ConfirmPayment.terms3" | translate }}
                                </p>
                            </li>
                          </ul>
        
                            <ul>
                               <li>
                                <h5>
                                    {{ "ConfirmPayment.terms4" | translate }}
                                </h5>
                               </li>
                               <li>
                                <h5>
                                    {{ "ConfirmPayment.terms5" | translate }}
                                </h5>
                               </li>
                            </ul>
                            <p class="message">
                                {{ "ConfirmPayment.terms6" | translate }}
        
                            </p>
                          <div class="btn">
                           
                          <button class="Back"(click)="Back()"
                          type="submit" mat-button>
                          {{ "ConfirmPayment.Back" | translate }}
                        </button>
                        <button  class="Next"
                        type="submit" mat-button (click)="Next()">
                        {{ "ConfirmPayment.Next" | translate }}
                      </button>
                          </div>
                        </div>
                    </div>
                </div>
    
       
    </div>

</div>