import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'offlineFilter'
})
export class OfflineFilterPipe implements PipeTransform {

  transform(value: any[], args?: string): any[] {
    if (!value || !args){
      return value
    }
    else {
      return value.filter(va => va.placement === args);
    }
  }

}