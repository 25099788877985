import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { faCheckCircle, faEnvelope, faTimesCircle, } from '@fortawesome/free-solid-svg-icons';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { selectUserLoader } from '@src/app/store/selectors';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthService } from 'angularx-social-login';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {

  faTimesCircle = faTimesCircle;
  faCheckCircle =faCheckCircle;
  faEnvelope= faEnvelope;
  showPassword = false ; 
  showConfirmPassword = false;

  userLoader$ = this.store.select(selectUserLoader);
  userLoader : boolean = false;

  forgetPassForm = new FormGroup({
    'Email': new FormControl('', [Validators.email, Validators.minLength(8), Validators.required]),
  })

  constructor(
    public translate : TranslateService,
    public dialog    : MatDialog,
    private authService : SocialAuthService,
    public store: Store
  ) { }

  ngOnInit() {
    this.authService.authState.subscribe((res)=>{
      console.log("SOCIAL AUTH STATE" , res);
    })
  }

  onSubmit(){
    this.userLoader = true;
  }

  submitFbLogin(){
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }
  submitGoogleLogin(){
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  closeDialog(){
    this.dialog.closeAll()
  }
  toggleShowPassword(){
    this.showPassword = !this.showPassword; 
  }
  toggleShowConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

}
