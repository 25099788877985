import { CodToCityPipe } from "../pipes/cod-to-city.pipe";
import { FlightSearchResult, passengLists } from "../interface/flight-search-result";
import { CurruncyServiceService } from "../services/curruncy-service.service";
import { FlightsInfoModule } from "src/app/models/flights-info/flights-info.module";
import { DatePipe, isPlatformBrowser } from "@angular/common";
import {
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import {
  NgbDate,
  NgbCalendar,
  NgbDateStruct,
  NgbPopover,
  NgbDateParserFormatter,
  NgbDatepicker,
  NgbInputDatepicker,
} from "@ng-bootstrap/ng-bootstrap";
import { BehaviorSubject, Observable, Subject, Subscription } from "rxjs";
import { CitiesModule } from "../models/cities/cities.module";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
  OnDestroy,
  ElementRef,
  ChangeDetectorRef,
} from "@angular/core";
import { MyapiService } from "src/app/services/myapi.service";
import { TranslateService } from "@ngx-translate/core";
import { PointOfsaleModule } from "src/app/models/point-ofsale/point-ofsale.module";
import { SearchFlightModule } from "src/app/models/search-flight/search-flight.module";
import { ActivatedRoute, Router,NavigationEnd  } from "@angular/router";
import { SessionService } from "src/app/services/session.service";
import airportar from "src/assets/airports/airportar.json";
import airporten from "src/assets/airports/airporten.json";
import { selectAppCurrency, selectAppPOS, State } from "../store/selectors";

import {
  faAngleDown,
  faInfoCircle,
  faMinusCircle,
  faPlusCircle,
  faTimes,
  faCheck,
  faTimesCircle,
  faMapMarker,
  faPlane,

  faExchangeAlt,
  faSearch,
  faCaretDown
} from "@fortawesome/free-solid-svg-icons";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { AirportCodeService } from "../services/airport-code.service";
import { selectSettingsFeature } from "../store/selectors";
import { Store } from "@ngrx/store";
import { FlightServiceService } from "../services/flight-service.service";
import { cFlightSearchs, loadFlightSearchs, updateFlightSummaryData, updateFlightType } from "../store/flight-search/flight-search.actions";
import { take } from "rxjs/internal/operators/take";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CurrencyModule } from "../models/currency/currency.module";
import { setAirport } from "../store/app-setting/app-setting.actions";

export interface flightWithType {
  flightIndex: number,
  departType: string,
  landType: string
}

@Component({
  selector: "app-searchflight",
  templateUrl: "./searchflight.component.html",
  styleUrls: ["./searchflight.component.scss"],
  
})


export class SearchflightComponent implements OnInit, OnDestroy {
  @ViewChild('roundTripCalenderTrigger') roundTripCalenderTrigger: ElementRef;
  @ViewChild('oneWayCalenderTrigger') oneWayCalenderTrigger: ElementRef;
  @ViewChild('myDatepicker') datepicker: NgbInputDatepicker;
  @ViewChild('roundTripCalender2') roundTripCalender2!: ElementRef;
  @ViewChild("multiCityCalenderTrigger_0") multiCityCalenderTrigger_0: ElementRef;
  @ViewChild("multiCityCalenderTrigger_1") multiCityCalenderTrigger_1: ElementRef;
  @ViewChild("multiCityCalenderTrigger_2") multiCityCalenderTrigger_2: ElementRef;
  @ViewChild("multiCityCalenderTrigger_3") multiCityCalenderTrigger_3: ElementRef;
  date1;
  date2;
  date3;
  date4;
  alertDate: boolean = false;

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate;
  toDate: NgbDate | null = null;
  fromDateBinding: Date[] = [];
  toDateBinding: any = null;
  flightList :any ;
  selectedCurrency$: Observable<CurrencyModule> = this.store.select(selectAppCurrency);
  adultAlert: boolean = false;
  maxPassangers: boolean = false;
  infentMax: boolean = false;
  faAngleDown = faAngleDown;
  faTimes = faTimes;
  faPlusCircle = faPlusCircle;
  faExchangeAlt = faExchangeAlt;
  faInfoCircle = faInfoCircle;
  firstDate:NgbDate;
  @ViewChild('popOverAirport') public popOverAirport: NgbPopover;
  openClass: boolean = false;
  searchFlight?: FormGroup;
  faCaretDown = faCaretDown as IconProp;
  faSearch = faSearch as IconProp;
  @Output() research: EventEmitter<string> = new EventEmitter();
  maxinfent: number = 1;
  maxnumber: number = 9;
  //passsenger
  adults = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  child = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  infentL = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  travellerNo = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  vAdults = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  vChild = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  vInfent = [1, 2, 3, 4];

  flightsnumber: number = 0;
  cities: CitiesModule[] = [];
  citiesar: CitiesModule[] = [];
  citiesen: CitiesModule[] = [];
  returnlink: Subscription = new Subscription();
  citiesNames: string[] = [];
  citiesCode: string[] = [];
  pointof: PointOfsaleModule = new PointOfsaleModule(
    "",
    "",
    "",
    "",
    "",
    "AE",
    "AE",
    "",
    "",
    false,
    "",
    0,
    0,
    "etyety",
    "erty",
    "erty",
    "AED",
    "ar",
    "",
    ""
  );
  datainput?: FlightSearchResult;
  storage: boolean = false;
  localform?: object;
  flightForm?: object;
  @ViewChild("picker", { static: false }) picker: any = '';
  message: Subject<string> = new BehaviorSubject("loading :(");

  private subscription: Subscription = new Subscription();
  pointofSaleCity: any;
  return = new FormControl('');
  roundType: boolean = false;
  multiDest: boolean = false;
  single: boolean = true;
  flightN: number = 0;
  POS$ = this.store.select(selectAppPOS);
  lang: string = "en";
  lan: string = localStorage.getItem("lang") ? localStorage.getItem("lang") as string : 'en';
  setting$ = this.store.select(selectSettingsFeature);


  @Input() homePage: boolean = true;
  isHomeComponent: boolean = false;
  minDate = this.calendar.getToday();
	model: NgbDateStruct;
  departureDate: NgbDate;
  arrivalDate: NgbDate;
  @ViewChild('departureDatepicker', { static: false })
  private departureDatepicker: NgbDatepicker;

  @ViewChild('arrivalDatepicker', { static: false })
  private arrivalDatepicker: NgbDatepicker;

  constructor(
    private Router: Router,
    private calendar: NgbCalendar,
    private myApi: MyapiService,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private city: CodToCityPipe,
    public translate: TranslateService,
    private carruncy: CurruncyServiceService,
    private session: SessionService,
    private readonly store: Store<State>,
    private changeDetectorRef: ChangeDetectorRef,
    public formatter: NgbDateParserFormatter,
    public sharedData: FlightServiceService,
    private elementRef: ElementRef


  ) {
    iconRegistry.addSvgIcon(
      "switch-arrow",
      sanitizer.bypassSecurityTrustResourceUrl("assets/img/sw")
    );
    this.fromDate = this.calendar.getToday();
    this.toDate = this.calendar.getNext(this.fromDate, 'd', 10);
    //animation logic
    this.Router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isHomeComponent = (this.route.snapshot.url[0].path === 'home');
      }
    });
  
  }

  ngOnInit() {

    this.subscription.add(
      this.setting$.subscribe((v) => {
        this.lan = v.currentLang;
        this.pointof = v.pos;
       
                console.log("search point hereeeeee" , this.pointof)


      }));
    
    this.subscription.add(
      this.POS$.subscribe((result: PointOfsaleModule) => {
        if (result) {
          this.pointofSaleCity = result.city;
          if (!result.airport) {
            this.store.dispatch(setAirport({ data: this.setDcity(result) }));
          }

        }
        return
      }));
    if (localStorage.getItem("form")) {
      console.log("formlast", JSON.parse(localStorage.getItem("form") as string));
      let formo: any = JSON.parse(localStorage.getItem("form") as string);
      this.storage = true;
      this.searchFlight = new FormGroup({
        // intial flightType is one way//
        // to set onther flight type as intial stae make sure to
        //add Fligts in the Flights array

        flightType: new FormControl(formo["flightType"], [Validators.required]),
        Direct: new FormControl(formo["Direct"], [Validators.required]),
        Flights: new FormArray([], [Validators.required]),

        returnDate: new FormControl(formo["returnDate"]),
        passengers: new FormGroup(
          {
            adults: new FormControl(formo["passengers"]["adults"], [
              Validators.required,
              Validators.min(1),
            ]),
            child: new FormControl(formo["passengers"]["child"], [
              Validators.required,
              Validators.min(0),
            ]),
            infent: new FormControl(formo["passengers"]["infent"], [
              Validators.required,
              Validators.max(this.maxinfent),
              Validators.min(0),
            ]),
          },
          [this.maxValueReached.bind(this)]
        ),
        class: new FormControl(formo["class"], [Validators.required]),
      });
      this.return.setValue(formo["returnDate"]);
      this.intialLocal(this.searchFlight?.get("flightType")?.value);
      this.subscription.add(
        this.flightType.valueChanges.subscribe((value) => {

          this.intial(this.flightType.value);
          this.cdr.detectChanges();

        }));
      this.subscription.add(
        this.searchFlight.get("passengers.adults")?.valueChanges?.subscribe((value) => {
          this.setmaxinfentval(value);
          this.searchFlight?.get("passengers.infent")?.updateValueAndValidity();
        })
      );

      this.flightsnumber = this.searchFlight?.get("Flights")?.value.length;
      this.calendersBinding();
    } else {
      this.fromDateBinding.push(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day));
      this.fromDateBinding.push(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day + 5));
      this.toDateBinding = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
      this.searchFlight = new FormGroup({
        // intial flightType is one way//
        // to set onther flight type as intial stae make sure to
        //add Fligts in the Flights array
        flightType: new FormControl("RoundTrip", [Validators.required]),
        Direct: new FormControl(false, [Validators.required]),
        Flights: new FormArray([], [Validators.required]),
        returnDate: new FormControl(),
        passengers: new FormGroup(
          {
            adults: new FormControl(1, [
              Validators.required,
              Validators.min(1),
            ]),
            child: new FormControl(0, [Validators.required, Validators.min(0)]),
            infent: new FormControl(0, [
              Validators.required,
              Validators.max(this.maxinfent),
              Validators.min(0),
            ]),
          },
          [this.maxValueReached.bind(this)]
        ),
        class: new FormControl("Economy", [Validators.required]),
      });
      this.searchFlight.get("Flights")?.get("0")?.get("departingD").setValue(this.fromDateBinding[0])
      this.searchFlight.get("Flights")?.get("1")?.get("departingD").setValue(this.fromDateBinding[0])
      this.onAddFlight();

      this.subscription.add(
        this.searchFlight?.get("flightType")?.valueChanges.subscribe((value) => {

          this.intial(this.flightType.value);
        })
      );
      this.searchFlight?.get("flightType").setValue("RoundTrip");
      this.subscription.add(this.searchFlight?.get("passengers.adults")?.valueChanges.subscribe((value) => {
        this.setmaxinfentval(value);
      })
      );
      this.searchFlight.get("Flights")?.get("0")?.get("departingD").setValue(this.fromDateBinding[0])
      this.searchFlight.get("Flights")?.get("1")?.get("departingD").setValue(this.fromDateBinding[0])

      this.searchFlight.get("returnDate").setValue(this.toDateBinding)
      this.flightsnumber = this.searchFlight?.get("Flights")?.value.length;
    }

    //set inital values of the form
    this.citiesar = airportar;
    this.citiesNameExtract(this.citiesar);
    this.citiesen = airporten;
    this.citiesNameExtract(this.citiesen);
    if (localStorage.getItem("lang") == "en" || !localStorage.getItem("lang")) {
      this.cities = this.citiesen;
      this.cities = [...this.citiesen, ...this.citiesar];
      this.citiesNameExtract(this.cities);
    } else {
      this.cities = this.citiesar;
      this.cities = [...this.citiesar, ...this.citiesen];
      this.citiesNameExtract(this.cities);
    }

    // check on change langs  
    this.subscription.add(
      this.translate.onLangChange.subscribe((result) => {
        this.lang = result.lang;
        if (result.lang == "ar") {
          this.flightLangMapper(this.citiesar);
          this.cities = [...this.citiesar, ...this.citiesen];
          this.citiesNameExtract(this.cities);
        } else {
          this.flightLangMapper(this.citiesen);
          this.cities = [...this.citiesen, ...this.citiesar];
          this.citiesNameExtract(this.cities);
        }
      })
    );

    this.subscription.add(
      this.searchFlight.get("Flights")?.valueChanges.subscribe((val) => {
        this.flightN = val.length;
        this.flightList = this.bendingFlights();
        
      })
    );
    this.translate.onLangChange.subscribe((event) => {
      console.log(event.translations.datepicker
        , 'testtt');
      this.cdr.detectChanges();

      // Reinitialize the datepicker after the language changes
      if (this.datepicker) {
                this.cdr.detectChanges();
      }
    });

  }
 
  //set point of sale as defult departing cit
  setDcity(point: PointOfsaleModule): string {
    if (point) {
      let city = point.city.toLocaleLowerCase();
      let capital = point.country_capital.toLocaleLowerCase();

      let enairport = this.citiesen.filter(air =>
        (air.cityName.toLocaleLowerCase() == city) ? air.cityName.toLocaleLowerCase() == city : air.cityName.toLocaleLowerCase() == capital
      )[0];
      // let arairport = this.citiesar.filter(air => air.airportCode === enairport.airportCode)[0];
      return enairport.airportCode
    } else {
      return undefined
    }
  }

  ngAfterViewInit() {

    this.changeDetectorRef.detectChanges();
    
  }
  public get flightType(): FormControl {
    return this.searchFlight?.get('flightType') as FormControl
  }
  public get CClass(): FormControl {
    return this.searchFlight?.get('class') as FormControl
  }
  public get flightsArray(): FormArray {
    return this.searchFlight?.get('Flights') as FormArray
  }
  public get returnDate(): FormControl {
    return this.searchFlight?.get('returnDate') as FormControl;
  }

  public get totalPassangers(): Number {
    return Number(this.searchFlight?.get('passengers').get('adults').value) +
      Number(this.searchFlight?.get('passengers').get('child').value) +
      Number(this.searchFlight?.get('passengers').get('infent').value)
  }

  stopMenuClosing(event: any) {
    event.stopPropagation();
  }

  roundTripI() {
    const flight = new FormGroup(
      {
        departing: new FormControl(""),
        landing: new FormControl(""),
        departingD: new FormControl(),
      },
      [this.invalidFlightDis]
    );

    this.removeArrayControllers();

    const Rflight = new FormGroup(
      {
        departing: new FormControl(
          this.searchFlight?.get("Flights")?.get("0")?.get("landing")?.setValidators([Validators.required, this.citynotfound.bind(this)])
        ),
        landing: new FormControl(
          this.searchFlight?.get("Flights")?.get("0")?.get("departing").setValidators([Validators.required, this.citynotfound.bind(this)])
        ),
        departingD: new FormControl(this.searchFlight.get("returnDate").value),
      },
      [this.invalidFlightDis]
    );

    (<FormArray>this.searchFlight.get("Flights")).push(Rflight);
  }

  setreturn() {
    if (this.searchFlight.get("returnDate").value != null) {
      (<FormArray>this.searchFlight.get("Flights")).updateValueAndValidity();
      return (<FormArray>this.searchFlight.get("Flights"))["value"][1][
        "departingD"
      ];
    } else {
      return (<FormArray>this.searchFlight.get("Flights"))["value"][0][
        "departingD"
      ];
    }
  }
  updateReturn(myvalue) {
    this.searchFlight
      .get("Flights")
      .get("1")
      .get("departingD")
      .setValue(myvalue),
      this.searchFlight
        .get("Flights")
        .get("1")
        .get("departing")
        .setValue(
          this.searchFlight.get("Flights").get("0").get("landing").value
        ),
      this.searchFlight
        .get("Flights")
        .get("1")
        .get("landing")
        .setValue(
          this.searchFlight.get("Flights").get("0").get("departing").value
        );
  }

  intial(mvalue: string) {
    if (mvalue == "oneway") {
      this.single = true;
      this.roundType = false;
      this.multiDest = false;
      if (this.returnlink) {
        this.returnlink.unsubscribe();
      }
      this.removeArrayControllers();
      this.searchFlight
        .get("returnDate")
        .setValidators(Validators.nullValidator);
      this.searchFlight.get("returnDate").updateValueAndValidity();
      if ((<FormArray>this.searchFlight.get("Flights")).length < 1) {
        this.onAddFlight();
      }

      return;
    }
    if (mvalue == "RoundTrip") {
      this.single = false;
      this.multiDest = false;
      this.roundType = true;

      this.returnlink = this.searchFlight
        .get("returnDate")
        .valueChanges.subscribe((myvalue) => {
          this.searchFlight
            .get("Flights")
            .get("1")
            .get("departingD")
            .setValue(myvalue),
            this.searchFlight
              .get("Flights")
              .get("1")
              .get("departing")
              .setValue(
                this.searchFlight.get("Flights").get("0").get("landing").value
              ),
            this.searchFlight
              .get("Flights")
              .get("1")
              .get("landing")
              .setValue(
                this.searchFlight.get("Flights").get("0").get("departing").value
              );
        });
      this.roundTripI();
      this.searchFlight.get("returnDate").setValidators(Validators.required);
      this.searchFlight.get("returnDate").updateValueAndValidity();

      return;
    }

    if (mvalue == "Multicity" || "multicity") {
      this.single = false;
      this.multiDest = true;
      this.roundType = false;
      if (this.returnlink) {
        this.returnlink.unsubscribe();
      }

      this.removeArrayControllers();
      this.searchFlight
        .get("returnDate")
        .setValidators(Validators.nullValidator);
      this.searchFlight.get("returnDate").updateValueAndValidity();
      this.onAddFlight();
    }
  }

  //reset the value to thatof local storage
  intialLocal(mvalue: string) {
    this.localform = JSON.parse(localStorage.getItem("form"));
    if (mvalue == "oneway") {
      if (this.returnlink) {
        this.returnlink.unsubscribe();
      }
      this.removeArrayControllers();
      this.searchFlight
        .get("returnDate")
        .setValidators(Validators.nullValidator);
      this.searchFlight.get("returnDate").updateValueAndValidity();
      (<FormArray>this.searchFlight.get("Flights")).push(
        new FormGroup(
          {
            departing: new FormControl(
              this.localform["Flights"][0]["departing"],
              [Validators.required, this.citynotfound.bind(this)]
            ),
            landing: new FormControl(this.localform["Flights"][0]["landing"], [
              Validators.required,
              this.citynotfound.bind(this),
            ]),
            departingD: new FormControl(
              this.localform["Flights"][0]["departingD"],
              [Validators.required]
            ),
          },
          [this.invalidFlightDis]
        )
      );

      return;
    }
    if (mvalue == "RoundTrip") {
      if (this.returnlink) {
        this.returnlink.unsubscribe();
      }
      this.removeArrayControllers();
      this.searchFlight?.get("returnDate")?.setValidators(Validators.nullValidator);
      this.searchFlight?.get("returnDate")?.updateValueAndValidity();
      (<FormArray>this.searchFlight?.get("Flights")).push(
        new FormGroup(
          {
            departing: new FormControl(
              (this.localform["Flights"][0]["departing"]), [Validators.required, this.citynotfound.bind(this)]
            ),
            landing: new FormControl(this.localform["Flights"][0]["landing"], [
              Validators.required,
              this.citynotfound.bind(this),
            ]),
            departingD: new FormControl(
              this.localform["Flights"][0]["departingD"],
              [Validators.required]
            ),
          },
          [this.invalidFlightDis]
        )
      );
      (<FormArray>this.searchFlight.get("Flights")).push(
        new FormGroup(
          {
            departing: new FormControl(
              this.localform["Flights"][0]["landing"],
              [Validators.required, this.citynotfound.bind(this)]
            ),
            landing: new FormControl(
              this.localform["Flights"][0]["departing"],
              [Validators.required, this.citynotfound.bind(this)]
            ),
            departingD: new FormControl(this.localform["returnDate"], [
              Validators.required,
            ]),
          },
          [this.invalidFlightDis]
        )
      );

      return;
    }

    if (mvalue == "Multicity" || "multicity") {
      if (this.returnlink) {
        this.returnlink.unsubscribe();
      }
      this.removeArrayControllers();
      this.searchFlight
        .get("returnDate")
        .setValidators(Validators.nullValidator);
      this.searchFlight.get("returnDate").updateValueAndValidity();
      let array: [] = [];
      array = this.localform["Flights"];

      array.forEach((element) => {
        (<FormArray>this.searchFlight.get("Flights")).push(
          new FormGroup(
            {
              departing: new FormControl(element["departing"], [
                Validators.required,
                this.citynotfound.bind(this),
              ]),
              landing: new FormControl(element["landing"], [
                Validators.required,
                this.citynotfound.bind(this),
              ]),
              departingD: new FormControl(element["departingD"], [
                Validators.required,
              ]),
            },
            [this.invalidFlightDis]
          )
        );
      });
    }
  }
  //  city name to citycode
  cityNametoCitycode(cityname: string): string {
    let code: string = "";
    this.cities.forEach((element) => {
      if (element.cityName + "(" + element.airportCode + ")" == cityname) {
        code = element.airportCode;
      }
    });
    return code;
  }
  //

  // to cities
  tocity(code: string): string {
    let city: string = "";
    this.cities.forEach((element) => {
      if (code.toLocaleLowerCase() == element.cityCode.toLocaleLowerCase()) {
        city = element.cityName;
      }
    });
    return city;
  }

  // switch destenation
  switchDes(item: FormGroup) {
    let value1 = item.get("landing").value;
    let value2 = item.get("departing").value;
    item.get("departing").setValue(value1);
    item.get("landing").setValue(value2);
    item.updateValueAndValidity();
  }

  //to match the formGroups name with the Flights array index
  indexadd1(index: number) {
    return index + 1;
  }

  //call to rest form array
  removeArrayControllers() {
    while ((<FormArray>this.searchFlight.get("Flights")).length > 1) {
      (<FormArray>this.searchFlight.get("Flights")).removeAt(1);
    }
  }

  // to add more flights to flights array by add buton
  onAddFlight() {
    // debugger
    let i = (<FormArray>this.searchFlight.get("Flights")).length;
    let reval = "";
    let land = "";
    if (i > 0) {
      reval = (<FormArray>this.searchFlight.get("Flights")).value[i - 1][
        "landing"
      ];
      land = "";
    }
    // change depart & land city init 3la 7sab lang

    (<FormArray>this.searchFlight.get("Flights")).push(
      new FormGroup(
        {
          departing: new FormControl(reval, [
            Validators.required,
            this.citynotfound.bind(this),
          ]),
          landing: new FormControl(land, [
            Validators.required,
            this.citynotfound.bind(this),
          ]),
          departingD: new FormControl('', [Validators.required]),
        },
        [this.invalidFlightDis]
      )
    );
    this.flightsnumber = this.searchFlight.get("Flights").value.length;
    this.fromDateBinding.push(undefined)
  }

  // invalid flight number
  maxFlights() {
    if (this.flightsnumber >= 4) {
      return false;
    } else {
      return true;
    }
  }
  // choose date from depart open calender returndata
  reOpenCalendar() {
    let self = this;
    setTimeout(() => {
      self.picker.open();
    }, 50);
  }
  //match Flights form array values with FlightInfoModule
  bendingFlights() {
    let flightout: FlightsInfoModule[] = [];
    if (this.searchFlight.get("flightType").value == 'RoundTrip') {
      const roundElement1 = (<FormArray>this.searchFlight.get("Flights")).controls[0];
      let depart = this.cityNametoCitycode(roundElement1.value['departing'])
      let landing = this.cityNametoCitycode(roundElement1.value['landing'])
      let flight = new FlightsInfoModule(
        depart,
        landing,
        this.datePipe.transform(roundElement1.value['departingD'], 'MMMM dd, y'));
      flightout.push(flight);

      const roundElement2 = (<FormArray>this.searchFlight.get("Flights")).controls[0];
      let flight2 = new FlightsInfoModule(
        landing,
        depart,
        this.datePipe.transform(this.returnDate.value, 'MMMM dd, y'));
      flightout.push(flight2);

      return flightout
    }
    for (let index = 0; index < (<FormArray>this.searchFlight.get("Flights")).length; index++) {
      const element = (<FormArray>this.searchFlight.get("Flights")).controls[index];
      
      let flight = new FlightsInfoModule(
        this.cityNametoCitycode(element.value['departing']),
        this.cityNametoCitycode(element.value['landing']),
        this.datePipe.transform(element.value['departingD'], 'MMMM dd, y'));
      flightout.push(flight);
    }

    return flightout;
  }

  citiesNameExtract(cities: CitiesModule[]) {
    cities.forEach(element => {
      let city = element.cityName
      // let airportName = element.airportName
      let airportcode = element.airportCode
      this.citiesNames.push(city + '(' + airportcode + ')');
    });
  }

  //return cites code
  citiescode() {
    this.cities.forEach((element) => {
      let city = element.cityCode.toLowerCase();
      this.citiesCode.push(city);
    });
  }
  // validation passenger
  validatePassangersList(
    adultsNumber: number,
    child: number,
    infent: number
  ): passengLists {

    let total = adultsNumber + child + infent;
    let AvalidN = 9 - (child + infent);
    let CvalidN = 9 - (adultsNumber + infent);
    let IvalidN = 9 - (adultsNumber + child);
    let validInf = adultsNumber > IvalidN ? IvalidN : adultsNumber;
    let Adl =
      adultsNumber > AvalidN
        ? Array.from(Array(adultsNumber + 1).keys())
        : Array.from(Array(AvalidN + 1).keys());
    let Chl =
      child > CvalidN
        ? Array.from(Array(child + 1).keys())
        : Array.from(Array(CvalidN + 1).keys());
    let infl =
      infent > IvalidN
        ? Array.from(Array(infent + 1).keys())
        : Array.from(Array(validInf + 1).keys());
    return {
      adult: Adl,
      child: Chl,
      infent: infl,
    };
  }
  //infents number setup
  setmaxinfentval(value) {
    this.maxinfent = value;
    this.searchFlight
      .get("passengers.infent")
      .setValidators([Validators.max(this.maxinfent)]);
  }
  //get curr
  getcurr() {
    let currentCurr: string = ''
    this.subscription.add(this.selectedCurrency$.pipe(take(1)).subscribe((curr) => {
      if (curr) {
        currentCurr = curr.Currency_Code
      }
      else {
        currentCurr = 'KWD'
      }
    }))
    return currentCurr
  }
  // to submit the form and call the search api
  onSubmit() {
    this.searchFlight.updateValueAndValidity();
    if (this.searchFlight.get('flightType').value == 'RoundTrip') {
      this.updateReturn(this.searchFlight.get('returnDate').value)
    }
    this.searchFlight.get("Flights")?.get("1")?.get("departingD").setValue(this.fromDateBinding[1])

    if (this.searchFlight.valid) {
    console.log("flightarrayData", this.searchFlight.value)

      let searchApi: SearchFlightModule = new SearchFlightModule(
        localStorage.getItem("lang") || "en",
        this.getcurr(),
        this.pointof.country? this.pointof.country:"kw",
        
        this.searchFlight.get("flightType").value,
        
        this.myApi.flightInfoFormatter(this.bendingFlights()),
        this.myApi.passingerFormatter([
          this.searchFlight.get("passengers.adults").value,
          this.searchFlight.get("passengers.child").value,
          this.searchFlight.get("passengers.infent").value,
        ]),
        this.searchFlight.get("class").value,
        this.id(),
        this.searchFlight.get("Direct").value,
        "all"
      );

      let language = searchApi.lan;
      let currency = searchApi.Currency;
      let SearchPoint = searchApi.pointOfReservation
        ? searchApi.pointOfReservation
        : "kw";
      let flightType = searchApi.flightType;
      let flightInfo = searchApi.flightsInfo;
      let searchId = searchApi.serachId;
      let passengers = searchApi.passengers;
      let Cclass = searchApi.Cclass;
      let directOnly = searchApi.showDirect; //true Or False 
      this.session.setSessionTime(30);
      this.session.startTimer();


      this.searchFlight.updateValueAndValidity();
      let searchObject = {
        flightType: this.searchFlight.get("flightType").value,
        Direct: this.searchFlight.get("Direct").value,
        returnDate: this.searchFlight.get("returnDate").value,
        passengers: this.searchFlight.get("passengers").value,
        class: this.searchFlight.get("class").value,
        Flights: [],
      };
      this.flightList = this.bendingFlights()
      for (var i = 0; i < this.flightList.length; i++) {
        searchObject.Flights.push({
          departing: this.flightList[i].departingCity,
          departingD: this.flightList[i].departionDate,
          landing: this.flightList[i].arrivalCity,
        });
      }
      localStorage.setItem("form", JSON.stringify(this.searchFlight.value));
    
      this.Router.navigate([
        "/flightResult",
        language,
        currency,
        SearchPoint,
        flightType,
        flightInfo,
        searchId,
        passengers,
        Cclass,
        directOnly,
      ]);
      let search: SearchFlightModule = new SearchFlightModule(
        language,
        currency,
        SearchPoint,
        flightType,
        flightInfo,
        passengers,
        Cclass,
        searchId,
        directOnly,
        "All"
      );
      localStorage.setItem("lastSearchModule", JSON.stringify(search))
      this.sharedData.lastSearchModule = search;
      this.store.dispatch(updateFlightSummaryData({ searchData: this.searchFlight.value }));
      this.store.dispatch(loadFlightSearchs({ search: search }));

    } 
  }
  id() {
    let date = new Date();
    let myId =
      date.getFullYear() +
      "B" +
      date.getUTCMonth() +
      "I" +
      date.getUTCDay() +
      "S" +
      date.getMilliseconds() +
      "H" +
      Math.floor(Math.random() * (9 - 0 + 1)) +
      0 +
      "B" +
      Math.floor(Math.random() * (9 - 0 + 1)) +
      0 +
      "I" +
      Math.floor(Math.random() * (9 - 0 + 1)) +
      0 +
      "S" +
      Math.floor(Math.random() * (9 - 0 + 1)) +
      0 +
      "H" +
      Math.floor(Math.random() * (9 - 0 + 1)) +
      0 +
      "I" +
      Math.floor(Math.random() * (9 - 0 + 1)) +
      0;
    return myId;
  }

  //other functions end
  //custom validators start
  // departing and landing not the same
  invalidFlightDis(flight: FormGroup): { [a: string]: boolean } {
    if (flight.get("departing").value === flight.get("landing").value) {
      return { distenationNotValid: true };
    }
    return null;
  }
  radioChange(event: any) {
    this.sharedData.flightTypeInSearchBox = event.value;
  }
  // retun today date
  todayDate() {
    let date = new Date();
    return date.toISOString().split("T")[0];
  }

  // passengers number cant be more than 9
  maxValueReached(search: FormGroup): { [b: string]: boolean } {
    if (
      search.get("adults").value +
      search.get("child").value +
      search.get("infent").value >
      9
    ) {
      return { maxReched: true };
    }
    return null;
  }

  citynotfound(input: FormControl): { [c: string]: boolean } {
    if (this.citiesNames.indexOf(input.value) === -1) {
      return { notValidcity: true };
    }
    return null;
  }

  //set values for the form
  // return date coing value
  returndateInput() {
    if (this.datainput.searchCriteria.flightType == "roundtrip") {
      return this.datainput.searchCriteria.flights[1].departingOnDate;
    } else {
      return this.todayDate();
    }
  }
  selectClass(classValue: string) {
    this.searchFlight.get("class").setValue(classValue);
  }
  selectFlightType(value: string) {
    this.searchFlight.get("flightType").setValue(value);
  }

  //  // return array of flights
  onAddFlightinput() {
    for (
      let index = 0;
      index < this.datainput.searchCriteria.flights.length;
      index++
    ) {
      const element = this.datainput.searchCriteria.flights[index];
      (<FormArray>this.searchFlight.get("Flights")).push(
        new FormGroup(
          {
            departing: new FormControl(element.departingFrom, [
              Validators.required,
              this.citynotfound.bind(this),
            ]),
            landing: new FormControl(
              this.city.transform(element.arrivingTo, this.cities),
              [Validators.required, this.citynotfound.bind(this)]
            ),
            departingD: new FormControl(element.departingOnDate, [
              Validators.required,
            ]),
          },
          [this.invalidFlightDis]
        )
      );
    }
    this.flightsnumber = this.searchFlight.get("Flights").value.length;

  }
  // remove flight from the array
  removeflight(flightIndex: number) {
    (<FormArray>this.searchFlight.get("Flights")).removeAt(flightIndex);
    this.fromDateBinding.splice(flightIndex, 1)
  }
  //remove all Flights
  resetFlights(){
    let arrLength = (<FormArray>this.searchFlight.get("Flights"))?.length;
    for(let i =2; i< arrLength ; i++){
      (<FormArray>this.searchFlight.get("Flights")).removeAt(arrLength-i);
    }
  }

  //make departure of next flight  of multiFlight be landing of prev
  updateDepMulti(city: any, i: number) {
    if (this.flightType.value === "Multicity" || "multicity") {
      this.searchFlight.get("Flights")["controls"][i + 1].get("departing").setValue(city);
      this.searchFlight.get("Flights")["controls"][i + 1].get("departing").updateValueAndValidity();
      this.searchFlight.get("Flights").updateValueAndValidity();
    }
  }

  logReturn(val) {
    this.returnDate.setValue(val);
  }

  checkAirportPopUp() {
    if (this.popOverAirport.isOpen()) this.popOverAirport.close();
  }
  onOneWayDateSelection(date: NgbDate, flightIndex: number) {
    this.fromDate = date;

    if(this.toDate  > this.fromDate)
    {
      this.toDate=  this.toDate
    }
    this.fromDateBinding[flightIndex] = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    
    (<FormArray>this.searchFlight.get("Flights")).at(flightIndex).get("departingD").setValue(this.fromDateBinding[flightIndex]);

     // Open the arrival datepicker after setting the departure date
     this.roundTripCalender2.nativeElement.click();

    

  }
  closeOneWay(){
    this.oneWayCalenderTrigger.nativeElement.click();

  }
  onMultiCityDateSelection(date: NgbDate, flightIndex: number) {
    this.fromDate = date;

    this.fromDateBinding[flightIndex] = new Date(
      this.fromDate.year,
      this.fromDate.month - 1,
      this.fromDate.day
    );
    (<FormArray>this.searchFlight.get("Flights"))
      .at(flightIndex)
      .get("departingD")
      .setValue(this.fromDateBinding[flightIndex]);

      this.date1 =this.fromDateBinding[0].getTime();
      this.date2 =this.fromDateBinding[1].getTime();
      

      if(flightIndex == 0){
        this.date1 = this.fromDateBinding[flightIndex].getTime(); // convert date to number
        this.multiCityCalenderTrigger_0.nativeElement.click();
        if(this.date2 < this.date1){
          this.alertDate=true;
        }
        else{
          this.alertDate=false;
        }
      }
      else if(flightIndex == 1){
        this.date2 = this.fromDateBinding[flightIndex].getTime(); 
        this.multiCityCalenderTrigger_1.nativeElement.click();
        if(this.date2 < this.date1 || this.date3 < this.date2){
          this.alertDate=true
        }
        else{
          this.alertDate=false
        }

      }
      else if(flightIndex == 2){
        this.date3 = this.fromDateBinding[flightIndex].getTime();
        this.multiCityCalenderTrigger_2.nativeElement.click();
        if(this.date3 < this.date2 || this.date4 < this.date3){
          this.alertDate=true
        }
        else{
          this.alertDate=false
        }
      }
      else{
          this.date4 = this.fromDateBinding[flightIndex].getTime();
        this.multiCityCalenderTrigger_3.nativeElement.click();
        if(this.date4 < this.date3){
          this.alertDate=true
        }
        else{
          this.alertDate=false
        }
      }
      // if(this.fromDateBinding[flightIndex].getTime() > this.fromDateBinding[flightIndex+1].getTime()){
      //   this.alertDate=true;
      // }
      // else{
      //   this.alertDate=false;
      // }
  }
  onDateSelection(date: NgbDate, flightIndex: number) {
    //when dep & return have value
    if(this.fromDate && this.toDate){
this.toDate = date;

this.roundTripCalenderTrigger.nativeElement.click();
    }
    //
    else if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
      // this.datepicker.close();

    } 
    else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    }

    else {
      this.toDate = null;
      this.fromDate = date;

    }

    this.fromDateBinding[flightIndex] = new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day);
    if (this.toDate) {
      this.toDateBinding = new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day);
    }

    (<FormArray>this.searchFlight.get("Flights")).at(flightIndex).get("departingD").setValue(this.fromDateBinding[flightIndex]);
    this.searchFlight.get("returnDate").setValue(this.toDateBinding);

  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }
  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  changeAdultValue(type: string) {
    this.adultAlert = false;
    this.maxPassangers = false;
    var oldVal = this.searchFlight.get("passengers").get("adults").value;
    if (type == "decrease") {
      if ((oldVal - 1) == 0) {
        this.adultAlert = true
        return
      }
      this.searchFlight.get("passengers").get("adults").setValue(oldVal - 1);
      this.searchFlight.get("passengers").get("adults").updateValueAndValidity();
    } 
    else {
      if (((this.searchFlight.get('passengers').get('adults').value + this.searchFlight.get('passengers').get('child').value + this.searchFlight.get('passengers').get('infent').value) + 1) > 9) {
        this.maxPassangers = true
        return;
      }
      this.searchFlight.get("passengers").get("adults").setValue(oldVal + 1);
      this.searchFlight.get("passengers").get("adults").updateValueAndValidity();
    }
  }

  changeChildValue(type: string) {
    this.maxPassangers = false;
    var oldVal = this.searchFlight.get("passengers").get("child").value;
    if (type == "decrease") {
      if ((oldVal - 1) < 0) {
        return
      }
      this.searchFlight.get("passengers").get("child").setValue(oldVal - 1);
      this.searchFlight.get("passengers").get("child").updateValueAndValidity();
    } else {
      if (((this.searchFlight.get('passengers').get('adults').value + this.searchFlight.get('passengers').get('child').value + this.searchFlight.get('passengers').get('infent').value) + 1) > 9) {
        this.maxPassangers = true
        return;
      }
      this.searchFlight.get("passengers").get("child").setValue(oldVal + 1);
      this.searchFlight.get("passengers").get("child").updateValueAndValidity();
    }
  }

  changeInfantValue(type: string) {
    this.infentMax = false;
    this.maxPassangers = false;
    var oldVal = this.searchFlight.get("passengers").get("infent").value;
    if (type == "decrease") {
      if ((oldVal - 1) < 0) {
        return
      }
      this.searchFlight.get("passengers").get("infent").setValue(oldVal - 1);
      this.searchFlight.get("passengers").get("infent").updateValueAndValidity();
    } else {
      if (((this.searchFlight.get('passengers').get('adults').value + this.searchFlight.get('passengers').get('child').value + this.searchFlight.get('passengers').get('infent').value) + 1) > 9) {
        this.maxPassangers = true
        return;
      }
      else if ((this.searchFlight.get('passengers').get('infent').value) + 1 > this.searchFlight.get('passengers').get('adults').value) {
        this.infentMax = true
        return
      }
      this.searchFlight.get("passengers").get("infent").setValue(oldVal + 1);
      this.searchFlight.get("passengers").get("infent").updateValueAndValidity();
    }
  }

  /**
   * change the selected airports/cities according to the app current languague
   * @param lang app current languague
   */
  
  updateAirportValues(lang: string) {

    let arrr = (<FormArray>this.searchFlight.get('Flights'))
    if (arrr) {
      if (lang == "ar") {
        for (var i = 0; i < (<FormArray>this.searchFlight.get('Flights')).length; i++) {
          let depart = (<FormArray>this.searchFlight.get('Flights')).at(i).get('departing').value;
          let landing = (<FormArray>this.searchFlight.get('Flights')).at(i).get('landing').value;
          if (depart != '' && landing != '') {
            let dCity = this.citiesar.filter(v => v.airportCode === this.extractAirportCode(depart))[0];
            let lCity = this.citiesar.filter(v => v.airportCode === this.extractAirportCode(landing))[0];
            { (<FormArray>this.searchFlight.get('Flights')).at(i).get('departing').setValue(dCity.cityName + '(' + dCity.airportCode + ')') };
            { (<FormArray>this.searchFlight.get('Flights')).at(i).get('landing').setValue(lCity.cityName + '(' + lCity.airportCode + ')') };
          }
          else {
            return
          }
        }
      } else {
        this.cities = this.citiesen;
        this.citiesNameExtract(this.citiesen);
        for (var i = 0; i < (<FormArray>this.searchFlight.get('Flights')).length; i++) {
          let depart = (<FormArray>this.searchFlight.get('Flights')).at(i).get('departing').value;
          let landing = (<FormArray>this.searchFlight.get('Flights')).at(i).get('landing').value;
          if (depart != '' && landing != '') {
            let dCity = this.citiesen.filter(v => v.airportCode === this.extractAirportCode(depart))[0];
            let lCity = this.citiesen.filter(v => v.airportCode === this.extractAirportCode(landing))[0];
            { (<FormArray>this.searchFlight.get('Flights')).at(i).get('departing').setValue(dCity.cityName + '(' + dCity.airportCode + ')') };
            { (<FormArray>this.searchFlight.get('Flights')).at(i).get('landing').setValue(lCity.cityName + '(' + lCity.airportCode + ')') };
          } else {
            return
          }
        }
      }
    }
  }

  /**
  * extract only the airport code from the slected airport format
  */
  extractAirportCode(val: string) {
    var splice = val.split('(');
    if (splice[1]) {
      var spliceAgain = splice[1].split(')')
      return spliceAgain[0];
    }
    else { return '' }

  }

  calendersBinding() {
    for (let i = 0; i < (<FormArray>this.searchFlight.get('Flights')).length; i++) {
      var flightDate = new Date((<FormArray>this.searchFlight.get('Flights')).at(i).get('departingD').value)
      var fromCalenderDate = new NgbDate(flightDate.getUTCFullYear(), flightDate.getMonth() + 1, flightDate.getDate() - 1)
       if(i ==0){
        this.firstDate = fromCalenderDate;
       }
      this.fromDateBinding.push(new Date(flightDate.getUTCFullYear(), flightDate.getMonth(), flightDate.getDate()));
      // this.fromDate = this.calendar.getNext(fromCalenderDate);
    }
    var returnDate = new Date(this.searchFlight.get('returnDate').value)
    var returnCalenderDate = new NgbDate(flightDate.getUTCFullYear(), flightDate.getMonth() + 1, flightDate.getDate() - 1)

    this.toDateBinding = new Date(returnDate.getUTCFullYear(), returnDate.getMonth(), returnDate.getDate());
    // this.toDate = this.calendar.getNext(returnCalenderDate);
    if(this.flightType.value ==='RoundTrip'){
      let D1:Date = this.fromDateBinding[0];
      let D2:Date = this.toDateBinding;
      let deff = (D2.getTime() - D1.getTime())/(1000*60*60*24)
      this.fromDate = this.calendar.getNext(this.firstDate);
      this.toDate = this.calendar.getNext(this.fromDate,'d',(deff))
    }
  

  }

  flightLangMapper(citiesArray: CitiesModule[]) {
    this.flightsArray.controls.forEach(element => {
      if (element.get('departing')?.value && element.get('departing')?.value != '') {
        let code: string = (<string>element.get('departing')?.value).split('(')[1].split(')')[0];
        let newAirport = citiesArray.filter((v) => v.airportCode == code)[0];
        let newValue = newAirport.cityName + "(" + newAirport.airportCode + ")";
        element.get('departing')?.setValue(newValue);
      }

      if (element.get('landing')?.value && element.get('landing')?.value != '') {
        let code = (<string>element.get('landing')?.value).split('(')[1].split(')')[0];
        let newAirport = citiesArray.filter((v) => v.airportCode == code)[0];
        let newValue = newAirport.cityName + "(" + newAirport.airportCode + ")";
        element.get('landing')?.setValue(newValue);
      }
      return
    });
  }
  getFormattedDate(date: Date,i:number): string {
    const day = date.getDate();
   
    const month = date.getMonth();
    const year = date.getFullYear();

    // Translate month and day names
    if(this.translate.currentLang=="en"){
    let translatedMonth = this.translate.instant(`datepicker.monthNames.${month}`).slice(0,3);
    let translatedDay = this.translate.instant(`datepicker.dayNames.${date.getDay()}`).slice(0,3);
    
    return `${day} ${translatedMonth}, ${translatedDay}`;
  }
    else {
      let translatedMonth = this.translate.instant(`datepicker.monthNames.${month}`);
      let translatedDay = this.translate.instant(`datepicker.dayNames.${date.getDay()}`);
    
      return `${day} ${translatedMonth}, ${translatedDay}`;

    }
    
  }

  // unsubscribe
  validateDate(){
    if(this.localform){
      if(this.fromDateBinding[0].getTime() > this.fromDateBinding[1].getTime()){
        this.alertDate=true;
      }
      else{
        this.alertDate=false;
      }
    }
    else{
      this.searchFlight.get("Flights")?.get("1")?.get("departingD").setValue(this.fromDateBinding[1])
      if(this.fromDateBinding[0].getTime() > this.fromDateBinding[1].getTime()){
        this.alertDate=true;
      }
      else{
        this.alertDate=false;
      }
    }
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}