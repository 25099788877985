<div class="wrapper" [ngClass]="{ar: translate.currentLang =='ar'}">
  <div class="container pb-4">
    <div class="d-flex justify-content-center text-center buttonslist">
      <div class="" [ngClass]="{ selected: toggleHF == 'flight' }">
        <button   routerLinkActive="router-link-active" class="btn listbutton" [routerLink]="['/home']" 
          (click)="switchHF('flight')">
    
          {{"header.flight"|translate}}   
        </button>
    
      </div>
      <div class="" [ngClass]="{ selected: toggleHF == 'hotel' }">
        <button [routerLink]="['/home']" routerLinkActive="router-link-active" class="btn listbutton"
          (click)="switchHF('hotel')" id="hotels">
          {{"header.hotels"|translate}} 
        </button>
    
      </div>
      <div class="" [ngClass]="{ selected: toggleHF == 'packages' }">
        <button  [routerLink]="['/home']" routerLinkActive="router-link-active" class="btn listbutton"
           (click)="switchHF('packages')" id="packages">
          {{"header.packages"|translate}} 
        </button>
    
      </div>
    </div>
  </div>

</div>
