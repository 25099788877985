<div class="container">
    <div class="row justify-content-center align-items-center">
        <div class="  col-12 col-12 col-md-12 ">
            <div class="col-12 text-center">
                <h4>{{'user.useracount'|translate}}</h4>
            </div>

            <div style="min-height: fit-content card-body">
                <form [formGroup]="editForm" class="row justify-content-center" (ngSubmit)="onSubmit()">
                    <div class="col-12 col-md-3">
                        <mat-form-field color="accent" appearance="outline">
                            <i class="fas fa-user-tag feildicon" matPrefix></i>
                            <input matInput placeholder="First name" required formControlName='FirstName' type="name">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-4">
                        <mat-form-field color="accent" appearance="outline">
                            <!-- <i class="fas fa-user feildicon" matPrefix></i> -->
                            <input matInput placeholder="Last name" required formControlName='LastName' type="name">
                        </mat-form-field>
                    </div>
                    <div class="col-12 col-md-7">
                        <mat-form-field color="accent" appearance="outline">
                            <i class="fas fa-user feildicon" matPrefix></i>
                            <input matInput placeholder="Name" required formControlName='User_Name' type="email">
                        </mat-form-field>
                    </div>

                    <!-- <div class="col-12 col-md-7">
                <mat-form-field color="accent" appearance="outline">
                  <i class="fas fa-envelope feildicon" matPrefix></i>
                  <input matInput placeholder="email Address" required formControlName='Email' type="email"
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$">
                </mat-form-field>
              </div> -->
                    <div class="col-12 col-md-7">
                        <mat-form-field color="accent" appearance="outline">
                            <i class="fas fa-phone feildicon" matPrefix></i>
                            <input matInput placeholder="Phone" required formControlName='PhoneNumber' type="">
                        </mat-form-field>
                    </div>


                    <div class=" col-12 col-md-7 text-center">
                        <button class="fouchiaColor" mat-raised-button click='onSubmit'>{{'user.Save'|translate}}</button>
                    </div>

                </form>
            </div>
        </div>
    </div>
    <ng-container *ngIf="preloader">
        <ng-container *ngTemplateOutlet="loadingkst"></ng-container>
    </ng-container>
</div>